import dayjs from "dayjs";
import { IUser } from "../../types/Auth/user";
import { ITraceEntry } from "../../types/Inventory";
import { IGoodsIssue } from "../../types/Inventory/goodsIssue";
import {
  InventoryDocumentStatus,
  InventoryDocumentType,
  ItemSkuQtysQuery,
  Tracability,
  WarehouseBinLocation,
} from "../../generated/inventory";
import { v4 as uuidv4 } from "uuid";

export const goodsIssueCreatePayloadFormatter = (
  data: IGoodsIssue,
  submit: boolean
) => {
  const {
    created_date,
    main_status,
    aggrid_status,
    trace_entry_list,
    ...otherPayload
  } = data;

  // Helper function to format trace entries
  const formatTraceEntry = ({
    serial_list,
    uom,
    source_bin_location,
    destination_bin_location,
    all_bin_locations,
    ...trace
  }: ITraceEntry): ITraceEntry[] => {
    if (serial_list?.length) {
      return serial_list.map(
        ({
          serial_list,
          uom,
          source_bin_location,
          destination_bin_location,
          all_bin_locations,
          ...serial
        }) => ({
          ...serial,
          qty: Number(serial.qty) || 0,
          document_item_qty: trace.document_item_qty,
          posted_qty: trace.posted_qty,
          uom_unique_id: uom?.unique_id,
          status: submit ? InventoryDocumentStatus.IsScan : undefined,
        })
      );
    }

    return [
      {
        ...trace,
        qty: Number(trace.qty) || 0,
        uom_unique_id: uom?.unique_id,
        status: submit ? InventoryDocumentStatus.IsScan : undefined,
      },
    ];
  };

  // Flatten all formatted trace entries
  const allTraceEntries: ITraceEntry[] =
    trace_entry_list.flatMap(formatTraceEntry);

  // Merge duplicate items by summing their qty
  const mergedTraceEntries = allTraceEntries.reduce<ITraceEntry[]>(
    (acc, entry) => {
      const existingItem = acc.find(
        (item) => item.item_unique_id === entry.item_unique_id
      );

      if (existingItem) {
        existingItem.qty += entry.qty;
      } else {
        acc.push({ ...entry });
      }

      return acc;
    },
    []
  );

  // Find exceeded stock items
  const exceededItems = mergedTraceEntries.filter(
    (item) => item.qty > (item.stock_qty || 0)
  );

  // If any items exceed stock, return details
  if (exceededItems.length > 0)
    return `${exceededItems
      .map(({ item_unique_id }) => item_unique_id)
      .join(",")} เกินจำนวนคงคลัง`;

  // Remove stock_qty from final payload
  return {
    ...otherPayload,
    trace_entry_list: allTraceEntries.map(({ stock_qty, ...entry }) => entry),
  };
};

const groupByCompositeKey = (xs: any[], key1: string, key2: string) => {
  return xs.reduce((rv: Record<string, ITraceEntry[]>, x) => {
    const compositeKey = `${x[key1]}_${
      x[key2]?.line_item_unique_id || "no_ref"
    }`;
    (rv[compositeKey] = rv[compositeKey] || []).push(x);
    return rv;
  }, {});
};

export const goodsIssueQueryFormatter = (
  data: IGoodsIssue,
  allSkuQtys?: ItemSkuQtysQuery
) => {
  if (allSkuQtys?.itemSkuQtys) {
    const { trace_entry_list, ...otherData } = data;

    // Explicitly define groupedTrace type
    const groupedTrace: Record<string, ITraceEntry[]> = groupByCompositeKey(
      trace_entry_list,
      "item_unique_id",
      "reference_line_item"
    );

    const allTraceEntries: ITraceEntry[] = [];
    for (const [, value] of Object.entries(groupedTrace)) {
      const foundAllSku = allSkuQtys.itemSkuQtys.filter(
        (sku) => sku?.barcode === value[0].barcode
      );

      const allBinLocation = foundAllSku.map((sku) => ({
        id: sku?.bin_id,
        bin_name: sku?.bin_location?.bin_name,
        stock_qty: sku?.stock_qty,
        warehouse_unique_id:
          sku?.warehouse_level_3?.sub_level_2?.sub_level_1?.warehouse_unique_id,
      }));

      const filterBinLocations = allBinLocation.filter(
        (bin) =>
          bin.warehouse_unique_id === otherData.source_warehouse_unique_id
      );

      const foundBinLocation = filterBinLocations.find(
        (bin) => bin.id === value[0].source_bin_location_id
      );

      const formatSerial = value
        .map((serial) => ({
          ...serial,
          posted_date: serial.posted_date ?? undefined,
          scanned_by: serial.scanned_by ?? undefined,
          all_bin_locations: filterBinLocations,
          stock_qty: foundBinLocation?.stock_qty,
        }))
        .filter((list) => list.scanned_by);

      const formatTrace: ITraceEntry = {
        item_unique_id: value[0].item_unique_id,
        reference_line_item:
          value[0].tracability === Tracability.Normal
            ? value[0].reference_line_item ?? undefined
            : undefined,
        item_name: value[0].item_name,
        item_img_url: value[0].item_img_url,
        tracability: value[0].tracability,
        type: value[0].type,
        unique_id: value[0].unique_id,
        qty: value.reduce((prev, curr) => prev + curr.qty, 0),
        posted_qty: value[0].posted_qty,
        document_item_qty: value[0].document_item_qty,
        posted_date:
          value[0].tracability === Tracability.Normal
            ? value[0].posted_date
            : undefined,
        created_date: value[0].created_date,
        uom: value[0].uom,
        source_bin_location:
          value[0].tracability === Tracability.Normal
            ? value[0].source_bin_location
            : undefined,
        source_bin_location_id:
          value[0].tracability === Tracability.Normal
            ? value[0].source_bin_location_id
            : undefined,
        barcode:
          value[0].tracability === Tracability.Normal
            ? value[0].barcode
            : undefined,
        serial_list: value.some(
          (serial) => serial.tracability === Tracability.Normal
        )
          ? undefined
          : formatSerial,
        status:
          value[0].tracability === Tracability.Normal
            ? value[0].status
            : undefined,
        scanned_by:
          value[0].tracability === Tracability.Normal
            ? value[0].scanned_by ?? undefined
            : undefined,
        all_bin_locations: value[0].scanned_by ? filterBinLocations : [],
        stock_qty: foundBinLocation?.stock_qty,
        reference_unique_id: value[0].reference_unique_id,
      };
      allTraceEntries.push(formatTrace);
    }

    return {
      ...otherData,
      trace_entry_list: allTraceEntries,
    };
  }
};

export const goodsIssueTraceEntryFormatter = (
  trace: ITraceEntry,
  binLocations: (WarehouseBinLocation & {
    warehouse_unique_id: string;
    stock_qty?: number;
  })[],
  user: IUser | null
) => {
  const {
    type,
    posted_date,
    created_date,
    scanned_by,
    unique_id,
    ...otherPayload
  } = trace;

  if (user) {
    const formatTrace: ITraceEntry = {
      ...otherPayload,
      qty: 0,
      source_bin_location: binLocations?.[0],
      source_bin_location_id: binLocations?.[0]?.id,
      all_bin_locations: binLocations,
      unique_id: uuidv4(),
      type: InventoryDocumentType.GoodsIssue,
      scanned_by: {
        user_unique_id: user.unique_id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        img_url: user.img_url,
      },
      posted_date: dayjs().toDate(),
      stock_qty: binLocations?.[0]?.stock_qty,
      status: InventoryDocumentStatus.IsScan,
    };
    return formatTrace;
  }
  return;
};

export const goodsIssueUpdatePayloadFormatter = (data: IGoodsIssue) => {
  const {
    created_date,
    unique_id,
    main_status,
    aggrid_status,
    source_warehouse,
    destination_warehouse,
    trace_entry_list,
    created_by,
    ...otherPayload
  } = data;
  let allTraceEntries: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            uom,
            created_date,
            source_bin_location,
            destination_bin_location,
            all_bin_locations,
            stock_qty,
            reference_line_item,
            ...serial
          }) => ({
            ...serial,
            qty:
              typeof serial.qty === "string"
                ? parseFloat(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            posted_qty: trace.posted_qty,
            uom_unique_id: uom?.unique_id,
            status: InventoryDocumentStatus.IsScan,
            reference_line_item: reference_line_item ?? undefined,
          })
        );
        allTraceEntries.push(...formatSerialList);
      } else {
        const {
          serial_list,
          uom,
          source_bin_location,
          destination_bin_location,
          created_date,
          all_bin_locations,
          stock_qty,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          qty:
            typeof otherTrace.qty === "string"
              ? parseFloat(otherTrace.qty)
              : otherTrace.qty,
          uom_unique_id: uom?.unique_id,
          status: InventoryDocumentStatus.IsScan,
        };
        allTraceEntries.push(formatTrace);
      }
    } else {
      allTraceEntries.push({
        ...trace,
        status: InventoryDocumentStatus.IsScan,
      });
    }
  });
  const payload = {
    ...otherPayload,
    trace_entry_list: allTraceEntries,
  };
  return payload;
};

export const goodsIssueTypeValueFormatter = (type: string) => {
  switch (type) {
    case "sales_order":
      return "ขาย";
    case "manufacture_order":
      return "ผลิต";
    case "purchase_return":
      return "ส่งคืน";
    case "other":
      return "อื่นๆ";
    default:
      return "";
  }
};
