import React from "react";
import { ITask } from "../../../types/Project";
import { Box, Grid, Typography } from "@mui/material";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "@hello-pangea/dnd";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import CustomizedChips from "../../Custom/CustomizedChips";
import { formatDate } from "../../../utils/Formatter/Date";
import { useTranslation } from "react-i18next";
import CustomizedCheckbox from "../../Custom/CustomizedCheckbox";
import dayjs from "dayjs";

type Props = {
  task: ITask;
  index: number;
  statusChangeHandler: (task: ITask) => void;
  onClick: () => void;
};

const TaskItem = ({ task, index, statusChangeHandler, onClick }: Props) => {
  const { t } = useTranslation();
  const renderChipColor = () => {
    if (task.status === 0) {
      if (task.due_date) {
        if (dayjs().isAfter(task.due_date, "day")) return "#DD2C00";
        return "#2167D3";
      }
      return "#2167D3";
    } else {
      return "#8F8F8F";
    }
  };

  return (
    <Draggable key={task.id} draggableId={task.id} index={index}>
      {(
        providedDraggable: DraggableProvided,
        snapshotDraggable: DraggableStateSnapshot
      ) => (
        <Box
          onClick={(e) => {
            onClick();
          }}
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
          {...providedDraggable.dragHandleProps}
          sx={{
            userSelect: "none",
            background: "#FFFFFF",
            padding: "5px",
            display: "flex",
            flexDirection: "column",
            boxShadow: snapshotDraggable.isDragging
              ? "0px 2px 2px 0px #AEAEAE33,  0px 2px 2px 0px #00000040"
              : "none",

            ...providedDraggable.draggableProps.style,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <CustomizedCheckbox
              value={task.status === 1}
              onClick={() => statusChangeHandler(task)}
            />
            <Typography
              sx={{
                color: task.status === 0 ? "#333333" : "#8F8F8F",
                textDecoration: task.status === 1 ? "line-through" : "none",
              }}
            >
              {task.name}
            </Typography>
          </Box>
          <Grid
            container
            direction={"row"}
            // spacing={2}
            alignItems={"center"}
            ml={5}
          >
            <Grid item mr={1}>
              {task.due_date && (
                <CustomizedChips
                  sx={{
                    borderColor: renderChipColor(),
                    color: renderChipColor(),
                  }}
                  value={`${t("project.task.due_date")} : ${formatDate(
                    dayjs(task.due_date).toDate()
                  )}`}
                />
              )}
            </Grid>
            <Grid item>
              {task.related_user_list && task.related_user_list?.length > 0 ? (
                <CustomizedAvatar
                  avatars={task.related_user_list?.map((user) => {
                    return {
                      unique_id: user.user_unique_id ?? "",
                      first_name: user.first_name ?? "",
                      last_name: user.last_name ?? "",
                      img_url: user.img_url ? user.img_url[0] : "",
                    };
                  })}
                />
              ) : null}
            </Grid>
          </Grid>
        </Box>
      )}
    </Draggable>
  );
};

export default TaskItem;
