import { useCallback, useEffect } from "react";
import { IDefaultForm } from "../../../../types/global";
import { useWatch } from "react-hook-form";
import { Typography } from "@mui/material";
import { formatNumber } from "../../../../utils/dataTransformer";
import { ISalesItemList } from "../../../../types/Sales";
import { PriceVatType } from "../../../../generated/sales";

interface Props {
  control: IDefaultForm["control"];
  index: number;
  salesBomIndex: number;
  disabled: IDefaultForm["disabled"];
  setValue: IDefaultForm["setValue"];
  field: ISalesItemList;
}

const SalesBomPreVatAmountCell = ({
  control,
  field,
  index,
  salesBomIndex,
  disabled,
  setValue,
}: Props) => {
  const qty =
    useWatch({
      control,
      name: `item_list.${index}.sales_bom_item.${salesBomIndex}.qty`,
    }) || 0;

  const pricePerUnit = useWatch({
    control,
    name: `item_list.${index}.sales_bom_item.${salesBomIndex}.price_per_unit`,
  });

  const discount = useWatch({
    control,
    name: `item_list.${index}.sales_bom_item.${salesBomIndex}.discount`,
  });

  const discountType = useWatch({
    control,
    name: `item_list.${index}.sales_bom_item.${salesBomIndex}.discount_type`,
  });

  const vatPercentage = useWatch({
    control,
    name: `item_list.${index}.sales_bom_item.${salesBomIndex}.vat_percentage`,
  });

  const priceVatType = useWatch({
    control,
    name: `price_vat_type`,
  });

  const calculation = useCallback(
    (
      qty: number,
      price_per_unit: number,
      discount: number,
      discount_type: string
    ) => {
      const discountAmount =
        discount_type === "percent"
          ? price_per_unit * (discount / 100)
          : discount;
      return qty * (price_per_unit - discountAmount);
    },
    []
  );

  const newPrevat = useCallback(
    (
      qty: number,
      price_per_unit: number,
      discount: number,
      discount_type: string,
      vat_percentage: string | number
    ) => {
      const baseAmount = calculation(
        qty,
        price_per_unit,
        discount,
        discount_type
      );

      if (priceVatType !== PriceVatType.IncludedVat) {
        return baseAmount;
      }

      if (vat_percentage === "7" || vat_percentage === 7) {
        // Subtract 7% VAT from the base amount
        return baseAmount / 1.07;
      } else {
        // No VAT, return the base amount
        return baseAmount;
      }
    },
    [calculation, priceVatType]
  );

  useEffect(() => {
    const preVatAmount = calculation(qty, pricePerUnit, discount, discountType);
    const preDiscountAmount = newPrevat(
      qty,
      pricePerUnit,
      discount,
      discountType,
      vatPercentage
    );

    setValue(
      `item_list.${index}.sales_bom_item.${salesBomIndex}.pre_vat_amount`,
      preVatAmount
    );
    setValue(
      `item_list.${index}.sales_bom_item.${salesBomIndex}.pre_discount_amount`,
      preDiscountAmount
    );
  }, [
    salesBomIndex,
    newPrevat,
    calculation,
    discount,
    index,
    pricePerUnit,
    qty,
    discountType,
    vatPercentage,
    setValue,
    priceVatType,
  ]);

  if (disabled) {
    return (
      <Typography fontSize={14}>
        {formatNumber(field.pre_discount_amount || field.pre_vat_amount)}
      </Typography>
    );
  }

  return (
    <Typography fontSize={14}>
      {formatNumber(
        newPrevat(qty, pricePerUnit, discount, discountType, vatPercentage)
      )}
    </Typography>
  );
};

export default SalesBomPreVatAmountCell;
