import { useState } from "react";
import { TextFieldProps, IconButton, InputAdornment } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomizedTextField from "../Custom/CustomizedTextField";

type PinCodeFieldProps = {
  name: string;
  label?: TextFieldProps["label"];
  control: Control<any>;
  error?: TextFieldProps["error"];
  helperText?: TextFieldProps["helperText"];
  required?: TextFieldProps["required"];
  disabled?: TextFieldProps["disabled"];
  testId?: string;
  autoFocus?: TextFieldProps["autoFocus"];
  sx?: TextFieldProps["sx"];
};

const ControlledPinCodeField = ({
  name,
  label,
  control,
  error,
  helperText,
  required,
  disabled,
  testId,
  autoFocus,
  sx,
}: PinCodeFieldProps) => {
  const [showPin, setShowPin] = useState(false);

  const toggleShowPin = () => setShowPin((prev) => !prev);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedTextField
          {...field}
          label={label}
          type={showPin ? "text" : "password"}
          error={error}
          helperText={helperText}
          required={required}
          disabled={disabled}
          testId={testId}
          autoFocus={autoFocus}
          sx={sx}
          inputProps={{
            maxLength: 4,
            pattern: "\\d*",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowPin} edge="end">
                  {showPin ? (
                    <VisibilityOff sx={{ fontSize: "16px" }} />
                  ) : (
                    <Visibility sx={{ fontSize: "16px" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, "").slice(0, 4);
            field.onChange(value);
          }}
        />
      )}
    />
  );
};

export default ControlledPinCodeField;
