import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatNumber } from "../../../utils/dataTransformer";
import { IHeaderTable } from "../../../types/global";

interface Props {
  data: any;
  start_no: number;
  type: string | undefined | null;
}

const GoodsIssuePDFTable = ({ data, start_no, type }: Props) => {
  const header: IHeaderTable[] = [
    {
      label: "No.",
      thaiLabel: " ",
      width: 20,
      align: "left",
    },
    {
      label: "Name",
      thaiLabel: "รายการ",
      width: 180,
      align: "left",
    },
    {
      label: "Issued Qty",
      thaiLabel: "จำนวนนำออก",
      width: 100,
      align: "right",
    },
    {
      label: "Bin Location",
      thaiLabel: "สถานที่",
      width: 180,
      align: "left",
    },
    {
      label: "Barcode",
      thaiLabel: "Barcode",
      width: 180,
      align: "left",
    },
  ];

  const renderTableBody = () => {
    return (
      <TableBody>
        {data &&
          data.map((item: any, index: number) => (
            <TableRow
              key={item.item_unique_id}
              sx={{
                breakInside: "avoid",
                height: "50px",
                verticalAlign: "top",
              }}
            >
              <TableCell align="center">
                <Typography fontSize={11}>{start_no + index}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  maxWidth: "295px",
                }}
              >
                <Typography
                  fontSize={11}
                  fontWeight={600}
                  sx={{ wordBreak: "break-word" }}
                >
                  {item.item_unique_id} - {item.item_name}
                </Typography>
                <Typography
                  fontSize={11}
                  sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                >
                  {item.item_sku_desc}
                </Typography>
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  "&.MuiTableCell-root": {
                    pt: 1,
                    px: 0,
                  },
                }}
              >
                <Typography fontSize={11}>
                  {formatNumber(item.qty ?? 0)}
                </Typography>
                <Typography fontSize={9} color="#737373">
                  {typeof item.uom === "string" ? item.uom : item.uom?.name}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  fontSize={11}
                  ml={1}
                  sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                >
                  {item.source_bin_location?.bin_name}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  fontSize={11}
                  ml={1}
                  sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                >
                  {item.barcode}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    );
  };

  return (
    <TableContainer
      sx={{
        minHeight: "480px",
        maxHeight: "850px",
      }}
    >
      <Table
        aria-label="goods issue item table"
        sx={{ tableLayout: "fixed", width: "100%" }}
      >
        <TableHead>
          <TableRow>
            {header.map((head, index) => (
              <TableCell
                align={head.align}
                key={index}
                sx={{
                  pr: head.label === "Name" ? "" : 0,
                  pl: head.label === "Name" ? 1 : "",
                  py: 1,
                  backgroundColor: "#ECF6FD",
                  borderTopLeftRadius: index === 0 ? 4 : 0,
                  borderBottomLeftRadius: index === 0 ? 4 : 0,
                  borderTopRightRadius: index === header.length - 1 ? 4 : 0,
                  borderBottomRightRadius: index === header.length - 1 ? 4 : 0,
                  borderBottom: "none",
                }}
                width={`${head.width} px`}
              >
                <Typography
                  fontSize={11}
                  fontWeight={600}
                  textAlign={head.align}
                >
                  {head.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {renderTableBody()}
      </Table>
    </TableContainer>
  );
};

export default GoodsIssuePDFTable;
