import { useCallback, useState } from "react";

export const useDebouncedCallback = (
  callback: (data: any) => void,
  delay: number
) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  return useCallback(
    (data: any) => {
      if (timer) clearTimeout(timer);
      const newTimer = setTimeout(() => {
        callback(data);
      }, delay);

      setTimer(newTimer);
    },
    [callback, delay, timer]
  );
};
