import { gql } from "graphql-request";

export const GENERATE_EXPORT = gql`
  query GenerateExport($input: ExportGenerateInput) {
    GenerateExport(input: $input)
  }
`;

export const GET_MANUFACTURE_ORDER_REPORT_VIEW = gql`
  query ManufactureOrderExportViewsAggrid($aggridInput: AnyAggridInput) {
    ManufactureOrderExportViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        unique_id
        issue_date
        aggrid_status
        reference_unique_ids
        external_reference_id
        production_date
        production_completion_date
        delivery_date
        customer_contact_unique_id
        customer_name
        item_unique_id
        item_name
        production_qty
        actual_production_qty
        goods_receive_qty
        uom
        item_description
        item_remark
        prod_type
        bom_name
        mt_name
        routing_name
        other_manufacture_cost
        work_order_cost
        bom_cost
        total_cost
        internal_remark
        created_date
        created_by
        related_users
        attachment_urls
      }
    }
  }
`;

export const GET_MANUFACTURE_PROCESS_REPORT_VIEW = gql`
  query ManufactureProcessExportViewsAggrid($aggridInput: AnyAggridInput) {
    ManufactureProcessExportViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        unique_id
        issue_date
        status
        reference_unique_ids
        external_reference_id
        production_date
        production_completion_date
        delivery_date
        customer_contact_unique_id
        customer_name
        item_unique_id
        item_name
        production_qty
        actual_production_qty
        goods_receive_qty
        uom
        item_description
        item_remark
        bom_name
        routing_name
        mfg_type
        group
        other_manufacture_cost
        work_order_cost
        bom_cost
        total_cost
        goods_remark
        created_date
        created_by
        related_users
        attachment_urls
        order
        step_status
        responsible_users
        work_order_id
        name
        factory
        production_center
        duration
        duration_unit
        cost_price
        started_date
        finished_date
        remark
      }
    }
  }
`;

export const GET_MANUFACTURE_INGREDIENT_REPORT_VIEW = gql`
  query ManufactureIngredientExportViewsAggrid($aggridInput: AnyAggridInput) {
    ManufactureIngredientExportViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        ingredient_order_id
        unique_id
        issue_date
        aggrid_status
        reference_unique_ids
        external_reference_id
        production_date
        production_completion_date
        delivery_date
        customer_contact_unique_id
        customer_name
        mo_item_unique_id
        mo_item_name
        production_qty
        actual_production_qty
        goods_receive_qty
        mo_uom
        mo_item_description
        remark
        bom_name
        routing_name
        mfg_type
        group
        other_manufacture_cost
        work_order_cost
        bom_cost
        total_cost
        item_remark
        created_date
        created_by
        related_users
        attachment_urls
        rank
        item_unique_id
        item_name
        item_description
        qty
        cost_per_unit
        good_issue_qty
        moi_uom
      }
    }
  }
`;

export const WASTE_MANUFACTURE_ORDER_REPORT = gql`
  query Query($aggridInput: AggridTemplateInput, $dateFilter: DateFilter) {
    WasteManufactureOrderReport(aggridInput: $aggridInput, dateFilter: $dateFilter) {
      count
      data {
        unique_id
        issue_date
        aggrid_status
        reference_unique_id_list
        external_reference_id
        production_date
        production_completion_date
        delivery_date
        customer_contact_unique_id
        customer_name
        item_unique_id
        item_name
        production_qty
        actual_production_qty
        goods_receive_qty
        uom
        item_description
        item_remark
        bom_name
        routing_name
        type
        tag_list
        other_manufacture_cost
        work_order_cost
        bom_cost
        total_cost
        remark
        created_date
        created_by
        related_user
        related_document
        waste_order
        waste_item_unique_id
        waste_item_name
        waste_qty
        waste_uom
        waste_remark
      }
    }
  }
`