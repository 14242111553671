import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, CircularProgress, Divider, Typography } from "@mui/material";

import { GraphQLClient } from "graphql-request";
import { formatDate } from "../../../utils/Formatter/Date";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  ManufactureOrderQuery,
  useManufactureOrderQuery,
} from "../../../generated/manufacture";
import { IIngredient } from "../../../types/Manufacture";
import { IWorkOrder } from "../../../types/Manufacture/workOrder";
import PDFWrapper from "../../../components/UI/Pdf/PDFWrapper";
import { useReactToPrint } from "react-to-print";
import { ReactInstance, useRef } from "react";
import ManufactureOrderPDFLayout from "../../../components/UI/Pdf/ManufacturePDF/ManufactureOrderPDF/PDFLayout";
import ManufactureOrderIngredientPdfTable from "../../../components/Table/Pdf/ManufactureOrderIngredientPdfTable";
import ManfactureOrderWorkOrderPdfTable from "../../../components/Table/Pdf/ManfactureOrderWorkOrderPdfTable";
import usePDFMultiplePage from "../../../hooks/Manufacture/use-manufacture-pdf-multipage";

const ManufactureOrderPDF = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const componentRef = useRef<ReactInstance | null>(null);

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { data, isLoading } = useManufactureOrderQuery<ManufactureOrderQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: { unique_id: id },
    },
    { enabled: !!id }
  );

  const leftHeader = [
    "ลูกค้า",
    t("manufacture.order.reference_unique_id_list"),
    t("manufacture.order.external_reference_id"),
    "หมายเหตุภายใน",
  ];

  const leftInfo: string[] = [
    data?.manufactureOrder?.customer_name || "-",
    data?.manufactureOrder?.reference_unique_id_list?.join(",") || "-",
    data?.manufactureOrder?.external_reference_id || "-",
    data?.manufactureOrder?.remark || "-",
  ];

  const highlightHeader = [
    t("manufacture.bom.created_date"),
    t("manufacture.order.issue_date"),
    t("manufacture.order.production_completion_date"),
    t("manufacture.order.delivery_date"),
  ];
  const highlightInfo = [
    formatDate(data?.manufactureOrder?.created_date) || "-",
    formatDate(data?.manufactureOrder?.issue_date) || "-",
    formatDate(data?.manufactureOrder?.production_completion_date) || "-",
    formatDate(data?.manufactureOrder?.delivery_date) || "-",
  ];

  const paginatedItems = usePDFMultiplePage(data as ManufactureOrderQuery);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data?.manufactureOrder?.unique_id || "",
  });

  if (isLoading || !paginatedItems) {
    return (
      <Box
        sx={{
          height: "calc(100vh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  let startNoIngredient = 0;
  let startNoWorkOrder = 0;

  return (
    <PDFWrapper
      documentName="ใบสั่งผลิต"
      documentId={id}
      handlePrint={handlePrint}
    >
      <Box ref={componentRef} sx={{ margin: "auto auto auto auto" }}>
        <Box>
          {paginatedItems.map((page, index) => {
            const currentStartNoIngredient = startNoIngredient;
            startNoIngredient += page[0].length;
            const currentStartNoWorkOrder = startNoWorkOrder;
            startNoWorkOrder += page[1].length;
            return (
              <ManufactureOrderPDFLayout
                key={index}
                documentName={"ใบสั่งผลิต"}
                documentType="manufacture_order"
                leftHeader={leftHeader}
                leftInfo={leftInfo}
                highlightHeader={highlightHeader}
                highlightInfo={highlightInfo}
                data={data?.manufactureOrder}
                page={index + 1}
                allPage={paginatedItems.length}
                footer={index === paginatedItems.length - 1}
              >
                {page[0].length > 0 && (
                  <ManufactureOrderIngredientPdfTable
                    data={page[0] as IIngredient[]}
                    start_no={currentStartNoIngredient}
                    manufactureOrderData={data?.manufactureOrder}
                  />
                )}
                {page[1].length > 0 && (
                  <ManfactureOrderWorkOrderPdfTable
                    data={page[1] as IWorkOrder[]}
                    start_no={currentStartNoWorkOrder}
                  />
                )}
              </ManufactureOrderPDFLayout>
            );
          })}
        </Box>
      </Box>
    </PDFWrapper>
  );
};

export default ManufactureOrderPDF;
