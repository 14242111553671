import { Typography } from "@mui/material";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useReducer, useRef, useState } from "react";
import AG_GRID_LOCALE_TH from "../../../locale.th";
import CustomDate from "../CustomDate";
import AgGrid from "../AgGrid";
import { ColDef, GridReadyEvent, ColumnState, ColGroupDef, ColumnApi, GridApi, ColumnMovedEvent, ColumnPinnedEvent, ColumnResizedEvent, ColumnValueChangedEvent, ColumnVisibleEvent, FilterChangedEvent, SortChangedEvent } from "ag-grid-community";
import CustomViewActionStatusPanel from "./CustomViewActionStatusPanel";
import CustomViewMessageStatusPanel from "./CustomViewMessageStatusPanel";
import { clear } from "console";
import { useStateContext } from "../../../contexts/auth-context";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useCustomTableViewDeleteMutation, useCustomTableViewSaveMutation, useGetCustomTableViewQuery } from "../../../generated/custom-table-view";
import LoadingUI from "../LoadingUI";
import { useSnackbar } from "notistack";

interface Props {
  isClientSide?: boolean;
  autoHeight?: boolean;
  suppressMenu?: boolean;
  disabledSidebar?: boolean;
  disableFloatingFilter?: boolean;
  headerClass?: [];
  disableResized?: boolean;
  paginationSize?: number;
  height?: number | string;
  width?: number | string;
  children?: ReactNode;
  path?: string;
  persistKey: string;
  ignoreFilterKey?: string[]
}

type ExtendProps = AgGridReactProps & Props;

export type ConfigurableAgGridReact<T> = AgGridReact<T> & {
  setMode: (mode: 'everyone' | 'onlyme' | undefined) => void
}

const ConfigurableAgGrid = forwardRef<any, ExtendProps>(
  (
    {
      onGridReady,
      columnDefs: initialColumnDefs,
      persistKey,
      ignoreFilterKey,
      onColumnMoved,
      onColumnPinned,
      onColumnResized,
      onColumnValueChanged,
      onColumnVisible,
      onFilterChanged,
      onSortChanged,
      ...rest
    },
    ref
  ) => {
    const graphQLClientWithHeaderSetting: GraphQLClient = createGraphQLClientWithMiddleware("general");
    const [editMode, seteditMode] = useState<'everyone' | 'onlyme'>()
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    // const [isColumnDirty, setisColumnDirty] = useState(false) // check if filter is default or not
    const isColumnDirty = useRef(false);

    const setisColumnDirty = (value: boolean) => {
      isColumnDirty.current = value;
    };
    const [isFilterDirty, setisFilterDirty] = useState(false) // check if filter is default or not
    const {
      state: { authUser },
    } = useStateContext();
    const { enqueueSnackbar } = useSnackbar();

    const {
      data: columnDefEveryOne,
      refetch: refetchColumnDefEveryOne,
      isFetched: isFetchedColumnDefEveryOne,
      isLoading: isLoadingColumnDefEveryOne
    } = useGetCustomTableViewQuery(graphQLClientWithHeaderSetting, {
      input: {
        name: `${persistKey}-column-def`
      }
    })
    const {
      data: columnDefOnlyMe,
      refetch: refetchColumnDefOnlyMe,
      isFetched: isFetchedColumnDefOnlyMe,
      isLoading: isLoadingColumnDefOnlyMe
    } = useGetCustomTableViewQuery(graphQLClientWithHeaderSetting, {
      input: {
        name: `${persistKey}-column-def`,
        user_unique_id: authUser?.unique_id
      }
    })
    const {
      data: filterModelEveryOne,
      refetch: refetchFilterModelEveryOne,
      isFetched: isFetchedFilterModelEveryOne,
      isLoading: isLoadingFilterModelEveryOne
    } = useGetCustomTableViewQuery(graphQLClientWithHeaderSetting, {
      input: {
        name: `${persistKey}-filter-model`
      }
    })
    const {
      data: filterModelOnlyMe,
      refetch: refetchFilterModelOnlyMe,
      isFetched: isFetchedFilterModelOnlyMe,
      isLoading: isLoadingFilterModelOnlyMe
    } = useGetCustomTableViewQuery(graphQLClientWithHeaderSetting, {
      input: {
        name: `${persistKey}-filter-model`,
        user_unique_id: authUser?.unique_id
      }
    })
    const {
      mutateAsync: saveCustomTableView
    } = useCustomTableViewSaveMutation(graphQLClientWithHeaderSetting, {
      onSuccess: async () => {
        return Promise.all([
          refetchColumnDefEveryOne(),
          refetchColumnDefOnlyMe(),
          refetchFilterModelEveryOne(),
          refetchFilterModelOnlyMe()
        ])
      }
    })
    const {
      mutateAsync: deleteCustomTableView
    } = useCustomTableViewDeleteMutation(graphQLClientWithHeaderSetting, {
      onSuccess: async () => {
        return Promise.all([
          refetchColumnDefEveryOne(),
          refetchColumnDefOnlyMe(),
          refetchFilterModelEveryOne(),
          refetchFilterModelOnlyMe()
        ])
      }
    })
    const [customizedColumnDefs, setcustomizedColumnDefs] = useState<(ColDef<any> | ColGroupDef<any>)[] | null>()
    const [customizedFilterModel, setcustomizedFilterModel] = useState<{
      [key: string]: any;
    } | null>(null);
    const [originalFilterModel, setoriginalFilterModel] = useState<{
      [key: string]: any;
    } | null>(null);
    const [gridApi, setgridApi] = useState<GridApi>()
    const [columnApi, setcolumnApi] = useState<ColumnApi>()

    useImperativeHandle(ref, () => {
      return {
        setMode: (mode: 'everyone' | 'onlyme') => {
          seteditMode(mode)
          setisColumnDirty(false)
          setisFilterDirty(false)
        },
        api: gridApi
      };
    }, [gridApi])

    const getSavedCustomizedColumnDefs = async (edit: 'everyone' | 'onlyme' | undefined = editMode ,mode: 'everyone' | 'onlyme' | undefined = undefined) => {
      const everyOneData = columnDefEveryOne?.getCustomTableView?.config
      const onlymeData = columnDefOnlyMe?.getCustomTableView?.config
      
      if (edit) {
        const data = {
          everyone: everyOneData,
          onlyme: onlymeData
        }
        let defaultColumnDefs = null
        // force select mode
        if(mode) {
          return data[mode] ? JSON.parse(data[mode] || '') : null
        }

        if (edit === 'onlyme') {
          defaultColumnDefs = everyOneData ? JSON.parse(everyOneData) : null
        }
        const result = data[edit]
        return result ? JSON.parse(result) : defaultColumnDefs
      }
      if (onlymeData) {
        return JSON.parse(onlymeData)
      }
      if (everyOneData) {
        return JSON.parse(everyOneData)
      }
      return null
    }
    const getSavedCustomizedFilterModel = async (edit: 'everyone' | 'onlyme' | undefined = editMode ,mode: 'everyone' | 'onlyme' | undefined = undefined) => {
      const everyOneData = filterModelEveryOne?.getCustomTableView?.config
      const onlymeData = filterModelOnlyMe?.getCustomTableView?.config
      if (edit) {
        const data = {
          everyone: everyOneData,
          onlyme: onlymeData
        }
        let defaultFilterModel = null
        // force select mode
        if(mode) {
          return data[mode] ? JSON.parse(data[mode] || '') : null
        }

        if (edit === 'onlyme') {
          defaultFilterModel = everyOneData ? JSON.parse(everyOneData) : null
        }
        const result = data[edit]
        return result ? JSON.parse(result) : defaultFilterModel
      }
      if (onlymeData) {
        return JSON.parse(onlymeData)
      }
      if (everyOneData) {
        return JSON.parse(everyOneData)
      }
      return null
    }

    const saveCustomizedColumnDefs = async (columnDefs: ColDef[], mode: string) => {
      if (mode === 'everyone') {
        // localStorage.setItem(`${persistKey}-column-def-${mode}`, JSON.stringify(columnDefs))
        await saveCustomTableView({
          input: {
            name: `${persistKey}-column-def`,
            config: JSON.stringify(columnDefs)
          }
        })
      } else {
        // localStorage.setItem(`${persistKey}-column-def-${mode}-${authUser?.unique_id}`, JSON.stringify(columnDefs))
        await saveCustomTableView({
          input: {
            name: `${persistKey}-column-def`,
            config: JSON.stringify(columnDefs),
            user_unique_id: authUser?.unique_id
          }
        })
      }
      
      return Promise.resolve()
    }

    const saveCustomizedFilterModel = async (filter: {
      [key: string]: any;
    }, mode: string) => {
      if (mode === 'everyone') {
        // localStorage.setItem(`${persistKey}-filter-model-${mode}`, JSON.stringify(filter))
        await saveCustomTableView({
          input: {
            name: `${persistKey}-filter-model`,
            config: JSON.stringify(filter)
          }
        })
      } else {
        // localStorage.setItem(`${persistKey}-filter-model-${mode}-${authUser?.unique_id}`, JSON.stringify(filter))
        await saveCustomTableView({
          input: {
            name: `${persistKey}-filter-model`,
            config: JSON.stringify(filter),
            user_unique_id: authUser?.unique_id
          }
        })
      }
      return Promise.resolve()
    }

    // const clearCustomizedColumnDefs = async (mode: string) => {
    //   if (mode === 'everyone') {
    //     localStorage.removeItem(`${persistKey}-column-def-${mode}`)
    //   } else {
    //     localStorage.removeItem(`${persistKey}-column-def-${mode}-${authUser?.unique_id}`)
    //   }
    //   return Promise.resolve()
    // }

    // const clearCustomizedFilterModel = async (mode: string) => {
    //   if (mode === 'everyone') {
    //     localStorage.removeItem(`${persistKey}-filter-model-${mode}`)
    //   } else {
    //     localStorage.removeItem(`${persistKey}-filter-model-${mode}-${authUser?.unique_id}`)
    //   }
    //   return Promise.resolve()
    // }

    const setFilter = (
      filterModel: {
        [key: string]: any;
      } | null,
      _gridApi: GridApi<any> | undefined = gridApi
    ) => {
      const originalFilterModel = _gridApi?.getFilterModel()
      const baseFilterModel = ignoreFilterKey?.reduce((filter, key) => {
        filter[key] = originalFilterModel?.[key]
        return filter
      }, {} as {
        [key: string]: any;
      })
      const filter = {
        ...baseFilterModel,
        ...filterModel,
      }
      // reset filter
      Object.keys(originalFilterModel || {}).forEach(key => {
        if (!ignoreFilterKey?.includes(key)) {
          const filterInstance = _gridApi?.getFilterInstance(key)
          filterInstance?.setModel(null)
        }
      })
      // set filter
      Object.keys(filter).forEach(key => {
        const filterInstance = _gridApi?.getFilterInstance(key)
        filterInstance?.setModel(filter[key])
      })
      // gridApi?.setFilterModel(filter)
      _gridApi?.onFilterChanged()
    }

    // load saved config
    useEffect(() => {
      const getSavedData = async () => {
        const savedCustomizedColumnDefs = await getSavedCustomizedColumnDefs()
        
        const savedCustomizedFilterModel = await getSavedCustomizedFilterModel()
        setcustomizedColumnDefs(savedCustomizedColumnDefs)
        setcustomizedFilterModel(savedCustomizedFilterModel)
      }
      getSavedData()
    }, [editMode, gridApi])

    useEffect(() => {
      setFilter(customizedFilterModel)
    }, [customizedFilterModel])


    useEffect(() => { // sync state to status panel
      if (!gridApi) {
        return
      }
      const customViewActionInstance = gridApi.getStatusPanel<any>('customViewAction')
      const customViewMessageInstance = gridApi.getStatusPanel<any>('customViewMessage')
      customViewActionInstance?.setMode(editMode)
      customViewActionInstance?.setColumnDirty(isColumnDirty.current || !!customizedColumnDefs)
      customViewActionInstance?.setFilterDirty(isFilterDirty || !!customizedFilterModel)
      customViewMessageInstance?.setMode(editMode)
    }, [editMode, gridApi, isColumnDirty.current, isFilterDirty, customizedColumnDefs, customizedFilterModel])

    const sideBar = {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            // suppressSideButtons: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            // suppressColumnExpandAll: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          toolPanelParams: {
            suppressExpandAll: true,
          },
        },
      ],
    };

    const convertColumnStateToColumnDef = (columnState: ColumnState[]): ColDef[] => {
      const columnDefs = columnState.map(state => {
        // Find base definition if needed for extra info (like headerName, field, etc.)
        const baseDef = initialColumnDefs && initialColumnDefs.find((def: ColDef) => def.field === state.colId) as ColDef<any> || {};
        
        const columnDef = {
          ...baseDef,
          colId: state.colId,
          width: state.width,
          flex: baseDef.width === state.width ? baseDef.flex : undefined,
          hide: state.hide,
          sort: state.sort,
          sortIndex: state.sortIndex,
          pinned: state.pinned
        } as ColDef;
        delete columnDef.filterParams
        delete columnDef.headerName
        return columnDef
      });
      return columnDefs
    }

    const applyDefaultColumnDef = (newColDef: ColDef[]): ColDef[] | undefined => {
      if (!newColDef) {
        return undefined
      }
      const columnDefs = newColDef.map(col => {
        // Find base definition if needed for extra info (like headerName, field, etc.)
        const baseDef = initialColumnDefs && initialColumnDefs.find((def: ColDef) => def.field === col.field) as ColDef<any> || {};
        const def = {
          ...baseDef,
          ...col,
        } as ColDef;
        return def
      });
      return columnDefs
    }
    const saveColumnState = async (columnApi: ColumnApi, mode: string, isDirty: boolean) => {
      if (isDirty) { //save data
        // get current column size
        const columnState = columnApi.getColumnState()
        const newColumnDef = convertColumnStateToColumnDef(columnState)
        // setcustomizedColumnDefs(columnState)
        return saveCustomizedColumnDefs(newColumnDef, mode)
      } else { // save clear
        return deleteCustomizedColumnDefs(mode)
      }
    }

    const deleteCustomizedColumnDefs = async (mode: string) => {
      if (mode === 'everyone') {
        // localStorage.removeItem(`${persistKey}-column-def-${mode}`)
        await deleteCustomTableView({
          input: {
            name: `${persistKey}-column-def`
          }
        })
      } else {
        // localStorage.removeItem(`${persistKey}-column-def-${mode}-${authUser?.unique_id}`)
        await deleteCustomTableView({
          input: {
            name: `${persistKey}-column-def`,
            user_unique_id: authUser?.unique_id
          }
        })
      }
      return Promise.resolve()
    }

    const saveFilterModel = async (gridApi: GridApi, mode: string, isDirty: boolean) => {
      const filterModel = gridApi.getFilterModel()
      const filter = filterModel
      debugger
      if (ignoreFilterKey) {
        ignoreFilterKey.forEach(key => {
          delete filter[key]
        })
      }
      if (isDirty && Object.keys(filter).length > 0) { // save data
        return saveCustomizedFilterModel(filter, mode)
      } else { // save clear
        return deleteCustomizedFilterModel(mode)
      }
    }

    const deleteCustomizedFilterModel = async (mode: string) => {
      if (mode === 'everyone') {
        // localStorage.removeItem(`${persistKey}-filter-model-${mode}`)
        await deleteCustomTableView({
          input: {
            name: `${persistKey}-filter-model`
          }
        })
      } else {
        // localStorage.removeItem(`${persistKey}-filter-model-${mode}-${authUser?.unique_id}`)
        await deleteCustomTableView({
          input: {
            name: `${persistKey}-filter-model`,
            user_unique_id: authUser?.unique_id
          }
        })
      }
      return Promise.resolve()
    }

    const exitEditMode = async (api: GridApi, columnApi: ColumnApi) => {
      // reload column def and filter mode
      const columnDef = await getSavedCustomizedColumnDefs()
      const filterModel = await getSavedCustomizedFilterModel()
      setcustomizedColumnDefs(columnDef)
      debugger
      setFilter(filterModel, api)
      seteditMode(undefined)
    }

    const saveConfig = async (api: GridApi, columnApi: ColumnApi, mode: string, isColumnDirty: boolean, isFilterDirty: boolean) => {
      if (!api || !columnApi) {
        return Promise.reject('API not ready')
      }
      await Promise.all([saveColumnState(columnApi, mode, isColumnDirty), saveFilterModel(api, mode, isFilterDirty)])
      enqueueSnackbar(
        `จัดการรูปแบบตารางสำเร็จ`,
        {
          variant: "success",
        }
      );
      // reload column def and filter mode
      await exitEditMode(api, columnApi)
    }

    const clearConfig = async (api: GridApi, columnApi: ColumnApi, editMode: 'everyone' | 'onlyme') => {
      if (!api || !columnApi) {
        return Promise.reject('API not ready')
      }
      let columnDef = null
      if (editMode === 'onlyme') {
        columnDef = await getSavedCustomizedColumnDefs(editMode, 'everyone')
      }
      let filterModel = null
      if (editMode === 'onlyme') {
        filterModel = await getSavedCustomizedFilterModel(editMode, 'everyone')
      }
      debugger
      setcustomizedColumnDefs(columnDef)
      setcustomizedFilterModel(filterModel)
      setisColumnDirty(false) // also rerender component
      setisFilterDirty(false) // also rerender component
    }

    const statusBar = {
      statusPanels: [
        {
          key: 'customViewAction',
          statusPanel: CustomViewActionStatusPanel,
          statusPanelParams: {
            onReset: clearConfig,
            onApply: saveConfig,
            editMode: editMode,
            onCancel: exitEditMode,
            isColumnDirty: isColumnDirty.current,
            isFilterDirty
          },
          align: "right",
        },
        {
          key: 'customViewMessage',
          statusPanel: CustomViewMessageStatusPanel,
          statusPanelParams: {
          },
          align: "left",
        },
      ]
    }
    // on grid ready
    const onGridReadyCallback = async (event: GridReadyEvent) => {
      setgridApi(event.api)
      setcolumnApi(event.columnApi)
      setFilter(customizedFilterModel || {})
      onGridReady && await onGridReady(event)
    }

    const onColumnMovedHandle = (e: ColumnMovedEvent) => {
      onColumnMoved && onColumnMoved(e)
      if (e.source !== 'gridOptionsChanged') { 
        setisColumnDirty(true)
      }
    }
    const onColumnPinnedHandle = (e: ColumnPinnedEvent) => {
      onColumnPinned && onColumnPinned(e)
      if (e.source !== 'gridOptionsChanged') { 
        setisColumnDirty(true)
      }
    }
    const onColumnResizedHandle = (e: ColumnResizedEvent) => {
      onColumnResized && onColumnResized(e)
      if (e.source !== 'gridOptionsChanged' && e.source !== 'flex') { 
        setisColumnDirty(true)
      }
    }
    const onColumnValueChangedHandle = (e: ColumnValueChangedEvent) => {
      onColumnValueChanged && onColumnValueChanged(e)
      if (e.source !== 'gridOptionsChanged') { 
        setisColumnDirty(true)
      }
    }
    const onColumnVisibleHandle = (e: ColumnVisibleEvent) => {
      onColumnVisible && onColumnVisible(e)
      if (e.source !== 'gridOptionsChanged') { 
        setisColumnDirty(true)
      }
    }
    const onFilterChangedHandle = (e: FilterChangedEvent) => {
      onFilterChanged && onFilterChanged(e)
      if (e.columns.length > 0) {
        // if (columnApi) {
        //   const columnState = columnApi?.getColumnState()
        //   const newColumnDef = convertColumnStateToColumnDef(columnState)
        //   setcustomizedColumnDefs(newColumnDef)
        // }
        // if (columnApi) {
        //   const columnState = columnApi?.getColumnState()
        //   const newColumnDef = convertColumnStateToColumnDef(columnState)
        //   setcustomizedColumnDefs(newColumnDef)
        // }
        setisFilterDirty(true)
      }
    }
    const onSortChangedHandle = (e: SortChangedEvent) => {
      onSortChanged && onSortChanged(e)
    }
    if (!isFetchedColumnDefEveryOne || !isFetchedColumnDefOnlyMe || !isFetchedFilterModelEveryOne || !isFetchedFilterModelOnlyMe) {
      return <LoadingUI />
    }
    console.log(customizedColumnDefs)
    return <>
      {editMode && <div style={{position: 'fixed', zIndex: 5, width: '100vw', height: '100vh', top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.1)'}}></div>}
      <AgGrid
        {...rest}
        columnDefs={customizedColumnDefs ? applyDefaultColumnDef(customizedColumnDefs) : applyDefaultColumnDef(initialColumnDefs as ColDef[])}
        onGridReady={onGridReadyCallback}
        ref={ref}
        sideBar={sideBar}
        statusBar={statusBar}
        containerStyle={editMode && {
          zIndex: 6,
          position: 'absolute',
          width: '100%'
          // top: 0, left: 0, right: 0, bottom: 0
        }}
        onColumnMoved={onColumnMovedHandle}
        onColumnPinned={onColumnPinnedHandle}
        onColumnResized={onColumnResizedHandle}
        onColumnValueChanged={onColumnValueChangedHandle}
        onColumnVisible={onColumnVisibleHandle}
        onFilterChanged={onFilterChangedHandle}
        onSortChanged={onSortChangedHandle}
        // onFilterModified={console.log}
      />
    </>
  }
);

export default ConfigurableAgGrid;
