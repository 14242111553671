import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import { Box, InputAdornment, Typography } from "@mui/material";
import CustomizedTextField from "../../../../Custom/CustomizedTextField";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import PosItemList from "./ItemList";
import PosSummary from "./Summary";

const PosRightPanel = () => {
  const { t } = useTranslation();
  return (
    <CustomizedBox
      minWidth={450}
      padding={"1rem"}
      margin={"1rem 0"}
      height={"100%"}
      border={`1px solid #E2E2E2`}
      boxShadow={"unset"}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
      >
        <Box
          width={350}
          bgcolor={"#F2F2F2"}
          sx={{ overflow: "hidden" }}
          borderRadius={1}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
            p={1}
            justifyContent={"space-between"}
          >
            <PersonIcon sx={{ color: "primary.main" }} />
            <Typography
              fontSize={14}
              color={"primary.main"}
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              fontWeight={"bold"}
            >
              เอ็นพีอาร์ ดิจิทัลพาร์ทเนอร์ จำกัด
            </Typography>
          </Box>
        </Box>
        <Box>
          <CustomizedTextField
            label={t("sales.pos.scan")}
            name="barcode"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter" && e.shiftKey === false) {
            //     onBarcodeSubmitHandler();
            //   }
            // }}
          />
        </Box>
      </Box>
      <PosItemList />
      <PosSummary />
    </CustomizedBox>
  );
};

export default PosRightPanel;
