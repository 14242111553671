import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatNumber } from "../../../utils/dataTransformer";
import { useCallback, useEffect, useState } from "react";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { ITEMS_SKU_AGGRID } from "../../../services/AgGrid/InventoryAgGrid";
import { BomQuery, useBomQuery } from "../../../generated/manufacture";

interface Props {
  data: any;
  start_no: number;
  bgColor?: string;
  exchange_rate?: number;
}

const ManufactureOrderItemPdfTable = ({
  data,
  start_no,
  bgColor,
  exchange_rate = 1,
}: Props) => {
  const [item, setItem] = useState<any>();
  const header = [
    {
      label: "Item",
      thaiLabel: "สินค้า",
      width: "45%",
    },
    {
      label: "Qty",
      thaiLabel: "จำนวน",
      width: "10%",
    },
    {
      label: "Type",
      thaiLabel: "ประเภทการผลิต",
      width: "15%",
    },
    {
      label: "BOM",
      thaiLabel: "สูตรการผลิต",
      width: "15%",
    },
    {
      label: "Description",
      thaiLabel: "คำอธิบาย",
      width: "15%",
    },
  ];

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { data: bomData } = useBomQuery<BomQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: { name: data.bom_name },
    },
    {
      enabled: Boolean(data.bom_name),
    }
  );

  const getSkuFormItem = useCallback(async () => {
    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("item");

    const { itemSkuDetailsFindManyAggrid } =
      await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
        aggridInput: {
          startRow: 0,
          endRow: 999,
          filterModel: {
            sku_name: {
              filterType: "set",
              values: [data.item_unique_id],
            },
          },
        },
      });
    const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

    const newItem = {
      item_unique_id: data.item_unique_id,
      item_name: data.item_name,
      item_img_url:
        itemSkuDetails &&
        itemSkuDetails[0].img_url &&
        Array.isArray(itemSkuDetails[0].img_url) &&
        itemSkuDetails[0].img_url.length > 0
          ? itemSkuDetails[0].img_url[0]
          : "",
      item_description: data.item_description,
      item_remark: data.item_remark,
      production_qty: data.production_qty,
      bom_name: data.bom_name,
      item_uom: data.uom,
      item_type: data.type,
    };

    setItem(newItem);
  }, [data]);

  useEffect(() => {
    getSkuFormItem();
  }, [getSkuFormItem, data]);

  return (
    <TableContainer>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            {header.map((head, index) => (
              <TableCell
                key={index}
                align={head.label === "Qty" ? "right" : "left"}
                sx={{
                  width: head.width,
                  maxWidth: head.width,
                  minWidth: "auto",
                  textAlign: head.label === "Qty" ? "right" : "left",
                  pr: head.label === "Item" ? 2 : 0,
                  pl:
                    head.label === "Item" ? 1.5 : head.label === "Type" ? 2 : 1,
                  py: 1,
                  backgroundColor: bgColor || "#ECF6FD",
                  borderTop: "none",
                  borderBottom: "none",
                }}
              >
                <Typography
                  noWrap
                  fontSize={11}
                  fontWeight={600}
                  textAlign={head.label === "Qty" ? "right" : "left"}
                >
                  {head.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {item && (
            <TableRow
              sx={{
                breakInside: "avoid",
                minHeight: "100px",
                verticalAlign: "top",
                borderBottom: "none",
                "& .MuiTableCell-root": {
                  borderBottom: "none",
                },
              }}
            >
              <TableCell
                sx={{
                  width: header[0].width,
                  p: "4px !important",
                }}
              >
                <Box sx={{ display: "flex", gap: 1, alignItems: "flex-start" }}>
                  {item.item_img_url && (
                    <Box
                      component="img"
                      src={item.item_img_url}
                      alt={`item's ${start_no}`}
                      sx={{
                        width: 65,
                        height: 65,
                        flexShrink: 0,
                        objectFit: "contain",
                      }}
                    />
                  )}
                  <Box sx={{ flex: 1, pt: 0.5 }}>
                    <Typography
                      fontSize={11}
                      fontWeight={600}
                      lineHeight="15px"
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {item.item_unique_id} - {item.item_name}
                    </Typography>
                    <Typography
                      fontSize={11}
                      color="text.secondary"
                      lineHeight="15px"
                      sx={{
                        wordBreak: "break-word",
                      }}
                    >
                      {item.item_description}
                    </Typography>
                    <Typography
                      fontSize={11}
                      lineHeight="15px"
                      sx={{
                        wordBreak: "break-word",
                      }}
                    >
                      {item.item_remark}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  width: header[1].width,
                  pr: "0px !important",
                }}
              >
                <Typography fontSize={11} lineHeight="15px">
                  {item.production_qty ? formatNumber(item.production_qty) : ""}
                </Typography>
                <Typography
                  fontSize={11}
                  lineHeight="11px"
                  color="text.secondary"
                >
                  {item.item_uom}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: header[2].width,
                  pl: "16px !important",
                }}
              >
                <Typography
                  fontSize={11}
                  lineHeight="15px"
                  sx={{ wordBreak: "break-word" }}
                >
                  {item.item_type}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: header[3].width,
                  pl: "8px !important",
                }}
              >
                <Typography
                  fontSize={11}
                  lineHeight="15px"
                  sx={{
                    wordBreak: "break-word",
                  }}
                >
                  {item.bom_name || ""}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: header[4].width,
                  pl: "8px !important",
                }}
              >
                <Typography
                  fontSize={11}
                  lineHeight="15px"
                  sx={{
                    wordBreak: "break-word",
                  }}
                >
                  {bomData?.bom?.description || ""}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ManufactureOrderItemPdfTable;
