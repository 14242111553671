import { TFunction } from "i18next";
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import {
  formatNumber,
  itemTypeEngToThai,
} from "../../../../utils/dataTransformer";
import {
  InventoryTagsFindByEntityQuery,
  ItemType,
} from "../../../../generated/inventory";
import CustomizedChips from "../../../Custom/CustomizedChips";
import { Box, Typography } from "@mui/material";
import { ISkuDetail } from "../../../../types/Inventory/item";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import SkuQtyChecker from "./SkuQtyChecker";
import ItemSkuChecker from "./ItemSkuChecker";

interface Props {
  t: TFunction;
  isReport?: boolean;
  enableCheckbox?: boolean;
  noService?: boolean;
  bom?: boolean;
  allTag: InventoryTagsFindByEntityQuery["itemTagsFindByEntity"];
}

// const mapQtyEngToThai = (eng: string) => {
//   switch (eng) {
//     case "stock_qty":
//       return "คงคลัง";
//     case "available_qty":
//       return "คงเหลือสุทธิ";
//     case "purchase_ordered_qty":
//       return "กำลังจัดซื้อ";
//     case "manufacture_ordered_qty":
//       return "กำลังผลิต";
//     case "sale_committed_qty":
//       return "ถูกจอง (ขาย)";
//     case "manufacture_committed_qty":
//       return "ถูกจอง (ผลิต)";
//     default:
//       return "คงคลัง";
//   }
// };

export const itemColumnDefs = (
  t: Props["t"],
  isReport: Props["isReport"],
  enableCheckbox: Props["enableCheckbox"],
  allTag: Props["allTag"],
  noService: Props["noService"],
  bom: Props["bom"] = false
): ColDef[] => {
  return [
    {
      field: "sku_name",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
      checkboxSelection: enableCheckbox,
    },
    {
      field: "img_url",
      headerName: t("inventory.img_url"),
      filter: false,
      floatingFilter: false,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.item_sku.item.img_url;
      },
      cellRenderer: (params: ICellRendererParams<ISkuDetail, string[]>) => {
        if (params.value && params.value.length) {
          return (
            <CustomizedAvatar
              variant="rounded"
              avatars={[
                {
                  img_url: params.value?.[0] ?? "",
                  unique_id: params?.data?.item_sku?.item?.unique_id ?? "",
                  first_name: params?.data?.item_sku?.item?.name ?? "",
                  last_name: "",
                },
              ]}
            />
          );
        } else
          return (
            <CustomizedAvatar
              variant="rounded"
              avatars={[
                {
                  img_url: undefined,
                  unique_id: params?.data?.item_sku?.item?.unique_id ?? "",
                  first_name: params?.data?.item_sku?.item?.name ?? "",
                  last_name: "",
                },
              ]}
            />
          );
      },
      minWidth: 100,
      flex: 1,
    },
    {
      field: "name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => {
        return params.data.item_sku.item.name;
      },
    },
    {
      field: "desc",
      headerName: t("inventory.items.desc"),
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => {
        return params.data.item_sku.item.desc;
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
      },
      cellRenderer: (params: ICellRendererParams) => {
        const maxLength = 200;
        const text = params.value || "";
        const truncatedText =
          text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

        return (
          <Typography
            sx={{
              whiteSpace: "nowrap",
              fontSize: 14,
            }}
            // title={text}
          >
            {truncatedText}
          </Typography>
        );
      },
    },
    {
      field: "type",
      headerName: t("inventory.items.type"),
      filter: "agSetColumnFilter",
      filterParams: {
        readOnly: enableCheckbox,
        values: bom
          ? [ItemType.Bom]
          : noService
          ? [ItemType.Normal, ItemType.Variant, ItemType.Bundle]
          : [
              ItemType.Normal,
              ItemType.Variant,
              ItemType.Bundle,
              ItemType.Service,
            ],
        valueFormatter: (params: ValueFormatterParams) => {
          switch (params.value) {
            case ItemType.Normal:
              return "สินค้าธรรมดา";
            case ItemType.Variant:
              return "สินค้ามีตัวเลือก";
            case ItemType.Bundle:
              return "สินค้าจัดเซ็ต";
            case ItemType.Service:
              return "บริการ";
            case ItemType.Bom:
              return "ชุดสินค้า";
            default:
              break;
          }
        },
      },
      valueFormatter: (params: ValueFormatterParams) =>
        itemTypeEngToThai(params.data.item_sku.item.type),
    },
    {
      field: "barcode",
      headerName: t("inventory.barcode"),
      filter: "agTextColumnFilter",
    },
    {
      field: "stock_qty",
      headerName: t("inventory.quantities.stock_qty"),
      headerClass: "ag-end-header",
      filter: "agNumberColumnFilter",
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        paddingTop: "12px",
      },
      cellRenderer: (params: ICellRendererParams) => {
        const value = params.value;
        if (value === 0) {
          return (
            <Typography color={"#000E58"} fontSize={14}>
              {formatNumber(value)}
            </Typography>
          );
        } else {
          return (
            <CustomizedTooltip
              borderRadius={5}
              placement="right"
              title={<SkuQtyChecker sku_name={params.data.sku_name} />}
            >
              <Typography color={"#000E58"} fontSize={14}>
                {formatNumber(value)}
              </Typography>
            </CustomizedTooltip>
          );
        }
      },
      width: 175,
    },
    {
      field: "available_qty",
      headerName: t("inventory.quantities.available_qty"),
      headerClass: "ag-end-header",
      filter: "agNumberColumnFilter",
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        paddingTop: "12px",
      },
      cellRenderer: (params: ICellRendererParams) => {
        const stock_qty = params.data.stock_qty || 0;
        const manufacture_ordered_qty =
          params.data.manufacture_ordered_qty || 0;
        const sale_committed_qty = params.data.sale_committed_qty || 0;
        const manufacture_committed =
          params.data.manufacture_committed_qty || 0;
        const purchase_ordered_qty = params.data.purchase_ordered_qty || 0;

        const available_qty =
          stock_qty -
          sale_committed_qty -
          manufacture_committed +
          purchase_ordered_qty +
          manufacture_ordered_qty;

        return (
          <CustomizedTooltip
            borderRadius={5}
            placement="right"
            title={<ItemSkuChecker sku_name={params.data.sku_name} />}
          >
            <Typography color={"#000E58"} fontSize={14}>
              {formatNumber(available_qty)}
            </Typography>
          </CustomizedTooltip>
        );
      },
      width: 175,
    },
    {
      field: "tag_list",
      headerName: t("inventory.items.group_tag_list"),
      filter: "agSetColumnFilter",
      sortable: false,
      filterParams: {
        values: async (params: any) => {
          // fetch values from server
          const values = allTag;
          const formatValues = values
            .filter((tag) => tag.entity === "item")
            .map((tag) => tag.name);
          params.success(formatValues);
        },
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (params.data.item_sku?.item)
          return params.data.item_sku?.item?.tag_list?.map(
            (tag: { name: string }, index: number) => (
              <Box key={index} sx={{ display: "inline-block", mr: 1, gap: 1 }}>
                <CustomizedChips key={tag.name} value={`${tag.name}`} />
              </Box>
            )
          );
      },
    },
    {
      field: "item_level_1",
      headerName: t("inventory.items.item_group"),
      filter: "agTextColumnFilter",
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) =>
        params.data.item_sku.item?.item_level_1?.name || "",
    },
    {
      field: "is_active",
      headerName: t("contact.contact_status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [1, 0],
        valueFormatter: ({ value }: { value: number }) => {
          if (value) return "ใช้งาน";
          return "หยุดใช้งาน";
        },
        readOnly: enableCheckbox,
      },
      cellRenderer: ({ value }: { value: boolean }) => {
        if (value) return <CustomizedStatus status="active" />;
        return <CustomizedStatus status="inactive" />;
      },
      cellStyle: {
        paddingTop: "6px",
      },
    },
  ];
};
