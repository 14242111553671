import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { GraphQLClient } from "graphql-request";
import { useReactToPrint } from "react-to-print";
import { Box, CircularProgress } from "@mui/material";
import { useRef, ReactInstance } from "react";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  useGoodsIssuePdfQuery,
  GoodsIssuePdfQuery,
} from "../../../generated/inventory";
import { usePDFMultiplePage } from "../../../hooks/Inventory/use-goods-issue-pdf-multiple-page";
import PDFWrapper from "../../../components/UI/Pdf/PDFWrapper";
import GIPdfLayout from "../../../components/UI/Pdf/InventoryPdf/GIPDFLayout";
import PDFTable from "../../../components/Table/Pdf/GoodsIssuePdfTable";
import { formatDate } from "../../../utils/Formatter/Date";

const formatGIType = (type: string | null | undefined) => {
  switch (type) {
    case "sales_order":
      return "ขาย";
    case "manufacture_order":
      return "ผลิต";
    case "purchase_return":
      return "ส่งคืน";
    case "other":
      return "อื่นๆ";
    default:
      return "-";
  }
};

const GoodsIssuePDF = () => {
  const { id } = useParams();
  const documentType = "goods_issue";

  const graphQLClientWithHeader: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data, isLoading } = useGoodsIssuePdfQuery<GoodsIssuePdfQuery>(
    graphQLClientWithHeader,
    {
      uniqueInput: {
        unique_id: id,
      },
    },
    {
      enabled: !!id,
    }
  );

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.goodsIssuePDF,
    180,
    790 + 60,
    480 + 60,
    213,
    169
  );

  const componentRef = useRef<ReactInstance | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data?.goodsIssuePDF?.unique_id || "",
  });

  const leftHeader = ["ประเภท", "เอกสารอ้างอิง", "ลูกค้า", "หมายเหตุ"];

  const leftInfo = [
    formatGIType(data?.goodsIssuePDF?.type),
    data?.goodsIssuePDF?.reference_unique_id || "-",
    data?.goodsIssuePDF?.customer_contact_unique_id
      ? `${data?.goodsIssuePDF?.customer_contact_unique_id} - ${data?.goodsIssuePDF?.customer_contact?.name}`
      : "-",
    data?.goodsIssuePDF?.remark || "-",
  ];

  const highlightHeader = ["วันที่ออกเอกสาร", "วันที่นำออก"];

  const highlightInfo = [
    data?.goodsIssuePDF?.created_date
      ? formatDate(data?.goodsIssuePDF?.created_date)
      : "-",
    data?.goodsIssuePDF?.posted_date
      ? formatDate(data?.goodsIssuePDF?.posted_date)
      : "-",
  ];

  const rightHeader = ["ผู้สร้างเอกสาร", "คลัง"];

  const rightInfo = [
    (data?.goodsIssuePDF?.created_by?.first_name ?? "") +
      " " +
      (data?.goodsIssuePDF?.created_by?.last_name ?? ""),
    data?.goodsIssuePDF?.source_warehouse_unique_id ?? "-",
  ];

  const { control, setValue, reset } = useForm({
    defaultValues: {
      id: 0,
      value: undefined,
    },
  });

  const addressValue = useWatch({
    control,
    name: "value",
  });

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PDFWrapper
      control={control}
      setValue={setValue}
      reset={reset}
      documentName="ใบนำออก"
      documentType={documentType}
      documentId={id}
      handlePrint={handlePrint}
    >
      <Box ref={componentRef} sx={{ margin: "auto auto auto auto" }}>
        {paginatedItems.map((item, index) => (
          <GIPdfLayout
            documentName="ใบนำออก/Goods Issue"
            data={data?.goodsIssuePDF}
            documentType={documentType}
            page={index + 1}
            allPage={paginatedItems.length}
            footer={index === paginatedItems.length - 1}
            leftHeader={leftHeader}
            leftInfo={leftInfo}
            highlightHeader={highlightHeader}
            highlightInfo={highlightInfo}
            rightHeader={rightHeader}
            rightInfo={rightInfo}
            value={addressValue}
          >
            <PDFTable
              data={item}
              start_no={startNumbers[index]}
              type={data?.goodsIssuePDF?.type}
            />
          </GIPdfLayout>
        ))}
      </Box>
    </PDFWrapper>
  );
};

export default GoodsIssuePDF;
