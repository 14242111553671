import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import {
  InventoryTagsFindByEntityQuery,
  ItemEntityType,
  useInventoryTagsFindByEntityQuery,
} from "../../../../generated/inventory";
import { Box } from "@mui/material";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../../utils/Formatter/Date";

export const useColumnDefs = () => {
  const { t } = useTranslation();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { refetch } =
    useInventoryTagsFindByEntityQuery<InventoryTagsFindByEntityQuery>(
      graphQLClientWithHeaderItem,
      {
        entityName: ItemEntityType.SalesBom,
      }
    );

  const colDefs: ColDef[] = [
    {
      field: "sku_name",
      headerName: t("inventory.sales_bom.unique_id"),
      filter: "agTextColumnFilter",
      lockVisible: true,
    },
    {
      field: "img_url",
      headerName: "",
      filter: false,
      floatingFilter: false,
      width: 66.5,
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value && params.value.length) {
          return (
            <CustomizedAvatar
              noTooltip
              variant="rounded"
              avatars={[
                {
                  img_url: params.value?.[0] || "",
                  unique_id: params?.data?.sku_name || "",
                  first_name: params?.data?.sku_name || "",
                  last_name: "",
                },
              ]}
              sx={{
                width: 32,
                height: 32,
              }}
            />
          );
        } else
          return (
            <CustomizedAvatar
              noTooltip
              variant="rounded"
              avatars={[
                {
                  img_url: undefined,
                  unique_id: params?.data?.sku_name || "",
                  first_name: params?.data?.sku_name || "",
                  last_name: "",
                },
              ]}
              sx={{
                width: 32,
                height: 32,
              }}
            />
          );
      },
      cellStyle: {
        paddingTop: "6px",
      },
      headerClass: "hide-header-text",
      suppressFiltersToolPanel: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "name",
      headerName: t("inventory.sales_bom.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "desc",
      headerName: t("inventory.sales_bom.desc"),
      filter: "agTextColumnFilter",
      cellStyle: {
        whiteSpace: "pre-wrap",
        lineHeight: 1.75,
        paddingTop: "12px",
        paddingBottom: "12px",
      },
      autoHeight: true,
    },
    {
      field: "seller_sku_name",
      headerName: t("inventory.items.seller_sku"),
      filter: "agTextColumnFilter",
      width: 160,
    },
    {
      field: "tag_list",
      headerName: t("inventory.items.group_tag_list"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const { data } = await refetch();
          const values = data?.itemTagsFindByEntity;
          const formatValues =
            values && values.length > 0 ? values.map((tag) => tag.name) : [];
          params.success(formatValues);
        },
      },
      cellRenderer: (params: ICellRendererParams) => {
        const tag_list = params.data.tag_list;
        if (tag_list && tag_list.length > 0) {
          return tag_list.map((tag: string, index: number) => (
            <Box key={index} sx={{ display: "inline-block", mr: 1, gap: 1 }}>
              <CustomizedChips key={tag} value={tag} />
            </Box>
          ));
        }
      },
      width: 160,
    },
    {
      field: "is_active",
      headerName: t("contact.contact_status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [1, 0],
        valueFormatter: ({ value }: { value: number }) => {
          if (value) return "ใช้งาน";
          return "หยุดใช้งาน";
        },
      },
      cellRenderer: ({ value }: { value: boolean }) => {
        if (value) return <CustomizedStatus status="active" />;
        return <CustomizedStatus status="inactive" />;
      },
      cellStyle: {
        alignContent: "center",
      },
      width: 160,
    },
    {
      field: "created_date",
      headerName: t("date.created_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
      sort: "desc",
      width: 160,
      hide: true,
    },
    {
      field: "created_by",
      headerName: t("sentence.created_by"),
      filter: "agTextColumnFilter",
      cellRenderer: (params: ICellRendererParams) => {
        return <CustomizedAvatar avatars={[params.data.created_by_object]} />;
      },
      width: 135,
      cellStyle: {
        display: "flex",
        alignItems: "flex-start",
        justifyContents: "center",
      },
      hide: true,
    },
  ];

  return colDefs;
};
