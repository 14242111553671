import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useState } from "react";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ISalesItemList } from "../../types/Sales";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import { IUomGroup } from "../../types/Inventory/item";
import { ItemType } from "../../generated/inventory";

export const useSalesItemListFormatter = (
  item_list: ISalesItemList[],
  document_type: string
) => {
  const [formatItemList, setFormatItemList] = useState<ISalesItemList[]>([]);

  const getUomName = useCallback(async () => {
    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("item");
    if (item_list && item_list.length > 0) {
      console.log("item_list", item_list);
      const itemListWithoutBom = item_list.filter(
        (list) => list.item_type !== ItemType.Bom
      );
      const allItemListUniqueId = itemListWithoutBom.map(
        (item) => item?.item_unique_id
      );

      const { itemSkuDetailsFindManyAggrid } =
        await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
          aggridInput: {
            startRow: 0,
            endRow: allItemListUniqueId.length,
            filterModel: {
              sku_name: {
                filterType: "set",
                values: allItemListUniqueId,
              },
            },
          },
        });

      let formatItemList: ISalesItemList[] = [];

      const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

      const getUomGroup = (foundItem: any): IUomGroup =>
        foundItem?.item_sku?.item?.uom_group;

      const getItemSkuQty = (foundItem: any) => ({
        stock_qty: foundItem?.stock_qty,
        available_qty: foundItem?.available_qty,
        purchase_ordered_qty: foundItem?.purchase_ordered_qty,
        manufacture_ordered_qty: foundItem?.manufacture_ordered_qty,
        sale_committed_qty: foundItem?.sale_committed_qty,
        manufacture_committed_qty: foundItem?.manufacture_committed_qty,
      });

      itemListWithoutBom.forEach((item) => {
        const foundItem = itemSkuDetails.find(
          (realItem: any) => realItem.sku_name === item.item_unique_id
        );

        const uom_group = getUomGroup(foundItem);
        let stock_qty = 1;

        if (document_type === "sales_return" && uom_group) {
          const conversionUom = uom_group.uom_conversion_list?.find(
            (conversion: any) => conversion.target_uom_unique_id === item.uom
          );

          if (conversionUom) {
            stock_qty =
              conversionUom.base_uom_rate / conversionUom.target_uom_rate;
          }
        }

        formatItemList.push({
          ...item,
          qty: document_type === "sales_return" ? 1 : item.qty,
          so_qty: document_type === "sales_return" ? item.qty : undefined,
          uom_group,
          stock_qty,
          item_sku_qty: getItemSkuQty(foundItem),
        });
      });
      return formatItemList;
    } else {
      return [];
    }
  }, [document_type, item_list]);

  useEffect(() => {
    const fetcher = async () => {
      const formatWithUom = await getUomName();
      setFormatItemList(formatWithUom);
    };
    fetcher();
  }, [getUomName]);

  return formatItemList;
};
