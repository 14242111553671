import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Avatar,
  TextField,
} from "@mui/material";
import { useFieldArray, useWatch } from "react-hook-form";
import { useState, useEffect, Fragment, SetStateAction, Dispatch } from "react";
import { useTranslation } from "react-i18next";

import { useModal } from "../../../../hooks/use-modal";
import { ISkuDetail } from "../../../../types/Inventory/item";
import { IBundleDetail } from "../../../../types/Inventory/item";
import { IDefaultForm, IHeaderTable } from "../../../../types/global";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ControlledTextField from "../../../Controller/ControlledTextField";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import CheckedItemModal from "../../CheckedItemModal";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";

type FieldArrayType = {
  bundle_item_detail_list: IBundleDetail[];
};

type ExtendedProps = IDefaultForm & {
  itemIds: string[];
  setItemIds: Dispatch<SetStateAction<string[]>>;
  itemIdsSnapshot: string[];
  setItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
};

const BundleTable = ({
  control,
  setValue,
  getValues,
  errors,
  disabled,
  itemIds,
  setItemIds,
  itemIdsSnapshot,
  setItemIdsSnapshot,
}: ExtendedProps) => {
  const { t } = useTranslation();
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    if (!disabled)
      setHeaders([
        { label: "", width: 50 },
        { label: t("inventory.items.index"), width: 200 },
        { label: t("inventory.quantity"), width: 150 },
        { label: t("inventory.unit"), width: 150 },
        { label: t("inventory.bundle.header.price_per_unit"), width: 170 },
        { label: t("inventory.bundle.header.price_all_unit"), width: 170 },
        { label: "", width: 50 },
      ]);
    else
      setHeaders([
        { label: "", width: 50 },
        { label: t("inventory.items.index"), width: 200 },
        { label: t("inventory.quantity"), width: 150 },
        { label: t("inventory.unit"), width: 150 },
        { label: t("inventory.bundle.header.price_per_unit"), width: 170 },
        { label: t("inventory.bundle.header.price_all_unit"), width: 170 },
      ]);
  }, [disabled, t]);

  const { fields, replace, remove } = useFieldArray<
    FieldArrayType,
    "bundle_item_detail_list",
    "id"
  >({
    control,
    name: "bundle_item_detail_list",
  });

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const addItemsHandler = (selectedItems: ISkuDetail[]) => {
    if (selectedItems) {
      const newItem = selectedItems.map((item) => ({
        reference_item_unique_id: item.item_sku?.item?.unique_id || "",
        reference_item: item.item_sku?.item,
        item_sku_name: item.sku_name,
        item_sku_detail: item,
        qty: 1,
      }));
      replace(newItem);
    }
    closeItemModalHandler();
  };

  const SumAllPurchasePrice = () => {
    const bundleList = useWatch({ control, name: "bundle_item_detail_list" });
    return (
      <Typography>
        {bundleList
          .reduce(
            (prev: any, curr: any) =>
              prev +
              curr.qty * (curr.reference_item?.purchase_standard_price || 0),
            0
          )
          .toLocaleString() ?? "-"}
      </Typography>
    );
  };

  const SumPurchasePrice = ({
    index,
    purchasePrice,
  }: {
    index: number;
    purchasePrice?: number;
  }) => {
    const watchQty = useWatch({
      control,
      name: `bundle_item_detail_list.${index}.qty`,
    });

    return (
      <Typography fontSize={14}>
        {((purchasePrice ?? 0) * watchQty).toLocaleString()}
      </Typography>
    );
  };

  return (
    <Fragment>
      {!disabled && (
        <Box my={2}>
          <CustomizedButton
            variant="outlined"
            title="เพิ่มสินค้า"
            onClick={openItemModalHandler}
            startIcon={<AddIcon />}
          />
        </Box>
      )}
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell align="center" width={header.width} key={index}>
                  <Typography
                    textAlign="center"
                    fontSize={14}
                    fontWeight={700}
                    width={header.width}
                  >
                    {header.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((list, index) => (
              <TableRow key={list.id}>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      minWidth: "90px",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      alt={list.reference_item?.name}
                      src={
                        list.reference_item?.img_url
                          ? list.reference_item?.img_url[0]
                          : ""
                      }
                      sx={{
                        width: 60,
                        height: 60,
                        border: "1px solid #BEBEBE",
                        borderRadius: "2px",
                      }}
                      variant="square"
                    >
                      <ImageOutlinedIcon
                        sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                        fontSize="medium"
                      />
                    </Avatar>
                  </Box>
                </TableCell>
                <TableCell>
                  {!disabled ? (
                    <Fragment>
                      <ControlledTextField
                        control={control}
                        name={`bundle_item_detail_list.${index}.item_sku_name`}
                        disabled
                      />
                      <Typography fontSize={10} color="#666666" my={0.5}>
                        {list.reference_item_unique_id}
                      </Typography>
                      <ControlledTextField
                        control={control}
                        name={`bundle_item_detail_list.${index}.reference_item.desc`}
                        disabled
                      />
                    </Fragment>
                  ) : (
                    <>
                      <Box display="flex" my={0.5} alignItems="center">
                        <Typography
                          color={(theme) =>
                            !list.reference_item?.is_active
                              ? theme.palette.error.main
                              : undefined
                          }
                        >
                          {list.item_sku_name}
                        </Typography>
                        {!list.reference_item?.is_active && (
                          <CustomizedTooltip
                            title={t("inventory.sentence.item_disabled")}
                          >
                            <ErrorOutlineIcon
                              sx={{ fontSize: "14px", ml: 1 }}
                            />
                          </CustomizedTooltip>
                        )}
                      </Box>
                      <Typography fontSize={10} color="#666666" my={0.5}>
                        {list.reference_item_unique_id}
                      </Typography>{" "}
                      <Typography my={0.5}>
                        {list.reference_item?.desc}
                      </Typography>
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  {disabled ? (
                    list.qty
                  ) : (
                    <ControlledNumberTextField
                      control={control}
                      name={`bundle_item_detail_list.${index}.qty`}
                      error={Boolean(
                        errors?.bundle_item_detail_list?.[index]?.qty
                      )}
                      helperText={
                        errors?.bundle_item_detail_list?.[index]?.qty?.message
                      }
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {disabled ? (
                    list.reference_item?.sales_uom?.name
                  ) : (
                    <TextField
                      value={list.reference_item?.sales_uom?.name}
                      autoComplete="off"
                      size="small"
                      disabled
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  <Typography fontSize={14}>
                    {list.reference_item?.purchase_standard_price?.toLocaleString() ??
                      "-"}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <SumPurchasePrice
                    index={index}
                    purchasePrice={list.reference_item?.purchase_standard_price}
                  />
                </TableCell>
                {!disabled && (
                  <TableCell align="center">
                    <IconButton
                      onClick={() => {
                        setItemIdsSnapshot((prev) =>
                          prev.filter((_, fIndex) => fIndex !== index)
                        );
                        setItemIds((prev) =>
                          prev.filter((_, fIndex) => fIndex !== index)
                        );
                        remove(index);
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="flex-end" mt={1} gap={10}>
        <Typography fontWeight="bold">รวมทั้งหมด</Typography>
        <SumAllPurchasePrice />
        <Typography>บาท</Typography>
      </Box>
      <CheckedItemModal
        showSelectItem={itemModal}
        closeItemTable={closeItemModalHandler}
        finishItemsSelect={addItemsHandler}
        itemIds={itemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIds={setItemIds}
        setItemIdsSnapshot={setItemIdsSnapshot}
        rowSelection="multiple"
      />
      {/* <ItemTableModal
        ref={gridRef}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
      /> */}
    </Fragment>
  );
};

export default BundleTable;
