import styled from "@emotion/styled";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useWatch, UseFieldArrayReplace } from "react-hook-form";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

import { bomSchema } from "../../Bom/schema";

import BomTableForm from "../../Bom/Table";
import BomDetailForm from "../../Bom/Detail";
import ModalUI from "../../../../UI/ModalUI";
import CheckedBomModal from "../../../../Table/CheckedBomModal";

import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../../Controller/ControlledTextField";

import { useModal } from "../../../../../hooks/use-modal";

import { IBom } from "../../../../../types/Manufacture/bom";
import { IDefaultForm } from "../../../../../types/global";
import { IManufactureOrder } from "../../../../../types/Manufacture/order";
import { useBomDescription } from "../../../../../hooks/use-bom-description";
import { bomQueryFormatter } from "../../../../../utils/Formatter/Bom";
import { useConfirmation } from "../../../../../hooks/use-confrimation";
import Confirmation from "../../../../UI/Confirmation";

type Props = IDefaultForm & {
  ingredientReplace: UseFieldArrayReplace<IManufactureOrder, "ingredient_list">;
  ingredientQtyCalculation: (isUpdateQty?: boolean) => void;
};

const BomDetail = ({
  control,
  errors,
  getValues,
  setValue,
  disabled,
  reset,
  ingredientQtyCalculation,
}: Props) => {
  const { t } = useTranslation();
  const [bomData, setBomData] = useState<IBom>();

  const {
    control: bomControl,
    formState: { errors: bomErrors },
    reset: bomReset,
    getValues: bomGetValues,
    setValue: bomSetValue,
  } = useForm({
    defaultValues: bomSchema,
  });

  const watchMinimunStockQty = useWatch({
    control: bomControl,
    name: "mfg_qty",
  });

  // const watchStatus = useWatch({
  //   control,
  //   name: "main_status",
  // });

  const watchItemId = useWatch({ control, name: "item_unique_id" });

  const {
    openBomDetail,
    openBomDetailHandler,
    closeBomDetailHandler,
    bomIds,
    setBomIds,
    bomIdsSnapshot,
    setBomIdsSnapshot,
  } = useBomDescription(control, bomReset);

  const StyledLink = styled(Link)<any>(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
  }));

  const {
    modal: showBomTable,
    openModalHandler: openBomTable,
    closeModalHandler: closeBomTable,
  } = useModal();

  const watchBomId = useWatch({ control, name: "bom_id" });
  const watchBomName = useWatch({ control, name: "bom_name" });
  // const watchProductionQty = useWatch({ control, name: "production_qty" });

  // const watchBomDetail = useWatch({ control, name: "bom_detail" });

  useEffect(() => {
    if (watchBomName) {
      setBomIds([watchBomName]);
      setBomIdsSnapshot([watchBomName]);
    }
  }, [setBomIds, setBomIdsSnapshot, watchBomName]);

  const handleBomValue = async (data: IBom) => {
    setValue("bom_id", data.id);
    setValue("bom_name", data.name);

    const { creator_unique_id, created_by, created_date, ...otherData } = data;

    const bom_detail = await bomQueryFormatter(otherData);

    // setValue("bom_detail", bom_detail);

    const attachment_list = getValues("attachment_list");
    const bom_attachment_list = bom_detail.attachment_list || [];

    setValue(
      "attachment_list",
      [...attachment_list, ...bom_attachment_list].splice(0, 10)
    );

    ingredientQtyCalculation(true);
    // ingredientQtyCalculation(bom_detail, true);
  };

  const onConfirmChangeBom = async () => {
    if (bomData) {
      const data = bomData;
      handleBomValue(data);
    } else {
      reset &&
        reset((prev: IBom) => ({
          ...prev,
          bom_id: "",
          bom_name: "",
          bom_detail: null,
        }));
    }
  };

  const finishBomSelectHandler = async (data: IBom) => {
    if (data) {
      if (watchBomId) {
        setBomData(data);
        openConfirmationHandler();
      } else {
        handleBomValue(data);
      }
    } else {
      reset &&
        reset((prev: IBom) => ({
          ...prev,
          bom_id: "",
          bom_name: "",
          bom_detail: null,
        }));
    }
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(onConfirmChangeBom);

  return (
    <Box mt={3}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography ml={1} fontWeight="bold">
          {t("manufacture.order.bom")}
        </Typography>
        {!disabled && watchItemId && (
          <CustomizedTooltip title="เลือกสูตรผลิต">
            <IconButton onClick={openBomTable} color="primary">
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="bom_name"
            label={t("manufacture.order.bom_name")}
            control={control}
            error={Boolean(errors?.bom_name)}
            helperText={errors?.bom_name && errors?.bom_name.message}
            disabled
            sx={{
              input: { visibility: disabled ? "hidden" : "visible" },
            }}
            InputProps={{
              startAdornment: disabled && watchBomName !== "" && (
                <InputAdornment position="start">
                  <StyledLink onClick={openBomDetailHandler}>
                    {watchBomName || t("manufacture.order.bom_name")}
                  </StyledLink>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!disabled && watchBomName && watchBomName !== "" && (
                    <CustomizedTooltip title="กดเพื่อดูรายละเอียดสูตรการผลิต">
                      <IconButton
                        onClick={openBomDetailHandler}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    </CustomizedTooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {!disabled && (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <CustomizedTooltip title="คำนวณ BOM ใหม่">
              <IconButton
                onClick={() => ingredientQtyCalculation(true)}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <RestartAltIcon />
              </IconButton>
            </CustomizedTooltip>
          </Grid>
        )}
      </Grid>
      <CheckedBomModal
        showSelectBom={showBomTable}
        closeBomTable={closeBomTable}
        finishBomSelect={finishBomSelectHandler}
        watchItemId={watchItemId}
        bomIds={bomIds}
        setBomIds={setBomIds}
        bomIdsSnapshot={bomIdsSnapshot}
        setBomIdsSnapshot={setBomIdsSnapshot}
      />
      <ModalUI
        open={openBomDetail}
        navigateTo={`/manufacture/bom/${watchBomId}`}
        handleClose={closeBomDetailHandler}
        fullWidth
        // title={bom.name ?? ""}
        maxWidth="lg"
      >
        <BomDetailForm
          control={bomControl}
          errors={bomErrors}
          reset={bomReset}
          getValues={bomGetValues}
          setValue={bomSetValue}
          disabled={true}
          minimumStockQty={parseFloat(watchMinimunStockQty ?? "0") ?? 0}
        />
        <Divider />
        <BomTableForm
          control={bomControl}
          errors={bomErrors}
          disabled={true}
          getValues={bomGetValues}
          setValue={bomSetValue}
        />
      </ModalUI>
      <Confirmation
        title="ยืนยันการเปลี่ยนสูตรการผลิต"
        message="หากเปลี่ยนสูตรการผลิต ส่วนประกอบเดิมจะถูกแทนที่ด้วยรายการใหม่ทั้งหมด"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </Box>
  );
};

export default BomDetail;
