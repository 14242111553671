import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useDisable } from "../../../../hooks/use-disable";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { NewCustomizedBox } from "../../../Custom/NewCustomizedBox";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledRadioGroup from "../../../Controller/ControlledRadioGroup";
import TagList from "../../../UI/TagList/InventoryTagList";

import { IRadio } from "../../../../types/global";
import {
  InventoryUniqueIdQuery,
  ItemEntityType,
  ModelType,
  useInventoryUniqueIdQuery,
} from "../../../../generated/inventory";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";

const SalesBomInfo = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { refetch: refetchUniqueId } =
    useInventoryUniqueIdQuery<InventoryUniqueIdQuery>(
      graphQLClientWithHeaderItem,
      {
        modelType: ModelType.ItemBom,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.utilGetUniqueId ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) generateUniqueId();
  }, [generateUniqueId, id]);

  const field = [
    {
      name: "unique_id",
      label: t("inventory.sales_bom.unique_id"),
      props: { sm: id ? 12 : 11, md: id ? 4 : 3.5 },
      required: true,
      error: errors?.unique_id,
      helperText: errors?.unique_id?.message,
      disabled: Boolean(id),
    },
    {
      name: "fetch_unique_id",
      props: { sm: 1, md: 0.5 },
    },
    {
      name: "name",
      label: t("inventory.sales_bom.name"),
      props: { sm: 12, md: 4 },
      required: true,
      error: errors?.name,
      helperText: errors?.name?.message,
    },
    {
      name: "seller_sku_name",
      label: t("inventory.items.seller_sku"),
      props: { sm: 12, md: 4 },
    },
    {
      name: "desc",
      testId: "item-description-text-field",
      label: t("inventory.sales_bom.desc"),
      props: { sm: 12 },
      error: errors?.desc,
      helperText: errors?.desc?.message,
      multiline: true,
    },
  ];

  const radioLists: IRadio[] = [
    { value: 1, label: t("status.active") },
    { value: 0, label: t("status.in_active") },
  ];

  return (
    <>
      <NewCustomizedBox>
        <Typography variant="subtitle2">
          {t("inventory.sales_bom.information")}
        </Typography>
        <Grid container spacing={2} mt={0} mb={2}>
          {field.map((item, index) =>
            index !== 1 ? (
              <Grid item {...item.props} key={index}>
                <ControlledTextField
                  control={control}
                  name={item.name}
                  label={item.label}
                  required={item.required}
                  error={Boolean(item.error)}
                  helperText={item.helperText?.toString()}
                  disabled={item.disabled || disabled}
                  multiline={item.multiline}
                  minRows={item.multiline ? 1 : undefined}
                  maxRows={item.multiline ? 10 : undefined}
                />
              </Grid>
            ) : (
              !id && (
                <Grid item {...item.props} key={index}>
                  <CustomizedTooltip title="เรียกรหัสชุดสินค้าใหม่">
                    <IconButton onClick={generateUniqueId} color="primary">
                      <RestartAltOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                </Grid>
              )
            )
          )}
        </Grid>
        <Box>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2">{t("inventory.status")}</Typography>
            <CustomizedTooltip
              title="ไม่สามารถหยุดใช้งานได้ หากกลุ่มสินค้ายังดำเนินการอยู่ในเอกสาร"
              placement="right"
              color="primary"
            >
              <ErrorOutlineIcon sx={{ fontSize: "14px", ml: 1 }} />
            </CustomizedTooltip>
          </Box>
          <ControlledRadioGroup
            control={control}
            name="is_active"
            radioLists={radioLists}
            disabled={disabled}
            row
          />
          <ControlledTextField
            control={control}
            label={t("inventory.sales_bom.remark_status")}
            name="remark_status"
            disabled={disabled}
            sx={{ mt: 1 }}
          />
        </Box>
        <Grid container spacing={1} my={1} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle2" mb={1}>
              {t("inventory.items.group_tag_list")}
            </Typography>
          </Grid>
          <TagList
            control={control}
            disabled={disabled}
            setValue={setValue}
            getValues={getValues}
            name="tag_list"
            entity={ItemEntityType.SalesBom}
          />
        </Grid>
      </NewCustomizedBox>
    </>
  );
};

export default SalesBomInfo;
