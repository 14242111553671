import { t } from "i18next";
import { formatNumber } from "./dataTransformer";

export const customChartTooltip = (
  name: "กำไร" | "ยอดขาย" | "ยอดซื้อ",
  nameColor: "#84B6F7" | "#FFABAB" | "#A488E7",
  month: string,
  year: number,
  profit?: number | null,
  yoyPercentage?: string | null,
  yoyColor: "#22915A" | "#E41B1B" | null = "#22915A"
) => {
  return `
        <div style="padding:8px; width:200px;">
          <div style="display:flex; justify-content:space-between;">
            <div style="display:flex; align-items:center; gap:4px;">
              <div style="width:14px; height:14px; border-radius:100%; background-color:${nameColor};"></div>
              <p style="color:${nameColor}; margin:0; font-size:14px;">${name}</p>
            </div>
            <p style="color:#737373; margin:0; font-size:14px;">${month} ${year}</p>
          </div>
          ${
            profit !== null && profit !== undefined
              ? `<h1 style="text-align:right; margin:0;">${formatNumber(
                  profit
                )}</h1>`
              : ""
          }
          ${
            yoyPercentage
              ? `<p style="text-align:right; color:${yoyColor}; margin:0; font-size:12px;">(${yoyPercentage}% YoY)</p>`
              : ""
          }
        </div>
      `;
};

export const customChartTooltipBarChart = (
  status: string,
  totalCount: number,
  totalAmount?: number
) => {
  const generateStatusHTML = (
    status: string,
    backgroundColor: string,
    textColor: string
  ) => `
  <div style="
    display: flex; 
    flex-direction: row; 
    justify-content: center; 
    align-items: center; 
    padding: 4px 8px; 
    width: 140px; 
    background: ${backgroundColor}; 
    border-radius: 4px;">
    <p style="
      color: ${textColor}; 
      margin: 0; 
      font-size: 14px;">
      ${t(`status.${status}`)}
    </p>
  </div>
`;

  const formatStatus = (status: string) => {
    switch (status) {
      case "draft":
        return generateStatusHTML(status, "#F2F2F2", "#737373");
      case "wait_accept":
      case "wait_approve":
      case "pending_manu":
        return generateStatusHTML(status, "#FFF1C5", "#E58000");
      case "not_approved":
        return generateStatusHTML(status, "#FCE8E8", "#E41B1B");
      case "accepted":
      case "approved":
      case "in_progress":
        return generateStatusHTML(status, "#E5F0FF", "#2167D3");
      case "expired":
        return generateStatusHTML(status, "#fff2f2", "#F4A4A4");
      case "partially_ordered":
      case "fully_ordered":
      case "partially_imported":
      case "fully_imported":
      case "finished":
        return generateStatusHTML(status, "#E6F9F0", "#22915A");
      case "cancelled":
        return generateStatusHTML(status, "#BDBDBD", "#FFFFFF");
      default:
        return null;
    }
  };

  return `
        <div style="padding:8px; width:200px; height: 100%;">
          <div style="display:flex; align-items:center;">
            <p style="font-weight: 600; margin:0; width: 65px; font-size:14px;">เอกสาร</p>
            <p style="margin: 0; font-size:14px;">${totalCount.toLocaleString()}</p>
          </div>
          ${
            totalAmount
              ? `<div style="display:flex; align-items:center;">
            <p style="font-weight: 600; margin:0; width: 65px; font-size:14px;">มูลค่ารวม</p>
            <p style="margin: 0; font-size:14px;">${formatNumber(
              totalAmount
            )} บาท</p>
          </div>`
              : ""
          }
          <div style="display:flex; align-items:center;">
            <p style="font-weight: 600; margin:0; width: 65px; font-size:14px;">สถานะ</p>
            ${formatStatus(status)}
          </div>
        </div>
      `;
};

export const customChartTooltipDonutChart = (
  itemColor: string,
  itemName: string,
  totalCount: number,
  itemPercentage: number
) => {
  return `
        <div style="padding:8px; width:200px;">
          <div style="display:flex; align-items:center; gap:4px;">
            <div style="height:14px; min-width: 14px; border-radius:100%; background-color:${itemColor};"></div>
            <p style="color:#333333; margin:0; font-size:14px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${itemName}</p>
            <p style="color:#333333; margin:0; font-size:14px; width: fit-content">(${itemPercentage}%)</p>
          </div>
          <h1 style="margin:0;">${formatNumber(totalCount)}</h1>
        </div>
      `;
};

export const handleChartReady = (chartWrapper: any) => {
  const chart = chartWrapper.getChart();
  if (chart) {
    const svg = chart.getContainer().getElementsByTagName("svg")[0];

    // Function to customize tooltip
    const customizeTooltip = () => {
      const tooltip = document.querySelector(
        ".google-visualization-tooltip"
      ) as HTMLElement;
      if (tooltip) {
        tooltip.childNodes.forEach((node) => {
          if (
            node.nodeType === Node.TEXT_NODE &&
            node.nodeValue &&
            node.nodeValue.includes("%")
          ) {
            node.remove(); // Remove the unwanted percentage
          }
        });

        // Adjust tooltip height
        tooltip.style.height = "auto";
        tooltip.style.height = `${tooltip.scrollHeight}px`; // Correct any white space

        // Move the tooltip to a fixed position below the chart
        tooltip.style.position = "absolute";
        tooltip.style.top = "50px"; // Adjust top position
        tooltip.style.pointerEvents = "none"; // Prevent interaction with the tooltip
      }
    };

    // Function to apply customizations to legend items
    const applyLegendCustomizations = () => {
      const legendGroups = Array.from(
        svg.querySelectorAll("g > g") as NodeListOf<SVGGElement>
      ).filter((group) => {
        return group.querySelector("rect") && group.querySelector("text");
      });

      legendGroups.forEach((group) => {
        const rect = Array.from(group.querySelectorAll("rect")).find((r) => {
          const fill = r.getAttribute("fill");
          const fillOpacity = r.getAttribute("fill-opacity");
          return fill && fill !== "#ffffff" && fillOpacity !== "0";
        }) as SVGRectElement | null;

        if (rect) {
          const x = rect.getAttribute("x");
          const y = rect.getAttribute("y");
          const fill = rect.getAttribute("fill");

          const circle = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "circle"
          );
          circle.setAttribute("cx", (parseFloat(x || "0") + 6).toString());
          circle.setAttribute("cy", (parseFloat(y || "0") + 6).toString());
          circle.setAttribute("r", "6");
          circle.setAttribute("fill", fill || "");

          rect.parentNode?.replaceChild(circle, rect);
        }
      });
    };

    // Apply initial customizations
    applyLegendCustomizations();

    // Observe tooltip changes and reapply customizations
    const tooltipObserver = new MutationObserver(() => {
      customizeTooltip();
    });

    const tooltipContainer = document.body; // Tooltips are often added to the body
    if (tooltipContainer) {
      tooltipObserver.observe(tooltipContainer, {
        childList: true,
        subtree: true,
      });
    }

    // Observe legend changes
    const legendObserver = new MutationObserver(() => {
      applyLegendCustomizations();
    });

    legendObserver.observe(svg, { childList: true, subtree: true });

    // Disconnect observers when chart is destroyed to avoid memory leaks
    return () => {
      tooltipObserver.disconnect();
      legendObserver.disconnect();
    };
  }
};
