import { Box, IconButton, Typography } from "@mui/material";

import LaunchIcon from "@mui/icons-material/Launch";
import CircleIcon from "@mui/icons-material/Circle";

import { IDefaultForm } from "../../../../types/global";
import { IIngredient } from "../../../../types/Manufacture";

import ItemQty from "../../../UI/ItemQty";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { useItemSkuQty } from "../../../../hooks/use-item-sku-qty";

interface Props {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: boolean;
  field: IIngredient;
  index: number;
}

const ItemNameCellWaste = ({
  control,
  field,
  errors,
  index,
  disabled,
}: Props) => {
  const { isSufficient, formatData, uom } = useItemSkuQty(
    control,
    index,
    "manufacture_order",
    "waste_production_list"
  );
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "stretch",
          flex: 1,
          textAlign: "left",
        }}
      >
        {disabled ? (
          <Typography
            variant="body2"
            align="left"
            mt={1}
            color="primary"
            onClick={() =>
              window.open(
                `/inventory/items/${field.item_unique_id}?tab=item&subtab=general`,
                "_blank"
              )
            }
            sx={{ cursor: "pointer" }}
          >
            {field.item_name}
          </Typography>
        ) : (
          <ControlledTextField
            control={control}
            error={Boolean(
              errors?.waste_production_list &&
                errors?.waste_production_list[index] &&
                errors?.waste_production_list[index]?.item_name
            )}
            helperText={
              errors?.waste_production_list &&
              errors?.waste_production_list[index] &&
              errors?.waste_production_list[index]?.item_name &&
              errors?.waste_production_list[index]?.item_name?.message
            }
            name={`waste_production_list.${index}.item_name`}
            sx={{ mt: 1 }}
          />
        )}
        <Typography
          variant="overline"
          sx={{
            my: 0,
            ml: !disabled ? 2 : 0,
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {field.item_unique_id}
        </Typography>
        {disabled ? (
          <Typography
            variant="body2"
            align="left"
            gutterBottom
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            {field.item_description}
          </Typography>
        ) : (
          <ControlledTextField
            control={control}
            name={`waste_production_list.${index}.item_description`}
            multiline
            minRows={1}
            maxRows={3}
            sx={{ mb: 1 }}
            error={Boolean(
              errors?.waste_production_list &&
                errors?.waste_production_list[index] &&
                errors?.waste_production_list[index]?.item_description
            )}
            helperText={
              errors?.waste_production_list &&
              errors?.waste_production_list[index] &&
              errors?.waste_production_list[index]?.item_description &&
              errors?.waste_production_list[index]?.item_description?.message
            }
          />
        )}
      </Box>
      {!disabled && (
        <CustomizedTooltip title="กดเพื่อดูรายละเอียดสินค้า">
          <IconButton
            size="small"
            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
            onClick={() =>
              window.open(
                `/inventory/items/${field.item_unique_id}?tab=item&subtab=general`,
                "_blank"
              )
            }
          >
            <LaunchIcon fontSize="small" />
          </IconButton>
        </CustomizedTooltip>
      )}
      <CustomizedTooltip
        title={
          <ItemQty
            formatData={formatData}
            isSufficient={isSufficient}
            uom={uom}
          />
        }
      >
        <IconButton size="small" sx={{ color: "#8FCBB1", fontSize: "13px" }}>
          <CircleIcon fontSize="inherit" />
        </IconButton>
      </CustomizedTooltip>
    </Box>
  );
};

export default ItemNameCellWaste;
