import { Box, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import Chart from "react-google-charts";
import {
  customChartTooltipBarChart,
  handleChartReady,
} from "../../../../../utils/chartUtils";
import { Link } from "react-router-dom";
import EmptyBarChart from "../EmptyBarChart";

type Props = {
  data: any;
  isFetching: boolean;
  isHaveApproval: boolean;
};

const QuotationChart = ({ data, isFetching, isHaveApproval }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const statusData = [
    { key: "draft", color: "#E2E2E2", label: t("status.draft") },
    {
      key: "wait_approve",
      color: "#FFA34D",
      label: t("status.wait_approve"),
      show: isHaveApproval,
    },
    {
      key: "not_approved",
      color: "#E7614B",
      label: t("status.not_approved"),
      show: isHaveApproval,
    },
    { key: "wait_accept", color: "#6CDAEB", label: t("status.wait_accept") },
    { key: "accepted", color: "#4F89E3", label: t("status.accepted") },
    { key: "finished", color: "#2CBA7A", label: t("status.finished") },
    // { key: "cancelled", color: "#E2E2E2", label: t("status.cancelled") },
  ];

  const formatData = [
    [
      t("sales.quotation.index"),
      ...statusData
        .filter((status) => status.show !== false)
        .flatMap((status) => [
          status.label,
          { role: "tooltip", p: { html: true } },
        ]),
    ],
    [
      t("status.index"),
      ...statusData
        .filter((status) => status.show !== false)
        .flatMap((status) => [
          data?.[status.key]?.count || 0,
          customChartTooltipBarChart(
            status.key,
            data?.[status.key]?.count || 0,
            data?.[status.key]?.amount || 0
          ),
        ]),
    ],
  ];

  const total = statusData
    .filter((status) => status.show !== false)
    .reduce((acc, status) => acc + (data?.[status.key]?.count || 0), 0);

  const options = {
    chartArea: {
      width: "100%",
      height: "100%",
      top: 16,
    },
    isStacked: "percent",
    hAxis: { textPosition: "none", gridlines: { count: 0 } },
    vAxis: { textPosition: "none", gridlines: { count: 0 } },
    baselineColor: "transparent",
    legend: { position: "none" },
    tooltip: {
      isHtml: true,
      textStyle: {
        fontName: "Kanit",
        fontSize: 12,
        color: "#737373",
      },
    },
    bar: { groupWidth: "100%" },
    colors: statusData
      .filter((status) => status.show !== false)
      .map((status) => status.color),
  };

  return (
    <CustomizedBox
      height={183}
      border={`1px solid #E2E2E2`}
      margin={isMobile ? "unset" : "0.5rem 0"}
      padding={"1rem"}
      boxShadow={"unset"}
    >
      <Link to={"/sales/quotation"} style={{ textDecoration: "none", flex: 1 }}>
        <Box display={"flex"} gap={0.5} alignItems={"center"}>
          <Typography color={"secondary.main"} fontWeight={600}>
            {t("sales.quotation.index")}
          </Typography>
        </Box>
      </Link>

      {isFetching ? (
        <Skeleton variant="rectangular" height={111} animation={"wave"} />
      ) : (
        <>
          {total === 0 ? (
            <EmptyBarChart />
          ) : (
            <Chart
              chartType="BarChart"
              width={"100%"}
              height={42}
              data={formatData}
              options={options}
              chartEvents={[
                {
                  eventName: "ready",
                  callback: ({ chartWrapper }: { chartWrapper: any }) => {
                    handleChartReady(chartWrapper);
                  },
                },
                {
                  eventName: "select",
                  callback: ({ chartWrapper }: { chartWrapper: any }) => {
                    const chart = chartWrapper.getChart();
                    if (chart) {
                      chart.setSelection([]);
                    }
                  },
                },
              ]}
            />
          )}

          <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
            {statusData
              .filter((item) => item.show !== false)
              .map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    width: "calc(33.33% - 8px)",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: item.color,
                      width: 12,
                      height: 12,
                      borderRadius: "50%",
                    }}
                  />
                  <Typography sx={{ fontSize: 12, color: "#737373" }}>
                    {item.label}
                  </Typography>
                </Box>
              ))}
          </Box>
        </>
      )}
    </CustomizedBox>
  );
};

export default QuotationChart;
