import { useState } from "react";
import { useStateContext } from "../../../../contexts/auth-context";
import TotalSalesPurchaseByYear from "./TotalSalesPurchaseByYear";
import { Box, Tab, Tabs, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import InventoryDashboard from "./Inventory";
import ManufactureDashboard from "./Manufacture";
import SingleInputDateRangePicker from "./DateRange";
import dayjs from "dayjs";
import { IDashboardInput } from "../../../../types/Dashboard";
import SalesDashboard from "./Sales";
import PurchaseDashboard from "./Purchase";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const DashboardV2 = () => {
  const {
    state: { permissions },
  } = useStateContext();

  const { t } = useTranslation();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [input, setInput] = useState<IDashboardInput>({
    started_date: dayjs().startOf("day"),
    ended_date: dayjs().endOf("day"),
  });

  const isShowInventory = permissions && permissions.inventory.view === "ALLOW";
  const isShowManufacture =
    permissions && permissions.manufacture.view === "ALLOW";
  const isShowSales = permissions && permissions.sales.view === "ALLOW";
  const isShowPurchase = permissions && permissions.purchase.view === "ALLOW";

  const isShowTotalSalesPurchaseByYear =
    permissions && (isShowSales || isShowPurchase);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  // Dynamically calculate visible tabs
  const visibleTabs = [
    isShowSales && {
      label: t("sales.index"),
      component: <SalesDashboard input={input} />,
    },
    isShowInventory && {
      label: t("inventory.index"),
      component: <InventoryDashboard input={input} />,
    },
    isShowPurchase && {
      label: t("purchase.index"),
      component: <PurchaseDashboard input={input} />,
    },
    isShowManufacture && {
      label: t("manufacture.index"),
      component: <ManufactureDashboard input={input} />,
    },
  ].filter(Boolean) as any[]; // Filter out false values

  return (
    <>
      {isShowTotalSalesPurchaseByYear && <TotalSalesPurchaseByYear />}
      {visibleTabs.length > 0 && (
        <>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            flexDirection={isMobile ? "column" : "row"}
            mt={2}
            gap={2}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: isMobile ? "100%" : "fit-content",
              }}
            >
              {visibleTabs.map((tab, index) => (
                <Tab key={index} label={tab.label} />
              ))}
            </Tabs>
            <Box
              alignSelf={isMobile ? "flex-end" : "center"}
              mb={isMobile ? 2 : 0}
            >
              <SingleInputDateRangePicker input={input} setInput={setInput} />
            </Box>
          </Box>
          {visibleTabs.map((tab, index) => (
            <CustomTabPanel key={index} value={value} index={index}>
              {tab.component}
            </CustomTabPanel>
          ))}
        </>
      )}
    </>
  );
};

export default DashboardV2;
