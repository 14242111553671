import * as Yup from "yup";
import dayjs from "dayjs";
import { ISalesOrder } from "../../../../types/Sales/salesOrder";
import {
  AdditionalDiscountType,
  PriceVatType,
} from "../../../../generated/sales";

export const salesOrderSchema: ISalesOrder = {
  unique_id: "",
  created_by: null,
  created_date: dayjs().toDate(),
  credit_day: 0,
  issue_date: dayjs(),
  due_date: dayjs().add(7, "day"),
  delivery_date: dayjs().add(7, "day"),
  additional_discount: 0,
  additional_discount_type: AdditionalDiscountType.Baht,
  shipping_cost: 0,
  price_vat_type: PriceVatType.ExcludedVat,
  customer_contact: {
    unique_id_name: "",
    name: "",
    phone: "",
    email: "",
    fax: "",
    identity_no: "",
    branch: "",
    billing_address: {
      address: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
    delivery_address: {
      address_type: "",
      address_contact_name: "",
      address_contact_phone: "",
      address: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  remark: "",
  item_list: [],
  net_amount: 0,
  pre_vat_amount: 0,
  related_user_list: [],
  sales_contact_list: [],
  sub_total: 0,
  total_amount: 0,
  vat_0_amount: 0,
  vat_7_amount: 0,
  vat_amount: 0,
  vat_exempted_amount: 0,
  customer_contact_unique_id: null,
  withholding_tax_amount: 0,
  external_reference_id: "",
  tag_list: [],
  payment_type: "",
  sales_channel: "",
  account_id: "",
  attachment_list: [],
};

const salesBomItemListSchema = Yup.object().shape({
  item_name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
  item_sku_desc: Yup.string()
    .max(500, "กรอกได้ไม่เกิน 500 ตัวอักษร")
    .nullable(),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนที่มากกว่า 0")
    .required("กรุณาระบุจำนวนสินค้า"),
  uom: Yup.string().required("กรุณาเลือกหน่วย"),
  price_per_unit: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุราคาต่อหน่วยที่มากกว่า 0")
    .required("กรุณาระบุราคาต่อหน่วย"),
  discount: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
});

const salesOrderItemListSchema = Yup.object().shape({
  item_name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
  item_sku_desc: Yup.string()
    .max(500, "กรอกได้ไม่เกิน 500 ตัวอักษร")
    .nullable(),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนที่มากกว่า 0")
    .required("กรุณาระบุจำนวนสินค้า"),
  uom: Yup.string().required("กรุณาเลือกหน่วย"),
  price_per_unit: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    // .min(0, "ราคาต่อหน่วยไม่ต่ำกว่า 0")
    .required("กรุณาระบุราคาต่อหน่วย"),
  discount: Yup.number().transform((value) =>
    isNaN(value) || value === null || value === undefined ? 0 : value
  ),
  sales_bom_item: Yup.array()
    .of(salesBomItemListSchema)
    .when("item_type", (item_type, schema) => {
      if (item_type[0] === "bom") {
        return schema.min(1, "กรุณาเพิ่มสินค้าย่อยอย่างน้อย 1 ชิ้น");
      }
      return schema;
    }),
});

export const salesOrderValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date().required("กรุณาระบุวันที่ออกเอกสาร"),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
  customer_contact_unique_id: Yup.string().required("กรุณาระบุลูกค้า"),
  item_list: Yup.array()
    .of(salesOrderItemListSchema)
    .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
  remark: Yup.string().max(500, "กรอกได้ไม่เกิน 500 ตัวอักษร"),
});
