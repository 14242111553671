import { useTranslation } from "react-i18next";
import { IDashboardInput } from "../../../../../types/Dashboard";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import { useEffect } from "react";
import { Grid, Theme, useMediaQuery } from "@mui/material";
import {
  MostPurchaseItemByVendorQuery,
  MostPurchaseItemQuery,
  PurchaseApprovalTemplatesQuery,
  PurchaseOrderByStatusQuery,
  PurchaseRequestByStatusQuery,
  useMostPurchaseItemByVendorQuery,
  useMostPurchaseItemQuery,
  usePurchaseApprovalTemplatesQuery,
  usePurchaseOrderByStatusQuery,
  usePurchaseRequestByStatusQuery,
} from "../../../../../generated/purchase";
import TotalPurchaseChart from "./TotalPurchaseChart";
import PurchaseRequestChart from "./PurchaseRequestChart";
import PurchaseOrderChart from "./PurchaseOrderChart";

type Props = {
  input: IDashboardInput;
};

const PurchaseDashboard = ({ input }: Props) => {
  const { t } = useTranslation();
  const graphQLClient = createGraphQLClientWithMiddleware("purchase");

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const {
    data: mostPurchaseItem,
    refetch: refetchMostPurchaseItem,
    isFetching: isFetchingMostPurchaseItem,
  } = useMostPurchaseItemQuery<MostPurchaseItemQuery>(
    graphQLClient,
    {
      input: {
        ...input,
        top: 6,
      },
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
      cacheTime: 0,
    }
  );

  const {
    data: mostPurchaseItemByVendor,
    refetch: refetchMostPurchaseItemByVendor,
    isFetching: isFetchingMostPurchaseItemByVendor,
  } = useMostPurchaseItemByVendorQuery<MostPurchaseItemByVendorQuery>(
    graphQLClient,
    {
      input: {
        ...input,
        top: 6,
      },
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
      cacheTime: 0,
    }
  );

  const {
    data: purchaseRequest,
    refetch: refetchPurchaseRequest,
    isFetching: isFetchingPurchaseRequest,
  } = usePurchaseRequestByStatusQuery<PurchaseRequestByStatusQuery>(
    graphQLClient,
    {
      purchaseByStatusInput: input,
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
      cacheTime: 0,
    }
  );

  const {
    data: purchaseOrder,
    refetch: refetchPurchaseOder,
    isFetching: isFetchingPurchaseOrder,
  } = usePurchaseOrderByStatusQuery<PurchaseOrderByStatusQuery>(
    graphQLClient,
    {
      purchaseByStatusInput: input,
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
      cacheTime: 0,
    }
  );

  const { data: approvalTemplates } =
    usePurchaseApprovalTemplatesQuery<PurchaseApprovalTemplatesQuery>(
      graphQLClient,
      {},
      {
        cacheTime: 0,
      }
    );

  const isPurchaseRequestHaveApproval =
    approvalTemplates?.purchaseApprovalTemplates?.some(
      (template: any) =>
        template.document_type === "purchase_request" &&
        template.is_have_approval === true
    ) || false;

  const isPurchaseOrderHaveApproval =
    approvalTemplates?.purchaseApprovalTemplates?.some(
      (template: any) =>
        template.document_type === "purchase_order" &&
        template.is_have_approval === true
    ) || false;

  useEffect(() => {
    refetchMostPurchaseItem();
    refetchMostPurchaseItemByVendor();
    refetchPurchaseRequest();
    refetchPurchaseOder();
  }, [
    refetchMostPurchaseItem,
    refetchMostPurchaseItemByVendor,
    refetchPurchaseRequest,
    refetchPurchaseOder,
  ]);

  return (
    <Grid container spacing={isMobile ? 2 : 1.5}>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <TotalPurchaseChart
          name={t("dashboard.total_purchase_by_item")}
          data={mostPurchaseItem?.MostPurchaseItem}
          isFetching={isFetchingMostPurchaseItem}
          type="total_purchase_by_item"
          // url="/manufacture/order?filter=pending_manu"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <TotalPurchaseChart
          name={t("dashboard.total_purchase_by_vendor")}
          data={mostPurchaseItemByVendor?.MostPurchaseItemByVendor}
          isFetching={isFetchingMostPurchaseItemByVendor}
          type="total_purchase_by_vendor"
          // url="/manufacture/order?filter=in_progress"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <PurchaseRequestChart
          data={purchaseRequest?.PurchaseRequestByStatus}
          isFetching={isFetchingPurchaseRequest}
          isHaveApproval={isPurchaseRequestHaveApproval}
        />
        <PurchaseOrderChart
          data={purchaseOrder?.PurchaseOrderByStatus}
          isFetching={isFetchingPurchaseOrder}
          isHaveApproval={isPurchaseOrderHaveApproval}
        />
      </Grid>
    </Grid>
  );
};

export default PurchaseDashboard;
