import { useState, Fragment, useRef, useCallback, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import { IBreadcrumbsAndMenu, ITab, IExporter } from "../../../types/global";

import HeaderLayout from "../../../components/UI/HeaderLayout";
import ManufactureOrderTable from "../../../components/Table/Manufacture/Order";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import ButtonLayout from "../../../components/UI/ButtonLayout";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SortIcon from "@mui/icons-material/Sort";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import useConfigurableAgGridEditButtonOptions, {
  onConfigurableAgGridOptionSelect,
} from "../../../components/UI/ConfigurableAgGrid/useConfigurableAgGridEditButtonOptions";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import ExporterModal from "../../../components/UI/ExporterModal";
import { useModal } from "../../../hooks/use-modal";
import { useSnackbar } from "notistack";
import {
  useGenerateExportQuery,
  ExportGenerateInput,
  GenerateExportQuery,
  ManufacExportType,
} from "../../../generated/manufacture";
import { useStateContext } from "../../../contexts/auth-context";
import ConfigurableAgGridEditButton from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGridEditButton";
import { ConfigurableAgGridReact } from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGrid";
import GanttChart from "../../../components/UI/GanttChart/GanttChart";
import GanttChartFilter from "../../../components/UI/GanttChart/GanttChartFilter";
import { useForm } from "react-hook-form";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import dayjs from "dayjs";
import { GanttData } from "../../../types/Manufacture/gantt";
import { GanttStatus } from "../../../components/UI/GanttChart/GanttChartStatus";
import { MANUFACTURE_ORDER_AGGRID } from "../../../services/AgGrid/ManufactureAgGrid";
import { formatDataForGantt } from "../../../utils/Formatter/ManufactureOrder";

const ManufactureOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tableRef = useRef<ConfigurableAgGridReact<any>>();
  const ganttRef = useRef<any>(null);
  const isFilter = searchParams.get("filter");
  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const [startDate, setStartDate] = useState(
    dayjs().hour(0).minute(0).second(0).subtract(1, "day")
  );
  const [endDate, setEndDate] = useState(
    dayjs().hour(23).minute(59).second(59).add(5, "day")
  );
  const [formattedData, setFormattedData] = useState<GanttData>({
    tasks: [],
    links: [],
  });
  const [dataStatus, setDataStatus] = useState<GanttStatus>({
    draft: 0,
    pending_manu: 0,
    in_progress: 0,
    finished: 0,
    cancelled: 0,
  });
  const [countParentTask, setCountParentTask] = useState(0);
  const [ganttCollapsed, setGanttCollapsed] = useState(false);

  const getInitialGanttFilter = () => {
    try {
      const savedFilter = localStorage.getItem("ganttFilter");
      if (savedFilter) {
        return JSON.parse(savedFilter);
      }
    } catch (error) {
      console.log(error);
    }

    return {
      item_unique_id: "",
      item_name: "",
      type: [],
      tag_list: [],
      status: ["draft", "pending_manu", "in_progress", "finished"],
      related_user_list: [],
      reference_unique_id: "",
      customer_name: "",
    };
  };

  const initialGanttFilter = getInitialGanttFilter();
  const [ganttFilter, setGanttFilter] = useState(initialGanttFilter);

  const methods = useForm({
    defaultValues: {
      selectedDate: dayjs(),
      ...initialGanttFilter,
    },
  });

  const { control, watch, setValue, getValues } = methods;

  const handleToggleExpand = () => {
    if (ganttRef.current) {
      ganttRef.current.toggleTasks();
    }
  };

  const handleDateChange = (direction: "next" | "prev") => {
    const newStartDate =
      direction === "next"
        ? startDate.add(1, "week")
        : startDate.subtract(1, "week");
    const newEndDate =
      direction === "next"
        ? endDate.add(1, "week")
        : endDate.subtract(1, "week");
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleFilterClick = () => {
    const item_unique_id = getValues("item_unique_id");
    const item_name = getValues("item_name");
    const type = getValues("type");
    const tag_list = getValues("tag_list");
    const status = getValues("status");
    const related_user_list = getValues("related_user_list");
    const reference_unique_id = getValues("reference_unique_id");
    const customer_name = getValues("customer_name");

    const newFilter = {
      item_unique_id,
      item_name,
      type,
      tag_list,
      status,
      related_user_list,
      reference_unique_id,
      customer_name,
    };

    setGanttFilter(newFilter);
    localStorage.setItem("ganttFilter", JSON.stringify(newFilter));
  };

  const handleClearFilterClick = () => {
    const defaultFilter = {
      item_unique_id: "",
      item_name: "",
      type: [],
      tag_list: [],
      status: ["draft", "pending_manu", "in_progress", "finished"],
      related_user_list: [],
      reference_unique_id: "",
      customer_name: "",
    };

    setGanttFilter(defaultFilter);

    Object.entries(defaultFilter).forEach(([key, value]) => {
      setValue(key as any, value);
    });

    localStorage.setItem("ganttFilter", JSON.stringify(defaultFilter));
  };

  const fetchGanttData = useCallback(async () => {
    try {
      const createFilterModel = (condition: number) => {
        const filterModel: any = {
          aggrid_status: {
            filterType: "set",
            values: ganttFilter.status,
          },
        };

        if (condition === 1) {
          filterModel.production_date = {
            filterType: "date",
            type: "inRange",
            dateFrom: startDate.toDate(),
            dateTo: endDate.toDate(),
          };
        }

        if (condition === 2) {
          filterModel.production_completion_date = {
            filterType: "date",
            type: "inRange",
            dateFrom: startDate.toDate(),
            dateTo: endDate.toDate(),
          };
        }

        if (ganttFilter.item_unique_id) {
          filterModel.item_unique_id = {
            filterType: "text",
            type: "contains",
            filter: ganttFilter.item_unique_id,
          };
        }

        if (ganttFilter.item_name) {
          filterModel.item_name = {
            filterType: "text",
            type: "contains",
            filter: ganttFilter.item_name,
          };
        }

        if (ganttFilter.tag_list && ganttFilter.tag_list.length > 0) {
          filterModel.tag_list = {
            filterType: "objectArray",
            filter: {
              name: {
                filterType: "set",
                values: ganttFilter.tag_list,
              },
            },
            type: "some",
          };
        }

        if (ganttFilter.customer_name) {
          filterModel.customer_name = {
            filterType: "text",
            type: "contains",
            filter: ganttFilter.customer_name,
          };
        }

        return filterModel;
      };

      const firstFilterModel = createFilterModel(1);
      const secondFilterModel = createFilterModel(2);

      const { manufactureOrdersFindManyAggrid: firstMOData } =
        await graphQLClientWithHeaderManufacture.request(
          MANUFACTURE_ORDER_AGGRID,
          {
            aggridInput: {
              startRow: 0,
              endRow: 10000,
              filterModel: firstFilterModel,
            },
          }
        );
      const { manufactureOrdersFindManyAggrid: secondMOData } =
        await graphQLClientWithHeaderManufacture.request(
          MANUFACTURE_ORDER_AGGRID,
          {
            aggridInput: {
              startRow: 0,
              endRow: 10000,
              filterModel: secondFilterModel,
            },
          }
        );

      const mergeData = [
        ...new Map(
          [...firstMOData.data, ...secondMOData.data].map((item) => [
            item.id,
            item,
          ])
        ).values(),
      ];

      const { ganttData, ganttStatus, countParentTask } = formatDataForGantt(
        mergeData,
        ganttFilter,
        startDate.toDate(),
        endDate.toDate()
      );

      setCountParentTask(countParentTask);
      setDataStatus(ganttStatus);
      setFormattedData({ tasks: ganttData, links: [] });
    } catch (error) {
      console.error(error);
    }
  }, [startDate, endDate, ganttFilter]);

  useEffect(() => {
    fetchGanttData();
    setGanttCollapsed(false);
  }, [ganttFilter, startDate, endDate]);

  const selectedDate = watch("selectedDate");
  useEffect(() => {
    const newStartDate = dayjs(selectedDate)
      .hour(0)
      .minute(0)
      .second(0)
      .subtract(1, "day");
    const newEndDate = dayjs(selectedDate)
      .hour(23)
      .minute(59)
      .second(59)
      .add(5, "day");
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [selectedDate]);

  const { enqueueSnackbar } = useSnackbar();
  const configrableGridOptions = useConfigurableAgGridEditButtonOptions();
  const { modal, openModalHandler, closeModalHandler } = useModal();
  const [exportInput, setExportInput] = useState<ExportGenerateInput | null>(null);
  const {
    state: {authUser, permissions},
  } = useStateContext()
  const [aggridExport, setAggridExport] = useState<any>();

  const graphqlClient = createGraphQLClientWithMiddleware("manufacture");

  const manufactureTypeOptions = [
    {
      label: "รายเอกสาร",
      value: "manufac_order_report",
    }
  ]

  const options = [
    {
      value: "นำออก",
    },
    ...configrableGridOptions,
  ];

  const { refetch: refetchData, isLoading: isExporting } =
    useGenerateExportQuery<GenerateExportQuery>(
      graphqlClient,
      {
        input: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: IExporter) => {
    if (data) {
      const { type, start_date, end_date } = data;
      setExportInput({
        user_unique_id: authUser?.unique_id,
        export_type: ManufacExportType.ManufacOrderReport,
        start_date: start_date,
        end_date: end_date,
        params: null
      });
    }
  };

  useEffect(() => {
      const fetchDataAndOpenWindow = async () => {
        try {
          const { data: exportData } = await refetchData();
          window.open(exportData?.GenerateExport || "", "_blank");
          enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
            variant: "success",
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
            variant: "error",
          });
        }
      };
  
      if (exportInput !== null) {
        fetchDataAndOpenWindow();
      }
    }, [enqueueSnackbar, exportInput, refetchData, t]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.order.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.pending_manu"),
      path: `${pathname}?filter=pending_manu`,
    },
    {
      label: t("status.in_progress"),
      path: `${pathname}?filter=in_progress`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const [view, setView] = useState<string>("table");

  const handleView = (_: any, newView: any) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const renderView = (view: string) => {
    switch (view) {
      case "table":
        return (
          <>
            <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
            <ManufactureOrderTable isFilter={isFilter} ref={tableRef} />
          </>
        );
      case "gantt":
        return (
          <>
            <GanttChartFilter
              control={control}
              setValue={setValue}
              startDate={startDate}
              endDate={endDate}
              ganttCollapsed={ganttCollapsed}
              setGanttCollapsed={setGanttCollapsed}
              handleDateChange={handleDateChange}
              handleFilterCilck={handleFilterClick}
              handleClearFilterClick={handleClearFilterClick}
              handleToggleExpand={handleToggleExpand}
              ganttStatus={dataStatus}
              sx={{ marginBottom: "20px" }}
            />
            <GanttChart
              ref={ganttRef}
              data={formattedData}
              countParentTask={countParentTask}
              watch={watch}
              startDate={startDate}
              endDate={endDate}
              ganttCollapsed={ganttCollapsed}
              setGanttCollapsed={setGanttCollapsed}
            />
          </>
        );
      default:
        return (
          <>
            <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
            <ManufactureOrderTable isFilter={isFilter} ref={tableRef} />
          </>
        );
    }
  };

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("manufacture.order.index")}</Typography>
        <ButtonLayout>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              alignContent: "center",
              height: "35px",
            }}
          >
            <CustomizedButton
              title={`สร้าง${t("manufacture.order.index")}`}
              variant="contained"
              onClick={() => navigate(`/manufacture/order/add`)}
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "24px",
              }}
            />
            <ToggleButtonGroup
              color="primary"
              value={view}
              size="small"
              exclusive
              onChange={handleView}
              aria-label="view"
            >
              <ToggleButton value="table" aria-label="table view">
                <FormatListBulletedIcon />
              </ToggleButton>
              <ToggleButton
                disabled
                value="calendar"
                aria-label="calendar view"
              >
                <CalendarTodayIcon />
              </ToggleButton>
              <ToggleButton value="gantt" aria-label="gantt view">
                <SortIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            {view === "table" && (
              <CustomizedMenuOptions
                isIcon
                label="item-options"
                options={options}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `นำออก`:
                      openModalHandler();
                      break;
                    default:
                      onConfigurableAgGridOptionSelect(
                        value.innerText,
                        tableRef
                      );
                  }
                }}
              />
            )}
          </Box>
        </ButtonLayout>
      </HeaderLayout>
      {renderView(view)}
      <ExporterModal
        open={modal}
        closeModalHandler={closeModalHandler}
        typeOptions={manufactureTypeOptions}
        exportHandler={exportHandler}
      />
    </Fragment>
  );
};

export default ManufactureOrder;
