import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import { IGoodsReceive } from "../../../../types/Inventory/goodsReceive";
import { IDefaultForm } from "../../../../types/global";
import { useFieldArray } from "react-hook-form";
import {
  formatDate,
  formatDateTimeNoAMPM,
} from "../../../../utils/Formatter/Date";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { InventoryDocumentStatus } from "../../../../generated/inventory";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { ITraceEntry } from "../../../../types/Inventory";
import { formatNumber } from "../../../../utils/dataTransformer";

type Props = {
  control: IDefaultForm<IGoodsReceive>["control"];
  nestedIndex: number;
  status?: string | null;
  printBarcodeHandler: (traceEntryList: ITraceEntry[]) => void;
};

const SerialListScan = ({
  control,
  nestedIndex,
  status,
  printBarcodeHandler,
}: Props) => {
  const { fields, update } = useFieldArray({
    control,
    name: `trace_entry_list[${nestedIndex}].serial_list` as `trace_entry_list`,
  });

  const rescanBarcodeHandler = (index: number) => {
    const { id, ...otherFields } = fields[index];
    update(index, {
      ...otherFields,
      status: InventoryDocumentStatus.IsActive,
      source_bin_location: undefined,
      source_bin_location_id: undefined,
      scanned_by: undefined,
      posted_date: undefined,
    });
  };

  // const isMobile = useMediaQuery((theme: Theme) =>
  //   theme.breakpoints.down("md")
  // );

  const printLabelHandler = async (traceEntryList: ITraceEntry[]) => {
    const formatValues = traceEntryList.map((trace) => ({
      productId: trace.item_unique_id,
      productName: trace.item_name,
      // productDescription: trace.item_description,
      productSn: trace.serial_no,
      productLot: trace.lot_date,
      productQty: trace.qty,
      productUom: trace.uom?.name,
      productBarcode: trace.barcode,
    }));
    if (formatValues && formatValues.length > 0) {
      localStorage.setItem("barcode-list", JSON.stringify(formatValues));
    }
    window.open("/print-label", "_blank", "noreferrer");
  };

  return (
    <>
      {fields.map((serial, index) => (
        <TableRow key={serial.id}>
          <TableCell></TableCell>
          <TableCell align="center">
            {formatDateTimeNoAMPM(serial.posted_date)}
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="right">{formatNumber(serial.qty)}</TableCell>
          <TableCell align="center">{serial.uom?.name}</TableCell>
          <TableCell align="center">{serial.serial_no}</TableCell>
          <TableCell align="center">
            {serial.source_bin_location?.bin_name}
          </TableCell>
          <TableCell align="center">
            <CustomizedStatus status={serial.status} />
          </TableCell>
          {status !== "finished" && status !== "cancelled" && (
            <TableCell align="center">
              {serial.status === InventoryDocumentStatus.IsScan && (
                <CustomizedTooltip title="สแกนใหม่">
                  <IconButton
                    color="primary"
                    onClick={() => rescanBarcodeHandler(index)}
                  >
                    <ReplayOutlinedIcon fontSize="small" />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </TableCell>
          )}

          <TableCell align="center">
            {serial.scanned_by && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CustomizedAvatar
                  avatars={[
                    {
                      unique_id: serial.scanned_by.user_unique_id || "",
                      first_name: serial.scanned_by.first_name || "",
                      last_name: serial.scanned_by.last_name || "",
                      img_url: serial.scanned_by.img_url
                        ? serial.scanned_by.img_url[0]
                        : "",
                    },
                  ]}
                />
              </Box>
            )}
          </TableCell>
          <TableCell>{serial.barcode}</TableCell>
          <TableCell align="center">{serial.remark}</TableCell>
          <TableCell>{formatDate(serial.lot_date)}</TableCell>
          <TableCell>
            <CustomizedButton
              variant="outlined"
              title="พิมพ์"
              onClick={() => {
                printLabelHandler([serial]);
              }}
            />
          </TableCell>
          {/* {!isMobile && (
            <TableCell>
              <CustomizedButton
                variant="outlined"
                title="พิมพ์"
                onClick={() => printBarcodeHandler([serial])}
              />
            </TableCell>
          )} */}
        </TableRow>
      ))}
    </>
  );
};

export default SerialListScan;
