import { Box, Skeleton, Typography } from "@mui/material";
import { CompanyQuery, useCompanyQuery } from "../../../generated/company-user";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { formatContactName } from "../../../utils/Formatter/Global";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface Props {
  value?: any;
  noCompany?: boolean;
  imgSize?: number;
  isInternational?: boolean;
}

const PDFCompanyInfo = ({
  noCompany,
  imgSize,
  value,
  isInternational,
}: Props) => {
  const tenantId = sessionStorage.getItem("tenant-id");
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const { data, isLoading, error } = useCompanyQuery<CompanyQuery>(
    graphQLClientWithHeaderCompany,
    {
      uniqueInput: {
        unique_id: tenantId,
      },
    }
  );

  const [defaultAddress, setDefalutAddress] = useState(
    data?.company &&
      data.company.address_list &&
      data.company.address_list.find((address) => address.is_default)
  );

  useEffect(() => {
    if (value) {
      setDefalutAddress(value);
    } else if (data?.company?.address_list) {
      setDefalutAddress(
        data.company.address_list.find((address) => address.is_default)
      );
    }
  }, [value, data]);

  if (isLoading) {
    return <Skeleton animation="wave" />;
  }

  // const phoneNumber =
  //   data?.company &&
  //   data.company.contact_channel_list &&
  //   data.company.contact_channel_list
  //     .filter((channel) => channel.contact_channel_type === "เบอร์โทรศัพท์")
  //     .map((c) => c.contact_channel_name);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "top" }}>
          <Box
            sx={{
              width: 65,
              height: 65,
              minWidth: 65,
              minHeight: 65,
              mt: 0.5,
            }}
          >
            <img
              alt="npr-erp-logo"
              src={
                data?.company?.img_url
                  ? data?.company.img_url[0]
                    ? data?.company.img_url[0]
                    : "/static/logo.png"
                  : "/static/logo.png"
              }
              className="image-no-hover"
              style={{ borderRadius: 3 }}
            />
          </Box>
          {!noCompany && (
            <Box>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: !isInternational ? "24px" : "20px",
                  mb: isInternational ? 0.5 : 0,
                }}
              >
                {isInternational
                  ? "J.P. AUTOMOTIVE CORPORATION, CO., LTD"
                  : formatContactName(
                      "นิติบุคคล",
                      data?.company?.type || "",
                      "",
                      data?.company?.name || "",
                      ""
                    )}
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  lineHeight: !isInternational ? "24px" : "20px",
                }}
              >
                {isInternational
                  ? "88, Moo 7, Kanchanaphisek-Bang Pa-In Road, Lam Pho Subdistrict, Bang Bua Thong District, Nonthaburi 11110, Thailand"
                  : `${defaultAddress?.address || ""} ${
                      defaultAddress?.sub_district || ""
                    } ${defaultAddress?.district || ""} ${
                      defaultAddress?.province || ""
                    } ${defaultAddress?.postal_code || ""}`}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  height: "24px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 11,
                    fontWeight: 600,
                    lineHeight: !isInternational ? "24px" : "20px",
                    mr: 1,
                  }}
                  component="span"
                >
                  {!isInternational ? "เลขประจำตัวผู้เสียภาษี" : "TIN"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    lineHeight: !isInternational ? "24px" : "20px",
                  }}
                  component="span"
                >
                  {`${data?.company?.identity_no || "-"}  ${
                    !type
                      ? `${
                          !defaultAddress
                            ? ""
                            : defaultAddress.name === "สำนักงานใหญ่"
                            ? "(สำนักงานใหญ่)"
                            : defaultAddress.name === "ไม่ระบุ"
                            ? ""
                            : defaultAddress.unique_id
                            ? `(สาขา ${defaultAddress.unique_id})`
                            : ""
                        }`
                      : `${
                          !defaultAddress
                            ? ""
                            : defaultAddress.unique_id
                            ? `(${defaultAddress.unique_id})`
                            : ""
                        }`
                  }`}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PDFCompanyInfo;
