import { v4 as uuidv4 } from "uuid";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useGoodsReceiveItemList } from "../../../../hooks/Inventory/use-receive-item-list";
import {
  IDefaultForm,
  IMenuOption,
  ISelectOption,
} from "../../../../types/global";
import { IBarcodeForm, ITraceEntry } from "../../../../types/Inventory";
import {
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  UseFieldArrayUpdate,
  useWatch,
} from "react-hook-form";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { useModal } from "../../../../hooks/use-modal";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import {
  GoodsReceiveQuery,
  InventoryDocumentStatus,
  InventoryDocumentType,
  Tracability,
} from "../../../../generated/inventory";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import SerialModal from "../../../Form/Inventory/GoodsReceive/SerialModal";
import {
  IGoodsReceive,
  ISerialForm,
} from "../../../../types/Inventory/goodsReceive";
import { useForm } from "react-hook-form";
import SerialList from "./SerialList";
import TotalPostedQuantityCell from "./TotalPostedQuantityCell";
import { ISkuDetail } from "../../../../types/Inventory/item";
import GoodsReceiveScanItemList from "./GoodsReceiveScanItemList";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledSelect from "../../../Controller/ControlledSelect";
import { useStateContext } from "../../../../contexts/auth-context";
import dayjs from "dayjs";
import { exportAsExcelBarcode } from "../../../../utils/exporter";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import CheckedItemModal from "../../CheckedItemModal";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { formatDate } from "../../../../utils/Formatter/Date";
import useBarcodeScanner from "../../../../hooks/Inventory/use-barcode-scanner";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { QrReader } from "react-qr-reader";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import { formatNumber } from "../../../../utils/dataTransformer";
import { IWarehouse } from "../../../../types/Setting/inventory";
import GRMultiScanBarcodeModal from "../../../UI/Modal/GRMultiScanBarcodeModal";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type Props = IDefaultForm & {
  status?: string | null;
  allWarehouses?: IWarehouse[];
  step: number;
  goodReceiveData?: GoodsReceiveQuery["goodsReceive"];
  itemIds: string[];
  setItemIds: Dispatch<SetStateAction<string[]>>;
  itemIdsSnapshot: string[];
  setItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  initialTraceItems?: string[];
  existingInitialTraceItems?: string[];
  fields: FieldArrayWithId<IGoodsReceive, "trace_entry_list", "id">[];
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsReceive, "trace_entry_list">;
  replace: UseFieldArrayReplace<IGoodsReceive, "trace_entry_list">;
};

const options: IMenuOption[] = [
  {
    value: "นำออก Excel",
    disabled: false,
  },
  {
    value: "นำออก CSV",
    disabled: false,
  },
  {
    value: "พิมพ์ทั้งหมด",
    disabled: false,
  },
];

const filterScanOptions: IMenuOption[] = [
  {
    value: "แสดงทั้งหมด",
    disabled: false,
  },
  {
    value: "สแกนแล้ว",
    disabled: false,
  },
  {
    value: "รอสแกน",
    disabled: false,
  },
];

const GoodsReceiveItemList = ({
  control,
  disabled,
  getValues,
  setValue,
  errors,
  allWarehouses,
  step,
  status,
  goodReceiveData,
  itemIds,
  setItemIds,
  itemIdsSnapshot,
  setItemIdsSnapshot,
  initialTraceItems,
  existingInitialTraceItems,
  fields,
  replace,
  update,
  remove,
}: Props) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [filterName, setFilterName] = useState<string>("แสดงทั้งหมด");

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  // const gridRef = useRef<AgGridReact<ISkuDetail>>(null);

  const watchType = useWatch({
    control,
    name: "type",
  });

  const watchSourceWarehouseUniqueId = useWatch({
    control,
    name: "source_warehouse_unique_id",
  });

  const headers = useGoodsReceiveItemList(step, disabled);

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const {
    control: serialControl,
    reset: serialReset,
    getValues: getSerialValues,
    setValue: setSerialValues,
  } = useForm<ISerialForm>({
    defaultValues: {
      serial_quantity: 1,
      quantity_per_serial: 1,
      is_lot: false,
      lot_date: null,
      remark: "",
    },
  });

  const {
    state: { authUser },
  } = useStateContext();

  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  const addItemsHandler = (selectedItems: ISkuDetail[]) => {
    const documentUniqueId = getValues("unique_id");
    if (selectedItems) {
      let filteredItems = fields
        .filter((item) => itemIds.includes(item.item_unique_id))
        .map(({ id, ...other }) => other) as ITraceEntry[];

      // get current items id to prevent duplication when appending newly selected items
      const filteredItemsId = filteredItems.map((item) => item.item_unique_id);

      selectedItems.forEach((item) => {
        if (!filteredItemsId.includes(item.sku_name)) {
          let formatItems: ITraceEntry | undefined;
          if (item.item_sku?.item?.tracability === Tracability.Serial) {
            formatItems = {
              unique_id: uuidv4(),
              type: InventoryDocumentType.GoodsReceive,
              item_unique_id: item.sku_name,
              item_name: item.item_sku.item.name,
              item_img_url: item.img_url,
              tracability: item.item_sku?.item?.tracability,
              qty: 0,
              uom: item.item_sku.item.stock_uom,
              reference_unique_id: documentUniqueId,
              serial_list: [],
            };
          } else {
            formatItems = {
              unique_id: uuidv4(),
              type: InventoryDocumentType.GoodsReceive,
              item_unique_id: item.sku_name,
              item_name: item?.item_sku?.item?.name || "",
              item_img_url: item.img_url,
              tracability:
                item.item_sku?.item?.tracability || Tracability.Normal,
              qty: 0,
              uom: item.item_sku?.item?.stock_uom,
              status: InventoryDocumentStatus.IsActive,
              reference_unique_id: documentUniqueId,
              barcode: item.barcode,
            };
          }
          filteredItems.push(formatItems);
        }
      });
      // sort for proerly render order (and for role assignment when submitting)
      filteredItems = filteredItems.sort((a, b) => {
        return (
          itemIds.indexOf(a.item_unique_id) - itemIds.indexOf(b.item_unique_id)
        );
      });
      replace(filteredItems);
    }
    closeItemModalHandler();
  };

  const {
    modal: serialModal,
    openModalHandler: openSerialModalHandler,
    closeModalHandler: closeSerialModalHandler,
  } = useModal();

  const multipleScanModal = useModal();

  const findDuplicates = (arr: ITraceEntry[]) => {
    const countMap: Record<string, number> = arr.reduce((acc, item) => {
      acc[item.item_unique_id] = (acc[item.item_unique_id] || 0) + 1;
      return acc;
    }, {} as Record<string, number>); // Explicitly cast to Record<string, number>

    return arr.filter((item) => countMap[item.item_unique_id] > 1);
  };

  const addSerialsHandler = () => {
    const { serial_quantity, quantity_per_serial, lot_date, remark } =
      getSerialValues();

    const trace = getValues("trace_entry_list");
    const currentTrace = trace[currentIndex || 0];

    const filterTrace = trace.filter(
      (entry: ITraceEntry) =>
        entry.item_unique_id === currentTrace.item_unique_id
    );

    const currentSerialList = currentTrace.serial_list || [];

    const duplicateItemIds = findDuplicates(filterTrace);

    const sumOfSerialDuplicate = duplicateItemIds.reduce(
      (total, entry) => total + (entry.serial_list?.length || 0),
      0
    );

    for (let i = 0; i < serial_quantity; i++) {
      const padSn = String(
        duplicateItemIds.length > 1
          ? sumOfSerialDuplicate + 1 + i
          : currentSerialList.length + 1
      ).padStart(5, "0");
      currentSerialList.push({
        item_unique_id: currentTrace.item_unique_id,
        item_name: currentTrace.item_name,
        item_img_url: currentTrace.item_img_url,
        reference_unique_id: currentTrace.reference_unique_id,
        reference_line_item: currentTrace.reference_line_item,
        type: currentTrace.type,
        unique_id: uuidv4(),
        qty: quantity_per_serial,
        tracability: Tracability.Serial,
        uom: currentTrace.uom,
        lot_date,
        remark,
        serial_no: padSn,
        status: InventoryDocumentStatus.IsActive,
      });
    }
    setValue(
      `trace_entry_list[${currentIndex}].serial_list`,
      currentSerialList
    );

    closeSerialModalHandler();
    serialReset();
  };

  const foundWarehouse = allWarehouses?.find(
    (warehouse) => warehouse?.unique_id === watchSourceWarehouseUniqueId
  );

  let allBinLocation: any[] | undefined | null = [];

  foundWarehouse?.warehouse_level_1_list?.forEach((level1) => {
    level1.sub_level_2_list?.forEach((level2) => {
      level2?.sub_level_3_list?.forEach((level3) => {
        if (level3?.bin_location_list) {
          allBinLocation?.push(...level3.bin_location_list);
        }
      });
    });
  });

  const binLocationOptions: ISelectOption[] = allBinLocation.map((bin) => ({
    label: bin.bin_name,
    value: bin.id,
  }));

  const {
    control: barcodeControl,
    getValues: getBarcodeValues,
    reset: resetBarcode,
    watch: watchBarcode,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      source_bin_location_id: "",
    },
  });

  const onBarcodeSubmitHandler = (barcode?: string) => {
    const source_bin_location_id = getBarcodeValues("source_bin_location_id");

    const foundBinLocation = allBinLocation?.find(
      (bin) => bin.id === source_bin_location_id
    );

    const fields = getValues("trace_entry_list") as ITraceEntry[];

    const trimBarcode = barcode?.trim();

    if (barcode) {
      if (
        fields
          .filter((trace) => trace.status !== InventoryDocumentStatus.IsScan)
          .filter((trace) => trace.barcode === trimBarcode).length > 1
      )
        return multipleScanModal.openModalHandler();

      fields.forEach((trace, index) => {
        if (trace.tracability === Tracability.Normal) {
          if (trace.barcode?.trim() === trimBarcode) {
            if (trace.status === InventoryDocumentStatus.IsActive) {
              const currentTrace = getValues(`trace_entry_list.${index}`);
              update(index, {
                ...currentTrace,
                scanned_by: {
                  first_name: authUser?.first_name || "",
                  last_name: authUser?.last_name || "",
                  email: authUser?.email || "",
                  img_url: authUser?.img_url,
                  user_unique_id: authUser?.unique_id || "",
                },
                source_bin_location: foundBinLocation,
                source_bin_location_id,
                status: InventoryDocumentStatus.IsScan,
                posted_date: dayjs(),
              });
              enqueueSnackbar(`สแกน SN :\n${currentTrace.barcode}\nสำเร็จ`, {
                variant: "success",
                style: { whiteSpace: "pre-line" },
              });
            } else {
              if (
                fields
                  .filter(
                    (trace) => trace.status !== InventoryDocumentStatus.IsScan
                  )
                  .filter((trace) => trace.barcode === trimBarcode).length !== 1
              )
                enqueueSnackbar(
                  `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                  {
                    variant: "error",
                    style: { whiteSpace: "pre-line" },
                  }
                );
            }
          }
        } else {
          const totalSerial = trace.serial_list?.length;
          const totalScanned = trace.serial_list
            ? trace.serial_list.filter(
                (serial) => serial.status === InventoryDocumentStatus.IsScan
              ).length
            : 0;
          trace.serial_list?.forEach((serial, subIndex) => {
            if (serial.barcode === trimBarcode) {
              if (serial.status === InventoryDocumentStatus.IsActive) {
                const currentTrace = getValues(`trace_entry_list.${index}`);
                const currentSerial = getValues(
                  `trace_entry_list.${index}.serial_list[${subIndex}]`
                );

                const formatSerial = {
                  ...currentSerial,
                  scanned_by: {
                    first_name: authUser?.first_name || "",
                    last_name: authUser?.last_name || "",
                    email: authUser?.email || "",
                    img_url: authUser?.img_url,
                    user_unique_id: authUser?.unique_id || "",
                  },
                  source_bin_location: foundBinLocation,
                  source_bin_location_id,
                  status: InventoryDocumentStatus.IsScan,
                  posted_date: dayjs(),
                };
                currentTrace.serial_list[subIndex] = formatSerial;

                console.log("currentTrace serail", currentTrace);
                update(index, currentTrace);
                enqueueSnackbar(
                  `สแกน SN :\n${currentSerial.barcode}\nสำเร็จ จำนวน ${
                    totalScanned + 1
                  }/${totalSerial}`,
                  {
                    variant: "success",
                    style: { whiteSpace: "pre-line" },
                  }
                );
              } else {
                enqueueSnackbar(
                  `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                  {
                    variant: "error",
                    style: { whiteSpace: "pre-line" },
                  }
                );
              }
            }
          });
        }
      });

      if (
        !fields.some((trace) => {
          if (trace.tracability === Tracability.Normal) {
            return trace.barcode === trimBarcode;
          } else {
            if (
              !trace.serial_list?.some(
                (serial) => serial.barcode === trimBarcode
              )
            ) {
              return false;
            } else {
              return true;
            }
          }
        })
      ) {
        enqueueSnackbar(`QR/Barcode\nสินค้านี้ไม่อยู่ในรายการ\nกรุณาสแกนใหม่`, {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        });
      }
    }

    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  };

  const selectedTraceToScan = (tracesToScan: ITraceEntry[]) => {
    if (!tracesToScan || tracesToScan.length === 0) return;

    const source_bin_location_id = getBarcodeValues(
      "source_bin_location_id"
    ) as number;

    const foundBinLocation = allBinLocation?.find(
      (bin) => bin.id === source_bin_location_id
    );

    const fields = getValues("trace_entry_list") as ITraceEntry[];

    tracesToScan.forEach((traceToScan) => {
      const currentTraceIndex = fields.findIndex(
        (trace) => trace.unique_id === traceToScan.unique_id
      );

      const currentTrace = fields[currentTraceIndex];

      console.log("currentTrace in duplicate normal barcode", currentTrace);

      update(currentTraceIndex, {
        ...currentTrace,
        scanned_by: {
          first_name: authUser?.first_name || "",
          last_name: authUser?.last_name || "",
          email: authUser?.email || "",
          img_url: authUser?.img_url,
          user_unique_id: authUser?.unique_id || "",
        },
        source_bin_location: foundBinLocation,
        source_bin_location_id,
        status: InventoryDocumentStatus.IsScan,
        posted_date: dayjs() as unknown as Date,
      });

      enqueueSnackbar(`สแกน SN :\n${traceToScan.barcode}\nสำเร็จ`, {
        variant: "success",
        style: { whiteSpace: "pre-line" },
      });

      resetBarcode((prev) => ({
        ...prev,
        barcode: "",
      }));
    });
  };

  const exportBarcodeHandler = (type?: string) => {
    const formatData =
      goodReceiveData &&
      goodReceiveData.trace_entry_list &&
      goodReceiveData.trace_entry_list.map((trace) => {
        if (trace) {
          return {
            รหัสสินค้า: trace.item_unique_id,
            ชื่อสินค้า: trace.item_name,
            SN: trace.serial_no,
            Lot: formatDate(trace.lot_date),
            Barcode: trace.barcode,
            จำนวน: trace.qty,
            หน่วย: trace.uom?.name,
            สถานะ:
              trace.status === InventoryDocumentStatus.IsActive
                ? "รอสแกน"
                : "สแกนแล้ว",
          };
        } else {
          return true;
        }
      });
    exportAsExcelBarcode(formatData, "Barcode" + id, undefined, type);
    enqueueSnackbar("นำออกBarcodeสำเร็จ", {
      variant: "success",
    });
  };

  const printBarcodeHandler = async (traceEntryList: ITraceEntry[]) => {
    const formatValues = traceEntryList.map((trace) => ({
      productId: trace.item_unique_id,
      productName: trace.item_name,
      productSn: trace.serial_no,
      productLot: trace.lot_date,
      productQty: trace.qty,
      productUom: trace.uom?.name,
      productBarcode: trace.barcode,
    }));
    try {
      const res = await fetch(`http://localhost:4000/print`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formatValues),
      });
      const data = await res.json();
      if (data.status === "success") {
        enqueueSnackbar("พิมพ์สำเร็จ", {
          variant: "success",
        });
      } else if (data.status === "error") {
        enqueueSnackbar("พบการขัดข้องในการพิมพ์", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ไม่สามารถเชื่อมต่อกับเครื่องพิมพ์", {
        variant: "error",
      });
    }
  };

  const printLabelHandler = async (traceEntryList: ITraceEntry[]) => {
    const formatValues = traceEntryList.map((trace) => ({
      productId: trace.item_unique_id,
      productName: trace.item_name,
      productSn: trace.serial_no,
      productLot: trace.lot_date,
      productQty: trace.qty,
      productUom: trace.uom?.name,
      productBarcode: trace.barcode,
    }));
    if (formatValues && formatValues.length > 0) {
      localStorage.setItem("barcode-list", JSON.stringify(formatValues));
    }
    window.open("/print-label", "_blank", "noreferrer");
  };

  const { scrollRef, showCamera, setShowCamera, setScanData, setIsInterval } =
    useBarcodeScanner(onBarcodeSubmitHandler);

  return (
    <>
      {step === 2 && (
        <>
          {!isMobile && (
            <Box display="flex" justifyContent="space-between">
              <Typography fontWeight="bold">สแกน Barcode</Typography>
              <CustomizedMenuOptions
                size="medium"
                label="นำออก Barcode"
                options={options}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `นำออก Excel`:
                      exportBarcodeHandler("xlsx");
                      break;
                    case `นำออก CSV`:
                      exportBarcodeHandler();
                      break;
                    case `พิมพ์ทั้งหมด`:
                      const traceEntry =
                        goodReceiveData?.trace_entry_list as ITraceEntry[];
                      // printBarcodeHandler(traceEntry);
                      printLabelHandler(traceEntry);
                      break;
                    default:
                  }
                }}
              />
            </Box>
          )}
          <Grid container spacing={2} mt={1} mb={3}>
            {isMobile && (
              <Grid item xs={12}>
                <CustomizedMenuOptions
                  fullWidth
                  size="large"
                  label={filterName}
                  options={filterScanOptions}
                  onSelect={(e) => {
                    const value = e.target as HTMLElement;
                    setFilterName(value.innerText);
                  }}
                  disabled={disabled}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <ControlledSelect
                label="เลือกสถานที่"
                control={barcodeControl}
                name="source_bin_location_id"
                options={binLocationOptions || []}
                disabled={disabled}
              />
            </Grid>
            {!isMobile ? (
              <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                <ControlledTextField
                  label="Scan Barcode"
                  control={barcodeControl}
                  name="barcode"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey === false) {
                      const target = e.target as HTMLInputElement;
                      const data = target.value;
                      onBarcodeSubmitHandler(data);
                    }
                  }}
                  disabled={disabled || !watchBarcode("source_bin_location_id")}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={10}>
                  <ControlledTextField
                    label="Scan Barcode"
                    control={barcodeControl}
                    name="barcode"
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.shiftKey === false) {
                        const target = e.target as HTMLInputElement;
                        const data = target.value;
                        onBarcodeSubmitHandler(data);
                      }
                    }}
                    disabled={
                      disabled || !watchBarcode("source_bin_location_id")
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    disabled={
                      disabled || !watchBarcode("source_bin_location_id")
                    }
                    onClick={() => setShowCamera(!showCamera)}
                  >
                    <QrCodeScannerIcon />
                  </IconButton>
                </Grid>
              </>
            )}
          </Grid>
          {isMobile ? (
            <Box display={showCamera ? "block" : "none"} my={2}>
              {showCamera && (
                <QrReader
                  constraints={{ facingMode: "environment" }}
                  onResult={(result) => {
                    if (!!result) {
                      setScanData(result.getText());
                      setIsInterval(true);
                    }
                  }}
                  scanDelay={2000}
                  containerStyle={{
                    width: "calc(100vw-48px)",
                    height: "100%",
                    contentVisibility: "auto",
                  }}
                  videoContainerStyle={{
                    width: "calc(100vw-48px)",
                    height: "100%",
                    contentVisibility: "auto",
                  }}
                  videoStyle={{
                    height: "100%",
                    contentVisibility: "auto",
                  }}
                />
              )}
            </Box>
          ) : null}
        </>
      )}
      <TableContainer ref={scrollRef}>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align ? header.align : "center"}
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    width={header.width}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {step === 2 ? (
            <GoodsReceiveScanItemList
              control={control}
              fields={fields}
              headers={headers}
              update={update}
              status={status}
              printBarcodeHandler={printBarcodeHandler}
              filterName={filterName}
            />
          ) : (
            <TableBody>
              {fields.map((trace, index) => (
                <Fragment key={trace.id}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[0]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{index + 1}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[1]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          minWidth: "90px",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          alt={trace.item_name}
                          src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                          sx={{
                            width: 60,
                            height: 60,
                            border: "1px solid #BEBEBE",
                            borderRadius: "2px",
                          }}
                          variant="square"
                        >
                          <ImageOutlinedIcon
                            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                            fontSize="medium"
                          />
                        </Avatar>
                      </Box>
                    </TableCell>
                    <TableCell
                      width={
                        (headers && headers.length > 0
                          ? headers[2]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {trace.item_unique_id}
                      </Typography>
                      <Typography fontSize={14}>{trace.item_name}</Typography>
                      {goodReceiveData?.main_status === "draft" &&
                        initialTraceItems?.includes(trace.item_unique_id) &&
                        !existingInitialTraceItems?.includes(
                          trace.item_unique_id
                        ) && (
                          <Typography
                            fontSize={12}
                            sx={{
                              mt: 0.5,
                              color: (theme) => theme.palette.error.main,
                            }}
                          >
                            สินค้านี้ถูกลบออกจากระบบแล้ว กรุณาลบสินค้า
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[3]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {formatNumber(trace.document_item_qty || 0)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[4]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {formatNumber(trace.posted_qty || 0)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        px: 1,
                        py: 1,
                      }}
                      width={
                        (headers && headers.length > 0
                          ? headers[5]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>
                        {formatNumber(
                          (trace.document_item_qty || 0) -
                            (trace.posted_qty || 0) >=
                            0
                            ? (trace.document_item_qty || 0) -
                                (trace.posted_qty || 0)
                            : 0
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      width={
                        (headers && headers.length > 0
                          ? headers[6]?.width || 0
                          : 0) + 16
                      }
                    >
                      {trace.tracability === Tracability.Normal ? (
                        // <ControlledTextField
                        //   type="number"
                        // control={control}
                        // name={`trace_entry_list[${index}].qty`}
                        // disabled={disabled}
                        // error={Boolean(
                        //   errors &&
                        //     errors.trace_entry_list &&
                        //     errors.trace_entry_list[index] &&
                        //     errors.trace_entry_list[index].qty
                        // )}
                        //   InputProps={{
                        //     inputProps: { min: 0 },
                        //   }}
                        // />
                        <ControlledNumberTextField
                          control={control}
                          name={`trace_entry_list.${index}.qty`}
                          disabled={disabled}
                          error={Boolean(
                            errors &&
                              errors.trace_entry_list &&
                              errors.trace_entry_list[index] &&
                              errors.trace_entry_list[index].qty
                          )}
                          inputFontSize={14}
                        />
                      ) : (
                        <TotalPostedQuantityCell
                          control={control}
                          nestedIndex={index}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[7]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography fontSize={14}>{trace.uom?.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[8]?.width || 0
                          : 0) + 16
                      }
                    >
                      {!disabled &&
                        trace.tracability === Tracability.Serial && (
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setCurrentIndex(index);
                              openSerialModalHandler();
                            }}
                            disabled={!watchSourceWarehouseUniqueId}
                            size="medium"
                          >
                            <AddCircleOutlineIcon fontSize="small" />
                          </IconButton>
                        )}
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[9]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{trace.serial_no}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[10]?.width || 0
                          : 0) + 16
                      }
                    >
                      <Typography fontSize={14}>{trace.remark}</Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    {!disabled && (
                      <TableCell
                        align="center"
                        width={
                          (headers && headers.length > 0
                            ? headers[11]?.width || 0
                            : 0) + 16
                        }
                      >
                        <IconButton
                          onClick={() => {
                            remove(index);
                            setItemIds((prev) =>
                              prev.filter((id) => id !== trace.item_unique_id)
                            );
                            setItemIdsSnapshot((prev) =>
                              prev.filter((id) => id !== trace.item_unique_id)
                            );
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                  <SerialList
                    control={control}
                    errors={errors}
                    nestedIndex={index}
                    disabled={disabled}
                  />
                </Fragment>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Box mt={2} display={"flex"} alignItems={"center"} gap={1}>
        {watchType === "other" && !disabled && step !== 2 && (
          <CustomizedButton
            variant="outlined"
            title="เพิ่มสินค้า"
            onClick={openItemModalHandler}
          />
        )}
        {/* <InfoOutlinedIcon fontSize="small" />
        <Typography variant="caption" color={"error.main"}>
          จำนวนรายการสูงสุด 150 รายการ
        </Typography> */}
      </Box>

      {/* <ItemTableModal
        ref={gridRef}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
      /> */}
      <CheckedItemModal
        showSelectItem={itemModal}
        closeItemTable={closeItemModalHandler}
        finishItemsSelect={addItemsHandler}
        itemIds={itemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIds={setItemIds}
        setItemIdsSnapshot={setItemIdsSnapshot}
        rowSelection="multiple"
        noService
      />
      <SerialModal
        control={serialControl}
        reset={serialReset}
        serialModal={serialModal}
        closeSerialModalHandler={closeSerialModalHandler}
        addSerialsHandler={addSerialsHandler}
        setValue={setSerialValues}
        getValues={getValues}
      />
      <GRMultiScanBarcodeModal
        traceEntry={fields}
        modal={multipleScanModal.modal}
        closeModalHandler={() => {
          resetBarcode((prev) => ({
            ...prev,
            barcode: "",
          }));
          multipleScanModal.closeModalHandler();
        }}
        selectedTraceToScan={selectedTraceToScan}
      />
    </>
  );
};

export default GoodsReceiveItemList;
