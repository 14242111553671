import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  IDefaultForm,
  IHeaderTable,
  IMenuOption,
  ISelectOption,
} from "../../../../types/global";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";
import { Controller, FieldArrayWithId } from "react-hook-form";
import ItemNameCell from "./ItemNameCell";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledSelect from "../../../Controller/ControlledSelect";
import CloseIcon from "@mui/icons-material/Close";
import { IPurchaseItemList } from "../../../../types/Purchase";
import { formatNumber } from "../../../../utils/dataTransformer";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import PreVatAmountCell from "./PreVatAmountCell";
import WithholdingTaxCell from "./WithholdingTaxCell";
import StockQtyCell from "./StockQtyCell";
import { EntityTypeEnum } from "../../../../generated/creatable";
import { AdditionalDiscountType } from "../../../../generated/purchase";
import CustomizedIconMenuButton from "../../../Custom/CustomizedIconMenuOptions";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";

type FieldArrayType = {
  item_list: IPurchaseItemList[];
};

type Props = IDefaultForm & {
  fields: FieldArrayWithId<FieldArrayType, "item_list", "id">[];
  headers: IHeaderTable[];
  dragEndHandler: (result: DropResult) => any;
  openDeleteItemConfirmationHandler: (index: number) => void;
  vatOptions: ISelectOption[];
};

const additionalDiscountTypeOptions: IMenuOption[] = [
  {
    value: "บาท",
  },
  {
    value: "%",
  },
];

const PurchaseReturnItemList = ({
  fields,
  headers,
  control,
  errors,
  getValues,
  setValue,
  disabled,
  dragEndHandler,
  openDeleteItemConfirmationHandler,
  vatOptions,
}: Props) => {
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, overflow: "scroll" }}
        aria-label="simple table"
      >
        <TableHead
          sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
        >
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                align={!header.align ? "center" : header.align}
                key={index}
                sx={{
                  px: 1,
                  py: 1,
                }}
                width={header.width}
              >
                <Typography fontSize={14} fontWeight={600} width={header.width}>
                  {header.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={dragEndHandler}>
          <Droppable droppableId="droppable" isDropDisabled={disabled}>
            {(provided) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((field, index) => (
                  <Draggable
                    key={field.id}
                    draggableId={"item-" + field.id}
                    index={index}
                    isDragDisabled={disabled}
                  >
                    {(provided) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          ...provided.draggableProps.style,
                          breakInside: "avoid",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[0]?.width || 0
                              : 0) + 16
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {!disabled && (
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() =>
                                  openDeleteItemConfirmationHandler(index)
                                }
                                sx={{ p: 0 }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            )}
                            {!disabled && (
                              <MenuIcon fontSize="small" color="action" />
                            )}
                            <Typography fontSize={14}>{index + 1}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[1]?.width || 0
                              : 0) + 16
                          }
                        >
                          <ItemNameCell
                            control={control}
                            errors={errors}
                            field={field}
                            index={index}
                            disabled={disabled}
                            documentType="purchase_return"
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[2]?.width || 0
                              : 0) + 16
                          }
                        >
                          {disabled ? (
                            <Typography fontSize={14}>
                              {formatNumber(field.po_qty || 0)}
                            </Typography>
                          ) : (
                            <ControlledNumberTextField
                              control={control}
                              name={`item_list.${index}.po_qty`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.po_qty
                              )}
                              helperText={
                                errors?.item_list &&
                                errors?.item_list[index] &&
                                errors?.item_list[index]?.po_qty &&
                                errors?.item_list[index]?.po_qty.message
                              }
                              disabled={true}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[3]?.width || 0
                              : 0) + 16
                          }
                        >
                          {disabled ? (
                            <Typography fontSize={14}>
                              {formatNumber(field.qty)}
                            </Typography>
                          ) : (
                            <ControlledNumberTextField
                              control={control}
                              name={`item_list.${index}.qty`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.qty
                              )}
                              onChange={(v) => {
                                const value = v.floatValue || 0;
                                const currentUomUniqueId = getValues(
                                  `item_list.${index}.uom`
                                );
                                if (currentUomUniqueId) {
                                  if (field.uom_group) {
                                    if (
                                      currentUomUniqueId ===
                                      field.uom_group.base_uom?.unique_id
                                    ) {
                                      setValue(
                                        `item_list.${index}.stock_qty`,
                                        value
                                      );
                                    } else {
                                      if (
                                        field.uom_group.uom_conversion_list &&
                                        field.uom_group.uom_conversion_list
                                          .length > 0
                                      ) {
                                        const conversionUom =
                                          field.uom_group.uom_conversion_list.find(
                                            (conversion) =>
                                              conversion.target_uom_unique_id ===
                                              currentUomUniqueId
                                          );
                                        if (conversionUom) {
                                          const targetUomQty =
                                            conversionUom.base_uom_rate /
                                            conversionUom.target_uom_rate;
                                          setValue(
                                            `item_list.${index}.stock_qty`,
                                            value * targetUomQty
                                          );
                                        }
                                      }
                                    }
                                  }
                                }
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[4]?.width || 0
                              : 0) + 16
                          }
                        >
                          {disabled ? (
                            <Typography fontSize={14}>{field.uom}</Typography>
                          ) : (
                            <ControlledSelect
                              control={control}
                              name={`item_list.${index}.uom`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.uom
                              )}
                              options={
                                field.uom_group
                                  ? field.uom_group.convertable_uom_list &&
                                    field.uom_group.convertable_uom_list
                                      .length > 0
                                    ? [
                                        {
                                          label:
                                            field.uom_group?.base_uom?.name ||
                                            "",
                                          value:
                                            field.uom_group?.base_uom
                                              ?.unique_id || "",
                                        },
                                        ...field.uom_group.convertable_uom_list.map(
                                          (uom) => ({
                                            label: uom.name,
                                            value: uom.unique_id,
                                          })
                                        ),
                                      ]
                                    : [
                                        {
                                          label:
                                            field.uom_group?.base_uom?.name ||
                                            "",
                                          value:
                                            field.uom_group?.base_uom
                                              ?.unique_id || "",
                                        },
                                      ]
                                  : []
                              }
                              onChange={(e: any) => {
                                const currentQty = getValues(
                                  `item_list.${index}.qty`
                                );
                                if (field.uom_group) {
                                  console.log(
                                    e.target.value ===
                                      field.uom_group.base_uom?.unique_id
                                  );
                                  if (
                                    e.target.value ===
                                    field.uom_group.base_uom?.unique_id
                                  ) {
                                    setValue(
                                      `item_list.${index}.stock_qty`,
                                      currentQty
                                    );
                                  } else {
                                    if (
                                      field.uom_group.uom_conversion_list &&
                                      field.uom_group.uom_conversion_list
                                        .length > 0
                                    ) {
                                      const conversionUom =
                                        field.uom_group.uom_conversion_list.find(
                                          (conversion) =>
                                            conversion.target_uom_unique_id ===
                                            e.target.value
                                        );
                                      if (conversionUom) {
                                        const targetUomQty =
                                          conversionUom.base_uom_rate /
                                          conversionUom.target_uom_rate;
                                        setValue(
                                          `item_list.${index}.stock_qty`,
                                          currentQty * targetUomQty
                                        );
                                      }
                                    }
                                  }
                                }
                              }}
                              disabled={true}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[5]?.width || 0
                              : 0) + 16
                          }
                        >
                          <StockQtyCell
                            control={control}
                            index={index}
                            disabled={disabled}
                            field={field}
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[6]?.width || 0
                              : 0) + 16
                          }
                        >
                          {disabled ? (
                            <Typography fontSize={14}>
                              {formatNumber(field.price_per_unit)}
                            </Typography>
                          ) : (
                            <ControlledNumberTextField
                              control={control}
                              name={`item_list.${index}.price_per_unit`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.price_per_unit
                              )}
                              helperText={
                                errors?.item_list &&
                                errors?.item_list[index] &&
                                errors?.item_list[index]?.price_per_unit &&
                                errors?.item_list[index]?.price_per_unit.message
                              }
                              disabled={true}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[7]?.width || 0
                              : 0) + 16
                          }
                        >
                          {disabled ? (
                            <Typography fontSize={14}>
                              {formatNumber(field.discount)}
                            </Typography>
                          ) : (
                            <ControlledNumberTextField
                              control={control}
                              name={`item_list.${index}.discount`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.discount
                              )}
                              disabled={true}
                              endAdorment={
                                <>
                                  <Controller
                                    name={`item_list.${index}.discount_type`}
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        {field.value ===
                                          AdditionalDiscountType.Percent && (
                                          <Typography
                                            fontSize={14}
                                            color="#737373"
                                            marginLeft={1}
                                          >
                                            %
                                          </Typography>
                                        )}
                                      </>
                                    )}
                                  />
                                  <CustomizedIconMenuButton
                                    icon={<ArrowDropDownIcon />}
                                    options={additionalDiscountTypeOptions}
                                    onSelect={(e) => {
                                      const target = e.target as HTMLElement;
                                      if (!setValue) return;
                                      if (e.target) {
                                        setValue(
                                          `item_list.${index}.discount_type`,
                                          target.textContent === "บาท"
                                            ? AdditionalDiscountType.Baht
                                            : AdditionalDiscountType.Percent
                                        );
                                      }
                                      setValue(
                                        `item_list.${index}.discount`,
                                        0
                                      );
                                    }}
                                    sx={{
                                      marginLeft: 1,
                                      marginRight: "-14px",
                                      backgroundColor: "#F2F2F2",
                                      borderLeft: "#E2E2E2 solid 1px",
                                      borderRadius: "0px 4px 4px 0",
                                      padding: "8px",
                                    }}
                                    disabled={true}
                                    disableRipple
                                  />
                                </>
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[8]?.width || 0
                              : 0) + 16
                          }
                        >
                          {disabled ? (
                            <Typography fontSize={14}>
                              {field.vat_percentage}
                            </Typography>
                          ) : (
                            <ControlledSelect
                              control={control}
                              name={`item_list.${index}.vat_percentage`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.vat_percentage
                              )}
                              helperText={
                                errors?.item_list &&
                                errors?.item_list[index] &&
                                errors?.item_list[index]?.vat_percentage &&
                                errors?.item_list[index]?.vat_percentage
                                  ?.message
                              }
                              options={vatOptions}
                              disabled={true}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[9]?.width || 0
                              : 0) + 16
                          }
                        >
                          <PreVatAmountCell
                            control={control}
                            index={index}
                            field={field}
                            disabled={disabled}
                            setValue={setValue}
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[10]?.width || 0
                              : 0) + 16
                          }
                        >
                          <WithholdingTaxCell
                            control={control}
                            errors={errors}
                            index={index}
                            documentType={EntityTypeEnum.PurchaseReturn}
                            disabled={disabled}
                            setValue={setValue}
                            field={field}
                          />
                        </TableCell>
                        {/* <TableCell
                          align="center"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[11]?.width || 0
                              : 0) + 16
                          }
                        >
                          <Typography fontSize={14}>
                            {field.pr_reference_unique_id || "-"}
                          </Typography>
                        </TableCell> */}

                        <TableCell
                          align="center"
                          sx={{
                            px: 1,
                            py: 1,
                          }}
                          width={
                            (headers && headers.length > 0
                              ? headers[11]?.width || 0
                              : 0) + 16
                          }
                        >
                          {disabled ? (
                            <Typography fontSize={14}>
                              {field.remark}
                            </Typography>
                          ) : (
                            <ControlledTextField
                              control={control}
                              name={`item_list.${index}.remark`}
                              error={Boolean(
                                errors?.item_list &&
                                  errors?.item_list[index] &&
                                  errors?.item_list[index]?.remark
                              )}
                              helperText={
                                errors?.item_list &&
                                errors?.item_list[index] &&
                                errors?.item_list[index]?.remark &&
                                errors?.item_list[index]?.remark.message
                              }
                              disabled={true}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};

export default PurchaseReturnItemList;
