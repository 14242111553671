import {
  Box,
  IconButton,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";

import ItemBomNameCell from "./ItemBomNameCell";
import SalesBomPreVatAmountCell from "./SalesBomPreVatAmountCell";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledTextField from "../../../Controller/ControlledTextField";
import CustomizedIconMenuButton from "../../../Custom/CustomizedIconMenuOptions";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";

import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { EntityTypeEnum } from "../../../../generated/general";
import { AdditionalDiscountType } from "../../../../generated/sales";
import { ISalesItemList } from "../../../../types/Sales";
import { IQuotation } from "../../../../types/Sales/quotation";
import { ISalesOrder } from "../../../../types/Sales/salesOrder";
import {
  IHeaderTable,
  IMenuOption,
  ISelectOption,
} from "../../../../types/global";

import { formatNumber } from "../../../../utils/dataTransformer";
import { calculateParentSalesBom } from "../../../../utils/Formatter/Global";
import { useDebouncedCallback } from "../../../../hooks/use-debounced-callback";
import CustomTableCell from "../../../Custom/CustomizedTableCell";

type Props = {
  index: number;
  documentType?: EntityTypeEnum;
  openDeleteSalesBomConfirmationHandler: (
    itemIndex: number,
    salesBomIndex: number
  ) => void;
  openItemPriceHistoryModalHandler: (
    index: number,
    item_unique_id: string | undefined,
    salesBomIndex?: number
  ) => void;
  disabled: boolean;
  headers: IHeaderTable[];
};

const SalesBomRow = ({
  index,
  documentType = EntityTypeEnum.SalesOrder,
  openDeleteSalesBomConfirmationHandler,
  openItemPriceHistoryModalHandler,
  disabled,
  headers,
}: Props) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<ISalesOrder | IQuotation>();

  const { update: updateItemList } = useFieldArray({
    control,
    name: `item_list` as `item_list`,
  });

  const { fields, move } = useFieldArray({
    control,
    name: `item_list.${index}.sales_bom_item` as `item_list`,
  });

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const vatOptions: ISelectOption[] = [
    {
      label: "ไม่มี",
      value: "ไม่มี",
    },
    {
      label: "0 %",
      value: "0",
    },
    {
      label: "7 %",
      value: "7",
    },
  ];

  const additionalDiscountTypeOptions: IMenuOption[] = [
    {
      value: "บาท",
    },
    {
      value: "%",
    },
  ];

  const handleDebouncedChange = useDebouncedCallback((index: number) => {
    const itemListValue = getValues(`item_list.${index}`);
    // Handle the value update logic here

    const result = calculateParentSalesBom(itemListValue) as ISalesItemList;

    updateItemList(index, result);
  }, 500);

  return (
    <DragDropContext onDragEnd={dragEndHandler}>
      <Droppable droppableId="droppable" isDropDisabled={disabled}>
        {(provided) => (
          <TableBody
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{ display: "contents" }}
          >
            {fields.map((field, salesIndex) => (
              <>
                <Draggable
                  key={field.id}
                  draggableId={"item-" + field.id}
                  index={index}
                  isDragDisabled={true}
                >
                  {(provided, snapshot) => (
                    <TableRow
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        ...provided.draggableProps.style,
                        breakInside: "avoid",
                        backgroundColor: "#ECF6FDB2",
                        borderLeft: "6px solid rgba(226, 226, 226, 1)",
                        padding: "0 0 0 10px !important",
                      }}
                    >
                      <CustomTableCell
                        width={headers[0]?.width}
                        align={headers[0]?.align}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {!disabled && (
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                openDeleteSalesBomConfirmationHandler(
                                  index,
                                  salesIndex
                                )
                              }
                              sx={{ p: 0 }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          )}
                          {/* {!disabled && (
                            <MenuIcon fontSize="small" color="action" />
                          )} */}
                          <Typography fontSize={14}>
                            {index + 1}.{salesIndex + 1}
                          </Typography>
                        </Box>
                      </CustomTableCell>
                      <CustomTableCell
                        width={headers[1]?.width}
                        align={headers[1]?.align}
                      >
                        <ItemBomNameCell
                          control={control}
                          errors={errors}
                          field={field}
                          index={index}
                          subIndex={salesIndex}
                          disabled={disabled}
                          documentType={documentType}
                        />
                      </CustomTableCell>
                      <CustomTableCell
                        width={headers[2]?.width}
                        align={headers[2]?.align}
                      >
                        {disabled ? (
                          <Typography fontSize={14}>
                            {formatNumber(field.qty)}
                          </Typography>
                        ) : (
                          <ControlledNumberTextField
                            control={control}
                            name={`item_list.${index}.sales_bom_item.${salesIndex}.qty`}
                            error={Boolean(
                              errors?.item_list?.[index]?.sales_bom_item?.[
                                salesIndex
                              ]?.qty
                            )}
                            onChange={(v) => {
                              const parseValue = v.floatValue || 0;
                              const currentUomUniqueId = getValues(
                                `item_list.${index}.sales_bom_item.${salesIndex}.uom`
                              );
                              if (currentUomUniqueId) {
                                if (field.uom_group) {
                                  if (
                                    currentUomUniqueId ===
                                    field.uom_group.base_uom?.unique_id
                                  ) {
                                    setValue(
                                      `item_list.${index}.sales_bom_item.${salesIndex}.stock_qty`,
                                      parseValue
                                    );
                                  } else {
                                    if (
                                      field.uom_group.uom_conversion_list &&
                                      field.uom_group.uom_conversion_list
                                        .length > 0
                                    ) {
                                      const conversionUom =
                                        field.uom_group.uom_conversion_list.find(
                                          (conversion) =>
                                            conversion.target_uom_unique_id ===
                                            currentUomUniqueId
                                        );
                                      if (conversionUom) {
                                        const targetUomQty =
                                          conversionUom.base_uom_rate /
                                          conversionUom.target_uom_rate;
                                        setValue(
                                          `item_list.${index}.sales_bom_item.${salesIndex}.stock_qty`,
                                          parseValue * targetUomQty
                                        );
                                      }
                                    }
                                  }
                                }
                              }
                              handleDebouncedChange(index);
                            }}
                          />
                        )}
                      </CustomTableCell>
                      <CustomTableCell
                        width={headers[3]?.width}
                        align={headers[3]?.align}
                      >
                        {disabled ? (
                          <Typography fontSize={14}>{field.uom}</Typography>
                        ) : (
                          <ControlledSelect
                            control={control}
                            name={`item_list.${index}.sales_bom_item.${salesIndex}.uom`}
                            error={Boolean(
                              errors?.item_list?.[index]?.sales_bom_item?.[
                                salesIndex
                              ]?.uom
                            )}
                            options={
                              field.uom_group
                                ? field.uom_group.convertable_uom_list &&
                                  field.uom_group.convertable_uom_list.length >
                                    0
                                  ? [
                                      {
                                        label:
                                          field.uom_group?.base_uom?.name || "",
                                        value:
                                          field.uom_group?.base_uom
                                            ?.unique_id || "",
                                      },
                                      ...field.uom_group.convertable_uom_list.map(
                                        (uom) => ({
                                          label: uom.name,
                                          value: uom.unique_id,
                                        })
                                      ),
                                    ]
                                  : [
                                      {
                                        label:
                                          field.uom_group?.base_uom?.name || "",
                                        value:
                                          field.uom_group?.base_uom
                                            ?.unique_id || "",
                                      },
                                    ]
                                : []
                            }
                            onChange={(e: any) => {
                              const currentQty = getValues(
                                `item_list.${index}.sales_bom_item.${salesIndex}.qty`
                              );
                              if (field.uom_group) {
                                if (
                                  e.target.value ===
                                  field.uom_group.base_uom?.unique_id
                                ) {
                                  setValue(
                                    `item_list.${index}.sales_bom_item.${salesIndex}.stock_qty`,
                                    currentQty
                                  );
                                } else {
                                  if (
                                    field.uom_group.uom_conversion_list &&
                                    field.uom_group.uom_conversion_list.length >
                                      0
                                  ) {
                                    const conversionUom =
                                      field.uom_group.uom_conversion_list.find(
                                        (conversion) =>
                                          conversion.target_uom_unique_id ===
                                          e.target.value
                                      );
                                    if (conversionUom) {
                                      const targetUomQty =
                                        conversionUom.base_uom_rate /
                                        conversionUom.target_uom_rate;
                                      setValue(
                                        `item_list.${index}.sales_bom_item.${salesIndex}.stock_qty`,
                                        currentQty * targetUomQty
                                      );
                                    }
                                  }
                                }
                              }
                            }}
                          />
                        )}
                      </CustomTableCell>
                      <CustomTableCell
                        width={headers[4]?.width}
                        align={headers[4]?.align}
                      >
                        {disabled ? (
                          <Box sx={{ marginTop: "30px" }}>
                            <Typography fontSize={14}>
                              {formatNumber(field.price_per_unit)}
                            </Typography>
                            <IconButton
                              aria-label="view sales item price history"
                              size="small"
                              sx={{ color: "#2167D3" }}
                              onClick={() =>
                                openItemPriceHistoryModalHandler(
                                  index,
                                  field.item_unique_id
                                )
                              }
                            >
                              <AccessTimeIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        ) : (
                          <Box>
                            <ControlledNumberTextField
                              control={control}
                              name={`item_list.${index}.sales_bom_item.${salesIndex}.price_per_unit`}
                              error={Boolean(
                                errors?.item_list?.[index]?.sales_bom_item?.[
                                  salesIndex
                                ]?.price_per_unit
                              )}
                              onChange={() => {
                                handleDebouncedChange(index);
                              }}
                              sx={{ marginTop: "30px" }}
                            />
                            <IconButton
                              aria-label="view sales item price history"
                              size="small"
                              sx={{ color: "#2167D3" }}
                              onClick={() =>
                                openItemPriceHistoryModalHandler(
                                  index,
                                  field.item_unique_id,
                                  salesIndex
                                )
                              }
                            >
                              <AccessTimeIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        )}
                      </CustomTableCell>
                      <CustomTableCell
                        width={headers[5]?.width}
                        align={headers[5]?.align}
                      >
                        {disabled ? (
                          <Typography fontSize={14}>
                            {formatNumber(field.discount)}
                          </Typography>
                        ) : (
                          <ControlledNumberTextField
                            control={control}
                            name={`item_list.${index}.sales_bom_item.${salesIndex}.discount`}
                            error={Boolean(
                              errors?.item_list?.[index]?.sales_bom_item?.[
                                salesIndex
                              ]?.discount
                            )}
                            endAdorment={
                              <>
                                <Controller
                                  name={`item_list.${index}.sales_bom_item.${salesIndex}.discount_type`}
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      {field.value ===
                                        AdditionalDiscountType.Percent && (
                                        <Typography
                                          fontSize={14}
                                          color="#737373"
                                          marginLeft={1}
                                        >
                                          %
                                        </Typography>
                                      )}
                                    </>
                                  )}
                                />
                                <CustomizedIconMenuButton
                                  icon={<ArrowDropDownIcon />}
                                  options={additionalDiscountTypeOptions}
                                  onSelect={(e) => {
                                    const target = e.target as HTMLElement;
                                    if (!setValue) return;
                                    if (e.target) {
                                      setValue(
                                        `item_list.${index}.sales_bom_item.${salesIndex}.discount_type`,
                                        target.textContent === "บาท"
                                          ? AdditionalDiscountType.Baht
                                          : AdditionalDiscountType.Percent
                                      );
                                    }
                                    setValue(
                                      `item_list.${index}.sales_bom_item.${salesIndex}.discount`,
                                      0
                                    );
                                    handleDebouncedChange(index);
                                  }}
                                  sx={{
                                    marginLeft: 1,
                                    marginRight: "-14px",
                                    backgroundColor: "#F2F2F2",
                                    borderLeft: "#E2E2E2 solid 1px",
                                    borderRadius: "0px 4px 4px 0",
                                    padding: "8px",
                                  }}
                                  disabled={disabled}
                                  disableRipple
                                />
                              </>
                            }
                            onChange={() => {
                              handleDebouncedChange(index);
                            }}
                          />
                        )}
                      </CustomTableCell>
                      <CustomTableCell
                        width={headers[6]?.width}
                        align={headers[6]?.align}
                      >
                        {disabled ? (
                          <Typography fontSize={14}>
                            {field.vat_percentage}
                          </Typography>
                        ) : (
                          <ControlledSelect
                            control={control}
                            name={`item_list.${index}.sales_bom_item.${salesIndex}.vat_percentage`}
                            error={Boolean(
                              errors?.item_list?.[index]?.sales_bom_item?.[
                                salesIndex
                              ]?.vat_percentage
                            )}
                            options={vatOptions}
                            disabled={true}
                          />
                        )}
                      </CustomTableCell>
                      <CustomTableCell
                        width={headers[7]?.width}
                        align={headers[7]?.align}
                      >
                        <SalesBomPreVatAmountCell
                          control={control}
                          index={index}
                          salesBomIndex={salesIndex}
                          field={field}
                          disabled={disabled}
                          setValue={setValue}
                        />
                      </CustomTableCell>
                      <CustomTableCell
                        width={headers[8]?.width}
                        align={headers[8]?.align}
                      >
                        <></>
                      </CustomTableCell>
                      <CustomTableCell
                        width={headers[9]?.width}
                        align={headers[9]?.align}
                      >
                        {disabled ? (
                          <>
                            <Typography fontSize={14}>
                              {formatNumber(field.stock_qty)}
                            </Typography>
                            <Typography variant="caption">
                              {field.uom_group?.base_uom?.name}
                            </Typography>
                          </>
                        ) : (
                          <ControlledNumberTextField
                            sx={{ mt: 3 }}
                            control={control}
                            name={`item_list.${index}.sales_bom_item.${salesIndex}.stock_qty`}
                            helperText={field.uom_group?.base_uom?.name}
                            disabled={true}
                            helperTextAlign="right"
                          />
                        )}
                      </CustomTableCell>
                      <CustomTableCell
                        width={headers[10]?.width}
                        align={headers[10]?.align}
                      >
                        {disabled ? (
                          <Typography fontSize={14}>{field.remark}</Typography>
                        ) : (
                          <ControlledTextField
                            control={control}
                            name={`item_list.${index}.sales_bom_item.${salesIndex}.remark`}
                            error={Boolean(
                              errors?.item_list?.[index]?.sales_bom_item?.[
                                salesIndex
                              ]?.remark
                            )}
                          />
                        )}
                      </CustomTableCell>
                      {documentType === "sales_order" && disabled && (
                        <>
                          <CustomTableCell
                            width={headers[11]?.width}
                            align={headers[11]?.align}
                          >
                            <Typography fontSize={14}>
                              {formatNumber(field.qty_manufactured || 0)}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: (theme) => theme.palette.grey[600],
                              }}
                            >
                              {`ดำเนินการ ${formatNumber(
                                field.qty_manufacturing || 0
                              )}`}
                            </Typography>
                            <Typography
                              sx={{
                                color: (theme) => theme.palette.grey[600],
                                fontSize: 12,
                              }}
                            >
                              {`ค้างผลิต ${formatNumber(
                                field.qty -
                                  (field.qty_manufacturing || 0) -
                                  (field.qty_manufactured || 0) >
                                  0
                                  ? field.qty -
                                      (field.qty_manufacturing || 0) -
                                      (field.qty_manufactured || 0)
                                  : 0
                              )}`}
                            </Typography>
                          </CustomTableCell>
                          <CustomTableCell
                            width={headers[12]?.width}
                            align={headers[12]?.align}
                          >
                            <Typography fontSize={14}>
                              {formatNumber(field.qty_issued || 0)}
                            </Typography>
                          </CustomTableCell>

                          <CustomTableCell
                            width={headers[13]?.width}
                            align={headers[13]?.align}
                          >
                            <Typography fontSize={14}>
                              {formatNumber(field.qty_shipped || 0)}
                            </Typography>
                          </CustomTableCell>
                        </>
                      )}
                    </TableRow>
                  )}
                </Draggable>
              </>
            ))}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SalesBomRow;
