import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab, IExporter } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Typography } from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import PurchaseReturnTable from "../../../components/Table/Purchase/Return";

import ButtonLayout from "../../../components/UI/ButtonLayout";
import { useState, useEffect } from "react";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import { useSnackbar } from "notistack";
import { useModal } from "../../../hooks/use-modal";
import ExporterModal from "../../../components/UI/ExporterModal";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { usePurchaseReturnGenerateExportQuery, PurchaseReturnGenerateExportQuery, PurchaseExportInput, PurchaseExportType  } from "../../../generated/purchase";

const PurchaseReturn = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const { enqueueSnackbar } = useSnackbar();

  const [exportInput, setExportInput] = useState<PurchaseExportInput | null>(
    null
  );

  const graphqlClient = createGraphQLClientWithMiddleware("purchase");

  const { refetch: refetchData, isLoading: isExporting } =
    usePurchaseReturnGenerateExportQuery<PurchaseReturnGenerateExportQuery>(
      graphqlClient,
      {
        input: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: IExporter) => {
      if (data) {
        const { type, start_date, end_date } = data;
        setExportInput({
          export_type: type as PurchaseExportType,
          start_date: start_date,
          end_date: end_date,
        });
      }
    };

  const { modal, openModalHandler, closeModalHandler } = useModal();
  
  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        const { data: exportData } = await refetchData();
        window.open(exportData?.PurchaseReturnGenerateExport || "", "_blank");
        enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [enqueueSnackbar, exportInput, refetchData, t]);

  const options = [
    {
      value: "นำออกใบส่งคืน",
    },
  ];

  const purchaseReturnTypeOptions = [
    {
      label: "รายเอกสาร",
      value: "order",
    },
    {
      label: "รายสินค้า",
      value: "item",
    }
  ]

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.return.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("global.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_approve"),
      path: `${pathname}?filter=wait_approve`,
    },
    {
      label: t("status.not_approved"),
      path: `${pathname}?filter=not_approved`,
    },
    {
      label: t("status.approved"),
      path: `${pathname}?filter=approved`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("purchase.return.index")}</Typography>
        <ButtonLayout>
          <CustomizedMenuOptions
              isIcon
              label="contact-options"
              options={options}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case "นำออกใบส่งคืน":
                    openModalHandler();
                    break;
                  default:
                }
              }}
            />
        </ButtonLayout>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <PurchaseReturnTable isFilter={isFilter} />
      <ExporterModal
        open={modal}
        closeModalHandler={closeModalHandler}
        typeOptions={purchaseReturnTypeOptions}
        exportHandler={exportHandler}
      />
    </>
  );
};

export default PurchaseReturn;
