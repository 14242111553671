import { Control, Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
  OutlinedInput,
  SelectProps,
  Divider,
} from "@mui/material";
import { ISelectOption } from "../../types/global";

type ExtendedSelectProps = SelectProps & {
  name: string;
  label?: string;
  control: Control<any>;
  options: ISelectOption[];
  onChange?: SelectProps["onChange"];
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  required?: boolean;
  MenuProps?: SelectProps["MenuProps"];
  InputLabelProps?: SelectProps["sx"];
};

const ALL_OPTION_VALUE = "all";

const customMenuProps = {
  PaperProps: {
    style: {
      marginTop: "11px",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    },
  },
};

const ControlledSetSelect = ({
  name,
  label,
  control,
  options,
  error,
  helperText,
  disabled,
  required,
  MenuProps,
  onChange,
  InputLabelProps,
  ...rest
}: ExtendedSelectProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const currentValues = Array.isArray(field.value) ? field.value : [];
        const allOptionValues = options.map((option) => option.value);
        const isAllSelected =
          options.length > 0 && currentValues.length === options.length;

        const handleChange = (event: any) => {
          const value = event.target.value;
          if (value[value.length - 1] === ALL_OPTION_VALUE) {
            const newValues = isAllSelected ? [] : allOptionValues;
            field.onChange(newValues);

            if (onChange) {
              const newEvent = {
                ...event,
                target: {
                  ...event.target,
                  value: newValues,
                },
              };
              onChange(newEvent, undefined);
            }
            return;
          }

          field.onChange(value);
          onChange && onChange(event, undefined);
        };

        return (
          <FormControl fullWidth error={error}>
            <InputLabel
              sx={{
                ...InputLabelProps,
                transform: "translate(14px, 10px) scale(1)",
                "&.Mui-focused": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
                "&.MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              }}
            >
              {label}
            </InputLabel>
            <Select
              {...field}
              multiple
              input={<OutlinedInput label={label} />}
              renderValue={(selected) => {
                if (isAllSelected) {
                  return "ทั้งหมด";
                }

                return Array.isArray(selected)
                  ? (selected as string[])
                      .map(
                        (value) =>
                          options.find((opt) => opt.value === value)?.label
                      )
                      .join(", ")
                  : "";
              }}
              MenuProps={customMenuProps}
              disabled={disabled}
              required={required}
              size="small"
              onChange={handleChange}
              value={currentValues}
              {...rest}
            >
              <MenuItem
                value={ALL_OPTION_VALUE}
                sx={{ height: "37px" }}
                selected={isAllSelected}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={currentValues.length > 0 && !isAllSelected}
                  />
                </ListItemIcon>
                <ListItemText primary="ทั้งหมด" />
              </MenuItem>
              <Divider sx={{ margin: "0px !important" }} />
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    height: "37px",
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    width: "100%",
                  }}
                >
                  <ListItemIcon>
                    <Checkbox checked={currentValues.includes(option.value)} />
                  </ListItemIcon>
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }}
    />
  );
};

export default ControlledSetSelect;
