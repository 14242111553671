import { Grid, Typography } from "@mui/material";
import { IDefaultForm } from "../../../../types/global";
import { useState } from "react";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { useWatch } from "react-hook-form";
import DocDropzoneUI from "../../../UI/DocDropzoneUI";

type Props = IDefaultForm & {
  isModal?: boolean;
};

const AcceptForm = ({
  isModal,
  control,
  errors,
  disabled,
  setValue,
  getValues,
}: Props) => {
  const status = useWatch({
    control,
    name: "aggrid_status",
  });
  const [acceptedDateIsOpen, setAcceptedDateIsOpen] = useState<boolean>(false);

  return (
    <>
      {!isModal && (
        <Typography fontWeight="bold" mb={2}>
          ยอมรับใบเสนอราคา
        </Typography>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item md={isModal ? 3 : 1}>
          <Typography>ยอมรับเมื่อ</Typography>
        </Grid>
        <Grid item md={isModal ? 4 : 2}>
          <ControlledDatePicker
            name="accepted_date"
            control={control}
            error={errors.accepted_date}
            open={acceptedDateIsOpen}
            onClose={() => setAcceptedDateIsOpen(false)}
            onOpen={() => setAcceptedDateIsOpen(true)}
            disabled={disabled && status !== "wait_accept"}
          />
        </Grid>
      </Grid>
      <Typography fontWeight="bold" mt={2}>
        หลักฐานอ้างอิง
      </Typography>
      {isModal ? (
        <ControlledTextField
          name="accepted_remark"
          control={control}
          error={Boolean(errors.accepted_remark)}
          disabled={disabled && status !== "wait_accept"}
          sx={{ mt: 2 }}
          multiline
          rows={4}
        />
      ) : (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <ControlledTextField
              name="accepted_remark"
              control={control}
              error={Boolean(errors.accepted_remark)}
              disabled={disabled && status !== "wait_accept"}
              sx={{ my: 2 }}
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      )}
      <DocDropzoneUI
        control={control}
        name="attachment_list"
        getValues={getValues}
        setValue={setValue}
        disabled={disabled && status !== "wait_accept"}
        isModal={isModal}
        maxSize={10}
        multiple
      />
    </>
  );
};

export default AcceptForm;
