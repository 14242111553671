import { useState } from "react";
import { Controller, useForm, useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Box, Divider, IconButton } from "@mui/material";
import { IContactChannelList, IDefaultForm } from "../../../types/global";

import ContactCard from "./ContactCard";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import CustomizedSelect from "../../Custom/CustomizedSelect";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import CustomizedCreatable from "../../Custom/CustomizedCreatable";

import CloseIcon from "@mui/icons-material/Close";

import DropzoneUI from "../../UI/DropzoneUI";
import ModalUI from "../../UI/ModalUI";

const defaultContactChannelValues = {
  contact_channel_type: "เบอร์โทรศัพท์",
  contact_channel_name: "",
};

const defaultNewContactState: IContactChannelList = {
  position: "",
  title_name: "",
  first_name: "",
  last_name: "",
  nick_name: "",
  contact_channel_list: [],
  remark: "",
  img_url: [],
};

const NewContactTab = ({
  control,
  errors,
  getValues,
  disabled,
}: IDefaultForm) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const {
    fields: displayCardsFields,
    append: appendContactPerson,
    update: updatedContactPerson,
    remove: removeContactPerson,
  } = useFieldArray({
    control,
    name: "contact_person_list",
  });

  const watchContactPersons = useWatch({
    control,
    name: "contact_person_list",
  });

  const {
    control: newContactControl,
    getValues: getNewContactValues,
    setValue: setNewContactValue,
    reset: resetNewContactForm,
  } = useForm({
    defaultValues: { ...defaultNewContactState },
  });

  const {
    fields: contactChannelFields,
    append: appendContactChannel,
    remove: removeContactChannel,
  } = useFieldArray({
    control: newContactControl,
    name: "contact_channel_list",
  });

  const watchNewContactChannelList: any[] = useWatch({
    control: newContactControl,
    name: "contact_channel_list",
  });

  const controlledChannelFields = contactChannelFields.map((field, index) => {
    return { ...field, ...watchNewContactChannelList[index] };
  });

  const controlledDisplayCards = displayCardsFields.map((field, index) => {
    return {
      ...field,
      ...watchContactPersons[index],
    };
  });

  const prefixes = [
    {
      id: 1,
      label: t("contact.contact_person.prefix.mr"),
      value: t("contact.contact_person.prefix.mr"),
    },
    {
      id: 2,
      label: t("contact.contact_person.prefix.mrs"),
      value: t("contact.contact_person.prefix.mrs"),
    },
    {
      id: 3,
      label: t("contact.contact_person.prefix.ms"),
      value: t("contact.contact_person.prefix.ms"),
    },

    {
      id: 4,
      label: t("contact.contact_person.prefix.sir"),
      value: t("contact.contact_person.prefix.sir"),
    },
    {
      id: 5,
      label: t("contact.contact_person.prefix.none"),
      value: t("contact.contact_person.prefix.none"),
    },
  ];

  const contactChannels = [
    "เบอร์โทรศัพท์",
    "Email",
    "แฟกซ์",
    "Line",
    "Facebook",
    "Website",
    "Instagram",
    "อื่นๆ",
  ];

  const formatUpdateContact = (contactPerson: any) => {
    const image = contactPerson.img_url;
    if (
      !Array.isArray(image) ||
      image.length === 0 ||
      typeof image?.[0] === "string"
    ) {
      return contactPerson;
    }
    image[0].preview = URL.createObjectURL(image[0]);
    const updatedContact = {
      ...contactPerson,
      img_url: image,
    };
    return updatedContact;
  };

  const stopEditingHandler = () => {
    setIsEditing(false);
    setEditingIndex(null);
  };

  const startEditingHandler = () => {
    resetNewContactForm({ ...defaultNewContactState });
    setIsEditing(true);
  };

  const editContactCardHandler = (index: number) => {
    const contactPerson = getValues("contact_person_list")[index];
    const contactChannelList = contactPerson.contact_channel_list ?? [];
    setIsEditing(true);
    resetNewContactForm({
      ...contactPerson,
      contact_channel_list: contactChannelList,
    });
    setEditingIndex(index);
  };

  const submitContactHandler = () => {
    const newContactValue = getNewContactValues();

    if (newContactValue.first_name || newContactValue.nick_name) {
      if (editingIndex !== null) {
        updatedContactPerson(
          editingIndex,
          formatUpdateContact(newContactValue)
        );
      } else {
        appendContactPerson(formatUpdateContact(newContactValue));
      }
    }
    setIsEditing(false);
    setEditingIndex(null);
    resetNewContactForm({ ...defaultNewContactState });
  };

  const removeContactPersonHandler = (index: number) => {
    removeContactPerson(index);
  };

  const renderContactsList = () => {
    if (!controlledDisplayCards) {
      return null;
    }
    return controlledDisplayCards
      .filter((contact) => {
        return contact.first_name || contact.nick_name;
      })
      .map((item, index) => {
        const image =
          item.img_url &&
          item.img_url.length > 0 &&
          typeof item.img_url[0] === "object"
            ? item.img_url[0].preview
            : item.img_url[0];
        const imageAlt = item.img_url !== null ? `img-${index}` : "";
        const contactChannel = item.contact_channel_list
          ? item.contact_channel_list[0]?.contact_channel ?? ""
          : "";
        const contactInfo = item.contact_channel_list
          ? item.contact_channel_list[0]?.contact_info_1 ?? ""
          : "";
        const subcontent =
          contactChannel || contactInfo
            ? contactChannel + ": " + contactInfo
            : "";
        return (
          <Grid key={item.id} item xs={12} sm={6} md={3} lg={3} xl={2}>
            <ContactCard
              img={image}
              imgAlt={imageAlt}
              title={item.position}
              title_name={item.title_name}
              first_name={item.first_name}
              last_name={item.last_name}
              nickname={item.nick_name}
              subcontent={subcontent}
              index={index}
              onEdit={editContactCardHandler}
              onRemove={removeContactPersonHandler}
              disabled={disabled}
            />
          </Grid>
        );
      });
  };

  const renderContactChannels = () => {
    return controlledChannelFields.map((item, index) => {
      return (
        <Grid item xs={12} key={item.id}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              order={{ xs: 2, md: 1 }}
            >
              <Controller
                control={newContactControl}
                name={`contact_channel_list.${index}.contact_channel_type`}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    label={t("contact.contact_channel.type")}
                    isOptionEqualToValue={(option, value) => {
                      if (value === "") {
                        return true;
                      }
                      return option === value;
                    }}
                    onChange={(e, value) => field.onChange(value)}
                    options={contactChannels}
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              order={{ xs: 2, md: 3 }}
            >
              <Controller
                control={newContactControl}
                name={`contact_channel_list.${index}.contact_channel_name`}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(
                      errors.contact_channel_list?.[index]?.contact_channel_name
                    )}
                    helperText={
                      errors.contact_channel_list?.[index]?.contact_channel_name
                        ?.message
                    }
                    label={
                      item.contact_channel_type ||
                      t("contact.contact_channel.info")
                    }
                    {...field}
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
            {!disabled && (
              <Grid item xs={1} order={{ xs: 1, md: 4 }}>
                <IconButton
                  aria-label="delete"
                  onClick={() => removeContactChannel(index)}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <>
      {!disabled && (
        <CustomizedButton
          title={"เพิ่มบุคคลติดต่อ"}
          variant="outlined"
          size="medium"
          onClick={startEditingHandler}
          disabled={disabled}
        />
      )}
      <Grid container spacing={2}>
        {renderContactsList()}
      </Grid>
      <ModalUI
        open={isEditing}
        handleClose={stopEditingHandler}
        fullWidth
        maxWidth="sm"
        action={
          !disabled && (
            <Box sx={{ my: 2 }}>
              <CustomizedButton
                title={editingIndex !== null ? "บันทึก" : "เพิ่มบุคคล"}
                variant="contained"
                size="medium"
                onClick={submitContactHandler}
                disabled={disabled}
              />
            </Box>
          )
        }
      >
        <Typography fontWeight={"bold"} sx={{ ml: 1, my: 2 }}>
          {t("contact.contact_person.index")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Box sx={{ mb: 1 }}>
              <Controller
                name="position"
                control={newContactControl}
                render={({ field }) => (
                  <CustomizedCreatable
                    {...field}
                    options={[]}
                    title={t("contact.contact_person.position")}
                    onChange={(e: any, option: any) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      if (option.value) {
                        return field.onChange(option.value);
                      }
                      if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    readOnly={disabled}
                    disabled={disabled}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <Controller
                name="title_name"
                control={newContactControl}
                render={({ field }) => (
                  <CustomizedSelect
                    fullWidth
                    error={Boolean(errors.title_name)}
                    helperText={errors.title_name?.message}
                    label={t("contact.contact_person.prefix.index")}
                    options={prefixes}
                    {...field}
                    disabled={disabled}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <Controller
                name="first_name"
                control={newContactControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(errors.first_name)}
                    helperText={errors.first_name?.message}
                    label={t("contact.contact_person.name.first_name")}
                    {...field}
                    disabled={disabled}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <Controller
                name="last_name"
                control={newContactControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(errors.last_name)}
                    helperText={errors.last_name?.message}
                    label={t("contact.contact_person.name.last_name")}
                    {...field}
                    disabled={disabled}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <Controller
                name="nick_name"
                control={newContactControl}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(errors.nick_name)}
                    helperText={errors.nick_name?.message}
                    label={t("contact.contact_person.name.nickname")}
                    {...field}
                    disabled={disabled}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <DropzoneUI
              control={newContactControl as typeof control}
              setValue={setNewContactValue}
              name={"img_url"}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Box sx={{ my: 4 }}>
          <Divider variant="fullWidth" />
        </Box>
        <Typography fontWeight={"bold"} sx={{ ml: 1, mb: 2 }}>
          {t("contact.contact_channel.index")}
        </Typography>
        <Grid container spacing={2}>
          {!disabled && (
            <Grid item xs={12}>
              <CustomizedButton
                title={"เพิ่มช่องทางการติดต่อ"}
                variant="outlined"
                size="medium"
                onClick={() =>
                  appendContactChannel({ ...defaultContactChannelValues })
                }
                disabled={disabled}
              />
            </Grid>
          )}
          {renderContactChannels()}
        </Grid>
        <Box sx={{ my: 4 }}>
          <Divider variant="fullWidth" />
        </Box>
        <Typography fontWeight={"bold"} sx={{ ml: 1, mb: 2 }}>
          หมายเหตุ
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="remark"
              control={newContactControl}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  multiline
                  rows={4}
                  error={Boolean(errors.remark)}
                  helperText={errors.remark}
                  {...field}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>
      </ModalUI>
    </>
  );
};

export default NewContactTab;
