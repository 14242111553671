import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ApproverListIcon from "./ApproverListIcon";

type Props = {
  control: any;
  documentType: string;
  approvalTemplates: any[];
  workflowProgress?: any[];
  data?: [];
};

const ApprovalListForm = ({ documentType, approvalTemplates, workflowProgress, data }: Props) => {
  const [approverList, setApproverList] = useState<any[]>([]);
  const [approvedReviewer, setApprovedReviewer] = useState<any | null>(null);

  useEffect(() => {
    const currentTemplate = approvalTemplates?.find(
      (template) => template.document_type === documentType
    );

    const currentApprovedReviewer = workflowProgress?.find(
      (progress) =>
        progress.approved_action === "approved" ||
        progress.approved_action === "ejected"
    );

    setApprovedReviewer(currentApprovedReviewer);

    if (currentApprovedReviewer) {
      const snapshotApproverList =
        currentApprovedReviewer.reviewer_unique_id_list
          ?.map((snapshot: string) => {
            try {
              const parsed = JSON.parse(snapshot);
              return parsed.approver_list || [];
            } catch (error) {
              console.error("Error parsing snapshot: ", error);
              return [];
            }
          })
          .flat() || [];
      setApproverList(snapshotApproverList);
    } else {
      setApproverList(
        currentTemplate?.approval_detail_list?.[0]?.approver_list || []
      );
    }
  }, [documentType, approvalTemplates, workflowProgress]);

  const renderAvatars = () => {
    const hasEjected = workflowProgress?.some(
      (progress) => progress.approved_action === "ejected"
    );

    const hasApproved = workflowProgress?.some(
      (progress) => progress.approved_action === "approved"
    );

    const isFlagStatusEmpty =
      Array.isArray(data) && data.every((item: any) => item === "not_approve");

    const lastWorkflowProgress = workflowProgress?.[0];
    const statusName = lastWorkflowProgress?.status_name;

    if (statusName === "draft" && hasEjected && !hasApproved) {
      const templateApproverList =
        approvalTemplates.find((template) => template.document_type === documentType)
          ?.approval_detail_list?.[0]?.approver_list || [];

      return templateApproverList.map((approver: any, index: number) => (
        <ApproverListIcon
          key={approver.unique_id}
          first_name={approver.first_name}
          last_name={approver.last_name}
          img_url={approver.img_url?.[0] || ""}
          disabled={true}
          isMain={false}
          index={index}
        />
      ));
    } else if (hasEjected && isFlagStatusEmpty && !hasApproved) {
      if (!hasApproved) {
        const templateApproverList =
          approvalTemplates.find((template) => template.document_type === documentType)
            ?.approval_detail_list?.[0]?.approver_list || [];
        return templateApproverList.map((approver: any, index: number) => (
          <ApproverListIcon
            key={approver.unique_id}
            first_name={approver.first_name}
            last_name={approver.last_name}
            img_url={approver.img_url?.[0] || ""}
            disabled={true}
            isMain={false}
            index={index}
          />
        ));
      } else {
        return approverList.map((approver: any, index: number) => (
          <ApproverListIcon
            key={approver.unique_id}
            first_name={approver.first_name}
            last_name={approver.last_name}
            img_url={approver.img_url?.[0] || ""}
            disabled={true}
            isMain={false}
            index={index}
          />
        ));
      }
    } else if (approvedReviewer) {
      const filteredApproverList = approverList.filter(
        (approver: any) =>
          approver.unique_id === approvedReviewer.reviewer_unique_id
      );

      return filteredApproverList.map((approver: any, index: number) => (
        <ApproverListIcon
          key={approver.unique_id}
          first_name={approver.first_name}
          last_name={approver.last_name}
          img_url={approver.img_url?.[0] || ""}
          disabled={true}
          isMain={false}
          index={index}
          status={approvedReviewer.approved_action}
          approverList={approverList}
        />
      ));
    }

    return approverList.map((approver: any, index: number) => (
      <ApproverListIcon
        key={approver.unique_id}
        first_name={approver.first_name}
        last_name={approver.last_name}
        img_url={approver.img_url?.[0] || ""}
        disabled={true}
        isMain={false}
        index={index}
      />
    ));
  };

  return (
    <Grid container spacing={1}>
      {renderAvatars()}
    </Grid>
  );
};

export default ApprovalListForm;
