import {
  Avatar,
  Box,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { ITraceEntry } from "../../../../types/Inventory";
import { FieldArrayWithId, UseFieldArrayUpdate } from "react-hook-form";
import { Fragment } from "react";
import { IDefaultForm, IHeaderTable } from "../../../../types/global";
import {
  formatDate,
  formatDateTimeNoAMPM,
} from "../../../../utils/Formatter/Date";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import SerialListScan from "./SerialListScan";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import {
  InventoryDocumentStatus,
  Tracability,
} from "../../../../generated/inventory";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedButton from "../../../Custom/CustomizedButton";

import { IGoodsReceive } from "../../../../types/Inventory/goodsReceive";
import { formatNumber } from "../../../../utils/dataTransformer";

type Props = {
  fields: FieldArrayWithId<IGoodsReceive, "trace_entry_list", "id">[];
  update: UseFieldArrayUpdate<IGoodsReceive, "trace_entry_list">;
  control: IDefaultForm["control"];
  headers: IHeaderTable[];
  status?: string | null;
  printBarcodeHandler: (traceEntryList: ITraceEntry[]) => void;
  filterName: string;
};

const GoodsReceiveScanItemList = ({
  fields,
  update,
  control,
  headers,
  status,
  printBarcodeHandler,
  filterName,
}: Props) => {
  const rescanBarcodeHandler = (index: number) => {
    const { id, ...otherFields } = fields[index];
    update(index, {
      ...otherFields,
      status: InventoryDocumentStatus.IsActive,
      source_bin_location: undefined,
      source_bin_location_id: undefined,
      scanned_by: undefined,
      posted_date: undefined,
    });
  };

  // const isMobile = useMediaQuery((theme: Theme) =>
  //   theme.breakpoints.down("md")
  // );

  const checkConditionRender = (trace: ITraceEntry) => {
    if (trace.tracability === Tracability.Normal) {
      if (filterName === "แสดงทั้งหมด") return true;
      else if (
        filterName === "รอสแกน" &&
        trace.status === InventoryDocumentStatus.IsActive
      )
        return true;
      else if (
        filterName === "สแกนแล้ว" &&
        trace.status === InventoryDocumentStatus.IsScan
      )
        return true;
      else return false;
    } else {
      if (filterName === "แสดงทั้งหมด") return true;
      else if (
        filterName === "รอสแกน" &&
        trace.serial_list &&
        trace.serial_list.filter(
          (serial) => serial.status === InventoryDocumentStatus.IsActive
        ).length > 0
      ) {
        return true;
      } else if (
        filterName === "สแกนแล้ว" &&
        trace.serial_list &&
        trace.serial_list.filter(
          (serial) => serial.status === InventoryDocumentStatus.IsScan
        ).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const printLabelHandler = async (traceEntryList: ITraceEntry[]) => {
    const formatValues = traceEntryList.map((trace) => ({
      productId: trace.item_unique_id,
      productName: trace.item_name,
      productSn: trace.serial_no,
      productLot: trace.lot_date,
      productQty: trace.qty,
      productUom: trace.uom?.name,
      productBarcode: trace.barcode,
    }));
    if (formatValues && formatValues.length > 0) {
      localStorage.setItem("barcode-list", JSON.stringify(formatValues));
    }
    window.open("/print-label", "_blank", "noreferrer");
  };

  return (
    <TableBody>
      {fields.map((trace, index) => (
        <Fragment key={trace.id}>
          {checkConditionRender(trace) && (
            <>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={
                    (headers && headers.length > 0
                      ? headers[0]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>{index + 1}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={
                    (headers && headers.length > 0
                      ? headers[1]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>
                    {formatDateTimeNoAMPM(trace.posted_date)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={
                    (headers && headers.length > 0
                      ? headers[2]?.width || 0
                      : 0) + 16
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      minWidth: "90px",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      alt={trace.item_name}
                      src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                      sx={{
                        width: 60,
                        height: 60,
                        border: "1px solid #BEBEBE",
                        borderRadius: "2px",
                      }}
                      variant="square"
                    >
                      <ImageOutlinedIcon
                        sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                        fontSize="medium"
                      />
                    </Avatar>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={
                    (headers && headers.length > 0
                      ? headers[3]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>{trace.item_unique_id}</Typography>
                  <Typography fontSize={14}>{trace.item_name}</Typography>
                </TableCell>
                <TableCell
                  align="right"
                  width={
                    (headers && headers.length > 0
                      ? headers[4]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>
                    {formatNumber(trace.qty)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  width={
                    (headers && headers.length > 0
                      ? headers[5]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>{trace.uom?.name}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  width={
                    (headers && headers.length > 0
                      ? headers[6]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>{trace.serial_no}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  width={
                    (headers && headers.length > 0
                      ? headers[7]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>
                    {trace.source_bin_location?.bin_name}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  width={
                    (headers && headers.length > 0
                      ? headers[8]?.width || 0
                      : 0) + 16
                  }
                >
                  <CustomizedStatus status={trace.status} />
                </TableCell>
                {status !== "finished" && status !== "cancelled" && (
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[9]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal &&
                      trace.status === InventoryDocumentStatus.IsScan && (
                        <CustomizedTooltip title="สแกนใหม่">
                          <IconButton
                            color="primary"
                            onClick={() => rescanBarcodeHandler(index)}
                          >
                            <ReplayOutlinedIcon fontSize="small" />
                          </IconButton>
                        </CustomizedTooltip>
                      )}
                  </TableCell>
                )}
                <TableCell align="center">
                  {trace.tracability === Tracability.Normal &&
                    trace.scanned_by && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CustomizedAvatar
                          avatars={[
                            {
                              unique_id: trace.scanned_by.user_unique_id || "",
                              first_name: trace.scanned_by.first_name || "",
                              last_name: trace.scanned_by.last_name || "",
                              img_url: trace.scanned_by.img_url
                                ? trace.scanned_by.img_url[0]
                                : "",
                            },
                          ]}
                        />
                      </Box>
                    )}
                </TableCell>
                <TableCell>
                  <Typography fontSize={14}>{trace.barcode}</Typography>
                </TableCell>
                <TableCell>
                  {trace.tracability === Tracability.Normal && (
                    <Typography fontSize={14} align="center">{trace.remark}</Typography>
                  )}
                </TableCell>
                <TableCell>
                  {trace.tracability === Tracability.Normal && (
                    <Typography fontSize={14}>
                      {formatDate(trace.lot_date)}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <CustomizedButton
                    variant="outlined"
                    title="พิมพ์"
                    onClick={() => {
                      if (trace.tracability === Tracability.Normal) {
                        printLabelHandler([trace]);
                      } else {
                        printLabelHandler(trace?.serial_list || []);
                      }
                    }}
                  />
                </TableCell>
                {/* {!isMobile && (
                  <TableCell>
                    <CustomizedButton
                      variant="outlined"
                      title="พิมพ์"
                      onClick={() => {
                        if (trace.tracability === Tracability.Normal) {
                          printBarcodeHandler([trace]);
                        } else {
                          printBarcodeHandler(trace?.serial_list || []);
                        }
                      }}
                    />
                  </TableCell>
                )} */}
              </TableRow>
              <SerialListScan
                control={control}
                nestedIndex={index}
                status={status}
                printBarcodeHandler={printBarcodeHandler}
              />
            </>
          )}
        </Fragment>
      ))}
    </TableBody>
  );
};

export default GoodsReceiveScanItemList;
