import { Box, Divider, Grid, Typography } from "@mui/material";
import { ReactNode } from "react";
import PDFCompanyInfo from "../../PDFCompanyInfo";

import PDFLeftInfo from "../../PDFLeftInfo";
import PDFHighlight from "../../PDFHighlight";
import PDFRightInfo from "../../PDFRightInfo";
import PDFSigner from "../../PDFSigner";
import ManufactureOrderItemPdfTable from "../../../../Table/Pdf/ManufactureOrderItemPdfTable";
import { t } from "i18next";
import { QRCodeSVG } from "qrcode.react";
import { useLocation, useParams } from "react-router-dom";

interface Props {
  children?: ReactNode;
  documentName: string;
  data: any;
  documentType?: string;
  noCompany?: boolean;
  page?: number;
  allPage?: number;
  footer?: boolean;
  leftHeader?: string[];
  leftInfo?: string[];
  highlightHeader?: string[];
  highlightInfo?: string[];
  rightHeader?: string[];
  rightInfo?: string[];
}

const ManufactureOrderPDFLayout = ({
  children,
  documentName,
  data,
  documentType,
  noCompany,
  page,
  allPage,
  footer,
  leftHeader = [],
  leftInfo = [],
  highlightHeader = [],
  highlightInfo = [],
  rightHeader = [],
  rightInfo = [],
}: Props) => {
  const related_user_list = [
    `${data?.created_by?.first_name || ""} ${
      data?.created_by?.last_name || ""
    }`.trim(),
    ...data?.related_user_list
      ?.map(
        (employee: { first_name: string; last_name: string }) =>
          `${employee.first_name} ${employee.last_name}`
      )
      .filter((name: string) => name.trim() !== ""),
  ].filter((name) => name !== "");

  const { id } = useParams();

  return (
    <Box>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          border: "1px solid #eee",
          borderRadius: "5px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "28px",
          mb: 5,
        }}
      >
        <Box sx={{ flex: 1 }}>
          {page === 1 && (
            <Grid container spacing={1.5}>
              <Grid xs={7}>
                <Box mx={2} mt={1}>
                  <PDFCompanyInfo noCompany={noCompany} />
                  <Divider sx={{ mt: 0.5 }} />
                  <PDFLeftInfo
                    leftHeader={leftHeader}
                    leftInfo={leftInfo}
                    isInternational={true}
                  />
                </Box>
              </Grid>
              <Grid xs={5} height="fit-content">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "end",
                  }}
                >
                  <Box mt={1} mr={1}>
                    <Typography
                      fontSize={28}
                      fontWeight={600}
                      align="right"
                      color="#2167D3"
                      lineHeight={1}
                    >
                      {documentName.split("/")[0]}
                    </Typography>
                    <Typography fontSize={11} align="right" color="#2167D3">
                      {documentName.split("/")[1]}
                    </Typography>

                    <Typography fontSize={11} align="right" color="#2167D3">
                      Manufacture Order
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Typography
                        fontSize={11}
                        fontWeight={600}
                        align="right"
                        color="#2167D3"
                        marginRight={1}
                      >
                        เลขที่เอกสาร
                      </Typography>
                      <Typography fontSize={11} align="right" color="#2167D3">
                        {data?.unique_id || "-"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "2px",
                      pr: "8px",
                      pb: "1px",
                    }}
                  >
                    <QRCodeSVG
                      value={`${window.location.origin}/manufacture/order/${id}`}
                      width={44}
                      height={44}
                    />
                    <Typography fontSize={8} align="center">
                      สแกนดูเอกสาร
                    </Typography>
                  </Box>
                </Box>
                <Box my={0.5}>
                  <PDFHighlight
                    highlightHeader={highlightHeader}
                    highlightInfo={highlightInfo}
                  />
                  <PDFRightInfo
                    rightHeader={rightHeader}
                    rightInfo={rightInfo}
                  />
                  <Box px={1}>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Typography
                        sx={{
                          fontSize: 11,
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                          fontWeight: 600,
                          color: "#333333",
                          flex: 0.55,
                        }}
                      >
                        {t("manufacture.order.employee_list")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                          width: "100%",
                          paddingTop: "2px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 11,
                            lineHeight: "20px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          {related_user_list.map((user, index) => (
                            <span key={index}>
                              {user}
                              {index !== related_user_list.length - 1
                                ? ", "
                                : ""}
                            </span>
                          )) || ""}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
          {page !== 1 && (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              marginBottom={"8px"}
            >
              <Typography
                fontSize={11}
                fontWeight={600}
                align="right"
                color="#2167D3"
                marginRight={1}
              >
                ใบสั่งผลิต
              </Typography>
              <Typography fontSize={11} align="right" color="#2167D3">
                {data?.unique_id || "-"}
              </Typography>
            </Box>
          )}
          {page === 1 && (
            <Box mt={1}>
              <ManufactureOrderItemPdfTable data={data} start_no={0} />
            </Box>
          )}
          <Box mt={0}>{children}</Box>
        </Box>
        {footer && <PDFSigner documentType="manufacture_order" />}

        <Box
          sx={{
            position: "absolute",
            bottom: 28,
            right: 28,
          }}
        >
          <Typography fontSize={11} align="right" color="#333333">
            หน้า {footer && page === 1 ? "1" : `${page}/${allPage}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ManufactureOrderPDFLayout;
