import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatNumber } from "../../../utils/dataTransformer";
import { useCallback, useEffect, useState } from "react";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { ITEMS_SKU_AGGRID } from "../../../services/AgGrid/InventoryAgGrid";
import { BomQuery, useBomQuery } from "../../../generated/manufacture";

interface Props {
  data: any;
  start_no: number;
  bgColor?: string;
  manufactureOrderData?: any;
}

const ManufactureOrderIngredientPdfTable = ({
  data,
  start_no,
  bgColor,
  manufactureOrderData,
}: Props) => {
  const [ingredientList, setIngredientList] = useState<any[]>([]);
  const [isHaveImage, setIsHaveImage] = useState<boolean>(false);
  const header = [
    {
      label: "No.",
      thaiLabel: " ",
      width: 30,
    },
    {
      label: "Ingredient",
      thaiLabel: "วัตถุดิบ",
      width: 335,
    },
    {
      label: "Qty",
      thaiLabel: "จำนวน",
      width: 40,
    },
    {
      label: "Used Qty",
      thaiLabel: "จำนวนที่นำไปใช้",
      width: 100,
    },
    {
      label: "",
      thaiLabel: "",
      width: 225,
    },
  ];
  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { data: bomData } = useBomQuery<BomQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: { name: manufactureOrderData.bom_name },
    },
    {
      enabled: Boolean(manufactureOrderData.bom_name),
    }
  );

  const getSkuFormItem = useCallback(async () => {
    const allItemListUniqueId = data.map((item: any) => item.item_unique_id);
    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("item");

    const { itemSkuDetailsFindManyAggrid } =
      await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
        aggridInput: {
          startRow: 0,
          endRow: 999,
          filterModel: {
            sku_name: {
              filterType: "set",
              values: allItemListUniqueId,
            },
          },
        },
      });
    const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

    const newItemList = data.map((item: any) => {
      const foundItemIndex = itemSkuDetails.findIndex(
        (realItem: any) => realItem.sku_name === item.item_unique_id
      );

      return {
        ...item,
        item_img_url: itemSkuDetails[foundItemIndex].img_url[0],
      };
    });

    let newItemListWithBomQty = newItemList;
    if (bomData && bomData?.bom?.ingredient_list) {
      newItemListWithBomQty = newItemList.map((item: any) => {
        const foundItemIndex =
          bomData?.bom?.ingredient_list?.findIndex(
            (realItem: any) => realItem.item_unique_id === item.item_unique_id
          ) ?? -1;

        return {
          ...item,
          item_bom_qty:
            bomData?.bom?.ingredient_list?.[foundItemIndex]?.qty ?? null,
          item_bom_uom:
            bomData?.bom?.ingredient_list?.[foundItemIndex]?.uom ?? null,
        };
      });
    }

    setIngredientList(newItemListWithBomQty);
  }, [data]);

  useEffect(() => {
    const isHaveImage = ingredientList.some((item: any) => item.item_img_url);
    setIsHaveImage(isHaveImage);
  }, [ingredientList]);

  useEffect(() => {
    getSkuFormItem();
  }, [getSkuFormItem, data]);

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead
          sx={{
            ...(start_no !== 0 && {
              height: 0,
              minHeight: 0,
              maxHeight: 0,
              overflow: "hidden",
              visibility: "collapse",
            }),
          }}
        >
          <TableRow>
            {header.map((head, index) => (
              <TableCell
                key={index}
                align={
                  head.label === "Qty" || head.label === "Used Qty"
                    ? "right"
                    : "left"
                }
                sx={{
                  textAlign:
                    head.label === "Qty" || head.label === "Used Qty"
                      ? "right"
                      : "left",
                  pr: head.label === "Ingredient" ? 2 : 0,
                  pl: head.label === "Ingredient" ? 1.5 : 2,
                  py: 1,
                  backgroundColor: bgColor || "#ECF6FD",
                  borderTop: "none",
                  borderBottom: "none",
                }}
                width={head.width}
              >
                <Typography
                  display="block"
                  fontSize={11}
                  fontWeight={600}
                  textAlign={
                    head.label === "Qty" || head.label === "Used Qty"
                      ? "right"
                      : "left"
                  }
                >
                  {head.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {ingredientList.map((ingredient, index) => (
            <TableRow
              key={ingredient.item_unique_id}
              sx={{
                breakInside: "avoid",
                minHeight: "100px",
                verticalAlign: "top",
                borderBottom: "none",
              }}
            >
              <TableCell>
                <Typography
                  fontSize={11}
                  fontWeight={400}
                  lineHeight="15px"
                  align="center"
                  sx={{ margin: 0 }}
                >
                  {start_no + index + 1}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "4px !important",
                  maxWidth: "250px",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row", gap: "4px" }}>
                  <Box
                    sx={{
                      display: "inline-flex",
                    }}
                  >
                    {ingredient?.item_img_url ? (
                      <img
                        alt={`item's ${start_no}`}
                        src={ingredient.item_img_url}
                        style={{
                          display: "block",

                          padding: 0,
                        }}
                        width={65}
                        height={65}
                      />
                    ) : isHaveImage ? (
                      <Box width={65} height={65} />
                    ) : null}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      pt: "4px !important",
                    }}
                  >
                    <Typography
                      fontSize={11}
                      fontWeight={600}
                      lineHeight={"15px"}
                      textAlign={"left"}
                    >
                      {ingredient.item_name}
                    </Typography>
                    <Typography
                      fontSize={11}
                      fontWeight={400}
                      lineHeight={"15px"}
                      textAlign={"left"}
                      sx={{
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {ingredient.item_description}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="right" sx={{ pr: "0px !important" }}>
                <Typography
                  fontSize={11}
                  fontWeight={400}
                  lineHeight="15px"
                  sx={{ margin: 0 }}
                >
                  {ingredient.item_bom_qty
                    ? formatNumber(ingredient.item_bom_qty)
                    : ""}
                </Typography>
                <Typography
                  fontSize={11}
                  fontWeight={400}
                  lineHeight="11px"
                  color={"#737373"}
                  sx={{ margin: 0 }}
                >
                  {ingredient.item_bom_uom}
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ pr: "0px !important" }}>
                <Typography
                  fontSize={11}
                  fontWeight={400}
                  lineHeight="15px"
                  sx={{ margin: 0 }}
                >
                  {formatNumber(ingredient.qty)}
                </Typography>
                <Typography
                  fontSize={11}
                  fontWeight={400}
                  lineHeight="11px"
                  color={"#737373"}
                  sx={{ margin: 0 }}
                >
                  {ingredient.uom}
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ManufactureOrderIngredientPdfTable;
