import React from "react";
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd";
import DraggableItem from "./DraggableItem";
import { IProject } from "../../../types/Project";
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";

interface Props {
  id: string;
  title: string;
  mainStatus: string;
  items: IProject[] | undefined;
  toggleSorting: (droppableId: string) => void;
  sorting: Record<string, boolean>;
}

const DroppableContainer = ({
  id,
  title,
  items,
  mainStatus,
  sorting,
  toggleSorting,
}: Props) => {
  const renderTopBorderColor = (): string => {
    switch (mainStatus) {
      case "pre_sales":
        return "#333333";
      case "post_sales":
        return "#1F5BB2";
      case "support":
        return "#C3762E";
      case "finished":
        return "#246527";
      case "cancelled":
        return "#666666";
      default:
        return "#333333";
    }
  };

  const renderItemAmountColor = (): string => {
    switch (mainStatus) {
      case "pre_sales":
        return "#EDEDED";
      case "post_sales":
        return "#E5F0FF";
      case "support":
        return "#FFF1C5";
      case "finished":
        return "#E5F8EF";
      case "cancelled":
        return "#DADADA";
      default:
        return "#EDEDED";
    }
  };

  return (
    <Droppable droppableId={id}>
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <Box
          sx={{
            width: "320px",
            borderRadius: "5px",
            backgroundColor: snapshot.isDraggingOver ? "#EFF2FF" : "#F8F8FC",
            padding: "15px",
            borderTop: `5px ${renderTopBorderColor()} solid`,
          }}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <Grid
            container
            justifyContent="space-between"
            pb="15px"
            alignItems="center"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography fontSize={22} fontWeight={700}>
                {title}
              </Typography>
              {sorting[id] ? (
                <IconButton
                  size="small"
                  sx={{ ml: 1 }}
                  onClick={() => toggleSorting(id)}
                >
                  <CustomizedTooltip title="เรียงลำดับจากชื่อโครงการ">
                    <ArrowDownwardIcon />
                  </CustomizedTooltip>
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  sx={{ ml: 1 }}
                  onClick={() => toggleSorting(id)}
                >
                  <CustomizedTooltip title="เรียงลำดับจากชื่อโครงการ">
                    <ArrowUpwardIcon />
                  </CustomizedTooltip>
                </IconButton>
              )}
            </Box>
            <CustomizedTooltip title="จำนวนโครงการ">
              <Chip
                label={items?.length}
                sx={{ backgroundColor: renderItemAmountColor() }}
              />
            </CustomizedTooltip>
          </Grid>
          <Box
            sx={{
              overflow: "scroll",
              maxHeight: "120vh",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {items?.map((item, index) => (
              <DraggableItem item={item} index={index} key={item.id} />
            ))}
          </Box>

          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
};

export default DroppableContainer;
