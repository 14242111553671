import { Box, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import { Chart } from "react-google-charts";
// import LaunchIcon from "@mui/icons-material/Launch";
import { customChartTooltipDonutChart } from "../../../../../utils/chartUtils";
// import { Link } from "react-router-dom";
import EmptyDonutChart from "../EmptyDonutChart";
// import { useLegendHandler } from "../../../../../hooks/Dashboard/useLegendHandler";

type Props = {
  name: string;
  data: any;
  isFetching: boolean;
  type: "total_purchase_by_item" | "total_purchase_by_vendor";
  //   url: string;
};

const TotalPurchaseChart = ({ name, data, isFetching, type }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const options = {
    pieHole: 0.4,
    is3D: false,
    chartArea: {
      height: "80%",
      width: "100%",
    },
    legend: {
      position: "none", // Hide the default legend
    },
    tooltip: {
      isHtml: true,
      textStyle: {
        fontName: "Kanit",
        fontSize: 12,
        color: "#737373",
      },
    },
    colors: ["#4F89E3", "#E7614B", "#FF9633", "#80D7FF", "#B44BAF", "#E16B8D"],
  };

  const totalSum = data?.reduce(
    (sum: any, item: any) => sum + item.total_amount,
    0
  );

  // Map over the data to calculate percentage for each item
  const result = data?.map((item: any) => ({
    ...item,
    percentage: ((item.total_amount / totalSum) * 100).toFixed(1), // Percentage rounded to 2 decimal places
  }));

  const formatData = [
    [
      type === "total_purchase_by_item" ? "สินค้า" : "ผู้ขาย",
      "จำนวน",
      { role: "tooltip", p: { html: true } },
    ],
    ...(result?.map((item: any, index: number) => [
      type === "total_purchase_by_item" ? item.item_name : item.vendor_name,
      item.total_amount,
      customChartTooltipDonutChart(
        options.colors[index],
        type === "total_purchase_by_item" ? item.item_name : item.vendor_name,
        item.total_amount,
        item.percentage
      ),
    ]) || []),
  ];

  return (
    <CustomizedBox
      height={374}
      border={`1px solid #E2E2E2`}
      margin={isMobile ? "unset" : "0.5rem 0"}
      padding={"1rem"}
      boxShadow={"unset"}
    >
      {/* <Link to={url} style={{ textDecoration: "none", flex: 1 }}> */}
      <Box display={"flex"} gap={0.5} alignItems={"center"}>
        <Typography color={"secondary.main"} fontWeight={600}>
          {name}
        </Typography>
        {/* <LaunchIcon sx={{ fontSize: 16 }} color="secondary" /> */}
      </Box>
      {/* </Link> */}

      {isFetching ? (
        <Skeleton variant="rectangular" height={314} animation={"wave"} />
      ) : (
        <>
          {totalSum === 0 ? (
            <EmptyDonutChart />
          ) : (
            <Chart
              chartType="PieChart"
              width={"100%"}
              height={200}
              data={formatData}
              options={options}
              chartEvents={[
                {
                  eventName: "select",
                  callback: ({ chartWrapper }: { chartWrapper: any }) => {
                    const chart = chartWrapper.getChart();
                    if (chart) {
                      chart.setSelection([]); // Clear any selection immediately
                    }
                  },
                },
              ]}
            />
          )}

          <Box
            display="flex"
            flexWrap="wrap"
            justifySelf={"center"}
            gap={1}
            width={300}
          >
            {formatData.slice(1).map((item, index) => (
              <Box
                key={index}
                // onMouseEnter={() => handleLegendHover(index)}
                // onMouseLeave={handleLegendMouseOut}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // cursor: "pointer",
                  gap: 1,
                  width: "calc(50% - 8px)", // Ensure 2 items per row
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: options.colors[index],
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    flexShrink: 0,
                  }}
                />
                <Typography
                  title={item[0]}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    flexGrow: 1,
                    fontSize: 12,
                    color: "#737373",
                  }}
                >
                  {item[0]}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </CustomizedBox>
  );
};

export default TotalPurchaseChart;
