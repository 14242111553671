import { IPosSetting } from "../../types/Sales/posSetting";

export const validatePosSetting = (savedDefaultPosSetting: string) => {
  if (savedDefaultPosSetting === "") return false;
  const posSetting = JSON.parse(savedDefaultPosSetting) as IPosSetting;
  if (!posSetting) return false;
  if (
    posSetting.pos_name === "" ||
    posSetting.pos_number === "" ||
    posSetting.pin_code === "" ||
    posSetting.warehouse_unique_id === "" ||
    posSetting.bin_id === undefined ||
    posSetting.default_customer_unique_id === ""
  ) {
    return false;
  } else {
    return true;
  }
};
