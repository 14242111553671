import { IDefaultForm, ISelectOption } from "../../../../types/global";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import { useFieldArray } from "react-hook-form";
import { ISalesItemList } from "../../../../types/Sales";
import { useSalesItemListHeader } from "../../../../hooks/Sales/use-sales-item-list-header";
import { useEffect, useRef, useState } from "react";
import Confirmation from "../../../UI/Confirmation";
import CustomizedButton from "../../../Custom/CustomizedButton";
import AddIcon from "@mui/icons-material/Add";
import { useModal } from "../../../../hooks/use-modal";
import ItemTableModal from "../../ItemTableModal";
import { AgGridReact } from "ag-grid-react";
import { ISkuDetail } from "../../../../types/Inventory/item";
import { ISalesItemPriceHistoryList } from "../../../../types/Sales";
import { EntityTypeEnum } from "../../../../generated/creatable";
import SumPreVatAmount from "./SumPreVatAmount";
import SalesReturnItemList from "./SalesReturnItemList";
import ItemListModal from "../../ItemListModal";
import { v4 as uuidv4 } from "uuid";
import ItemRow from "./ItemRow";
import SalesItemPriceHistoryModal from "../../../Form/Sales/SalesItemPriceHistoryModal";
import ButtonLayout from "../../../UI/ButtonLayout";
import SalesBomTableModal from "../../SalesBomTableModal";
import {
  calculateParentSalesBom,
  formatVatType,
} from "../../../../utils/Formatter/Global";
import { ItemType } from "../../../../generated/inventory";
import { useConfirmation } from "../../../../hooks/use-confrimation";
// import ConfigurableItemTableModal from "../../ConfigurableItemTableModal";
// import ConfigurableItemListModal from "../../ConfigurableItemListModal";

interface Props {
  control: IDefaultForm["control"];
  getValues: IDefaultForm["getValues"];
  setValue: IDefaultForm["setValue"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
  documentType: EntityTypeEnum;
  referenceItemList?: ISalesItemList[];
}

type FieldArrayType = {
  item_list: ISalesItemList[];
};

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0",
  },
  {
    label: "7 %",
    value: "7",
  },
];

const SalesItemList = ({
  control,
  errors,
  disabled,
  documentType,
  getValues,
  setValue,
  referenceItemList,
}: Props) => {
  const gridRef = useRef<AgGridReact<ISkuDetail>>(null);
  const itemPriceHistoryGridRef =
    useRef<AgGridReact<ISalesItemPriceHistoryList>>(null);
  const gridRefItemList = useRef<AgGridReact<ISalesItemList>>(null);
  const headers = useSalesItemListHeader(documentType, disabled);
  const [searchParams, setSearchParams] = useSearchParams();

  const [salesBomIndex, setSalesBomIndex] = useState<number | undefined>(
    undefined
  );

  const [haveSalesBom, setHaveSalesBom] = useState<Boolean>(false);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );
  const [deleteSalesBomIndex, setDeleteSalesBomIndex] = useState<
    | {
        itemIndex: number;
        salesBomIndex: number;
      }
    | undefined
  >(undefined);
  const [priceUpdateIndex, setPriceUpdateIndex] = useState<
    | {
        itemIndex: number;
        salesBomIndex: number | undefined;
      }
    | undefined
  >(undefined);
  const [priceUpdateItemUniqueId, setPriceUpdateItemUniqueId] = useState<
    string | undefined
  >(undefined);

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const {
    modal: itemPriceHistoryModal,
    openModalHandler: openItemPriceHistoryModal,
    closeModalHandler: closeItemPriceHistoryModal,
  } = useModal();

  const itemSalesBomModal = useModal();
  const salesBomModal = useModal();
  const deleteSalesBom = useConfirmation();

  const { fields, move, remove, append, update } = useFieldArray<
    FieldArrayType,
    "item_list",
    "id"
  >({
    control,
    name: "item_list",
  });

  useEffect(() => {
    if (fields.some((list) => list.item_type === ItemType.Bom))
      setHaveSalesBom(true);
    else setHaveSalesBom(false);
  }, [fields]);

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const openDeleteSalesBomConfirmationHandler = (
    itemIndex: number,
    salesBomIndex: number
  ) => {
    deleteSalesBom.openConfirmationHandler();
    setDeleteSalesBomIndex({ itemIndex, salesBomIndex });
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const closeDeleteSalesBomConfirmationHandler = () => {
    deleteSalesBom.closeConfirmationHandler();
    setDeleteSalesBomIndex(undefined);
  };

  const deleteItemConfirmationAction = () => {
    remove(deletedIndex);

    closeDeleteItemConfirmationHandler();
  };

  const deleteSalesBomConfirmationAction = () => {
    const itemList: ISalesItemList = getValues(
      `item_list.${deleteSalesBomIndex?.itemIndex}`
    );

    const newSalesBomItem = itemList?.sales_bom_item?.filter(
      (_, index) => index !== deleteSalesBomIndex?.salesBomIndex
    );

    const newItemList = {
      ...itemList,
      sales_bom_item: newSalesBomItem,
    };

    const result = calculateParentSalesBom(newItemList) as ISalesItemList;

    update(deleteSalesBomIndex?.itemIndex || 0, result);
    closeDeleteSalesBomConfirmationHandler();
  };

  const openItemPriceHistoryModalHandler = (
    itemIndex: number,
    item_unique_id: string | undefined,
    salesBomIndex: number | undefined
  ) => {
    setPriceUpdateIndex({ itemIndex, salesBomIndex });
    setPriceUpdateItemUniqueId(item_unique_id);
    setSearchParams({ type: "quotation" });
    openItemPriceHistoryModal();
  };

  const closeItemPriceHistoryModalHandler = () => {
    closeItemPriceHistoryModal();
    searchParams.delete("type");
    setSearchParams(searchParams);
    setPriceUpdateIndex(undefined);
  };

  const addItemsHandler = () => {
    const selectedItems = gridRef?.current?.api.getSelectedRows();
    if (!selectedItems?.length) return;

    const document_unique_id = getValues("unique_id");

    // Helper function to format items
    const formatItem = (
      skuDetail: any,
      isChild: boolean
    ): ISalesItemList | null => {
      if (!skuDetail?.item_sku?.item) return null;

      const { item_sku, sku_name, sale_price, img_url, barcode } = skuDetail;

      const { item } = item_sku;
      const {
        name,
        desc,
        type,
        tracability,
        sale_vat_type,
        uom_group,
        physical_attribute,
      } = item;
      const { volume, weight } = physical_attribute;

      return {
        unique_id: uuidv4(),
        item_unique_id: sku_name,
        item_name: name,
        item_sku_desc: desc ?? "",
        item_type: type,
        tracability,
        qty: 1,
        discount: 0,
        discount_type: "baht",
        pre_vat_amount: 0,
        pre_discount_amount: 0,
        price_per_unit: sale_price ?? item.sale_price ?? 0,
        uom: item.stock_uom_unique_id,
        remark: "",
        stock_qty: 1,
        vat_percentage: formatVatType(sale_vat_type || ""),
        withholding_tax_type: "ยังไม่ระบุ",
        withholding_tax_value: 0,
        reference_document_type: documentType,
        reference_unique_id: document_unique_id,
        uom_group,
        item_img_url: img_url,
        volume,
        weight,
        barcode: barcode || undefined,
        is_manufactured: item.is_manufactured,
        is_purchasable: item.is_purchasable,
        is_saleable: item.is_saleable,
        is_stockable: item.is_stockable,
        is_child: isChild,
        item_sku_qty: {
          stock_qty: skuDetail.stock_qty,
          available_qty: skuDetail.available_qty,
          purchase_ordered_qty: skuDetail.purchase_ordered_qty,
          manufacture_ordered_qty: skuDetail.manufacture_ordered_qty,
          sale_committed_qty: skuDetail.sale_committed_qty,
          manufacture_committed_qty: skuDetail.manufacture_committed_qty,
        },
      };
    };

    if (salesBomIndex == null) {
      selectedItems.forEach((item) => {
        const formattedItem = formatItem(item, false);
        if (formattedItem) append(formattedItem);
      });
    } else {
      const list = getValues(`item_list.${salesBomIndex}`);
      const salesBomList =
        getValues(`item_list.${salesBomIndex}.sales_bom_item`) || [];
      const mappingItems = selectedItems
        .map((item) => ({
          ...formatItem(item, true),
          vat_percentage: list.vat_percentage,
        }))
        .filter(Boolean);

      setValue(`item_list.${salesBomIndex}.sales_bom_item`, [
        ...salesBomList,
        ...mappingItems,
      ]);

      const itemList = getValues(`item_list.${salesBomIndex}`);

      const calValue = calculateParentSalesBom(itemList);

      setValue(`item_list.${salesBomIndex}`, calValue);
    }

    // Close modals and reset state
    closeItemModalHandler();
    salesBomModal.closeModalHandler();
    itemSalesBomModal.closeModalHandler();
    setSalesBomIndex(undefined);
  };

  const addItemPriceFromHistoryHandler = () => {
    const selectedItems =
      itemPriceHistoryGridRef?.current?.api.getSelectedRows();
    if (!selectedItems?.length) return;

    const { itemIndex, salesBomIndex } = priceUpdateIndex || {};
    const price = selectedItems[0].price_per_unit;

    if (typeof itemIndex === "number") {
      const fieldPath =
        salesBomIndex !== undefined
          ? `item_list.${itemIndex}.sales_bom_item.${salesBomIndex}.price_per_unit`
          : `item_list.${itemIndex}.price_per_unit`;

      setValue(fieldPath, price);

      if (salesBomIndex !== undefined) {
        const itemListValue = getValues(`item_list.${itemIndex}`);
        const result = calculateParentSalesBom(itemListValue) as ISalesItemList;
        update(itemIndex, result);
      }
    }

    closeItemPriceHistoryModalHandler();
  };

  const addReferenceItemsHandler = () => {
    const selectedItems = gridRefItemList?.current?.api.getSelectedRows();
    if (selectedItems) {
      append(selectedItems);
    }
    closeItemModalHandler();
  };

  if (documentType === EntityTypeEnum.SalesReturn) {
    return (
      <CustomizedBox padding={0} margin={0} sx={{ breakAfter: "auto" }}>
        <SalesReturnItemList
          control={control}
          disabled={disabled}
          dragEndHandler={dragEndHandler}
          errors={errors}
          fields={fields}
          getValues={getValues}
          headers={headers}
          openDeleteItemConfirmationHandler={openDeleteItemConfirmationHandler}
          setValue={setValue}
          vatOptions={vatOptions}
        />
        <Grid container p={3}>
          <Grid item xs={5}>
            {!disabled && (
              <CustomizedButton
                title="เพิ่มสินค้า"
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={openItemModalHandler}
              />
            )}
          </Grid>
          <Grid item xs={7}>
            <SumPreVatAmount control={control} />
          </Grid>
        </Grid>
        <Confirmation
          title="ยืนยันการลบสินค้า"
          open={openDeleteItemConfirmation}
          handleClose={closeDeleteItemConfirmationHandler}
          action={deleteItemConfirmationAction}
        />
        <ItemListModal
          gridRef={gridRefItemList}
          itemModal={itemModal}
          closeItemModalHandler={closeItemModalHandler}
          addItemsHandler={addReferenceItemsHandler}
          documentType="sales_return"
          itemList={referenceItemList}
          disabled={disabled}
        />
        {/* <ConfigurableItemListModal
          gridRef={gridRefItemList}
          itemModal={itemModal}
          closeItemModalHandler={closeItemModalHandler}
          addItemsHandler={addReferenceItemsHandler}
          documentType="sales_return"
          itemList={referenceItemList}
          disabled={disabled}
        /> */}
        {/* Item List columnDef not match with itemview */}
      </CustomizedBox>
    );
  }

  return (
    <CustomizedBox padding={0} margin={0} sx={{ breakAfter: "auto" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={!header.align ? "center" : header.align}
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    width={header.width}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable
              droppableId="droppable"
              type="ROW"
              isDropDisabled={disabled}
            >
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <>
                      <ItemRow
                        control={control}
                        disabled={disabled}
                        documentType={documentType}
                        errors={errors}
                        field={field}
                        getValues={getValues}
                        headers={headers}
                        index={index}
                        openDeleteItemConfirmationHandler={
                          openDeleteItemConfirmationHandler
                        }
                        openDeleteSalesBomConfirmationHandler={
                          openDeleteSalesBomConfirmationHandler
                        }
                        openItemPriceHistoryModalHandler={
                          openItemPriceHistoryModalHandler
                        }
                        setValue={setValue}
                        key={field.id}
                        salesBomModal={salesBomModal}
                        setSalesBomIndex={setSalesBomIndex}
                        haveSalesBom={haveSalesBom}
                      />
                    </>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <Grid container p={3}>
        <Grid item xs={5}>
          {!disabled && (
            <ButtonLayout>
              <CustomizedButton
                title="เพิ่มสินค้า"
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={openItemModalHandler}
              />
              <CustomizedButton
                title="เพิ่มชุดสินค้า"
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={itemSalesBomModal.openModalHandler}
              />
            </ButtonLayout>
          )}
        </Grid>
        <Grid item xs={7}>
          <SumPreVatAmount control={control} />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันการลบสินค้า"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
      <Confirmation
        title="ยืนยันการลบสินค้า"
        open={deleteSalesBom.confirmation}
        handleClose={closeDeleteSalesBomConfirmationHandler}
        action={deleteSalesBomConfirmationAction}
      />
      <ItemTableModal
        ref={gridRef}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
      />
      <SalesBomTableModal
        ref={gridRef}
        itemModal={itemSalesBomModal.modal}
        closeItemModalHandler={itemSalesBomModal.closeModalHandler}
        addItemsHandler={addItemsHandler}
      />
      <ItemTableModal
        ref={gridRef}
        itemModal={salesBomModal.modal}
        closeItemModalHandler={salesBomModal.closeModalHandler}
        addItemsHandler={addItemsHandler}
      />
      {/* <ConfigurableItemTableModal
        ref={gridRef}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
      /> */}
      <SalesItemPriceHistoryModal
        gridRef={itemPriceHistoryGridRef}
        control={control}
        open={itemPriceHistoryModal}
        closeModalHandler={closeItemPriceHistoryModalHandler}
        addPriceHandler={addItemPriceFromHistoryHandler}
        itemUniqueId={priceUpdateItemUniqueId}
        disabled={disabled}
      />
    </CustomizedBox>
  );
};

export default SalesItemList;
