import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IBom } from "../../../types/Manufacture/bom";
import { IBreadcrumbsAndMenu } from "../../../types/global";

import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import BomHeaderForm from "../../../components/Form/Manufacture/Bom/Header";
import BomDetailForm from "../../../components/Form/Manufacture/Bom/Detail";
import {
  bomSchema,
  bomValidation,
} from "../../../components/Form/Manufacture/Bom/schema";
import BomRemarkForm from "../../../components/Form/Manufacture/Bom/Remark";
import BomTableForm from "../../../components/Form/Manufacture/Bom/Table";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { Stack } from "@mui/material";

import {
  BomQuery,
  useBomCreateMutation,
  useBomDeleteMutation,
  useBomQuery,
  useBomUpdateMutation,
} from "../../../generated/manufacture";
import { bomQueryFormatter, formatterBom } from "../../../utils/Formatter/Bom";
import BomAttachment from "../../../components/Form/Manufacture/Bom/Attachment";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { useOnLeavePage } from "../../../hooks/use-on-leave-page";
import LoadingMascot from "../../../components/UI/LoadingMascot";

const BomContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(false);

  const [allLoading, setAllLoading] = useState<boolean>(false);

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { data, isSuccess, refetch } = useBomQuery<BomQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: { id: parseInt(id ?? "0") },
    },
    { enabled: !!id }
  );

  const { mutate: bomCreate } = useBomCreateMutation<Error>(
    graphQLClientWithHeaderManufacture,
    {
      onMutate(variables) {
        setDisabled(true);
      },
      onSuccess(data, variables, context) {
        allowNextNavigation();
        navigate("/manufacture/bom/" + data.bomCreate?.id);
        enqueueSnackbar("สร้างสูตรการผลิตสำเร็จ", {
          variant: "success",
        });
      },
      onError(error, variables, context) {
        const formatError = JSON.stringify(error);
        if (
          formatError.includes(
            "Unique constraint failed on the fields: (`name`)"
          )
        ) {
          enqueueSnackbar("ชื่อสูตรนี้มีในระบบแล้ว", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างสูตรการผลิตไม่สำเร็จ", {
            variant: "error",
          });
        }
        setDisabled(false);
      },
    }
  );

  const { mutate: bomUpdate } = useBomUpdateMutation<Error>(
    graphQLClientWithHeaderManufacture,
    {
      onMutate() {
        setDisabled(true);
      },
      async onSuccess(data, variables, context) {
        await refetch();
        // const bomType = data.bomUpdate as IBom;
        // reset(bomType);
        enqueueSnackbar("แก้ไขสูตรการผลิตสำเร็จ", {
          variant: "success",
        });
      },
      onError(error, variables, context) {
        const formatError = JSON.stringify(error);
        if (
          formatError.includes(
            "Unique constraint failed on the fields: (`name`)"
          )
        ) {
          enqueueSnackbar("ชื่อสูตรนี้มีในระบบแล้ว", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("แก้ไขสูตรการผลิตไม่สำเร็จ", {
            variant: "error",
          });
        }
        setDisabled(false);
      },
    }
  );

  const { mutate: bomDelete } = useBomDeleteMutation<Error>(
    graphQLClientWithHeaderManufacture,
    {
      onMutate(variables) {
        setDisabled(true);
      },
      onSuccess() {
        navigate("/manufacture/bom");
        enqueueSnackbar("ลบสูตรการผลิตสำเร็จ", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("ลบสูตรการผลิตไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({
    defaultValues: bomSchema,
    resolver: yupResolver(bomValidation),
  });

  useEffect(() => {
    if (id) {
      if (isSuccess) {
        const getBomData = async () => {
          const bomType = data.bom as IBom;
          const formattedBom = await bomQueryFormatter(bomType);
          reset(formattedBom);
        };

        setDisabled(true);
        getBomData();
      }
    } else if (state) {
      reset({ ...bomSchema, ...state });
    }
  }, [data?.bom, id, isSuccess, reset, state]);

  const isAttachmentDirty = Array.isArray(dirtyFields?.attachment_list)
    ? dirtyFields.attachment_list.some((attachment) =>
        Object.values(attachment).some(
          (value) =>
            typeof value === "object"
              ? Object.values(value).some(Boolean) // Check nested objects
              : Boolean(value) // Check top-level fields
        )
      )
    : Boolean(dirtyFields?.attachment_list);

  const { allowNextNavigation } = useOnLeavePage(isAttachmentDirty);

  const editButtonClickHandler = () => {
    setDisabled(false);
  };

  const onSubmit = async (data: IBom) => {
    if (dirtyFields.attachment_list) {
      setAllLoading(true);
    }
    const { id, ...formatData } = await formatterBom(data);
    setAllLoading(false);
    if (!id) bomCreate({ createInput: formatData });
    else {
      bomUpdate({
        updateinput: formatData,
        bomUpdateUniqueInput: { id: id },
      });
    }
  };

  const mainBomSubmitHandler = async () => {
    const bomType = data?.bom as IBom;
    if (dirtyFields.attachment_list) {
      setAllLoading(true);
    }
    const { id, ...formatData } = await formatterBom(bomType);
    setAllLoading(false);
    const payload = {
      ...formatData,
      is_main_bom: 1,
    };
    bomUpdate({
      updateinput: payload,
      bomUpdateUniqueInput: { id: id },
    });
  };

  const watchMinimunStockQty = useWatch({ control, name: "minimum_stock_qty" });

  const deleteBomHandler = () => {
    bomDelete({ bomDeleteUniqueInput: { id: parseInt(id ?? "") } });
  };

  const copyBomHandler = () => {
    const {
      id,
      is_main_bom,
      is_active,
      created_date,
      start_date,
      end_date,
      created_by,
      creator_unique_id,
      ingredient_list,
      ...otherData
    } = getValues();
    const formatIngredient =
      ingredient_list?.map(({ cuid, ...ingredient }) => ingredient) || [];
    navigate("/manufacture/bom/add", {
      state: {
        ...otherData,
        ingredient_list: formatIngredient,
      },
    });
    enqueueSnackbar("คัดลอกสำเร็จ", {
      variant: "success",
    });
  };

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.bom.index"),
      to: "/manufacture/bom",
    },
    {
      name: id ? data?.bom?.name ?? "" : `สร้าง${t("manufacture.bom.index")}`,
    },
  ];

  const handleClickOpenItem = async (unique_id?: string | null) => {
    if (unique_id && typeof unique_id === "string") {
      window.open(
        `/inventory/items/${unique_id}?tab=item&subtab=general`,
        "_blank"
      );
    } else {
      const unique_id = getValues("item_unique_id");
      if (unique_id && unique_id !== "")
        window.open(
          `/inventory/items/${unique_id}?tab=item&subtab=general`,
          "_blank"
        );
    }
  };

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <LoadingMascot isLoading={allLoading} />
        <BomHeaderForm
          control={control}
          errors={errors}
          disabled={disabled}
          getValues={getValues}
          setValue={setValue}
          editButtonClickHandler={editButtonClickHandler}
          mainBomSubmitHandler={mainBomSubmitHandler}
          deleteBomHandler={deleteBomHandler}
          copyBomHandler={copyBomHandler}
        />
        <BomDetailForm
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          reset={reset}
          minimumStockQty={watchMinimunStockQty ?? 0}
          handleClickOpenItem={handleClickOpenItem}
          isMainPage
        />
        <BomTableForm
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          handleClickOpenItem={handleClickOpenItem}
          isMainPage
        />
        <BomRemarkForm control={control} errors={errors} disabled={disabled} />
        <BomAttachment
          control={control}
          setValue={setValue}
          errors={errors}
          disabled={disabled}
          getValues={getValues}
        />
        {!disabled && (
          <BottomNavbar>
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={() => navigate("/manufacture/bom")}
              />
              <CustomizedButton
                type="submit"
                variant="contained"
                title={t("button.save")}
              />
            </Stack>
          </BottomNavbar>
        )}
      </form>
    </Fragment>
  );
};

export default BomContainer;
