import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GET_STOCK_REPORT_VIEW } from "../../../../services/AgGrid/InventoryReportAgGrid";
import { formatNumber } from "../../../../utils/dataTransformer";
import { RefObject } from "@fullcalendar/core/preact";
import {
  GET_GROUP_LEVEL_ONE,
  GET_GROUP_LEVEL_THREE,
  GET_GROUP_LEVEL_TWO,
} from "../../../../services/AgGrid/InventoryAgGrid";
import {
  ItemGroupLevel1,
  ItemGroupLevel2,
  ItemGroupLevel3,
} from "../../../../generated/inventory";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { useLocation } from "react-router-dom";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const StockReportTable = ({ gridRef }: Props) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const columnDefs: ColDef[] = [
    {
      field: "unique_id",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "img_url",
      headerName: t("inventory.img_url"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: ICellRendererParams<any, string[]>) => {
        if (params.value && params.value.length) {
          return (
            <CustomizedAvatar
              variant="rounded"
              avatars={[
                {
                  img_url: params.value?.[0] ?? "",
                  unique_id: params?.data?.unique_id ?? "",
                  first_name: params?.data?.name ?? "",
                  last_name: "",
                },
              ]}
            />
          );
        } else
          return (
            <CustomizedAvatar
              variant="rounded"
              avatars={[
                {
                  img_url: undefined,
                  unique_id: params?.data?.unique_id ?? "",
                  first_name: params?.data?.name ?? "",
                  last_name: "",
                },
              ]}
            />
          );
      },
      minWidth: 100,
      flex: 1,
    },
    {
      field: "name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "sku_name",
      headerName: t("inventory.items.sku"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_group_level_1",
      headerName: t("inventory.items.item_group") + " 1",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { itemGroupLevel1s } =
            await graphQLClientWithHeaderItem.request(GET_GROUP_LEVEL_ONE);
          const itemGroupLevel1sName = itemGroupLevel1s.map(
            (level1: ItemGroupLevel1) => level1.name
          );
          params.success(itemGroupLevel1sName);
        },
      },
    },
    {
      field: "item_group_level_2",
      headerName: t("inventory.items.item_group") + " 2",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { itemGroupLevel2s } =
            await graphQLClientWithHeaderItem.request(GET_GROUP_LEVEL_TWO);
          const itemGroupLevel2sName = itemGroupLevel2s.map(
            (level2: ItemGroupLevel2) => level2.name
          );
          params.success(itemGroupLevel2sName);
        },
      },
    },
    {
      field: "item_group_level_3",
      headerName: t("inventory.items.item_group") + " 3",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { itemGroupLevel3s } =
            await graphQLClientWithHeaderItem.request(GET_GROUP_LEVEL_THREE);
          const itemGroupLevel3sName = itemGroupLevel3s.map(
            (level3: ItemGroupLevel3) => level3.name
          );
          params.success(itemGroupLevel3sName);
        },
      },
    },
    {
      field: "purchase_ordered_qty",
      headerName: t("inventory.quantities.purchase_ordered_qty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "manufacture_ordered_qty",
      headerName: t("inventory.quantities.manufacture_ordered_qty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "sale_committed_qty",
      headerName: t("inventory.quantities.sale_committed_qty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "manufacture_committed_qty",
      headerName: t("inventory.quantities.manufacture_committed_qty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "stock_qty",
      headerName: t("inventory.quantities.stock_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "available_qty",
      headerName: t("inventory.quantities.available_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "purchase_price_per_unit",
      headerName: "ราคาซื้อต่อหน่วย",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "total_purchase_amount",
      headerName: "มูลค่าซื้อรวม",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "sale_price_per_unit",
      headerName: "ราคาขายต่อหน่วย",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "total_sale_amount",
      headerName: "มูลค่าขายรวม",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "minimum_stock",
      headerName: "MIN",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "maximum_stock",
      headerName: "MAX",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "purchase_minimum_qty",
      headerName: "จำนวนสั่งซื้อขั้นต่ำ",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "recommended_purchase_qty",
      headerName: "จำนวนที่ควรสั่งซื้อ",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "recommended_purchase_amount",
      headerName: "มูลค่าการสั่งซื้อ",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumber(params.value),
      headerClass: "ag-end-header",
      cellStyle: { display: "flex", justifyContent: "flex-end" },
    },
    {
      field: "stock_status",
      headerName: "สถานะสต๊อก",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ต้องสั่งซื้อ", "เกินสต๊อก", "ปกติ"],
      },
      cellStyle: (params) => {
        if (params.value === "ปกติ") {
          //mark police cells as red
          return { color: "#246527", fontWeight: 600 };
        } else if (params.value === "เกินสต๊อก") {
          return { color: "#C3762E", fontWeight: 600 };
        } else {
          return { color: "#B54839", fontWeight: 600 };
        }
      },
    },
    {
      field: "item_status",
      headerName: "สถานะสินค้า",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [1, 0],
        valueFormatter: ({ value }: { value: number }) => {
          if (value) return "ใช้งาน";
          return "หยุดใช้งาน";
        },
      },
      cellRenderer: ({ value }: { value: boolean }) => {
        if (value) return <CustomizedStatus status="active" />;
        return <CustomizedStatus status="inactive" />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { item_status, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        item_status: item_status && {
          ...item_status,
          values: item_status.values.map((v: string) => parseInt(v)),
        },
      };
      try {
        const { StockReportViews } = await graphQLClient.request(
          GET_STOCK_REPORT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: StockReportViews.data as any[],
          rowCount: StockReportViews.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = (params: any) => {
    const instaceItemStatus = params.api.getFilterInstance("item_status");
    instaceItemStatus?.setModel({ values: ["1"] });

    if (state) {
      const instaceStckStatus = params.api.getFilterInstance("stock_status");
      instaceStckStatus?.setModel({ values: state });
    }

    params.api.onFilterChanged();
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    onFilterChanged(params);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default StockReportTable;
