import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { IPurchaseReturn } from "../../../types/Purchase/purchaseReturn";
import PurchaseReturnHeader from "../../../components/Form/Purchase/Return/Header";
import VendorInfoForm from "../../../components/Form/VendorInfo/VendorInfoForm";
import ReasonForm from "../../../components/Form/Purchase/Return/Reason";
import PurchaseItemList from "../../../components/Table/Purchase/ItemList/PurchaseItemList";
import PurchaseFooter from "../../../components/Form/Purchase/Footer";
import {
  purchaseReturnSchema,
  purchaseReturnValidation,
} from "../../../components/Form/Purchase/Return/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  PurchaseDocumentType,
  PurchaseReturnCreateInput,
  PurchaseReturnQuery,
  PurchaseReturnUpdateInput,
  usePurchaseDocumentNextStatusMutation,
  usePurchaseReturnCreateMutation,
  usePurchaseReturnQuery,
  usePurchaseReturnUpdateMutation,
} from "../../../generated/purchase";
import { errorMessageFormatter } from "../../Setting/Inventory/Warehouse";
import {
  purchaseReturnCreatePayloadFormatter,
  purchaseReturnQueryFormatter,
  purchaseReturnUpdatePayloadFormatter,
} from "../../../utils/Formatter/PurchaseReturn";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { Box, CircularProgress, Stack } from "@mui/material";
import { IPurchaseItemList } from "../../../types/Purchase";
import { EntityTypeEnum } from "../../../generated/creatable";
import { usePurchaseError } from "../../../hooks/Purchase/use-purchase-error";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import PriceVatType from "../../../components/Form/Purchase/PriceVatType";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import dayjs from "dayjs";

const DocumentInfoTab = () => {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [referenceItemList, setReferenceItemList] = useState<
    IPurchaseItemList[]
  >([]);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const {
    control,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    resetField,
  } = useForm<IPurchaseReturn>({
    defaultValues: purchaseReturnSchema,
    resolver: yupResolver(purchaseReturnValidation),
    mode: "onChange",
  });

  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const { data, isLoading, isSuccess, refetch } =
    usePurchaseReturnQuery<PurchaseReturnQuery>(
      graphQLClientWithHeaderPurchase,
      {
        uniqueInput: {
          unique_id: id,
        },
      },
      {
        enabled: !!id,
      }
    );

  const { isLoading: isCreating, mutate: create } =
    usePurchaseReturnCreateMutation<Error>(graphQLClientWithHeaderPurchase, {
      onSuccess: ({ purchaseReturnCreate }) => {
        if (purchaseReturnCreate) {
          if (purchaseReturnCreate.sub_status === "wait_approve") {
            updateStatus({
              documentInput: {
                reference_document_type: PurchaseDocumentType.PurchaseReturn,
                unique_id: purchaseReturnCreate.unique_id,
              },
            });
          }
        }
        navigate(`/purchase/return/${purchaseReturnCreate?.unique_id}`);
        enqueueSnackbar("สร้างใบส่งคืนสำเร็จ", {
          variant: "success",
        });
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบส่งคืนไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutate: update } =
    usePurchaseReturnUpdateMutation<Error>(graphQLClientWithHeaderPurchase, {
      onSuccess: () => {
        enqueueSnackbar(`${snackbarMessage}ใบส่งคืนสำเร็จ`, {
          variant: "success",
        });
        if (id) {
          refetch();
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(`${snackbarMessage}ใบส่งคืนไม่สำเร็จ`, {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isChanging, mutate: updateStatus } =
    usePurchaseDocumentNextStatusMutation<Error>(
      graphQLClientWithHeaderPurchase,
      {
        onSuccess: () => {
          if (id) {
            refetch();
          }
        },
      }
    );

  useEffect(() => {
    if (isSuccess) {
      const { purchaseReturn } = data;

      const getPurchaseReturnData = async () => {
        const purchaseReturnType = purchaseReturn as IPurchaseReturn;
        const formattedPurchaseReturn = await purchaseReturnQueryFormatter(
          purchaseReturnType
        );
        reset(formattedPurchaseReturn);
      };
      getPurchaseReturnData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (state) {
      const { copied_id, copied_unique_id, reference_id_list, ...otherState } =
        state;
      reset({
        ...otherState,
        issue_date: dayjs(),
        delivery_date: dayjs(),
      });
      // reset(state);
      setReferenceItemList(state.item_list);
    }
  }, [reset, state]);

  useEffect(() => {
    if (!isLoading && id && data?.purchaseReturn?.aggrid_status !== "draft") {
      setDisabled(true);
    }
  }, [data?.purchaseReturn?.aggrid_status, id, isLoading]);

  const onPurchaseReturnCreate = (data: IPurchaseReturn, status: string) => {
    if (data) {
      const payload = purchaseReturnCreatePayloadFormatter(
        data,
        status
      ) as PurchaseReturnCreateInput;
      create({
        createInput: payload,
      });
    }
  };

  const onPurchaseReturnUpdate = (data: IPurchaseReturn, status: string) => {
    if (data) {
      const payload = purchaseReturnUpdatePayloadFormatter(
        data,
        status
      ) as PurchaseReturnUpdateInput;
      setSnackbarMessage("แก้ไข");
      update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
    }
  };

  const onPurchaseReturnSendApprove = (data: IPurchaseReturn) => {
    if (data) {
      const payload = purchaseReturnUpdatePayloadFormatter(
        data,
        "wait_approve"
      ) as PurchaseReturnUpdateInput;

      if (!id) {
        onPurchaseReturnCreate(data, "wait_approve");
      } else {
        setSnackbarMessage("ส่งอนุมัติ");
        update({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: payload,
        });
        updateStatus({
          documentInput: {
            reference_document_type: PurchaseDocumentType.PurchaseReturn,
            unique_id: data.unique_id,
          },
        });
      }
    }
  };

  const onPurchaseReturnNotApprove = (data: IPurchaseReturn) => {
    const payload = purchaseReturnUpdatePayloadFormatter(
      data,
      "wait_approve",
      true
    ) as PurchaseReturnUpdateInput;
    setSnackbarMessage("ไม่อนุมัติ");
    update({
      uniqueInput: {
        unique_id: id,
      },
      updateInput: payload,
    });
  };

  const onPurchaseReturnApprove = (data: IPurchaseReturn) => {
    const payload = purchaseReturnUpdatePayloadFormatter(
      data,
      "approved"
    ) as PurchaseReturnUpdateInput;
    setSnackbarMessage("อนุมัติ");
    update({
      uniqueInput: {
        unique_id: id,
      },
      updateInput: payload,
    });
    updateStatus({
      documentInput: {
        reference_document_type: PurchaseDocumentType.PurchaseReturn,
        unique_id: data.unique_id,
      },
    });
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    setIsEdit(false);
    reset();
  };

  const onPurchaseReturnEditHandler = (data: IPurchaseReturn) => {
    setDisabled(true);
    setIsEdit(false);
    setSnackbarMessage("แก้ไข");
    onPurchaseReturnUpdate(data, data.sub_status ? data.sub_status : "");
  };

  const renderButton = () => {
    switch (data?.purchaseReturn?.aggrid_status) {
      case "draft":
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isUpdating}
              onClick={handleSubmit((data) =>
                onPurchaseReturnUpdate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(onPurchaseReturnSendApprove)}
              disabled={isChanging}
            />
          </Stack>
        );
      case "wait_approve":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onPurchaseReturnEditHandler)}
              />
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.not_approve")}
                disabled={isUpdating}
                onClick={handleSubmit(onPurchaseReturnNotApprove)}
              />
              <CustomizedButton
                title={t("button.approve")}
                variant="contained"
                onClick={handleSubmit(onPurchaseReturnApprove)}
                disabled={isChanging}
              />
            </Stack>
          );
        }

      case "not_approved":
      case "approved":
      case "cancelled":
      case "partially_imported":
      case "fully_imported":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onPurchaseReturnEditHandler)}
              />
            </Stack>
          );
        }
        return;
      default:
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating}
              onClick={handleSubmit((data) =>
                onPurchaseReturnCreate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(onPurchaseReturnSendApprove)}
            />
          </Stack>
        );
    }
  };

  usePurchaseError(errors);

  if (id && (isLoading || isUpdating)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form>
      <PurchaseReturnHeader
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        editClickHandler={editClickHandler}
        refetch={refetch}
      />
      <ReasonForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
      />
      <VendorInfoForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        reset={reset}
        documentType={EntityTypeEnum.PurchaseReturn}
      />
      <CustomizedBox padding="1rem" sx={{ breakAfter: "auto" }}>
        <PriceVatType
          control={control}
          errors={errors}
          setValue={setValue}
          disabled
        />
        <PurchaseItemList
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          documentType={EntityTypeEnum.PurchaseReturn}
          referenceItemList={referenceItemList}
        />
      </CustomizedBox>
      <PurchaseFooter
        control={control}
        setValue={setValue}
        errors={errors}
        disabled={disabled}
        documentType="purchase_return"
        resetField={resetField}
      />
      {(![
        "not_approved",
        "approved",
        "cancelled",
        "partially_imported",
        "fully_imported",
      ].includes(data?.purchaseReturn?.aggrid_status || "") ||
        ([
          "not_approved",
          "approved",
          "cancelled",
          "partially_imported",
          "fully_imported",
        ].includes(data?.purchaseReturn?.aggrid_status || "") &&
          isEdit)) && <BottomNavbar>{renderButton()}</BottomNavbar>}
    </form>
  );
};

export default DocumentInfoTab;
