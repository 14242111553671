import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { validatePosSetting } from "../../../utils/validator/pos-setting";

const Pos = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.pos.index"),
    },
  ];

  const currentTenantId = sessionStorage.getItem("tenant-id");
  const savedDefaultPosSetting =
    localStorage.getItem(`POS_${currentTenantId}`) || "";

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("sales.pos.index")}</Typography>
        <CustomizedButton
          title={`POS`}
          startIcon={<LaunchOutlinedIcon />}
          variant="contained"
          onClick={() =>
            validatePosSetting(savedDefaultPosSetting)
              ? navigate(`${pathname}/add`)
              : navigate(`${pathname}/setting`)
          }
        />
      </HeaderLayout>
    </>
  );
};

export default Pos;
