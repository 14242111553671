import { useEffect, useContext, useRef } from "react";
import { useLocation, UNSAFE_NavigationContext } from "react-router-dom";

export const useOnLeavePage = (
  isDirty: boolean,
  message = "You have unsaved changes. Are you sure you want to leave?"
) => {
  const location = useLocation();
  const { navigator } = useContext(UNSAFE_NavigationContext);
  const isBlockingRef = useRef(false);
  const previousPathname = useRef(location.pathname);
  const allowNextNavigationRef = useRef(false);
  const isDirtyRef = useRef(isDirty);

  let hasConfirmedRef = useRef(false);
  // Store original navigation methods
  const originalPushRef = useRef(navigator.push);
  const originalReplaceRef = useRef(navigator.replace);

  // Keep `isDirtyRef` updated with latest `isDirty`
  useEffect(() => {
    isDirtyRef.current = isDirty;
  }, [isDirty]);

  // Function to allow next navigation programmatically
  const allowNextNavigation = () => {
    allowNextNavigationRef.current = true;
    isDirtyRef.current = false;
    // Auto-reset after a short delay to prevent permanent bypass
    setTimeout(() => {
      allowNextNavigationRef.current = false;
    }, 300);
  };

  // Check if navigation should be blocked
  const shouldBlockNavigation = (nextPathname: string) => {
    if (!isDirtyRef.current || allowNextNavigationRef.current) {
      return false;
    }
    return previousPathname.current !== nextPathname;
  };

  // ✅ Handle Browser Refresh / Tab Close
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirtyRef.current && !allowNextNavigationRef.current) {
        event.preventDefault();
        event.returnValue = message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [message]);

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);

    const handlePopState = () => {
      if (!isDirtyRef.current) {
        return;
      }

      if (hasConfirmedRef.current) {
        return;
      }

      hasConfirmedRef.current = true;

      const confirmed = window.confirm(message);
      if (confirmed) {
        window.history.back();
      } else {
        hasConfirmedRef.current = false;
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [message, location.pathname]);

  // ✅ Handle In-App Navigation (navigate(), <Link>)
  useEffect(() => {
    const handleNavigation = (
      method: (path: any, options?: any) => void,
      path: any,
      options?: any
    ) => {
      if (isBlockingRef.current) return;

      let nextPathname: string;
      if (typeof path === "string") {
        nextPathname = new URL(path, window.location.origin).pathname;
      } else if (path?.pathname) {
        nextPathname = path.pathname;
      } else {
        nextPathname = location.pathname;
      }

      if (!shouldBlockNavigation(nextPathname)) {
        method(path, options);
        allowNextNavigationRef.current = false;
        return;
      }

      isBlockingRef.current = true;

      if (window.confirm(message)) {
        previousPathname.current = nextPathname;
        method(path, options);
        allowNextNavigationRef.current = false;
      }

      setTimeout(() => (isBlockingRef.current = false), 300);
    };

    navigator.push = (path, options) =>
      handleNavigation(originalPushRef.current, path, options);
    navigator.replace = (path, options) =>
      handleNavigation(originalReplaceRef.current, path, options);

    return () => {
      navigator.push = originalPushRef.current;
      navigator.replace = originalReplaceRef.current;
    };
  }, [message, location.pathname, navigator]);

  return { allowNextNavigation };
};
