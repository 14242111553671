import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Typography } from "@mui/material";
import DropzoneField from "../../../UI/DropzoneUI";
import { NewCustomizedBox } from "../../../Custom/NewCustomizedBox";

import { useDisable } from "../../../../hooks/use-disable";

const SaleBomImg = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const { control, getValues, setValue } = useFormContext();

  const imgList = getValues("img_url");

  return (
    <NewCustomizedBox margin="0 0 4rem 0">
      <Typography mb={2} variant="subtitle2">
        {t("inventory.items.picture")}
      </Typography>
      {id && imgList && imgList.length === 0 && disabled && (
        <Typography textAlign="center">
          {t("inventory.sentence.no_img")}
        </Typography>
      )}
      {((imgList && imgList.length > 0 && disabled) || !disabled) && (
        <DropzoneField
          control={control}
          name="img_url"
          setValue={setValue}
          maxSize={10}
          multiple
          options={[{ value: "เลือกเป็นภาพหลัก" }, { value: "ลบ" }]}
          acceptedFileType="image"
          disabled={disabled}
          isNotRevoke
        />
      )}
    </NewCustomizedBox>
  );
};

export default SaleBomImg;
