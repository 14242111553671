import { useCallback, useEffect, useState } from "react";
import CustomPDFLayout from "../../../components/UI/CustomPDF/CustomPDFLayout";
import { GraphQLClient } from "graphql-request";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSalesOrderQuery, SalesOrderQuery } from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import DraggableItem from "../../../components/UI/CustomPDF/DraggableItem";
import customPrintingDefaultSettingDO from "../../../data/customPrintingDefaultSettingDO.json";
import { ThaiBaht } from "thai-baht-text-ts";
import { useSnackbar } from "notistack";
import { formatDate } from "../../../utils/Formatter/Date";
import "../../../custom-print.css";
import useLocalStorageChangeListener from "../../../hooks/use-local-storage-change";
import { formatNumber, roundingNumber } from "../../../utils/dataTransformer";
import {
  DeliveryOrderQuery,
  useDeliveryOrderQuery,
} from "../../../generated/logistic";

const DeliveryOrderCustomPDF = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [totalPages, setTotalPages] = useState(1);
  const [triggerRerenderPreview, setTriggerRerenderPreview] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modal, setModal] = useState(false);
  const { control, setValue, reset, watch } = useForm({
    defaultValues: {
      default_setting: "",
      documentWidth: 786,
      documentHeight: 1056,
      documentUnit: "px",
      documentFont: "Times New Roman",
      itemPerPage: 5,
      itemListMargin: 45,
      backgroundOpacity: 100,
      background: "",
      printBackground: false,
      variables: [] as any[],
      showOnlyPricedItems: false,
    },
  });
  const savedDefaultDocumentSetting_DO = localStorage.getItem(
    "defaultDocumentSetting_DO"
  );

  const graphQLClientWithHeaderLogistic: GraphQLClient =
    createGraphQLClientWithMiddleware("logistic");
  const { data } = useDeliveryOrderQuery<DeliveryOrderQuery>(
    graphQLClientWithHeaderLogistic,
    {
      uniqueInput: {
        unique_id: id,
      },
    },
    {
      enabled: !!id,
    }
  );

  const handleOpenModal = () => {
    if (savedDefaultDocumentSetting_DO) {
      setValue("default_setting", savedDefaultDocumentSetting_DO);
    } else {
      handleResetDefaultDocumentSetting();
    }
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleSaveToLocalStorage = () => {
    const defaultDocumentSetting_DO = watch("default_setting");

    // Validate if all fields exists
    if (validateSetting(defaultDocumentSetting_DO)) {
      localStorage.setItem(
        "defaultDocumentSetting_DO",
        defaultDocumentSetting_DO
      );
      enqueueSnackbar("แก้ไขการตั้งค่าสำเร็จ", {
        variant: "success",
      });

      // Read new setting
      handleReadSetting(defaultDocumentSetting_DO);

      // Dispatch event for local storage change
      const event = new Event("localStorageChange");
      window.dispatchEvent(event);
    } else {
      setIsError(true);
      enqueueSnackbar("รูปแบบการตั้งค่าไม่ถูกต้อง", {
        variant: "error",
      });
    }
  };

  // Listen for local storage changes
  useLocalStorageChangeListener(() => {
    // Re-render preview
    // setTriggerRerenderPreview((prev) => !prev);
    setIsError(false);
    handleCloseModal();
  });

  const validateSetting = (defaultDocumentSetting: string) => {
    try {
      // Extract default setting keys
      const defaultSettingKeys = Object.keys(customPrintingDefaultSettingDO);
      // Parse the JSON

      const findDuplicateKeys = (jsonString: string) => {
        // Regular expression to match JSON object keys
        const keyPattern = /"([^"]+)"\s*:/g;
        const seenKeys = new Set();
        const duplicateKeys = [];

        let match;
        // Iterate over each match (key) found in the JSON string
        while ((match = keyPattern.exec(jsonString)) !== null) {
          const key = match[1]; // Extract the key from the match
          // If the key has already been seen, add it to the duplicateKeys array
          if (
            ![
              "variables",
              "id",
              "contentType",
              "content",
              "isEditable",
              "width",
              "height",
              "isShow",
              "isLineItem",
              "scale",
              "position",
              "x",
              "y",
              "customItemListMargin",
              "textAlign",
            ].includes(key)
          )
            if (seenKeys.has(key)) {
              duplicateKeys.push(key);
              throw new Error(`Duplicated key: ${duplicateKeys}`);
            } else {
              seenKeys.add(key);
            }
        }
      };

      findDuplicateKeys(defaultDocumentSetting);

      const config = JSON.parse(defaultDocumentSetting);

      // Extract config keys
      const configKeys = Object.keys(config);

      // Check if all keys exist according to the default setting
      const allKeysExist = defaultSettingKeys.every((key) =>
        configKeys.includes(key)
      );
      if (!allKeysExist) {
        return false;
      }

      // Check for duplicate ids within the variables array
      const seenVariableIds = new Set();
      const duplicatedVariableIds = new Set();
      if (config.variables && Array.isArray(config.variables)) {
        for (const variable of config.variables) {
          if (seenVariableIds.has(variable.id)) {
            duplicatedVariableIds.add(variable.id);
            throw new Error(
              `Duplicated variable ids found in the variables array ${Array.from(
                duplicatedVariableIds
              )}`
            );
          }
          seenVariableIds.add(variable.id);
        }
      }

      return true;
    } catch (err: any) {
      console.error(err.message);
      setIsError(true);
      enqueueSnackbar("รูปแบบการตั้งค่าไม่ถูกต้อง", {
        variant: "error",
      });
    }
  };

  const handleReadSetting = useCallback(
    (savedDefaultDocumentSetting: string) => {
      try {
        const config = JSON.parse(savedDefaultDocumentSetting);
        // Update states with the new configuration
        if (config.documentWidth)
          setValue("documentWidth", config.documentWidth);
        if (config.documentHeight)
          setValue("documentHeight", config.documentHeight);
        if (config.documentUnit) setValue("documentUnit", config.documentUnit);
        if (config.documentFont) setValue("documentFont", config.documentFont);
        if (config.itemPerPage) setValue("itemPerPage", config.itemPerPage);
        if (config.itemListMargin)
          setValue("itemListMargin", config.itemListMargin);
        if (config.variables) {
          let variables = config.variables;
          variables = variables.concat(
            customPrintingDefaultSettingDO.variables.filter(
              (obj2) => !variables.some((obj1: any) => obj1.id === obj2.id)
            )
          );
          setValue("variables", variables);
        } else {
          setValue("variables", customPrintingDefaultSettingDO.variables);
        }
        if (config.printBackground) {
          setValue("printBackground", true);
        } else {
          setValue("printBackground", false);
        }

        if (config.backgroundOpacity) {
          setValue("backgroundOpacity", config.backgroundOpacity);
        } else {
          setValue("backgroundOpacity", 0);
        }

        if (config.background) {
          setValue("background", config.background);
        } else {
          setValue("background", "");
        }

        if (config.showOnlyPricedItems) {
          setValue("showOnlyPricedItems", true);
        } else {
          setValue("showOnlyPricedItems", false);
        }

        setIsError(false);
      } catch (error) {
        setIsError(true);
        enqueueSnackbar(
          "เกิดข้อผิดพลาด ไม่สามารถพิมพ์ได้ กรุณาแก้ไขการตั้งค่า",
          {
            variant: "error",
          }
        );
      }
    },
    [enqueueSnackbar, setValue]
  );

  const handleResetDefaultDocumentSetting = useCallback(() => {
    reset({
      default_setting: JSON.stringify(customPrintingDefaultSettingDO, null, 2),
      ...customPrintingDefaultSettingDO,
      // documentWidth: customPrintingDefaultSetting.documentWidth,
      // documentHeight: customPrintingDefaultSetting.documentHeight,
      // documentUnit: customPrintingDefaultSetting.documentUnit,
      // documentFont: customPrintingDefaultSetting.documentFont,
      // itemPerPage: customPrintingDefaultSetting.itemPerPage,
      // itemListMargin: customPrintingDefaultSetting.itemListMargin,
      // backgroundOpacity: customPrintingDefaultSetting.backgroundOpacity,
      // background: customPrintingDefaultSetting.background,
      // printBackground: customPrintingDefaultSetting.printBackground,
      // variables: customPrintingDefaultSetting.variables,
    });
  }, [reset]);

  const renderAddressString = (addressValues: any) => {
    if (addressValues) {
      const {
        address,
        sub_district,
        district,
        province,
        postal_code,
        country,
      } = addressValues;

      let result = "";

      if (addressValues) {
        if (Array.isArray(addressValues)) {
          addressValues.forEach((item) => {
            if (item?.length > 0) {
              result = result + item + ", ";
            }
          });
        }
        if (address) result += address + ", ";
        if (sub_district) result += sub_district + ", ";
        if (district) result += district + ", ";
        if (province) result += province + ", ";
        if (postal_code) result += postal_code + ", ";
        if (country) result += country + ", ";
        if (result?.trim().length === 0) {
          return "-";
        }
      }
      // remove whitespace and last comma
      return result?.trim().slice(0, -1);
    } else return "-";
  };

  // Render preview pages
  const renderPage = () => {
    if (data?.deliveryOrder) {
      const allPages = [];

      const filteredItemList =
        data.deliveryOrder.item_list?.filter(
          (item) =>
            !watch("showOnlyPricedItems") ||
            (item.price_per_unit !== null &&
              item.price_per_unit !== undefined &&
              item.price_per_unit > 0)
        ) || [];
      for (let i = 1; i <= totalPages; i++) {
        const currentPage = i;

        allPages.push(
          <div
            className={`document-container`}
            style={{
              breakAfter: totalPages > 1 ? "page" : undefined,
              width: `${watch("documentWidth")}${watch("documentUnit")}`,
              minHeight: `${watch("documentHeight")}${watch("documentUnit")}`,
              position: "relative",
              backgroundImage:
                watch("printBackground") && watch("background")
                  ? `linear-gradient(rgba(255, 255, 255, ${
                      (100 - watch("backgroundOpacity")) / 100
                    }), rgba(255, 255, 255, ${
                      (100 - watch("backgroundOpacity")) / 100
                    })), url(${watch("background")})`
                  : "none",
              backgroundPosition: "top left",
              backgroundRepeat: "no-repeat",
              // backgroundSize: "cover",
              WebkitPrintColorAdjust: watch("printBackground")
                ? "exact"
                : undefined,
            }}
            key={currentPage}
          >
            {/* DOCUMENT CONTENT */}
            {watch("variables").map((variable, index) => {
              // Mapping Data
              let mappingContent = variable.content;
              if (variable.id === "page") {
                mappingContent = `หน้าที่ ${currentPage}/${totalPages}`;
              } else if (variable.id === "unique_id") {
                mappingContent = data?.deliveryOrder?.unique_id;
              } else if (variable.id === "reference_unique_id_list") {
                mappingContent = data?.deliveryOrder?.reference_unique_id_list;
              } else if (variable.id === "external_reference_id") {
                mappingContent = data?.deliveryOrder?.external_reference_id;
              } else if (variable.id === "tag_list") {
                mappingContent = data?.deliveryOrder?.tag_list
                  ? data?.deliveryOrder?.tag_list.map((tag) => tag.name)
                  : [];
              } else if (variable.id === "customer_contact_unique_id") {
                mappingContent =
                  data?.deliveryOrder?.customer_contact_unique_id;
              } else if (variable.id === "customer_name") {
                mappingContent = data?.deliveryOrder?.customer_contact?.name;
              } else if (variable.id === "identify_no") {
                // mappingContent = formatTaxId(
                //   data?.salesOrder?.customer_contact?.identity_no || "",
                //   data?.salesOrder?.customer_contact?.branch || ""
                // );
                mappingContent =
                  data?.deliveryOrder?.customer_contact?.identity_no || "";
              } else if (variable.id === "branch") {
                mappingContent =
                  data?.deliveryOrder?.customer_contact?.branch || "";
              } else if (variable.id === "phone") {
                mappingContent =
                  data?.deliveryOrder?.customer_contact?.phone || "";
              } else if (variable.id === "email") {
                mappingContent =
                  data?.deliveryOrder?.customer_contact?.email || "";
              } else if (variable.id === "fax") {
                mappingContent = data?.deliveryOrder?.customer_contact?.fax;
              } else if (variable.id === "credit_terms") {
                mappingContent = data?.deliveryOrder?.credit_day + " วัน";
              } else if (variable.id === "issue_date") {
                mappingContent = formatDate(data?.deliveryOrder?.issue_date);
              } else if (variable.id === "delivery_date") {
                mappingContent = formatDate(data?.deliveryOrder?.delivery_date);
              } else if (variable.id === "address") {
                mappingContent = renderAddressString(
                  data.deliveryOrder?.customer_contact?.billing_address
                );
              } else if (variable.id === "billing_address") {
                mappingContent = renderAddressString(
                  data.deliveryOrder?.customer_contact?.billing_address
                );
              } else if (variable.id === "delivery_address") {
                mappingContent = renderAddressString(
                  data.deliveryOrder?.customer_contact?.delivery_address
                );
              } else if (variable.id === "remark") {
                mappingContent = data.deliveryOrder?.remark;
              } else if (variable.id === "delivery_order") {
                mappingContent = data.deliveryOrder?.external_reference_id;
              } else if (variable.id === "credit_day") {
                mappingContent = data.deliveryOrder?.credit_day;
              }
              if (currentPage === totalPages) {
                if (variable.id === "sub_total") {
                  mappingContent = `${data?.deliveryOrder?.sub_total?.toLocaleString(
                    "en-US",
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  )}`;
                } else if (variable.id === "vat_exempted_amount") {
                  mappingContent = formatNumber(
                    data?.deliveryOrder?.vat_exempted_amount || 0
                  );
                } else if (variable.id === "vat_amount") {
                  mappingContent = formatNumber(
                    data?.deliveryOrder?.vat_amount || 0
                  );
                } else if (variable.id === "additional_discount") {
                  mappingContent = formatNumber(
                    data?.deliveryOrder?.additional_discount || 0
                  );
                } else if (variable.id === "total_amount") {
                  mappingContent = formatNumber(
                    data?.deliveryOrder?.total_amount || 0
                  );
                } else if (variable.id === "total_amount_text") {
                  mappingContent = data?.deliveryOrder?.total_amount
                    ? `( ${
                        data?.deliveryOrder?.total_amount < 0 ? "ลบ" : ""
                      }${ThaiBaht(
                        roundingNumber(
                          Math.abs(data?.deliveryOrder?.total_amount)
                        )
                      )} )`
                    : "";
                } else if (variable.id === "pre_vat_amount_total") {
                  mappingContent = formatNumber(
                    data?.deliveryOrder?.pre_vat_amount || 0
                  );
                } else if (variable.id === "shipping_cost") {
                  mappingContent = formatNumber(
                    data.deliveryOrder?.shipping_cost
                  );
                } else if (variable.id === "branch") {
                  mappingContent =
                    data?.deliveryOrder?.customer_contact?.branch;
                }
              }

              return (
                variable.isShow &&
                !variable.isLineItem && (
                  <DraggableItem
                    startX={variable.position.x}
                    startY={variable.position.y}
                    width={variable.width}
                    height={variable.height}
                    key={`${variable.id}-${data?.deliveryOrder?.unique_id}-${currentPage}-${index}`}
                    id={variable.id}
                    contentType={variable.contentType}
                    content={mappingContent}
                    documentWidth={watch("documentWidth")}
                    documentHeight={watch("documentHeight")}
                    documentFont={watch("documentFont")}
                    itemListMargin={watch("itemListMargin")}
                    scale={variable.scale}
                    customItemListMargin={variable.customItemListMargin}
                    itemPerPage={watch("itemPerPage")}
                    editMode={false}
                    textAlign={variable.textAlign}
                  />
                )
              );
            })}

            {/* ITEM LIST CONTENT */}
            {watch("variables").map((variable) => {
              const indexOfLastItem = currentPage * watch("itemPerPage");
              const indexOfFirstItem = indexOfLastItem - watch("itemPerPage");
              const page_item_list = filteredItemList.slice(
                indexOfFirstItem,
                indexOfLastItem
              );

              return (
                variable.isShow &&
                variable.isLineItem &&
                page_item_list?.map((item, index) => {
                  // Margin between line
                  const marginY = variable.customItemListMargin
                    ? variable.customItemListMargin
                    : watch("itemListMargin");
                  let newY =
                    Number(variable.position.y) +
                    Number(variable.height) * index +
                    Number(marginY) * index;

                  // Mapping Data
                  let mappingContent = variable.content;
                  if (variable.id === "item_list") {
                    mappingContent = index + 1;
                    if (currentPage > 1)
                      mappingContent +=
                        (currentPage - 1) * watch("itemPerPage");
                  } else if (variable.id === "item_unique_id")
                    mappingContent = item.item_unique_id;
                  else if (variable.id === "item_name")
                    mappingContent = item.item_name;
                  else if (variable.id === "item_sku_desc")
                    mappingContent = item.item_sku_desc;
                  else if (variable.id === "qty")
                    mappingContent = item.qty?.toLocaleString();
                  // mappingContent = `${item.qty?.toLocaleString("en-US", {
                  //   minimumFractionDigits: 2,
                  //   maximumFractionDigits: 2,
                  // })}`;
                  else if (variable.id === "price_per_unit")
                    mappingContent = `${item.price_per_unit?.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`;
                  else if (variable.id === "discount")
                    mappingContent = `${item.discount?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`;
                  else if (variable.id === "pre_vat_amount")
                    mappingContent = `${item.pre_vat_amount?.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`;
                  else if (variable.id === "uom") mappingContent = item.uom;
                  else if (variable.id === "vat_percentage")
                    mappingContent = item.vat_percentage;
                  else if (variable.id === "stock_qty")
                    mappingContent = formatNumber(item.stock_qty || 0);
                  else if (variable.id === "withholding_tax_amount")
                    mappingContent = formatNumber(item.withholding_tax_value);
                  else if (variable.id === "withholding_tax_type")
                    mappingContent = item.withholding_tax_type;
                  return (
                    <DraggableItem
                      startX={variable.position.x}
                      startY={newY}
                      width={variable.width}
                      height={variable.height}
                      key={`${variable.id}-${data?.deliveryOrder?.unique_id}-${currentPage}-${index}`}
                      id={variable.id}
                      contentType={variable.contentType}
                      content={mappingContent}
                      documentWidth={watch("documentWidth")}
                      documentHeight={watch("documentHeight")}
                      documentFont={watch("documentFont")}
                      itemListMargin={watch("itemListMargin")}
                      scale={variable.scale}
                      customItemListMargin={variable.customItemListMargin}
                      editMode={false}
                      itemPerPage={watch("itemPerPage")}
                      textAlign={variable.textAlign}
                    />
                  );
                })
              );
            })}
          </div>
        );
      }

      return allPages;
    }
  };

  useEffect(() => {
    if (savedDefaultDocumentSetting_DO) {
      // Read old setting from local storage
      setValue("default_setting", savedDefaultDocumentSetting_DO);
      handleReadSetting(savedDefaultDocumentSetting_DO);

      // Re-render preview
      setTriggerRerenderPreview((prev) => !prev);
    } else {
      // Read default setting
      handleResetDefaultDocumentSetting();
    }
  }, [
    handleReadSetting,
    handleResetDefaultDocumentSetting,
    savedDefaultDocumentSetting_DO,
    setValue,
  ]);

  useEffect(() => {
    if (data?.deliveryOrder?.item_list) {
      setTotalPages(
        Math.ceil(data.deliveryOrder.item_list.length / watch("itemPerPage"))
      );
    }
  }, [data, watch, triggerRerenderPreview]);

  return (
    <CustomPDFLayout
      modal={modal}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      documentName="ใบส่งของ"
      control={control}
      handleSaveToLocalStorage={handleSaveToLocalStorage}
      isError={isError}
    >
      {renderPage()}
    </CustomPDFLayout>
  );
};

export default DeliveryOrderCustomPDF;
