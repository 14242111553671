import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  UseFieldArrayRemove,
  UseFieldArrayMove,
  UseFieldArrayAppend,
} from "react-hook-form";
import TocIcon from "@mui/icons-material/Toc";

import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  formatNumber,
  roundingNumber,
} from "../../../../utils/dataTransformer";
// import DeleteItemConfirmation from "../../../UI/Confirmation/DeleteItemConfirmation";

import ItemNameCell from "./ItemNameCell";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../Controller/ControlledTextField";

import Confirmation from "../../../UI/Confirmation";
import { IDefaultForm } from "../../../../types/global";
import { IManufactureOrder } from "../../../../types/Manufacture/order";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import { t } from "i18next";

type Props = {
  fields: IManufactureOrder["ingredient_list"];
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
  setValue: IDefaultForm["setValue"];
  remove: UseFieldArrayRemove;
  move: UseFieldArrayMove;
  watch: IDefaultForm["watch"];
  replace: UseFieldArrayAppend<IManufactureOrder, "ingredient_list">;
  setIngredientItemIds: Dispatch<SetStateAction<string[]>>;
  setIngredientItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  isTooltip?: boolean;
};

const IngredientTable = ({
  fields,
  move,
  replace,
  remove,
  control,
  errors,
  watch,
  disabled,
  setValue,
  isTooltip,
  setIngredientItemIds,
  setIngredientItemIdsSnapshot,
}: Props) => {
  const [headers, setHeaders] = useState<
    { label: string; width?: number; align?: TableCellProps["align"] }[]
  >([]);

  const [deletedIndex, setDeletedIndex] = useState<number>(0);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState(false);

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(0);
  };

  const deleteItemConfirmationAction = () => {
    remove(deletedIndex);
    setIngredientItemIds((ids) =>
      ids.filter((id, index) => index !== deletedIndex)
    );
    setIngredientItemIdsSnapshot((ids) =>
      ids.filter((id, index) => index !== deletedIndex)
    );
    closeDeleteItemConfirmationHandler();
  };

  useEffect(() => {
    if (disabled) {
      setHeaders([
        { label: t("manufacture.ingredient.no"), width: 40 },
        { label: t("manufacture.ingredient.name"), width: 250 },
        { label: t("manufacture.ingredient.qty"), width: 150, align: "right" },
        {
          label: t("manufacture.ingredient.cost_per_unit"),
          width: 150,
          align: "right",
        },
        {
          label: t("manufacture.ingredient.goods_issue_qty"),
          width: 150,
          align: "right",
        },
        { label: t("manufacture.ingredient.unit"), width: 150 },
      ]);
    } else {
      setHeaders([
        { label: t("manufacture.ingredient.no"), width: 40 },
        { label: t("manufacture.ingredient.name"), width: 250 },
        { label: t("manufacture.ingredient.qty"), width: 150, align: "right" },
        {
          label: t("manufacture.ingredient.cost_per_unit"),
          width: 150,
          align: "right",
        },
        { label: t("manufacture.ingredient.unit"), width: 150 },
        { label: "", width: 10 },
      ]);
    }
  }, [disabled]);

  return (
    <Box sx={{ my: 3 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={!header.align ? "center" : header.align}
                  key={index}
                  width={header.width}
                >
                  <Box
                    display="flex"
                    gap={1}
                    justifyContent={!header.align ? "center" : header.align}
                  >
                    <Typography
                      textAlign={!header.align ? "center" : header.align}
                      fontSize={14}
                      fontWeight={700}
                    >
                      {header.label}
                    </Typography>
                    {isTooltip && index === 2 && (
                      <CustomizedTooltip title="จำนวนด้านล่างคือจำนวนที่ต้องใช้ตาม BOM">
                        <InfoOutlinedIcon fontSize="small" />
                      </CustomizedTooltip>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable
              droppableId="droppable-ingredient"
              isDropDisabled={disabled}
            >
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields?.map((row: any, index: number) => (
                    <Draggable
                      key={row.id}
                      draggableId={"ingredient-" + row.id}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...provided.draggableProps.style,
                            breakInside: "avoid",
                          }}
                        >
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!disabled && <TocIcon fontSize="small" />}
                              <Typography fontSize={14}>{index + 1}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <ItemNameCell
                              index={index}
                              control={control}
                              errors={errors}
                              disabled={disabled}
                              field={row}
                              // handleShowItemModal={handleShowItemModal}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatNumber(parseFloat(row.qty))}
                              </Typography>
                            ) : (
                              <Box mt={fields[index].unrounded_qty ? 3 : 0}>
                                <ControlledNumberTextField
                                  control={control}
                                  name={`ingredient_list.${index}.qty`}
                                  error={Boolean(
                                    errors[`ingredient_list`] &&
                                      errors[`ingredient_list`][index] &&
                                      errors[`ingredient_list`][index].qty
                                  )}
                                  helperText={
                                    fields[index].unrounded_qty
                                      ? roundingNumber(
                                          fields[index].unrounded_qty || 0,
                                          3
                                        ).toFixed(3)
                                      : null
                                  }
                                />
                              </Box>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatNumber(
                                  parseFloat(row.cost_per_unit || "0")
                                )}
                              </Typography>
                            ) : (
                              <Box>
                                <ControlledNumberTextField
                                  control={control}
                                  name={`ingredient_list.${index}.cost_per_unit`}
                                  error={Boolean(
                                    errors[`ingredient_list`] &&
                                      errors[`ingredient_list`][index] &&
                                      errors[`ingredient_list`][index]
                                        .cost_per_unit
                                  )}
                                  helperText={
                                    watch &&
                                    `รวม ${formatNumber(
                                      (watch(
                                        `ingredient_list.${index}.cost_per_unit`
                                      ) || 0) *
                                        (watch(
                                          `ingredient_list.${index}.qty`
                                        ) || 0)
                                    )}`
                                  }
                                  allowNegative={false}
                                  helperTextAlign={"right"}
                                  sx={{ mt: 3 }}
                                />
                              </Box>
                            )}
                          </TableCell>
                          {disabled && (
                            <TableCell align="right">
                              <Typography fontSize={14}>
                                {formatNumber(
                                  parseFloat(row.good_issue_qty || 0)
                                )}
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {disabled ? (
                              <Typography fontSize={14}>{row.uom}</Typography>
                            ) : (
                              <ControlledTextField
                                name={`ingredient_list.${index}.uom`}
                                control={control}
                                disabled={true}
                              />
                            )}
                          </TableCell>
                          {!disabled && (
                            <TableCell align="center">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                onClick={() =>
                                  openDeleteItemConfirmationHandler(index)
                                }
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <Confirmation
        title="ต้องการลบสินค้าใช่หรือไม่"
        open={openDeleteItemConfirmation}
        action={deleteItemConfirmationAction}
        handleClose={closeDeleteItemConfirmationHandler}
      />
    </Box>
  );
};

export default IngredientTable;
