import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatNumber } from "../../../utils/dataTransformer";
import { useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { useParams } from "react-router-dom";

interface Props {
  data: any;
  start_no: number;
  bgColor?: string;
}

const ManfactureOrderWorkOrderPdfTable = ({
  data,
  start_no,
  bgColor,
}: Props) => {
  const [workOrderList, setWorkOrderList] = useState<any[]>([]);
  const { id } = useParams();

  const header = [
    {
      label: "QR",
      thaiLabel: " ",
      width: "8%",
    },
    {
      label: "No.",
      thaiLabel: " ",
      width: "4%",
    },
    {
      label: "WorkOrder",
      thaiLabel: "ขั้นตอนงาน",
      width: "18%",
    },
    {
      label: "Duration",
      thaiLabel: "เวลาการผลิต",
      width: "10%",
    },
    {
      label: "Plant",
      thaiLabel: "โรงงาน",
      width: "13%",
    },
    {
      label: "ProductionCenter",
      thaiLabel: "ศูนย์ผลิต",
      width: "14%",
    },
    { label: "Remark", thaiLabel: "หมายเหตุ", width: "25%" },
    {
      label: "QR",
      thaiLabel: " ",
      width: "8%",
    },
  ];

  useEffect(() => {
    setWorkOrderList(data);
  }, [data]);

  const formatDurationUom = (uom: string) => {
    switch (uom) {
      case "minute":
        return "นาที";
      case "hour":
        return "ชั่วโมง";
      case "day":
        return "วัน";
      default:
        return "-";
    }
  };

  return (
    <TableContainer>
      <Table sx={{ width: "100%" }}>
        <TableHead
          sx={{
            ...(start_no !== 0 && {
              height: 0,
              minHeight: 0,
              maxHeight: 0,
              overflow: "hidden",
              visibility: "collapse",
            }),
          }}
        >
          <TableRow>
            {header.map((head, index) => (
              <TableCell
                key={index}
                align={head.label === "Duration" ? "right" : "left"}
                sx={{
                  width: head.width,
                  maxWidth: head.width,
                  minWidth: "auto",
                  py: 1,
                  pl: head.label === "Plant" ? 1 : 0.5,
                  pr: "8px !important",
                  backgroundColor: bgColor || "#ECF6FD",
                  borderTop: "none",
                  borderBottom: "none",
                }}
              >
                <Typography
                  noWrap
                  fontSize={11}
                  fontWeight={600}
                  textAlign={head.label === "Duration" ? "right" : "left"}
                >
                  {head.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {workOrderList.map((workOrder, index) => (
            <TableRow
              key={workOrder.item_unique_id}
              sx={{
                breakInside: "avoid",
                "&:last-child td, &:last-child th": { border: 0 },
                verticalAlign: "top",
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              <TableCell
                sx={{
                  width: header[0].width,
                  p: "4px !important",
                  pb: "0px !important",
                }}
              >
                {index % 2 === 0 ? (
                  <QRCodeSVG
                    value={`${window.location.origin}/manufacture/order/${id}?subtab=work-info`}
                    width={50}
                    height={50}
                  />
                ) : (
                  <Box width={50} height={50} />
                )}
              </TableCell>
              <TableCell sx={{ width: header[1].width, p: "4px !important" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#333333",
                    width: "20px",
                    height: "18px",
                    borderRadius: "4px",
                  }}
                >
                  <Typography fontSize={11} color="white" textAlign="center">
                    {start_no + index + 1}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ width: header[2].width, p: "4px !important" }}>
                <Box>
                  <Typography fontSize={11} fontWeight={600}>
                    {start_no + index + 1 < 10
                      ? `WO0${start_no + index + 1}`
                      : `WO${start_no + index + 1}`}
                  </Typography>
                  <Typography
                    fontSize={11}
                    sx={{
                      wordBreak: "break-word",
                    }}
                  >
                    {workOrder.name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  width: header[3].width,
                  pt: "4px !important",
                }}
              >
                <Typography fontSize={11}>
                  {workOrder.duration || ""}
                </Typography>
                <Typography fontSize={11} color="text.secondary">
                  {formatDurationUom(workOrder.duration_unit)}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: header[4].width,
                  pt: "4px !important",
                  wordBreak: "break-word",
                }}
              >
                <Typography fontSize={11}>{workOrder.plant}</Typography>
              </TableCell>
              <TableCell sx={{ width: header[5].width, p: "4px !important" }}>
                <Typography
                  fontSize={11}
                  sx={{
                    wordBreak: "break-word",
                  }}
                >
                  {workOrder.production_center}
                </Typography>
              </TableCell>
              <TableCell sx={{ width: header[6].width, p: "4px !important" }}>
                <Typography
                  fontSize={11}
                  sx={{
                    wordBreak: "break-word",
                  }}
                >
                  {workOrder.remark}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  width: header[7].width,
                  p: "4px !important",
                  pb: "0px !important",
                }}
              >
                {index % 2 === 1 ? (
                  <QRCodeSVG
                    value={`${window.location.origin}/manufacture/order/${id}?subtab=work-info`}
                    width={50}
                    height={50}
                  />
                ) : (
                  <Box width={50} height={50} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ManfactureOrderWorkOrderPdfTable;
