import { Box, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
// import LaunchIcon from "@mui/icons-material/Launch";
import Chart from "react-google-charts";
import {
  customChartTooltipBarChart,
  handleChartReady,
} from "../../../../../utils/chartUtils";
import EmptyBarChart from "../EmptyBarChart";
// import { useLegendHandler } from "../../../../../hooks/Dashboard/useLegendHandler";

type Props = {
  data: any;
  isFetching: boolean;
};

const WorkOrderChart = ({ data, isFetching }: Props) => {
  const { t } = useTranslation();

  // const { setChartWrapper, handleLegendHover, handleLegendMouseOut } =
  //   useLegendHandler("column");

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const formatData = [
    [
      t("manufacture.order.index"),
      t("status.draft"),
      { role: "tooltip", p: { html: true } },
      t("status.in_progress"),
      { role: "tooltip", p: { html: true } },
      t("status.finished"),
      { role: "tooltip", p: { html: true } },
      t("status.cancelled"),
      { role: "tooltip", p: { html: true } },
    ],
    [
      t("status.index"),
      data?.draft || 0,
      customChartTooltipBarChart("draft", data?.draft || 0),
      data?.in_progress || 0,
      customChartTooltipBarChart("in_progress", data?.in_progress || 0),
      data?.finished || 0,
      customChartTooltipBarChart("finished", data?.finished || 0),
      data?.cancelled || 0,
      customChartTooltipBarChart("cancelled", data?.cancelled || 0),
    ],
  ];

  const total =
    (data?.draft ?? 0) +
    (data?.in_progress || 0) +
    (data?.finished ?? 0) +
    (data?.cancelled ?? 0);

  const options = {
    chartArea: {
      width: "100%",
      height: "100%",
      top: 16,
    },
    isStacked: "percent",
    hAxis: { textPosition: "none", gridlines: { count: 0 } },
    vAxis: { textPosition: "none", gridlines: { count: 0 } },
    baselineColor: "transparent",
    // legend: {
    //   position: "bottom",
    //   alignment: "center",
    //   textStyle: {
    //     fontName: "Kanit",
    //     fontSize: 12,
    //     color: "#737373",
    //   },
    // },
    legend: { position: "none" }, // Disable default legend
    tooltip: {
      isHtml: true,
      textStyle: {
        fontName: "Kanit",
        fontSize: 12,
        color: "#737373",
      },
    },
    bar: { groupWidth: "100%" },
    colors: ["#E2E2E2", "#4F89E3", "#2CBA7A", "#E2E2E2"],
  };

  const legendItems = [
    { color: "#E2E2E2", label: t("status.draft") },
    { color: "#4F89E3", label: t("status.in_progress") },
    { color: "#2CBA7A", label: t("status.finished") },
  ];

  return (
    <CustomizedBox
      height={166}
      border={`1px solid #E2E2E2`}
      margin={`${isMobile ? "1rem" : "0.5rem"} 0`}
      padding={"1rem"}
      boxShadow={"unset"}
    >
      <Box display={"flex"} gap={0.5} alignItems={"center"}>
        <Typography color={"secondary.main"} fontWeight={600}>
          {t("manufacture.workOrder.name")}
        </Typography>
        {/* <LaunchIcon sx={{ fontSize: 16 }} color="secondary" />   */}
      </Box>
      {isFetching ? (
        <Skeleton variant="rectangular" height={94} animation={"wave"} />
      ) : (
        <>
          {total === 0 ? (
            <EmptyBarChart />
          ) : (
            <Chart
              chartType="BarChart"
              width={"100%"}
              height={42}
              data={formatData}
              options={options}
              chartEvents={[
                {
                  eventName: "ready",
                  callback: ({ chartWrapper }: { chartWrapper: any }) => {
                    handleChartReady(chartWrapper);
                    // setChartWrapper(chartWrapper);
                  },
                },
                {
                  eventName: "select",
                  callback: ({ chartWrapper }: { chartWrapper: any }) => {
                    const chart = chartWrapper.getChart();
                    if (chart) {
                      chart.setSelection([]); // Clear any selection immediately
                    }
                  },
                },
              ]}
            />
          )}
          <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
            {legendItems.map((item, index) => (
              <Box
                key={index}
                // onMouseEnter={() => handleLegendHover(index)}
                // onMouseLeave={handleLegendMouseOut}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // cursor: "pointer",
                  gap: 1,
                  width: "calc(33.33% - 8px)", // Ensure 3 items per row
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: item.color,
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    flexShrink: 0,
                  }}
                />
                <Typography
                  title={item.label}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    flexGrow: 1,
                    fontSize: 12,
                    color: "#737373",
                  }}
                >
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </CustomizedBox>
  );
};

export default WorkOrderChart;
