import {
  Box,
  DialogContentText,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useWatch } from "react-hook-form";
import { useSalesReturnOption } from "../../../../hooks/Sales/use-sales-return-option";
import { useSalesReturnCreate } from "../../../../hooks/Sales/use-sales-return-create";

import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import { ICreatedBy, IDefaultForm } from "../../../../types/global";
import EmployeeListForm from "../../EmployeeList";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutateFunction,
} from "@tanstack/react-query";
import {
  DocumentInput,
  Exact,
  ModelType,
  SalesDocumentNextStatusMutation,
  SalesDocumentType,
  SalesReturnQuery,
  SalesReturnUpdateInput,
  SalesReturnUpdateMutation,
  SalesReturnWhereUniqueInput,
  SalesUniqueIdQuery,
  useSalesDocumentCancelMutation,
  useSalesUniqueIdQuery,
} from "../../../../generated/sales";
import { useSnackbar } from "notistack";
import {
  copySalesReturnFormatter,
  createGoodsReturnFromReturn,
  salesReturnUpdatePayloadFormatter,
} from "../../../../utils/Formatter/SalesReturn";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import Confirmation from "../../../UI/Confirmation";
import SalesEmployeeListForm from "../../EmployeeList/SalesEmployeeList";
import { useStateContext } from "../../../../contexts/auth-context";
import { ISalesReturn } from "../../../../types/Sales/salesReturn";
import ModalUI from "../../../UI/ModalUI";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import StepperUI from "../../../UI/StepperUI";
import { useActiveStep } from "../../../../hooks/use-active-step";
import { EntityTypeEnum } from "../../../../generated/creatable";

type Props = IDefaultForm & {
  editClickHandler: () => void;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<SalesReturnQuery, unknown>>;
  update: UseMutateFunction<
    SalesReturnUpdateMutation,
    Error,
    Exact<{
      uniqueInput: SalesReturnWhereUniqueInput;
      updateInput: SalesReturnUpdateInput;
    }>,
    unknown
  >;
  updateStatus: UseMutateFunction<
    SalesDocumentNextStatusMutation,
    Error,
    Exact<{
      documentInput: DocumentInput;
    }>,
    unknown
  >;
};

const SalesReturnHeader = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
  editClickHandler,
  refetch,
  update,
  updateStatus,
  handleSubmit,
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [, setOpenDrawer] = useState<boolean>(false);
  const [createdDateIsOpen, setCreatedDateIsOpen] = useState<boolean>(false);
  const [issueDateIsOpen, setIssueDateIsOpen] = useState<boolean>(false);

  const {
    state: { authUser },
  } = useStateContext();

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { refetch: getUniqueId } = useSalesUniqueIdQuery<SalesUniqueIdQuery>(
    graphQLClientWithHeaderSales,
    {
      modelType: ModelType.SalesReturn,
    },
    {
      enabled: false,
      onSuccess: ({ utilGetUniqueId }) => {
        setValue("unique_id", utilGetUniqueId);
      },
    }
  );

  const { mutate: cancel } = useSalesDocumentCancelMutation<Error>(
    graphQLClientWithHeaderSales,
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar("ยกเลิกสำเร็จ", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const reference_unique_id_list = useWatch({
    control,
    name: "reference_unique_id_list",
  });

  const watchMainStatus = useWatch({ control, name: "main_status" });

  const steps = [
    {
      label: t("status.draft"),
      value: "draft",
    },
    {
      label: t("status.wait_approve"),
      value: "wait_approve",
    },
    {
      label: t("status.approved"),
      value: "approved",
    },
    {
      label: t("status.finished"),
      value: "finished",
    },
  ];

  const {
    activeStep,
    percentage,
    error: stepperError,
  } = useActiveStep(status, watchMainStatus, EntityTypeEnum.SalesReturn);

  const selectModifyOptions = useSalesReturnOption(status);
  const { selectCreateOptions, disabledCreateOptions } =
    useSalesReturnCreate(status);

  const cancelDocumentHandler = () => {
    cancel({
      documentInput: {
        reference_document_type: SalesDocumentType.SalesReturn,
        unique_id: id ? id : "",
      },
    });
  };

  const copyDocumentHandler = () => {
    const salesReturn = getValues();
    navigate("/sales/return/add", {
      state: copySalesReturnFormatter(salesReturn),
    });
  };

  const saveCreditNoteHandler = (data: ISalesReturn) => {
    const payload = salesReturnUpdatePayloadFormatter(
      data,
      "finished"
    ) as SalesReturnUpdateInput;
    // setSnackbarMessage("อนุมัติ");
    update({
      uniqueInput: {
        unique_id: id,
      },
      updateInput: payload,
    });
    updateStatus({
      documentInput: {
        reference_document_type: SalesDocumentType.SalesReturn,
        unique_id: data.unique_id,
      },
    });
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(cancelDocumentHandler);

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: saveCreditNoteModal,
    openConfirmationHandler: openSaveCreditNoteModal,
    closeConfirmationHandler: closeSaveCreditNoteModal,
    submitConfirmationHandler: submitSaveCreditNoteModal,
  } = useConfirmation(
    handleSubmit ? handleSubmit(saveCreditNoteHandler) : undefined
  );

  const generateUniqueId = useCallback(() => {
    getUniqueId();
  }, [getUniqueId]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  return (
    <Fragment>
      <Grid container mb={3}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mb={2}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{t("sales.return.index")}</Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={5.5} sm={5.5} md={4} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "แก้ไข":
                      editClickHandler();
                      break;
                    case "คัดลอก":
                      openCopyConfirmation();
                      break;
                    case "ยกเลิก":
                      openConfirmationHandler();
                      break;
                    case "บันทึกใบลดหนี้":
                      openSaveCreditNoteModal();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={3} lg={3} xl={3}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"สร้าง"}
                options={selectCreateOptions}
                onSelect={async (e) => {
                  const salesReturn = getValues();
                  const currentUser: ICreatedBy = {
                    user_unique_id: authUser?.unique_id || "",
                    email: authUser?.email || "",
                    first_name: authUser?.first_name || "",
                    last_name: authUser?.last_name,
                    img_url: authUser?.img_url,
                  };
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "ใบนำเข้า": {
                      const state = await createGoodsReturnFromReturn(
                        salesReturn,
                        currentUser
                      );
                      navigate("/inventory/goods_return/add", {
                        state,
                      });
                      break;
                    }

                    default:
                  }
                }}
                disabled={disabledCreateOptions}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
                <IconButton
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                  color="primary"
                >
                  <RestoreOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StepperUI
        steps={steps}
        activeStep={activeStep}
        error={stepperError}
        percentageProgress={percentage}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">{t("sales.unique_id")}</Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
              <ControlledTextField
                name="unique_id"
                control={control}
                error={Boolean(errors?.unique_id)}
                helperText={errors?.unique_id && errors?.unique_id.message}
                disabled={Boolean(id)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              {!id && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton onClick={generateUniqueId} color="primary">
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.reference_unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={6} xl={6}>
              {reference_unique_id_list && reference_unique_id_list.length > 0
                ? reference_unique_id_list.map(
                    (reference_unique_id: string) => (
                      <CustomizedChips
                        key={reference_unique_id}
                        onClick={() =>
                          window.open(
                            `/sales/order/${encodeURIComponent(
                              reference_unique_id
                            )}`,
                            "_blank"
                          )
                        }
                        value={reference_unique_id}
                        color="primary"
                      />
                    )
                  )
                : "-"}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={3} xl={3} />
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.external_reference_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={6} xl={6}>
              <ControlledTextField
                name="external_reference_id"
                control={control}
                error={Boolean(errors?.external_reference_id)}
                helperText={
                  errors?.external_reference_id &&
                  errors?.external_reference_id.message
                }
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={3} xl={3} />
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={9} xl={9}>
              <EmployeeListForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.sales_contact_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={9} lg={9} xl={9}>
              <SalesEmployeeListForm
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                open={createdDateIsOpen}
                onClose={() => setCreatedDateIsOpen(false)}
                onOpen={() => setCreatedDateIsOpen(true)}
                label={t("date.created_date")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                error={errors.issue_date}
                open={issueDateIsOpen}
                onClose={() => setIssueDateIsOpen(false)}
                onOpen={() => setIssueDateIsOpen(true)}
                label={t("date.issue_date")}
                disabled={disabled}
                required
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            sx={{ mt: 0.5 }}
          >
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledTextField
                type="number"
                label={t("sales.credit_day")}
                control={control}
                name="credit_day"
                error={Boolean(errors?.credit_day)}
                helperText={errors?.credit_day && errors?.credit_day?.message}
                disabled={disabled}
                onChange={(e, field) => {
                  const parseIntValue = parseInt(e.target.value);
                  field.onChange(parseIntValue);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันหากต้องการยกเลิกเอกสาร"
        message="หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
      <ModalUI
        title="บันทึกใบลดหนี้"
        open={saveCreditNoteModal}
        handleClose={closeSaveCreditNoteModal}
        maxWidth="xs"
        fullWidth
        action={
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              gap: ".5rem",
            }}
          >
            <CustomizedButton
              title="ยกเลิก"
              variant="outlined"
              onClick={closeSaveCreditNoteModal}
            />
            <CustomizedButton
              title="ยืนยัน"
              variant="contained"
              onClick={submitSaveCreditNoteModal}
            />
          </Box>
        }
      >
        <DialogContentText sx={{ mb: 2 }}>
          หากบันทึกแล้วจะไม่สามารถเปลี่ยนแปลงได้
        </DialogContentText>
        <ControlledTextField
          label={t("sales.external_reference_id")}
          control={control}
          name="external_reference_id_confirmation"
        />
      </ModalUI>
    </Fragment>
  );
};

export default SalesReturnHeader;
