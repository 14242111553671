import * as Yup from "yup";
import { ItemType, Tracability } from "../../../../generated/inventory";

export const salesBomSchema = {
  unique_id: "",
  name: "",
  desc: "",
  type: ItemType.Bom,
  barcode: "",
  seller_sku_name: "",
  img_url: [],
  tag_list: [],
  item_level_1_unique_id: "",
  is_purchasable: false,
  is_saleable: false,
  is_stockable: false,
  is_manufactured: false,
  is_active: 1,
  remark_status: "",
  physical_attribute: {
    weight: 0,
    length: 0,
    height: 0,
    width: 0,
    thick: 0,
    weight_uom_unique_id: "",
    height_uom_unique_id: "",
    length_uom_unique_id: "",
    width_uom_unique_id: "",
    thick_uom_unique_id: "",
  },
  package_attribute: {
    weight: 0,
    length: 0,
    height: 0,
    width: 0,
    weight_uom_unique_id: "",
    height_uom_unique_id: "",
    length_uom_unique_id: "",
    width_uom_unique_id: "",
  },
  stock_uom_unique_id: "",
  purchase_uom_unique_id: "",
  sales_uom_unique_id: "",
  deliver_uom_unique_id: "",
  tracability: Tracability.Normal,
  item_variant_key_list: [],
  sku_list: [],
  bundle_item_detail_list: [],
  purchase_standard_price: 0,
  purchase_minimum_qty: 0,
  purchase_vat_type: "ไม่มี",
  sale_price: 0,
  special_price: 0,
  sale_vat_type: "ไม่มี",
  manufacturing_type: "",
  manufacture_minimum_qty: 0,
  bom_detail: { name: "" },
  routing_detail: { name: "" },
  attribute_list: [],
  variation_list: [],
  variation_img_list: [],
  option_1: { key: "", list: [] },
  option_2: { key: "", list: [] },
  option_3: { key: "", list: [] },
  minimum_stock: 0,
  maximum_stock: 0,
  reorder_point: 0,
};

export const salesBomValidate = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  name: Yup.string().required("กรุณาระบุ"),
  type: Yup.string(),
  desc: Yup.string().max(500, "กรอกได้ไม่เกิน 500 ตัวอักษร"),
});
