import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../../../../types/global";
import { Typography } from "@mui/material";
import { IGoodsAdjustment } from "../../../../types/Inventory/goodsAdjustment";
import { formatNumber } from "../../../../utils/dataTransformer";

interface Props {
  control: IDefaultForm<IGoodsAdjustment>["control"];
  index: number;
}

const NewStockQuantityCell = ({ control, index }: Props) => {
  const watchTrace =
    useWatch({
      control,
      name: `trace_entry_list.${index}` as `trace_entry_list.0`,
    }) || [];

  return (
    <Typography fontSize={14}>
      {formatNumber(
        (watchTrace.stock_qty ? watchTrace.stock_qty : 0) +
          (typeof watchTrace.qty === "string"
            ? watchTrace.qty
              ? parseInt(watchTrace.qty)
              : 0
            : watchTrace.qty)
      )}
    </Typography>
  );
};

export default NewStockQuantityCell;
