import { useTranslation } from "react-i18next";
import { IDashboardInput } from "../../../../../types/Dashboard";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import {
  MostSalesItemByCustomerQuery,
  MostSalesItemQuery,
  QuotationByStatusQuery,
  SalesApprovalTemplatesQuery,
  SalesOrderByStatusQuery,
  useMostSalesItemByCustomerQuery,
  useMostSalesItemQuery,
  useQuotationByStatusQuery,
  useSalesApprovalTemplatesQuery,
  useSalesOrderByStatusQuery,
} from "../../../../../generated/sales";
import { useEffect } from "react";
import { Grid, Theme, useMediaQuery } from "@mui/material";
import TotalSalesChart from "./TotalSalesChart";
import QuotationChart from "./QuotationChart";
import SalesOrderChart from "./SalesOrderChart";

type Props = {
  input: IDashboardInput;
};

const SalesDashboard = ({ input }: Props) => {
  const { t } = useTranslation();
  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const {
    data: mostSalesItem,
    refetch: refetchMostSalesItem,
    isFetching: isFetchingMostSalesItem,
  } = useMostSalesItemQuery<MostSalesItemQuery>(
    graphQLClient,
    {
      input: {
        ...input,
        top: 6,
      },
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
      cacheTime: 0,
    }
  );

  const {
    data: mostSalesItemByCustomer,
    refetch: refetchMostSalesItemByCustomer,
    isFetching: isFetchingMostSalesItemByCustomer,
  } = useMostSalesItemByCustomerQuery<MostSalesItemByCustomerQuery>(
    graphQLClient,
    {
      input: {
        ...input,
        top: 6,
      },
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
      cacheTime: 0,
    }
  );

  const {
    data: quotation,
    refetch: refetchQuotation,
    isFetching: isFetchingQuotation,
  } = useQuotationByStatusQuery<QuotationByStatusQuery>(
    graphQLClient,
    {
      salesByStatusInput: input,
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
      cacheTime: 0,
    }
  );

  const {
    data: salesOrder,
    refetch: refetchSalesOder,
    isFetching: isFetchingSalesOrder,
  } = useSalesOrderByStatusQuery<SalesOrderByStatusQuery>(
    graphQLClient,
    {
      salesByStatusInput: input,
    },
    {
      enabled: !!input.started_date && !!input.ended_date,
      staleTime: Infinity,
      cacheTime: 0,
    }
  );

  const { data: approvalTemplates } =
    useSalesApprovalTemplatesQuery<SalesApprovalTemplatesQuery>(
      graphQLClient,
      {},
      {
        cacheTime: 0,
      }
    );

  const isQuotationHaveApproval =
    approvalTemplates?.approvalTemplates?.some(
      (template: any) =>
        template.document_type === "quotation" &&
        template.is_have_approval === true
    ) || false;

  const isSalesOrderHaveApproval =
    approvalTemplates?.approvalTemplates?.some(
      (template: any) =>
        template.document_type === "sales_order" &&
        template.is_have_approval === true
    ) || false;

  useEffect(() => {
    refetchMostSalesItem();
    refetchMostSalesItemByCustomer();
    refetchQuotation();
    refetchSalesOder();
  }, [
    refetchMostSalesItem,
    refetchMostSalesItemByCustomer,
    refetchQuotation,
    refetchSalesOder,
  ]);

  return (
    <Grid container spacing={isMobile ? 2 : 1.5}>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <TotalSalesChart
          name={t("dashboard.total_sales_by_item")}
          data={mostSalesItem?.MostSalesItem}
          isFetching={isFetchingMostSalesItem}
          type="total_sales_by_item"
          // url="/manufacture/order?filter=pending_manu"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <TotalSalesChart
          name={t("dashboard.total_sales_by_customer")}
          data={mostSalesItemByCustomer?.MostSalesItemByCustomer}
          isFetching={isFetchingMostSalesItemByCustomer}
          type="total_sales_by_customer"
          // url="/manufacture/order?filter=in_progress"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <QuotationChart
          data={quotation?.QuotationByStatus}
          isFetching={isFetchingQuotation}
          isHaveApproval={isQuotationHaveApproval}
        />
        <SalesOrderChart
          data={salesOrder?.SalesOrderByStatus}
          isFetching={isFetchingSalesOrder}
          isHaveApproval={isSalesOrderHaveApproval}
        />
      </Grid>
    </Grid>
  );
};

export default SalesDashboard;
