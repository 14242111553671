import { useEffect, useMemo, useState } from "react";
import { useSalesApprovalTemplatesQuery, SalesApprovalTemplatesQuery } from "../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

const useSalesCheckApproval = (documentType: string) => {
  const graphQLClientWithHeaderSales = useMemo(
    () => createGraphQLClientWithMiddleware("sales"),
    []
  );

  const { data: approvalTemplatesData, refetch, isLoading } = useSalesApprovalTemplatesQuery<SalesApprovalTemplatesQuery>(
    graphQLClientWithHeaderSales, {}
  );

  const [isHaveApproval, setIsHaveApproval] = useState<boolean | null>(null);

  useEffect(() => {
    if (!isLoading) {
      setIsHaveApproval(
        approvalTemplatesData?.approvalTemplates?.some(
          (template) => template?.document_type === documentType && template?.is_have_approval
        ) ?? false
      );
    }
  }, [approvalTemplatesData, documentType, isLoading]);

  return { isHaveApproval, isLoading, refetch };
};

export default useSalesCheckApproval;