import { GraphQLClient } from "graphql-request";
import { EntityTypeEnum } from "../../generated/creatable";
import { InventoryDocumentType } from "../../generated/inventory";
import {
  PurchaseDocumentType,
  PurchaseReturnQuery,
} from "../../generated/purchase";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import { IPurchaseItemList } from "../../types/Purchase";
import { IPurchaseReturn } from "../../types/Purchase/purchaseReturn";
import { ICreatedBy } from "../../types/global";
import { itemListToTraceEntryListFormatter } from "./Global";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

export const purchaseReturnCreatePayloadFormatter = (
  data: IPurchaseReturn,
  status: string
) => {
  const { item_list, vendor_contact, tag_list, created_date, ...otherData } =
    data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];
  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, reference_line_item, ...otherItem }) => ({
      ...otherItem,
      reference_document_type: PurchaseDocumentType.PurchaseReturn,
      reference_unique_id: data.unique_id,
      reference_line_item: reference_line_item ?? undefined,
    })
  );

  const { unique_id_name, ...vendor } = vendor_contact;
  const formatPayload = {
    ...otherData,
    vendor_contact: vendor,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
  };
  return formatPayload;
};

export const purchaseReturnUpdatePayloadFormatter = (
  data: IPurchaseReturn,
  status: string,
  isNotApprove?: boolean
) => {
  const {
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    vendor_contact,
    item_list,
    tag_list,
    created_by,
    created_date,
    ...otherData
  } = data;
  const { unique_id_name, ...vendor } = vendor_contact;

  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, reference_line_item, ...otherItem }) => ({
      ...otherItem,
      reference_document_type: PurchaseDocumentType.PurchaseReturn,
      reference_unique_id: data.unique_id,
      reference_line_item: reference_line_item ?? undefined,
    })
  );

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];
  const formatPayload = {
    ...otherData,
    vendor_contact: vendor,
    flag_status:
      isNotApprove && flag_status
        ? !flag_status.find((fl) => fl === "not_approved")
          ? [...flag_status, "not_approved"]
          : flag_status
        : undefined,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
  };
  return formatPayload;
};

export const purchaseReturnQueryFormatter = async (data: IPurchaseReturn) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const allItemListUniqueId = data.item_list.map((item) => item.item_unique_id);
  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: 999,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });
  const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: IPurchaseItemList[] = [];

  data.item_list.forEach((item) => {
    const foundItemIndex = itemSkuDetails.findIndex(
      (realItem: any) => realItem.sku_name === item.item_unique_id
    );

    formatItemList.push({
      ...item,
      uom_group: itemSkuDetails[foundItemIndex]?.item_sku.item.uom_group,
      item_sku_qty: {
        stock_qty: itemSkuDetails[foundItemIndex]?.stock_qty,
        available_qty: itemSkuDetails[foundItemIndex]?.available_qty,
        purchase_ordered_qty:
          itemSkuDetails[foundItemIndex]?.purchase_ordered_qty,
        manufacture_ordered_qty:
          itemSkuDetails[foundItemIndex]?.manufacture_ordered_qty,
        sale_committed_qty: itemSkuDetails[foundItemIndex]?.sale_committed_qty,
        manufacture_committed_qty:
          itemSkuDetails[foundItemIndex]?.manufacture_committed_qty,
      },
    });
  });

  const formatVendor = {
    ...data.vendor_contact,
    unique_id_name: `${data.vendor_contact_unique_id} - ${data.vendor_contact.name}`,
  };

  const formatPayload = {
    ...data,
    vendor_contact: formatVendor,
    item_list: formatItemList,
  };
  return formatPayload;
};

export const copyPurchaseReturnFormatter = (
  data?: PurchaseReturnQuery["purchaseReturn"]
) => {
  if (data) {
    const {
      created_by,
      unique_id,
      created_date,
      issue_date,
      delivery_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(({ ...otherItemList }) => ({
        ...otherItemList,
      }));

    return {
      ...otherData,
      item_list: formatItemList,
    };
  }
};

export const createGoodsIssueFromReturn = (
  data: IPurchaseReturn,
  currentUser: ICreatedBy
) => {
  if (data) {
    const { unique_id, item_list, destination_warehouse_unique_id } = data;

    const formatTraceEntry = itemListToTraceEntryListFormatter(
      item_list,
      InventoryDocumentType.GoodsIssue,
      EntityTypeEnum.PurchaseReturn
    );

    return {
      type: "purchase_return",
      reference_unique_id: unique_id,
      trace_entry_list: formatTraceEntry,
      created_by: currentUser,
      source_warehouse_unique_id: destination_warehouse_unique_id,
    };
  }
};
