import { GraphQLClient } from "graphql-request";
import { useCompanyQuery, CompanyQuery } from "../../generated/company-user";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { renderAddressString } from "../../pages/Sales/Quotation/PDF";

export const usePDFMultiplePage = (
  data: any,
  rowWidth: number,
  maxHeightWithoutFooter: number, //Footer is summary + signer
  maxHeightWithFooter: number,
  leftHeaderMinHeight: number,
  rightHeaderMinHeight: number
) => {
  const baseHeaderHeight = Math.max(leftHeaderMinHeight, rightHeaderMinHeight);
  const companyAddressMaxWidth = 342;
  const addressMaxWidth = 289;

  let paginatedItems: any[][] = [];
  let currentItems: any[] = [];
  let currentTableHeight = 0;

  //todo flat map gi item list and handle different barcodes

  let itemList = data?.trace_entry_list?.flatMap((item: any) => item) ?? [];

  if (data?.so_item_list?.length > 0) {
    itemList = itemList.map((item: any) => {
      const matchingSOItem = data.so_item_list.find(
        (soItem: any) =>
          soItem.barcode === item.barcode ||
          soItem.item_unique_id === item.item_unique_id
      );
      return {
        ...item,
        item_name: matchingSOItem?.item_name ?? item.item_name,
        item_sku_desc: matchingSOItem?.item_sku_desc ?? "",
      };
    });
  }

  const tenantId = sessionStorage.getItem("tenant-id");

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const { data: company } = useCompanyQuery<CompanyQuery>(graphQLClient, {
    uniqueInput: {
      unique_id: tenantId,
    },
  });

  const defaultAddress = company?.company?.address_list?.find(
    (address: any) => address.is_default
  );

  //Calculate header height
  const calculateLeftHeaderHeight = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("Cannot get 2D context");
    }

    context.font = "11px";

    let numberOfLines = 2;

    const companyAddress = `${defaultAddress?.address} ${defaultAddress?.sub_district} ${defaultAddress?.district} ${defaultAddress?.province} ${defaultAddress?.postal_code}`;

    const customerAddress =
      renderAddressString(data?.customer_contact?.billing_address || {}) || "-";

    let companyAddressWidth = context.measureText(companyAddress).width;
    if (companyAddressWidth > companyAddressMaxWidth) {
      const lines = Math.ceil(companyAddressWidth / addressMaxWidth);
      numberOfLines += lines - 1;
    }

    let customerAddressWidth = context.measureText(customerAddress).width;
    if (customerAddressWidth > addressMaxWidth) {
      const lines = Math.ceil(customerAddressWidth / addressMaxWidth);
      numberOfLines += lines - 1;
    }

    return leftHeaderMinHeight + (numberOfLines - 2) * 24;
  };

  const calculateRightHeaderHeight = () => {
    let additionalLines = 0;
    return rightHeaderMinHeight + additionalLines * 24;
  };

  const leftHeaderHeight = calculateLeftHeaderHeight();
  const rightHeaderHeight = calculateRightHeaderHeight();
  const additionalHeaderHeight =
    Math.max(leftHeaderHeight, rightHeaderHeight) - baseHeaderHeight;

  maxHeightWithFooter -= additionalHeaderHeight;
  maxHeightWithoutFooter -= additionalHeaderHeight;

  //Calculate row height by item name and description
  itemList?.forEach((item: any) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("Cannot get 2D context");
    }
    context.font = "11px";

    let numberOfLines = 1;
    let rowHeight = 0;
    const {
      item_unique_id,
      item_name: itemName,
      item_sku_desc: itemDescription,
      barcode,
    } = item;

    const nameText = item_unique_id + " - " + itemName;
    const nameWidth = context.measureText(nameText).width;
    const nameLines = Math.ceil(nameWidth / rowWidth);
    numberOfLines += nameLines > 1 ? nameLines : 0;

    const barcodeWidth = context.measureText(barcode).width;
    const barcodeLines = Math.ceil(barcodeWidth / rowWidth);

    numberOfLines += barcodeLines > 1 ? barcodeLines - 1 : 0;

    // Handle multiline description
    const descriptionLines = itemDescription ? itemDescription.split("\n") : [];

    descriptionLines.forEach((line: string) => {
      const lineWidth = context.measureText(line).width;
      const linesRequired = Math.ceil(lineWidth / rowWidth);
      numberOfLines += linesRequired > 1 ? linesRequired : 1;
    });

    if (numberOfLines > 2) {
      rowHeight = 50 + (numberOfLines - 2) * 16.5;
    } else {
      rowHeight = 50;
    }

    if (currentTableHeight + rowHeight > maxHeightWithoutFooter) {
      paginatedItems.push(currentItems);
      currentItems = [];
      currentTableHeight = rowHeight;
      currentItems.push(item);
    } else {
      currentTableHeight += rowHeight;
      currentItems.push(item);
    }
  });

  paginatedItems.push(currentItems);
  if (currentTableHeight > maxHeightWithFooter) {
    paginatedItems.push([]);
  }

  let startNumbers: number[] = [];
  let currentNumber = 0;

  paginatedItems.forEach((pageItems, index) => {
    if (index === 0) {
      startNumbers.push(1);
      currentNumber = pageItems.length;
    } else {
      startNumbers.push(currentNumber + 1);
      currentNumber += pageItems.length;
    }
  });

  return {
    paginatedItems,
    startNumbers, //start number of each page
  };
};
