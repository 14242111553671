import { useMemo, useEffect, useState } from "react";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { PurchaseApprovalTemplatesQuery, usePurchaseApprovalTemplatesQuery } from "../../generated/purchase";

const usePurchaseCheckApproval = (documentType: string) => {
  const graphQLClientWithHeaderPurchase = useMemo(
    () => createGraphQLClientWithMiddleware("purchase"),
    []
  );

  const { data: approvalTemplatesData, refetch, isLoading } = usePurchaseApprovalTemplatesQuery<PurchaseApprovalTemplatesQuery>(
    graphQLClientWithHeaderPurchase, {}
  );

  const [isHaveApproval, setIsHaveApproval] = useState<boolean | null>(null);

  useEffect(() => {
    if (!isLoading) {
      setIsHaveApproval(
        approvalTemplatesData?.purchaseApprovalTemplates?.some(
          (template) => template?.document_type === documentType && template?.is_have_approval
        ) ?? false
      );
    }
  }, [approvalTemplatesData, documentType, isLoading]);

  return { isHaveApproval, isLoading, refetch };
};

export default usePurchaseCheckApproval;