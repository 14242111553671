import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { IPurchaseOrder } from "../../../types/Purchase/purchaseOrder";
import PurchaseOrderHeader from "../../../components/Form/Purchase/Order/Header";
import VendorInfoForm from "../../../components/Form/VendorInfo/VendorInfoForm";
import PurchaseItemList from "../../../components/Table/Purchase/ItemList/PurchaseItemList";
import PurchaseFooter from "../../../components/Form/Purchase/Footer";
import {
  WarehousesQuery,
  useWarehousesQuery,
} from "../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { Box, CircularProgress, Stack } from "@mui/material";
import {
  purchaseOrderSchema,
  purchaseOrderValidation,
} from "../../../components/Form/Purchase/Order/schema";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
import {
  purchaseOrderCreatePayloadFormatter,
  purchaseOrderQueryFormatter,
  purchaseOrderUpdatePayloadFormatter,
} from "../../../utils/Formatter/PurchaseOrder";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ActivityType,
  ApprovalAction,
  PurchaseActivityLogDocumentType,
  PurchaseApprovalTemplatesQuery,
  PurchaseDocumentType,
  PurchaseOrderCreateInput,
  PurchaseOrderQuery,
  PurchaseOrderUpdateInput,
  Status,
  usePurchaseApprovalTemplatesQuery,
  usePurchaseApproveWorkflowMutation,
  usePurchaseDocumentNextStatusMutation,
  usePurchaseOrderCreateMutation,
  usePurchaseOrderQuery,
  usePurchaseOrderUpdateMutation,
} from "../../../generated/purchase";
import { useSnackbar } from "notistack";
import { errorMessageFormatter } from "../../Setting/Inventory/Warehouse";
import PriceVatType from "../../../components/Form/Purchase/PriceVatType";
import { EntityTypeEnum } from "../../../generated/creatable";
import { usePurchaseError } from "../../../hooks/Purchase/use-purchase-error";
import { GraphQLClient } from "graphql-request";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { useActivityLogCreate } from "../../../hooks/use-global-activity-log";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { ICreatedBy } from "../../../types/global";
import { useStateContext } from "../../../contexts/auth-context";
import { sortDataByUniqueIdAndName } from "../../../utils/Formatter/Global";
import PurchaseOrderAttachment from "../../../components/Form/Purchase/Order/PurchaseOrderAttachment";
import { useOnLeavePage } from "../../../hooks/use-on-leave-page";
import LoadingMascot from "../../../components/UI/LoadingMascot";
import dayjs from "dayjs";

const DocumentInfoTab = () => {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [approvalTemplate, setApprovalTemplate] = useState<any[]>([]);
  const [allLoading, setAllLoading] = useState<boolean>(false);

  const {
    state: { authUser },
  } = useStateContext();

  const currentUser: ICreatedBy = {
    user_unique_id: authUser?.unique_id || "",
    email: authUser?.email || "",
    first_name: authUser?.first_name || "",
    last_name: authUser?.last_name,
    img_url: authUser?.img_url,
  };
  const {
    control,
    setValue,
    formState: { errors, dirtyFields },
    getValues,
    handleSubmit,
    watch,
    reset,
    resetField,
  } = useForm<IPurchaseOrder>({
    defaultValues: purchaseOrderSchema,
    resolver: yupResolver(purchaseOrderValidation),
    mode: "onChange",
  });

  const { purchaseActivityLogCreate } = useActivityLogCreate();
  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const isAttachmentDirty = Array.isArray(dirtyFields?.attachment_list)
    ? dirtyFields.attachment_list.some((attachment) =>
        Object.values(attachment).some(
          (value) =>
            typeof value === "object"
              ? Object.values(value).some(Boolean) // Check nested objects
              : Boolean(value) // Check top-level fields
        )
      )
    : Boolean(dirtyFields?.attachment_list);

  const { allowNextNavigation } = useOnLeavePage(isAttachmentDirty);

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const { data: approvalTemplates } =
    usePurchaseApprovalTemplatesQuery<PurchaseApprovalTemplatesQuery>(
      graphQLClientWithHeaderPurchase
    );

  useEffect(() => {
    if (
      approvalTemplates?.purchaseApprovalTemplates &&
      approvalTemplates.purchaseApprovalTemplates.length > 0
    ) {
      setApprovalTemplate(approvalTemplates.purchaseApprovalTemplates);
    } else {
      setApprovalTemplate([]);
    }
  }, [approvalTemplates]);

  const { mutateAsync: purchaseApproveWorkflow } =
    usePurchaseApproveWorkflowMutation<Error>(graphQLClientWithHeaderPurchase);

  const { data, isLoading, isSuccess, refetch } =
    usePurchaseOrderQuery<PurchaseOrderQuery>(
      graphQLClientWithHeaderPurchase,
      {
        uniqueInput: {
          unique_id: id,
        },
      },
      {
        enabled: !!id,
      }
    );

  const { isLoading: isCreating, mutateAsync: create } =
    usePurchaseOrderCreateMutation<Error>(graphQLClientWithHeaderPurchase);

  const { isLoading: isUpdating, mutateAsync: update } =
    usePurchaseOrderUpdateMutation<Error>(graphQLClientWithHeaderPurchase);

  const { isLoading: isChanging, mutateAsync: updateStatus } =
    usePurchaseDocumentNextStatusMutation<Error>(
      graphQLClientWithHeaderPurchase
    );

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useWarehousesQuery<WarehousesQuery>(graphQLClientWithHeaderItem);

  const sortedWarehouse = sortDataByUniqueIdAndName(
    warehouseData?.warehouses || []
  );

  useEffect(() => {
    if (!id) {
      if (!isWarehouseLoading && sortedWarehouse.length > 0) {
        setValue(
          "destination_warehouse_unique_id",
          sortedWarehouse[0]?.unique_id
        );
      }
    }
  }, [id, isWarehouseLoading, setValue, sortedWarehouse]);

  useEffect(() => {
    if (isSuccess) {
      const { purchaseOrder } = data;

      const getPurchaseOrderData = async () => {
        const purchaseOrderType = purchaseOrder as IPurchaseOrder;
        const formattedPurchaseOrder = await purchaseOrderQueryFormatter(
          purchaseOrderType
        );
        reset(formattedPurchaseOrder);
      };
      getPurchaseOrderData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (state) {
      const {
        copied_id,
        copied_unique_id,
        reference_id_list,
        credit_day,
        ...otherState
      } = state;
      reset({
        ...otherState,
        issue_date: dayjs(),
        due_date: dayjs(),
        expect_date: dayjs().add(7, "day"),
        payment_due_date: dayjs().add(7, "day"),
      });
    }
  }, [reset, state]);

  useEffect(() => {
    if (
      !isLoading &&
      !isWarehouseLoading &&
      id &&
      data?.purchaseOrder?.aggrid_status !== "draft" &&
      data?.purchaseOrder?.aggrid_status !== "not_approved"
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [data?.purchaseOrder?.aggrid_status, id, isLoading, isWarehouseLoading]);

  const onPurchaseOrderCreate = async (
    data: IPurchaseOrder,
    status: string
  ) => {
    try {
      setAllLoading(true);

      const payload = (await purchaseOrderCreatePayloadFormatter(
        data,
        status
      )) as PurchaseOrderCreateInput;

      const { purchaseOrderCreate } = await create({
        createInput: payload,
      });

      if (purchaseOrderCreate.sub_status === "wait_approve") {
        await updateStatus({
          documentInput: {
            reference_document_type: PurchaseDocumentType.PurchaseOrder,
            unique_id: purchaseOrderCreate.unique_id,
          },
        });
        try {
          await purchaseActivityLogCreate({
            activity_type: ActivityType.Create,
            document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
            reference_id: purchaseOrderCreate.id,
            activity_detail: {},
          });
          if (state && state.copied_unique_id) {
            await purchaseActivityLogCreate({
              activity_type: ActivityType.Copy,
              document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
              reference_id: purchaseOrderCreate.id,
              activity_detail: {
                copied_from: {
                  id: state.copied_id,
                  unique_id: state.copied_unique_id,
                },
                copied_to: {
                  id: purchaseOrderCreate.id,
                  unique_id: purchaseOrderCreate.unique_id,
                },
              },
            });
          }
          await purchaseActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
            reference_id: purchaseOrderCreate.id,
            activity_detail: {
              curr_status: Status.WaitApprove,
            },
          });
        } catch (err) {
          console.log(err);
        }
      } else if (purchaseOrderCreate.sub_status === "approved") {
        await updateStatus({
          documentInput: {
            reference_document_type: PurchaseDocumentType.PurchaseOrder,
            unique_id: purchaseOrderCreate.unique_id,
          },
        });
        try {
          await purchaseActivityLogCreate({
            activity_type: ActivityType.Create,
            document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
            reference_id: purchaseOrderCreate.id,
            activity_detail: {},
          });
          if (state && state.copied_id) {
            await purchaseActivityLogCreate({
              activity_type: ActivityType.Copy,
              document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
              reference_id: purchaseOrderCreate.id,
              activity_detail: {
                copied_from: {
                  id: state.copied_id,
                  unique_id: state.copied_unique_id,
                },
                copied_to: {
                  id: purchaseOrderCreate.id,
                  unique_id: purchaseOrderCreate.unique_id,
                },
              },
            });
          }
          await purchaseActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
            reference_id: purchaseOrderCreate.id,
            activity_detail: {
              curr_status: Status.Approved,
            },
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          await purchaseActivityLogCreate({
            activity_type: ActivityType.Create,
            document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
            reference_id: purchaseOrderCreate.id,
            activity_detail: {},
          });

          if (state && state.copied_unique_id) {
            await purchaseActivityLogCreate({
              activity_type: ActivityType.Copy,
              document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
              reference_id: purchaseOrderCreate.id,
              activity_detail: {
                copied_from: {
                  id: state.copied_id,
                  unique_id: state.copied_unique_id,
                },
                copied_to: {
                  id: purchaseOrderCreate.id,
                  unique_id: purchaseOrderCreate.unique_id,
                },
              },
            });
          }
          await purchaseActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
            reference_id: purchaseOrderCreate.id,
            activity_detail: {
              curr_status: Status.Draft,
            },
          });
        } catch (err) {
          console.log(err);
        }
      }

      setAllLoading(false);

      allowNextNavigation();

      navigate(`/purchase/order/${purchaseOrderCreate?.unique_id}`);
      enqueueSnackbar("สร้างใบสั่งซื้อสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      setAllLoading(false);
      const duplicatedUniqueId = errorMessageFormatter(err);
      if (duplicatedUniqueId) {
        enqueueSnackbar(duplicatedUniqueId, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("สร้างใบสั่งซื้อไม่สำเร็จ", {
          variant: "error",
        });
      }
    }
  };

  const onPurchaseOrderUpdate = async (
    data: IPurchaseOrder,
    status: string
  ) => {
    try {
      setAllLoading(true);
      const payload = (await purchaseOrderUpdatePayloadFormatter(
        data,
        status
      )) as PurchaseOrderUpdateInput;

      const { purchaseOrderUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      const changedData = Object.keys(dirtyFields);
      if (changedData.length > 0) {
        try {
          await purchaseActivityLogCreate({
            activity_type: ActivityType.Edit,
            document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
            reference_id: purchaseOrderUpdate.id,
            activity_detail: {},
          });
        } catch (error) {
          console.log(error);
        }
      }
      setAllLoading(false);

      enqueueSnackbar(`แก้ไขใบสั่งซื้อสำเร็จ`, {
        variant: "success",
      });

      await refetch();
    } catch (err) {
      setAllLoading(false);
      const duplicatedUniqueId = errorMessageFormatter(err);
      if (duplicatedUniqueId) {
        enqueueSnackbar(duplicatedUniqueId, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`แก้ไขใบสั่งซื้อไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const onPurchaseOrderSendApprove = async (
    data: IPurchaseOrder,
    isResend: boolean = false
  ) => {
    try {
      setAllLoading(true);
      const payload = (await purchaseOrderUpdatePayloadFormatter(
        data,
        "wait_approve"
      )) as PurchaseOrderUpdateInput;

      const isHaveApproval = approvalTemplates?.purchaseApprovalTemplates?.some(
        (template: any) =>
          template.document_type === "purchase_order" &&
          template.is_have_approval === true
      );

      if (!isHaveApproval) {
        const approvedPayload = (await purchaseOrderUpdatePayloadFormatter(
          data,
          "approved"
        )) as PurchaseOrderUpdateInput;

        if (!id) {
          await onPurchaseOrderCreate(data, "approved");
        } else {
          if (!id) {
            await onPurchaseOrderCreate(data, "wait_approve");
          } else {
            const { purchaseOrderUpdate } = await update({
              uniqueInput: {
                unique_id: id,
              },
              updateInput: approvedPayload,
            });
            await updateStatus({
              documentInput: {
                reference_document_type: PurchaseDocumentType.PurchaseOrder,
                unique_id: data.unique_id,
              },
            });
            try {
              await purchaseActivityLogCreate({
                activity_type: ActivityType.StatusChange,
                document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
                reference_id: purchaseOrderUpdate.id,
                activity_detail: {
                  curr_status: Status.Approved,
                },
              });
            } catch (error) {
              console.error(error);
            }
            await refetch();
          }
        }
      } else {
        if (!id) {
          await onPurchaseOrderCreate(data, "wait_approve");
        } else {
          const { purchaseOrderUpdate } = await update({
            uniqueInput: {
              unique_id: id,
            },
            updateInput: payload,
          });

          if (!isResend) {
            await updateStatus({
              documentInput: {
                reference_document_type: PurchaseDocumentType.PurchaseOrder,
                unique_id: data.unique_id,
              },
            });

            try {
              await purchaseActivityLogCreate({
                activity_type: ActivityType.StatusChange,
                document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
                reference_id: purchaseOrderUpdate.id,
                activity_detail: {
                  curr_status: Status.WaitApprove,
                },
              });
            } catch (error) {
              console.log(error);
            }

            enqueueSnackbar(`ส่งอนุมัติใบสั่งซื้อสำเร็จ`, {
              variant: "success",
            });
          } else {
            try {
              await purchaseActivityLogCreate({
                activity_type: ActivityType.StatusChange,
                document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
                reference_id: purchaseOrderUpdate.id,
                activity_detail: {
                  curr_status: Status.WaitApprove,
                },
              });
            } catch (error) {
              console.log(error);
            }
            enqueueSnackbar(`รออนุมัติใบสั่งซื้อสำเร็จ`, {
              variant: "success",
            });
          }
          await refetch();
        }
      }
      setAllLoading(false);
    } catch (err) {
      setAllLoading(false);
      if (!isResend) {
        enqueueSnackbar(`ส่งอนุมัติใบสั่งซื้อไม่สำเร็จ`, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`รออนุมัติใบสั่งซื้อไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const onPurchaseOrderNotApprove = async (data: IPurchaseOrder) => {
    try {
      setAllLoading(true);
      const payload = (await await purchaseOrderUpdatePayloadFormatter(
        data,
        "wait_approve",
        true
      )) as PurchaseOrderUpdateInput;

      const { purchaseOrderUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      const reviewerUniqueId = currentUser.user_unique_id;
      await purchaseApproveWorkflow({
        document: {
          reference_document_type: PurchaseDocumentType.PurchaseOrder,
          unique_id: data.unique_id,
        },
        reviewerUniqueId: reviewerUniqueId || "",
        approvedAction: ApprovalAction.Ejected,
      });
      try {
        await purchaseActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
          reference_id: purchaseOrderUpdate.id,
          activity_detail: {
            curr_status: Status.NotApproved,
          },
        });
      } catch (error) {
        console.log(error);
      }
      setAllLoading(false);

      enqueueSnackbar(`ไม่อนุมัติใบสั่งซื้อสำเร็จ`, {
        variant: "success",
      });

      await refetch();
    } catch (err) {
      setAllLoading(false);
      enqueueSnackbar(`ไม่อนุมัติใบสั่งซื้อไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onPurchaseOrderApprove = async (data: IPurchaseOrder) => {
    try {
      setAllLoading(true);
      const payload = (await purchaseOrderUpdatePayloadFormatter(
        data,
        "approved"
      )) as PurchaseOrderUpdateInput;

      const { purchaseOrderUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      const reviewerUniqueId = currentUser.user_unique_id;
      await purchaseApproveWorkflow({
        document: {
          reference_document_type: PurchaseDocumentType.PurchaseOrder,
          unique_id: data.unique_id,
        },
        reviewerUniqueId: reviewerUniqueId || "",
        approvedAction: ApprovalAction.Approved,
      });
      await updateStatus({
        documentInput: {
          reference_document_type: PurchaseDocumentType.PurchaseOrder,
          unique_id: data.unique_id,
        },
      });
      try {
        await purchaseActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
          reference_id: purchaseOrderUpdate.id,
          activity_detail: {
            curr_status: Status.Approved,
          },
        });
      } catch (error) {
        setAllLoading(false);
        console.log(error);
      }

      setAllLoading(false);

      enqueueSnackbar(`อนุมัติใบสั่งซื้อสำเร็จ`, {
        variant: "success",
      });

      await refetch();
    } catch {
      setAllLoading(false);
      enqueueSnackbar(`อนุมัติใบสั่งซื้อไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onPurchaseOrderWaitApprove = async (data: IPurchaseOrder) => {
    try {
      setAllLoading(true);
      const payload = (await purchaseOrderUpdatePayloadFormatter(
        data,
        "wait_approve"
      )) as PurchaseOrderUpdateInput;

      if (!id) {
        onPurchaseOrderCreate(data, "wait_approve");
      } else {
        const { purchaseOrderUpdate } = await update({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: payload,
        });
        try {
          await purchaseActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: PurchaseActivityLogDocumentType.PurchaseOrder,
            reference_id: purchaseOrderUpdate.id,
            activity_detail: {
              curr_status: Status.WaitApprove,
            },
          });
        } catch (error) {
          console.log(error);
        }
        setAllLoading(false);
        await refetch();
      }
    } catch (err) {
      setAllLoading(false);
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    setIsEdit(false);
    reset();
  };

  const onPurchaseOrderEditHandler = async (data: IPurchaseOrder) => {
    setDisabled(true);
    setIsEdit(false);
    await onPurchaseOrderUpdate(data, data.sub_status ? data.sub_status : "");
  };

  const isUserAuthorized = approvalTemplate.some(
    (template) =>
      template.document_type === "purchase_order" &&
      template.approval_detail_list.some(
        (detail: { approver_list: { unique_id: string }[] }) =>
          detail.approver_list.some(
            (approver: { unique_id: string }) =>
              approver.unique_id === currentUser.user_unique_id
          )
      ) &&
      template.is_have_approval === true
  );

  const renderButton = () => {
    switch (data?.purchaseOrder?.aggrid_status) {
      case "draft":
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isUpdating}
              onClick={handleSubmit((data) =>
                onPurchaseOrderUpdate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit((data) => onPurchaseOrderSendApprove(data))}
              disabled={isChanging}
            />
          </Stack>
        );
      case "wait_approve":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onPurchaseOrderEditHandler)}
              />
            </Stack>
          );
        } else {
          return (
            isUserAuthorized && (
              <Stack direction="row" spacing={1} alignItems="center">
                <>
                  <CustomizedButton
                    variant="outlined"
                    title={t("button.not_approve")}
                    disabled={isUpdating}
                    onClick={handleSubmit(onPurchaseOrderNotApprove)}
                  />
                  <CustomizedButton
                    title={t("button.approve")}
                    variant="contained"
                    onClick={handleSubmit(onPurchaseOrderApprove)}
                    disabled={isChanging}
                  />
                </>
              </Stack>
            )
          );
        }

      case "not_approved":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onPurchaseOrderEditHandler)}
              />
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.save")}
                onClick={handleSubmit((data) =>
                  onPurchaseOrderUpdate(data, "not_approved")
                )}
              />
              <CustomizedButton
                title={t("button.wait_approve")}
                variant="contained"
                onClick={handleSubmit(onPurchaseOrderWaitApprove)}
              />
            </Stack>
          );
        }
      case "approved":
      case "cancelled":
      case "partially_imported":
      case "fully_imported":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onPurchaseOrderEditHandler)}
              />
            </Stack>
          );
        }
        return;
      default:
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating}
              onClick={handleSubmit((data) =>
                onPurchaseOrderCreate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit((data) => onPurchaseOrderSendApprove(data))}
            />
          </Stack>
        );
    }
  };

  usePurchaseError(errors);

  if ((id && isLoading) || isWarehouseLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form>
      <LoadingMascot isLoading={allLoading} />
      <PurchaseOrderHeader
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        allWarehouses={sortedWarehouse || []}
        editClickHandler={editClickHandler}
        refetch={refetch}
        update={update}
        handleSubmit={handleSubmit}
      />
      <VendorInfoForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        reset={reset}
        documentType={EntityTypeEnum.PurchaseOrder}
      />
      <CustomizedBox padding="1rem 1rem 2rem 1rem" sx={{ breakAfter: "auto" }}>
        <PriceVatType
          control={control}
          errors={errors}
          disabled={disabled}
          setValue={setValue}
          multiCurrency
        />
        <PurchaseItemList
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          disabled={disabled}
          documentType={EntityTypeEnum.PurchaseOrder}
        />
      </CustomizedBox>
      <PurchaseFooter
        control={control}
        setValue={setValue}
        errors={errors}
        disabled={disabled}
        documentType="purchase_order"
        resetField={resetField}
      />
      <PurchaseOrderAttachment
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={disabled}
        getValues={getValues}
      />
      {(![
        "approved",
        "cancelled",
        "partially_imported",
        "fully_imported",
      ].includes(data?.purchaseOrder?.aggrid_status || "") ||
        ([
          "approved",
          "cancelled",
          "partially_imported",
          "fully_imported",
        ].includes(data?.purchaseOrder?.aggrid_status || "") &&
          isEdit)) && <BottomNavbar>{renderButton()}</BottomNavbar>}
    </form>
  );
};

export default DocumentInfoTab;
