import { Box, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";
import { IDefaultForm } from "../../../types/global";
import EmployeeListForm from "../../Form/EmployeeList";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import DocDropzoneUI from "../DocDropzoneUI";
import CustomizedChips from "../../Custom/CustomizedChips";
import { formatDate } from "../../../utils/Formatter/Date";
import { useWatch } from "react-hook-form";
import Confirmation from "../Confirmation";
import ControlledServerSideCreatable from "../../Controller/ControlledSSCreatable";
import { EntityTypeEnum } from "../../../generated/creatable";

type Props = IDefaultForm & {
  taskModal: boolean;
  closeTaskModalHandler: () => void;
  createTaskHandler: () => void;
  editTaskHandler: () => void;
  deleteTaskHandler: (id: string) => void;
};

const TaskModal = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
  taskModal,
  closeTaskModalHandler,
  createTaskHandler,
  editTaskHandler,
  deleteTaskHandler,
}: Props) => {
  const [dueDateIsOpen, setDueDateIsOpen] = useState(false);
  const [deleteTaskConfirmation, setDeleteTaskConfirmation] = useState(false);
  const watchTaskId = useWatch({ control, name: "id" });

  return (
    <ModalUI
      open={taskModal}
      handleClose={closeTaskModalHandler}
      title={watchTaskId ? getValues("name") : "สร้างงาน"}
      maxWidth="sm"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.save")}
            onClick={watchTaskId ? editTaskHandler : createTaskHandler}
            variant="contained"
            size="medium"
            disabled={disabled}
          />
          {watchTaskId && (
            <CustomizedButton
              title={t("button.delete")}
              onClick={() => setDeleteTaskConfirmation(true)}
              variant="outlined"
              size="medium"
              disabled={disabled}
            />
          )}
        </Box>
      }
    >
      <Grid container spacing={2} alignItems="center" mb={3}>
        <Grid item xs={12} sm={3}>
          <Typography fontWeight="bold">
            {t("project.project.related_user_list")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <EmployeeListForm
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            disabled={false}
            // hideCreator
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            required
            control={control}
            name="name"
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            label={t("project.task.name")}
            // disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledDatePicker
            name="due_date"
            control={control}
            error={errors.due_date}
            open={dueDateIsOpen}
            onClose={() => setDueDateIsOpen(false)}
            onOpen={() => setDueDateIsOpen(true)}
            label={t("date.due_date")}
            // disabled={false}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledServerSideCreatable
            title={t("project.task.type")}
            placeholder={t("project.task.type")}
            control={control}
            name="type"
            documentType={EntityTypeEnum.Task}
            defaultOptions={[]}
            setValue={setValue}
            // disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="desc"
            helperText={errors.name?.message}
            label={t("project.task.description")}
            // disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="remark"
            helperText={errors.name?.message}
            label={t("project.project.remark")}
            // disabled={disabled}
          />
        </Grid>
        <Grid item xs>
          <DocDropzoneUI
            isModal
            control={control}
            name="attachment_list"
            setValue={setValue}
            // disabled={disabled}
            maxSize={10}
            multiple
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", columnGap: 2 }}>
        <CustomizedChips
          value={`${t("project.project.created_date")} : ${formatDate(
            getValues("created_date")
          )}`}
        />
        <CustomizedChips
          value={`${t("project.task.updated_date")} : ${formatDate(
            getValues("updated_date")
          )}`}
        />
      </Box>
      <Confirmation
        title="ยืนยันการลบงาน"
        open={deleteTaskConfirmation}
        handleClose={() => setDeleteTaskConfirmation(false)}
        action={() => deleteTaskHandler(watchTaskId)}
      />
    </ModalUI>
  );
};

export default TaskModal;
