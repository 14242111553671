import CustomizedStatus from "../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../Custom/CustomizedTooltip";
import { Box, Grid, Typography } from "@mui/material";
import { Control, useWatch } from "react-hook-form";
import { formatDateTimeNoAMPM } from "../../utils/Formatter/Date";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
type Props = {
  control: Control<any>;
};

const OCRStatus = ({ control }: Props) => {
  const watchOCRLog = useWatch({
    control,
    name: "ocr_log",
  });

  if (!watchOCRLog) return <></>;

  const formatOCRStatus = (ocrStatus: string) => {
    switch (ocrStatus) {
      case "processing":
        return (
          <CustomizedStatus
            status="custom"
            bgColor="#FFF4E5"
            fontColor="#E58000"
            text="กำลังอ่าน"
          />
        );
      case "ReadyToCheck":
        return (
          <CustomizedStatus
            status="custom"
            bgColor="#E6F9F0"
            fontColor="#22915A"
            text="พร้อมใช้งาน"
          />
        );
    }
  };

  const formatHumanCheckStatus = (humanCheckStatus: string) => {
    switch (humanCheckStatus) {
      case "recheck":
        return (
          <>
            <InfoIcon
              sx={{ color: "#ED6C02", fontSize: "1.25rem", mr: "0.25rem" }}
            />
            <Typography color="#ED6C02">{"ยังไม่ได้รับการตรวจสอบ"}</Typography>
          </>
        );
      case "Done":
        return (
          <>
            <CheckCircleIcon
              sx={{ color: "#2167D3", fontSize: "1.25rem", mr: "0.25rem" }}
            />
            <Typography color="#2167D3">{"ตรวจสอบแล้ว"}</Typography>
          </>
        );
    }
  };

  return (
    <CustomizedTooltip
      title={
        <>
          <Box
            sx={{
              width: "360px",
            }}
          >
            <Grid container spacing={1.25} zeroMinWidth>
              <Grid item xs={4}>
                <Typography fontWeight="bold">{"ตรวจสอบ"}</Typography>
              </Grid>
              <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
                {formatHumanCheckStatus(watchOCRLog.ocr_human_check_status)}
              </Grid>
              {/* <Grid item xs={4}>
                <Typography fontWeight="bold">{"วันที่อัปโหลด"}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  {formatDateTimeNoAMPM(watchOCRLog.ocr_uploaded_time)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight="bold">{"วันที่ตรวจสอบ"}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  {formatDateTimeNoAMPM(watchOCRLog.ocr_human_check_time)}
                </Typography>
              </Grid> */}
              {/* <Grid item xs={4}>
                <Typography fontWeight="bold">{"สถานะ"}</Typography>
              </Grid>
              <Grid item xs={4}>
                {formatOCRStatus(watchOCRLog.ocr_status)}
              </Grid> */}
            </Grid>
          </Box>
        </>
      }
      enterNextDelay={200}
      backgroundColor={"#ffffff"}
      padding={".5rem 1rem"}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4px",
            backgroundColor: "#2167D3",
            padding: ".25rem 1rem",
            ml: -2,
          }}
        >
          <DocumentScannerOutlinedIcon
            sx={{ color: "#ffffff", fontSize: "1rem" }}
          />
          <Typography
            sx={{
              color: "#ffffff",
              variant: "button",
              fontSize: ".875rem",
            }}
          >
            {"OCR"}
          </Typography>
        </Box>
      </Box>
    </CustomizedTooltip>
  );
};

export default OCRStatus;
