import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import { SalesReportType } from "../../../generated/sales";
import SalesReportByItemTable from "../../../components/Table/Sales/Report/ByItem";
import { useSalesReport } from "../../../hooks/Sales/use-sales-report";

const SalesReportByItem = () => {
    const { t } = useTranslation();
    const gridRef = useRef<AgGridReact>(null);

    const { onBtnExport } = useSalesReport(
        gridRef,
        SalesReportType.SalesReportByItem
    );

    const breadcrumbs: IBreadcrumbsAndMenu[] = [
        {
            name: t("sales.index"),
            to: "/sales",
        },
        {
            name: t("report"),
            to: "/sales/report",
        },
        {
            name: t("sales.report.by_item"),
        },
    ];

    return (
        <>
            <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
            <HeaderLayout>
                <Typography variant="h5">
                    {t("sales.report.by_item")}
                </Typography>
                <CustomizedButton
                    title={`${t("button.export")}`}
                    variant="contained"
                    onClick={onBtnExport}
                />
            </HeaderLayout>
            <SalesReportByItemTable gridRef={gridRef}/>
        </>
    );
};

export default SalesReportByItem;
