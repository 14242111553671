import { Box, Grid, Typography } from "@mui/material";
import { ChangeEventHandler, FC, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { FileRejection } from "react-dropzone";
import { useSnackbar } from "notistack";
import { FileError } from "react-dropzone";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { IAttachment, IMenuOption } from "../../types/global";
import AttachmentCard from "./AttachmentCard";
import dayjs from "dayjs";
import { useStateContext } from "../../contexts/auth-context";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
  height: 100,
} as const;

const activeStyle = {
  borderColor: "#2196f3",
} as const;

const acceptStyle = {
  borderColor: "#00e676",
} as const;

const rejectStyle = {
  borderColor: "#ff1744",
} as const;

const DocDropzoneUI: FC<{
  control: Control;
  name: string;
  setValue: UseFormSetValue<any>;
  getValues?: UseFormGetValues<any>;
  multiple?: boolean;
  options?: IMenuOption[];
  acceptedFileType?: string;
  maxSize: number;
  maxFileSize?: number;
  disabled?: boolean;
  isModal?: boolean;
}> = ({
  control,
  name,
  getValues,
  setValue,
  multiple,
  options,
  acceptedFileType,
  maxSize,
  maxFileSize,
  disabled,
  isModal,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <Dropzone
          control={control}
          name={name}
          setValue={setValue}
          getValues={getValues}
          multiple={multiple}
          onChange={(e) =>
            onChange(multiple ? e.target.files : e.target.files?.[0] ?? null)
          }
          options={options}
          acceptedFileType={acceptedFileType}
          maxSize={maxSize}
          disabled={disabled}
          maxFileSize={maxFileSize}
          isModal={isModal}
        />
      )}
    />
  );
};

const Dropzone: FC<{
  control: Control;
  name: string;
  getValues?: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  multiple?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  options?: IMenuOption[];
  maxSize?: number;
  maxFileSize?: number;
  acceptedFileType?: string;
  isModal?: boolean;
}> = ({
  control,
  name,
  getValues,
  setValue,
  multiple,
  onChange,
  disabled,
  options,
  acceptedFileType,
  maxSize,
  maxFileSize,
  isModal,
}) => {
  const getAcceptFileType = (
    fileType: string | undefined
  ): {
    "image/*"?: any[];
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"?: any[];
    "application/pdf"?: any[];
  } => {
    switch (fileType) {
      case "image":
        return {
          "image/*": [],
        };
      case "xlsx":
        return {
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
        };
      default:
        return {
          "image/*": [],
          "application/pdf": [],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
        };
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    state: { authUser },
  } = useStateContext();

  const files = useWatch({
    control,
    name,
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: maxSize,
    accept: getAcceptFileType(acceptedFileType),
    maxSize: maxFileSize || 10485760, // 10MB default
    onDrop: (acceptedFiles: File[], fileRejections) => {
      // Handle rejected files
      fileRejections.forEach((file: FileRejection) => {
        file.errors.forEach((err: FileError) => {
          if (err.code === "file-too-large") {
            enqueueSnackbar(
              "ไม่สามารถอัปโหลดได้\nเนื่องจากไฟล์มีขนาดใหญ่เกิน 10MB",
              { variant: "error", style: { whiteSpace: "pre-line" } }
            );
          }
          if (err.code === "too-many-files") {
            enqueueSnackbar(`อัปโหลดได้สูงสุด ${maxSize} ไฟล์`, {
              variant: "error",
            });
          }
        });
      });

      // ✅ Get latest state before updating
      const existingFiles: IAttachment[] = getValues ? getValues(name) : [];

      // ✅ Prevent duplicate files
      const existingFileNames = new Set(
        existingFiles.map((file) => file.attachment_name)
      );

      const uniqueFiles = acceptedFiles.filter(
        (file) => !existingFileNames.has(file.name)
      );

      if (uniqueFiles.length === 0) return; // No new files to add

      // ✅ Convert to `File` type with metadata
      const formattedFiles: IAttachment[] = uniqueFiles.map((file) => {
        if (file instanceof File) {
          return Object.assign(file, {
            attachment_name: file.name,
            uploaded_by: {
              user_unique_id: authUser?.unique_id || "",
              first_name: authUser?.first_name || "",
              last_name: authUser?.last_name || "",
            },
            uploaded_date: dayjs().toDate(),
            url: URL.createObjectURL(file),
            isLoading: true, // Initially set loading state
          });
        } else {
          return file;
        }
      });

      const files = [...existingFiles, ...formattedFiles];

      if (files.length > (maxSize || 0)) {
        enqueueSnackbar(`อัปโหลดได้สูงสุด ${maxSize} ไฟล์`, {
          variant: "error",
        });
        return;
      }

      // ✅ Update form state
      setValue(name, files, {
        shouldDirty: true,
      });

      formattedFiles.forEach((file, index) => {
        setTimeout(() => {
          // ✅ Fetch the latest state dynamically
          const latestFiles = getValues ? getValues(name) : [];

          // ✅ Update only the correct file in the list
          const updatedFiles = latestFiles.map((f: any) =>
            f.attachment_name === file.attachment_name
              ? file instanceof File
                ? Object.assign(
                    new File([file], file.name, { type: file.type }),
                    {
                      ...file,
                      isLoading: false, // Update only the loading state
                    }
                  )
                : { ...f, isLoading: false } // Keep metadata intact for non-File objects
              : f
          );

          // ✅ Set the updated list in React Hook Form
          setValue(name, updatedFiles, { shouldDirty: true });
        }, (index + 1) * 1500);
      });
    },
    disabled,
    multiple,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const removeFile = (file: any) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);

    setValue(name, newFiles, {
      shouldDirty: true,
    });
  };

  return (
    <>
      {!disabled && (
        <Box my={2}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps({ onChange })} />
            <ImageOutlinedIcon color="primary" />
            <Typography color="primary">อัปโหลดไฟล์/รูปภาพ</Typography>
          </div>
        </Box>
      )}
      <Grid container spacing={2}>
        {Array.isArray(files) &&
          files?.map((file: any, index: number) => (
            <Grid item md={isModal ? 6 : 3} key={index}>
              <AttachmentCard
                control={control}
                disabled={disabled}
                file={file}
                index={index}
                removeFile={removeFile}
                name={name}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default DocDropzoneUI;
