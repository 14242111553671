import { FixedSizeList } from "react-window";
import PosItemCard from "./ItemCard";
import AutoSizer from "react-virtualized-auto-sizer";
import { Box } from "@mui/material";
import { useState } from "react";

const PosItemList = () => {
  const [visibleStartIndex, setVisibleStartIndex] = useState<number>(0);

  return (
    <Box sx={{ height: "calc(100vh - 356px)", my: 2 }}>
      <AutoSizer>
        {({ height, width }: any) => (
          <FixedSizeList
            height={height}
            width={width}
            itemSize={74}
            itemCount={20}
            onItemsRendered={({ visibleStartIndex }) =>
              setVisibleStartIndex(visibleStartIndex)
            }
          >
            {(props) => (
              <PosItemCard {...props} visibleStartIndex={visibleStartIndex} />
            )}
          </FixedSizeList>
        )}
      </AutoSizer>
    </Box>
  );
};

export default PosItemList;
