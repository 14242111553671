import { NumericFormat, OnValueChange } from "react-number-format";
// import CustomizedTextField from "../Custom/CustomizedTextField";
import { TextField, TextFieldProps } from "@mui/material";
import { StandardLonghandProperties } from "csstype";

type ExtendedNumberTextFieldProps = {
  label?: TextFieldProps["label"];
  sx?: TextFieldProps["sx"];
  helperText?: TextFieldProps["helperText"];
  onChange?: OnValueChange;
  onKeyDown?: TextFieldProps["onKeyDown"];
  required?: TextFieldProps["required"];
  textAlign?: StandardLonghandProperties["textAlign"];
  InputLabelProps?: TextFieldProps["InputLabelProps"];
  pricePerUnit?: number;
  endAdorment?: JSX.Element;
  helperTextAlign?: StandardLonghandProperties["textAlign"];
  fullWidth?: boolean;
  inputFontSize?: number;
  helperTextFontSize?: number;
  allowNegative?: boolean;
  value: number;
};

const CustomizedNumberTextFieldSalesBom = ({
  label,
  helperText,
  textAlign,
  InputLabelProps,
  helperTextAlign,
  fullWidth,
  endAdorment,
  required,
  inputFontSize,
  helperTextFontSize,
  allowNegative,
  value,
}: ExtendedNumberTextFieldProps) => {
  return (
    <NumericFormat
      fullWidth={fullWidth}
      size="small"
      label={label}
      value={value}
      allowNegative={allowNegative}
      thousandSeparator=","
      decimalScale={2}
      required={required}
      disabled={true}
      customInput={TextField}
      helperText={helperText}
      inputProps={{
        style: { textAlign: textAlign ? textAlign : "right" },
      }}
      InputProps={{
        style: { fontSize: inputFontSize || 16 },
        endAdornment: endAdorment, // Add your adornment here
      }}
      InputLabelProps={InputLabelProps}
      FormHelperTextProps={{
        style: {
          fontSize: helperTextFontSize ? helperTextFontSize : 12,
          textAlign: helperTextAlign ? "right" : "left",
        },
      }}
      placeholder={"0.00"}
      fixedDecimalScale
    />
  );
};

export default CustomizedNumberTextFieldSalesBom;
