import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import { PurchaseReportType } from "../../../generated/purchase";
import PurchaseReportByVendorTable from "../../../components/Table/Purchase/Report/ByVendor";
import { usePurchaseReport } from "../../../hooks/Purchase/use-purchase-report";

const PurchaseReportByVendor = () => {
    const { t } = useTranslation();
    const gridRef = useRef<AgGridReact>(null);

    const { onBtnExport } = usePurchaseReport(
        gridRef,
        PurchaseReportType.PurchaseReportByVendor
    );

    const breadcrumbs: IBreadcrumbsAndMenu[] = [
        {
            name: t("purchase.index"),
            to: "/purchase",
        },
        {
            name: t("report"),
            to: "/purchase/report",
        },
        {
            name: t("purchase.report.by_vendor"),
        },
    ];

    return (
        <>
            <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
            <HeaderLayout>
                <Typography variant="h5">
                    {t("purchase.report.by_vendor")}
                </Typography>
                <CustomizedButton
                    title={`${t("button.export")}`}
                    variant="contained"
                    onClick={onBtnExport}
                />
            </HeaderLayout>
            <PurchaseReportByVendorTable gridRef={gridRef}/>
        </>
    );
};

export default PurchaseReportByVendor;