import {
  Box,
  IconButton,
  SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Draggable } from "@hello-pangea/dnd";
import ItemNameCell from "./ItemNameCell";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { formatNumber } from "../../../../utils/dataTransformer";
import PreVatAmountCell from "./PreVatAmountCell";
import WithholdingTaxCell from "./WithholdingTaxCell";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Controller,
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { ISalesItemList } from "../../../../types/Sales";
import {
  IDefaultForm,
  IHeaderTable,
  IMenuOption,
  ISalesBomItemList,
  ISelectOption,
} from "../../../../types/global";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import { EntityTypeEnum } from "../../../../generated/creatable";
import MenuIcon from "@mui/icons-material/Menu";
import { AdditionalDiscountType } from "../../../../generated/sales";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CustomizedIconMenuButton from "../../../Custom/CustomizedIconMenuOptions";
import SalesBomRow from "./SalesBomRow";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
import { Dispatch, Fragment, SetStateAction } from "react";
import { ItemType } from "../../../../generated/inventory";
import CustomizedNumberTextFieldSalesBom from "../../../Custom/CustomizedNumberTextFieldSalesBom";
import ParentSalesBomPreVatAmountCell from "./ParentSalesBomPreVatAmountCell";
import { useDebouncedCallback } from "../../../../hooks/use-debounced-callback";
import { calculateParentSalesBom } from "../../../../utils/Formatter/Global";
import CustomTableCell from "../../../Custom/CustomizedTableCell";
import { useUomOptions } from "../../../../hooks/use-uom-option";

type FieldArrayType = {
  item_list: ISalesItemList[];
};

type salesBomModal = {
  modal: boolean;
  openModalHandler: () => void;
  closeModalHandler: () => void;
};

type Props = {
  documentType: EntityTypeEnum;
  field: FieldArrayWithId<FieldArrayType, "item_list", "id">;
  index: number;
  control: IDefaultForm["control"];
  getValues: IDefaultForm["getValues"];
  setValue: IDefaultForm["setValue"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
  headers: IHeaderTable[];
  openDeleteItemConfirmationHandler: (index: number) => void;
  openDeleteSalesBomConfirmationHandler: (
    itemIndex: number,
    salesBomIndex: number
  ) => void;
  openItemPriceHistoryModalHandler: (
    index: number,
    item_unique_id: string | undefined,
    salesBomIndex?: number | undefined
  ) => void;
  salesBomModal: salesBomModal;
  setSalesBomIndex: Dispatch<SetStateAction<number | undefined>>;
  haveSalesBom: Boolean;
};

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0",
  },
  {
    label: "7 %",
    value: "7",
  },
];

const additionalDiscountTypeOptions: IMenuOption[] = [
  {
    value: "บาท",
  },
  {
    value: "%",
  },
];

const ItemRow = ({
  documentType,
  field,
  index,
  control,
  disabled,
  errors,
  getValues,
  setValue,
  headers,
  openDeleteItemConfirmationHandler,
  openDeleteSalesBomConfirmationHandler,
  openItemPriceHistoryModalHandler,
  salesBomModal,
  setSalesBomIndex,
  haveSalesBom,
}: Props) => {
  const { t } = useTranslation();

  const { watch } = useFormContext();

  const { update: updateItemList } = useFieldArray({
    control,
    name: `item_list` as `item_list`,
  });

  const currentPricePerUnit = useWatch({
    control,
    name: `item_list.${index}.price_per_unit` as any,
  });

  const isSalesBomType = (type: string | undefined | null) =>
    type === ItemType.Bom;

  const handleDebouncedChange = useDebouncedCallback((index: number) => {
    const itemListValue = getValues(`item_list.${index}`);
    // Handle the value update logic here

    const result = calculateParentSalesBom(itemListValue) as ISalesItemList;

    updateItemList(index, result);
  }, 500);

  const stuckManu =
    field.qty - (field.qty_manufacturing || 0) - (field.qty_manufactured || 0);

  const { uomOptions } = useUomOptions();

  return (
    <Draggable
      key={field.id}
      draggableId={"item-" + field.id}
      index={index}
      isDragDisabled={haveSalesBom ? true : disabled}
    >
      {(provided, snapshot) => (
        <>
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              ...provided.draggableProps.style,
              breakInside: "avoid",
              backgroundColor:
                documentType === "sales_order" &&
                (!currentPricePerUnit || currentPricePerUnit === 0)
                  ? "#F2F2F266"
                  : "default",
            }}
          >
            <CustomTableCell
              width={headers[0]?.width}
              align={headers[0]?.align}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!disabled && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => openDeleteItemConfirmationHandler(index)}
                    sx={{ p: 0 }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
                {!haveSalesBom && !disabled && (
                  <MenuIcon fontSize="small" color="action" />
                )}
                <Typography fontSize={14}>{index + 1}</Typography>
              </Box>
            </CustomTableCell>
            <CustomTableCell
              width={headers[1]?.width}
              align={headers[1]?.align}
            >
              <ItemNameCell
                control={control}
                errors={errors}
                field={field}
                index={index}
                disabled={disabled}
                documentType={documentType}
                salesBom={isSalesBomType(field.item_type)}
              />
            </CustomTableCell>
            <CustomTableCell
              width={headers[2]?.width}
              align={headers[2]?.align}
            >
              {disabled ? (
                <Typography fontSize={14}>{formatNumber(field.qty)}</Typography>
              ) : (
                <ControlledNumberTextField
                  control={control}
                  name={`item_list.${index}.qty`}
                  error={Boolean(
                    errors?.item_list &&
                      errors?.item_list[index] &&
                      errors?.item_list[index]?.qty
                  )}
                  onChange={(v) => {
                    const parseValue = v.floatValue || 0;
                    const currentUomUniqueId = getValues(
                      `item_list.${index}.uom`
                    );
                    if (currentUomUniqueId) {
                      if (field.uom_group) {
                        if (
                          currentUomUniqueId ===
                          field.uom_group.base_uom?.unique_id
                        ) {
                          setValue(`item_list.${index}.stock_qty`, parseValue);
                        } else {
                          if (
                            field.uom_group.uom_conversion_list &&
                            field.uom_group.uom_conversion_list.length > 0
                          ) {
                            const conversionUom =
                              field.uom_group.uom_conversion_list.find(
                                (conversion) =>
                                  conversion.target_uom_unique_id ===
                                  currentUomUniqueId
                              );
                            if (conversionUom) {
                              const targetUomQty =
                                conversionUom.base_uom_rate /
                                conversionUom.target_uom_rate;
                              setValue(
                                `item_list.${index}.stock_qty`,
                                parseValue * targetUomQty
                              );
                            }
                          }
                        }
                      }
                    }
                    if (isSalesBomType(field.item_type))
                      handleDebouncedChange(index);
                  }}
                />
              )}
            </CustomTableCell>
            <CustomTableCell
              width={headers[3]?.width}
              align={headers[3]?.align}
            >
              {disabled ? (
                <Typography fontSize={14}>{field.uom}</Typography>
              ) : !isSalesBomType(field.item_type) ? (
                <ControlledSelect
                  control={control}
                  name={`item_list.${index}.uom`}
                  error={Boolean(
                    errors?.item_list &&
                      errors?.item_list[index] &&
                      errors?.item_list[index]?.uom
                  )}
                  options={
                    field.uom_group
                      ? field.uom_group.convertable_uom_list &&
                        field.uom_group.convertable_uom_list.length > 0
                        ? [
                            {
                              label: field.uom_group?.base_uom?.name || "",
                              value: field.uom_group?.base_uom?.unique_id || "",
                            },
                            ...field.uom_group.convertable_uom_list.map(
                              (uom) => ({
                                label: uom.name,
                                value: uom.unique_id,
                              })
                            ),
                          ]
                        : [
                            {
                              label: field.uom_group?.base_uom?.name || "",
                              value: field.uom_group?.base_uom?.unique_id || "",
                            },
                          ]
                      : [{ label: field.uom || "", value: field.uom || "" }]
                  }
                  onChange={(e: any) => {
                    const currentQty = getValues(`item_list.${index}.qty`);
                    if (field.uom_group) {
                      if (
                        e.target.value === field.uom_group.base_uom?.unique_id
                      ) {
                        setValue(`item_list.${index}.stock_qty`, currentQty);
                      } else {
                        if (
                          field.uom_group.uom_conversion_list &&
                          field.uom_group.uom_conversion_list.length > 0
                        ) {
                          const conversionUom =
                            field.uom_group.uom_conversion_list.find(
                              (conversion) =>
                                conversion.target_uom_unique_id ===
                                e.target.value
                            );
                          if (conversionUom) {
                            const targetUomQty =
                              conversionUom.base_uom_rate /
                              conversionUom.target_uom_rate;
                            setValue(
                              `item_list.${index}.stock_qty`,
                              currentQty * targetUomQty
                            );
                          }
                        }
                      }
                    }
                  }}
                />
              ) : (
                <ControlledSelect
                  control={control}
                  name={`item_list.${index}.uom`}
                  error={Boolean(
                    errors?.item_list &&
                      errors?.item_list[index] &&
                      errors?.item_list[index]?.uom
                  )}
                  options={uomOptions}
                />
              )}
            </CustomTableCell>
            <CustomTableCell
              width={headers[4]?.width}
              align={headers[4]?.align}
            >
              {disabled ? (
                <Box
                  sx={{
                    marginTop: !isSalesBomType(field.item_type)
                      ? "30px"
                      : "none",
                  }}
                >
                  <Typography fontSize={14}>
                    {formatNumber(field.price_per_unit)}
                  </Typography>
                  {!isSalesBomType(field.item_type) && (
                    <IconButton
                      aria-label="view sales item price history"
                      size="small"
                      sx={{ color: "#2167D3" }}
                      onClick={() =>
                        openItemPriceHistoryModalHandler(
                          index,
                          field.item_unique_id
                        )
                      }
                    >
                      <AccessTimeIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              ) : (
                <Box>
                  {isSalesBomType(field.item_type) ? (
                    <CustomizedNumberTextFieldSalesBom
                      value={
                        watch(`item_list.${index}.price_per_unit`) as number
                      }
                    />
                  ) : (
                    <ControlledNumberTextField
                      control={control}
                      name={`item_list.${index}.price_per_unit`}
                      error={Boolean(
                        errors?.item_list &&
                          errors?.item_list[index] &&
                          errors?.item_list[index]?.price_per_unit
                      )}
                      sx={{ marginTop: "30px" }}
                    />
                  )}
                  {!isSalesBomType(field.item_type) && (
                    <IconButton
                      aria-label="view sales item price history"
                      size="small"
                      sx={{ color: "#2167D3" }}
                      onClick={() =>
                        openItemPriceHistoryModalHandler(
                          index,
                          field.item_unique_id
                        )
                      }
                    >
                      <AccessTimeIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              )}
            </CustomTableCell>
            <CustomTableCell
              width={headers[5]?.width}
              align={headers[5]?.align}
            >
              {disabled ? (
                <Typography fontSize={14}>
                  {formatNumber(field.discount)}
                </Typography>
              ) : (
                <ControlledNumberTextField
                  control={control}
                  name={`item_list.${index}.discount`}
                  error={Boolean(
                    errors?.item_list &&
                      errors?.item_list[index] &&
                      errors?.item_list[index]?.discount
                  )}
                  endAdorment={
                    <>
                      <Controller
                        name={`item_list.${index}.discount_type`}
                        control={control}
                        render={({ field }) => (
                          <>
                            {field.value === AdditionalDiscountType.Percent && (
                              <Typography
                                fontSize={14}
                                color="#737373"
                                marginLeft={1}
                              >
                                %
                              </Typography>
                            )}
                          </>
                        )}
                      />
                      <CustomizedIconMenuButton
                        icon={<ArrowDropDownIcon />}
                        options={additionalDiscountTypeOptions}
                        onSelect={(e) => {
                          const target = e.target as HTMLElement;
                          if (!setValue) return;
                          if (e.target) {
                            setValue(
                              `item_list.${index}.discount_type`,
                              target.textContent === "บาท"
                                ? AdditionalDiscountType.Baht
                                : AdditionalDiscountType.Percent
                            );
                          }
                          setValue(`item_list.${index}.discount`, 0);
                          if (isSalesBomType(field.item_type))
                            handleDebouncedChange(index);
                        }}
                        sx={{
                          marginLeft: 1,
                          marginRight: "-14px",
                          backgroundColor: "#F2F2F2",
                          borderLeft: "#E2E2E2 solid 1px",
                          borderRadius: "0px 4px 4px 0",
                          padding: "8px",
                        }}
                        disabled={disabled}
                        disableRipple
                      />
                    </>
                  }
                  onChange={() => {
                    if (isSalesBomType(field.item_type))
                      handleDebouncedChange(index);
                  }}
                />
              )}
            </CustomTableCell>
            <CustomTableCell
              width={headers[6]?.width}
              align={headers[6]?.align}
            >
              {disabled ? (
                <Typography fontSize={14}>{field.vat_percentage}</Typography>
              ) : (
                <ControlledSelect
                  control={control}
                  name={`item_list.${index}.vat_percentage`}
                  error={Boolean(
                    errors?.item_list &&
                      errors?.item_list[index] &&
                      errors?.item_list[index]?.vat_percentage
                  )}
                  options={vatOptions}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    if (isSalesBomType(field.item_type)) {
                      const itemList = getValues(`item_list.${index}`);
                      const newField = itemList.sales_bom_item?.map(
                        (sales_item: ISalesBomItemList) => ({
                          ...sales_item,
                          vat_percentage: e.target.value,
                        })
                      );
                      setValue(`item_list.${index}.sales_bom_item`, newField);
                    }
                  }}
                />
              )}
            </CustomTableCell>
            <CustomTableCell
              width={headers[7]?.width}
              align={headers[7]?.align}
            >
              {isSalesBomType(field.item_type) ? (
                <ParentSalesBomPreVatAmountCell
                  control={control}
                  index={index}
                  field={field}
                  disabled={disabled}
                  setValue={setValue}
                />
              ) : (
                <PreVatAmountCell
                  control={control}
                  index={index}
                  field={field}
                  disabled={disabled}
                  setValue={setValue}
                />
              )}
            </CustomTableCell>
            <CustomTableCell
              width={headers[8]?.width}
              align={headers[8]?.align}
            >
              <WithholdingTaxCell
                control={control}
                errors={errors}
                index={index}
                documentType={documentType}
                disabled={disabled}
                setValue={setValue}
                field={field}
              />
            </CustomTableCell>
            <CustomTableCell
              width={headers[9]?.width}
              align={headers[9]?.align}
            >
              {!isSalesBomType(field.item_type) &&
                (disabled ? (
                  <>
                    <Typography fontSize={14}>
                      {formatNumber(field.stock_qty)}
                    </Typography>
                    <Typography variant="caption">
                      {field.uom_group?.base_uom?.name}
                    </Typography>
                  </>
                ) : (
                  <ControlledNumberTextField
                    sx={{ mt: 3 }}
                    control={control}
                    name={`item_list.${index}.stock_qty`}
                    helperText={field.uom_group?.base_uom?.name}
                    disabled={true}
                    helperTextAlign="right"
                  />
                ))}
            </CustomTableCell>
            <CustomTableCell
              width={headers[10]?.width}
              align={headers[10]?.align}
            >
              {disabled ? (
                <Typography fontSize={14}>{field.remark}</Typography>
              ) : (
                <ControlledTextField
                  control={control}
                  name={`item_list.${index}.remark`}
                  error={Boolean(
                    errors?.item_list &&
                      errors?.item_list[index] &&
                      errors?.item_list[index]?.remark
                  )}
                />
              )}
            </CustomTableCell>
            {documentType === "sales_order" && disabled && (
              <>
                <CustomTableCell
                  width={headers[11]?.width}
                  align={headers[11]?.align}
                >
                  {!isSalesBomType(field.item_type) ? (
                    <>
                      <Typography fontSize={14}>
                        {formatNumber(field.qty_manufactured || 0)}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: (theme) => theme.palette.grey[600],
                        }}
                      >
                        {`ดำเนินการ ${formatNumber(
                          field.qty_manufacturing || 0
                        )}`}
                      </Typography>
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.grey[600],
                          fontSize: 12,
                        }}
                      >
                        {`ค้างผลิต ${formatNumber(
                          stuckManu > 0 ? stuckManu : 0
                        )}`}
                      </Typography>
                    </>
                  ) : null}
                </CustomTableCell>
                <CustomTableCell
                  width={headers[12]?.width}
                  align={headers[12]?.align}
                >
                  {!isSalesBomType(field.item_type) && (
                    <Typography fontSize={14}>
                      {formatNumber(field.qty_issued || 0)}
                    </Typography>
                  )}
                </CustomTableCell>
                {/* <CustomTableCell
                  width={headers[13]?.width}
                  align={headers[13]?.align}
                >
                  <Typography fontSize={14}>
                    {!isSalesBomType(field.item_type) &&
                      (field.qty_to_ship || 0)}
                  </Typography>
                </CustomTableCell> */}
                <CustomTableCell
                  width={headers[13]?.width}
                  align={headers[13]?.align}
                >
                  {!isSalesBomType(field.item_type) && (
                    <Typography fontSize={14}>
                      {formatNumber(field.qty_shipped || 0)}
                    </Typography>
                  )}
                </CustomTableCell>
                {/* <CustomTableCell
                  width={headers[15]?.width}
                  align={headers[15]?.align}
                >
                  <Typography fontSize={13}>
                    {field.qty_returned || 0}
                  </Typography>
                </CustomTableCell> */}
              </>
            )}
          </TableRow>
          {isSalesBomType(field.item_type) && (
            <SalesBomRow
              index={index}
              openDeleteSalesBomConfirmationHandler={
                openDeleteSalesBomConfirmationHandler
              }
              openItemPriceHistoryModalHandler={
                openItemPriceHistoryModalHandler
              }
              disabled={disabled}
              documentType={documentType}
              headers={headers}
            />
          )}
          {!disabled && isSalesBomType(field.item_type) && (
            <TableRow>
              <TableCell
                colSpan={11}
                sx={{
                  backgroundColor: "rgba(249, 249, 249, 1)",
                  borderLeft: "6px solid rgba(226, 226, 226, 1)",
                  padding: "0 0 0 10px !important",
                }}
              >
                <CustomizedButton
                  title={t("sales.bom.add_bom")}
                  onClick={() => {
                    setSalesBomIndex(index);
                    salesBomModal.openModalHandler();
                  }}
                  addStartIcon
                />
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </Draggable>
  );
};

export default ItemRow;
