import { FormControlLabel, TextFieldProps } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { SxProps } from "@mui/material/styles";
import { CustomizedSwitch } from "../Custom/CustomizedSwitch";

type Props = {
  name: string;
  label?: TextFieldProps["label"];
  control: Control<any>;
  disabled?: boolean;
  onChange?: (e: any, field?: any) => void;
  sx?: SxProps;
  checked?: boolean;
};

const ControlledSwitch = ({
  name,
  control,
  label,
  disabled,
  onChange,
  sx,
}: Props) => {
  return (
    <Controller
      key={`${name}-switch`}
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <CustomizedSwitch
              {...field}
              sx={sx}
              onChange={
                onChange ? (e: any) => onChange(e, field) : field.onChange
              }
              checked={Boolean(field.value)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          disabled={disabled}
          label={label}
        />
      )}
    />
  );
};

export default ControlledSwitch;
