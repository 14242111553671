import { gql } from "graphql-request";

export const MANUFACTURE_TAG_CREATE_MANY = gql`
  mutation ManufactureTagCreateMany(
    $createManyInput: ManufactureTagCreateManyInput
  ) {
    manufactureTagCreateMany(createManyInput: $createManyInput) {
      id
      name
      entity
    }
  }
`;
