import { Box, Typography, Divider, Grid } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useSearchParams } from "react-router-dom";

interface Props {
  documentType?: string;
  isInternational?: boolean;
}

const PDFSigner = ({ documentType, isInternational }: Props) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const QRCodeURL = window.location.href.split("/").slice(0, -1).join("/");

  return documentType !== "manufacture_order" ? (
    <Box
      sx={{
        display: "flex",
        gap: "1.5rem",
        flexWrap: "nowrap",
        width: "197.3mm",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          mt: 1.5,
        }}
      >
        <QRCodeSVG
          value={
            documentType === "quotation" ? QRCodeURL : window.location.href
          }
          size={60}
        />
        <Typography sx={{ fontSize: 11, fontWeight: 600, mt: 1 }}>
          {!isInternational ? "สแกนดูเอกสาร" : "Scan Document"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          mt: 5,
        }}
      >
        <Box>
          <Divider
            flexItem
            sx={{
              borderBottomWidth: 1.33,
              borderBottomColor: "#BDBDBD",
              width: 170,
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              mt: "4px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 30,
              }}
            />
            <Typography fontSize={14}>/</Typography>
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 30,
              }}
            />
            <Typography fontSize={14}>/</Typography>
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 40,
              }}
            />
          </Box>
        </Box>
        <Typography sx={{ fontSize: 11, fontWeight: 600, mb: 1 }}>
          {!type &&
            (() => {
              switch (documentType) {
                case "sales_order":
                  return "ผู้สั่งขาย / Seller";
                case "purchase_order":
                  return !isInternational ? "ผู้สั่งซื้อ / Buyer" : "Buyer";
                case "quotation":
                  return "ผู้เสนอราคา / Quoted by";
                case "delivery_order":
                  return "ผู้รับสินค้า / Received by";
                case "goods_issue":
                  return "ผู้จ่าย / Issued by";
                default:
                  return "";
              }
            })()}

          {type && "ผู้ออกเอกสาร / Issued by"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          mt: 5,
        }}
      >
        <Box>
          <Divider
            flexItem
            sx={{
              borderBottomWidth: 1.33,
              borderBottomColor: "#BDBDBD",
              width: 170,
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              mt: "4px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 30,
              }}
            />
            <Typography fontSize={14}>/</Typography>
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 30,
              }}
            />
            <Typography fontSize={14}>/</Typography>
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 40,
              }}
            />
          </Box>
        </Box>
        <Typography sx={{ fontSize: 11, fontWeight: 600, mb: 1 }}>
          {!type &&
            (() => {
              switch (documentType) {
                case "sales_order":
                case "quotation":
                  return "ผู้ตรวจสอบ (ผู้ขาย) / Authorized by (Seller)";
                case "purchase_order":
                  return !isInternational
                    ? "ผู้ตรวจสอบ (ผู้ซื้อ) / Authorized by (Buyer)"
                    : "Approver";
                case "delivery_order":
                  return "ผู้ส่งสินค้า / Sent by";
                case "goods_issue":
                  return "ผู้รับ / Received by";
                default:
                  return "";
              }
            })()}
          {type && "ผู้รับเอกสาร / Received by"}
        </Typography>
      </Box>
      {!type && documentType !== "goods_issue" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            mt: 5,
          }}
        >
          <Box>
            <Divider
              flexItem
              sx={{
                borderBottomWidth: 1.33,
                borderBottomColor: "#BDBDBD",
                width: 170,
              }}
            />
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                mt: "4px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  borderBottomColor: "#BDBDBD",
                  width: 30,
                }}
              />
              <Typography fontSize={14}>/</Typography>
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  borderBottomColor: "#BDBDBD",
                  width: 30,
                }}
              />
              <Typography fontSize={14}>/</Typography>
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 1.33,
                  borderBottomColor: "#BDBDBD",
                  width: 40,
                }}
              />
            </Box>
          </Box>

          <Typography sx={{ fontSize: 11, fontWeight: 600, mb: 1 }}>
            {(() => {
              switch (documentType) {
                case "sales_order":
                case "quotation":
                  return "ลูกค้า / Customer";
                case "purchase_order":
                  return !isInternational ? "ผู้ขาย / Vendor" : "Vendor";
                case "delivery_order":
                  return "ผู้อนุมัติ / Approved by";
                default:
                  return "";
              }
            })()}
          </Typography>
        </Box>
      )}
    </Box>
  ) : (
    <>
      <Grid container my={2}>
        <Grid item xs={5}>
          <Typography
            sx={{
              fontSize: 12,
              lineHeight: "10px",
              letterSpacing: "0.15px",
              fontWeight: 600,
              color: "#333333",
              flex: 1,
              mb: 2,
            }}
          >
            เบิกสินค้า/วัตถุดิบ
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              flexWrap: "nowrap",
              alignItems: "end",
              justifyContent: "center",
            }}
          >
            {["ผู้เบิก", "ผู้ตรวจ/ฝ่ายบัญชี", "ผู้อนุมัติ"].map((signer) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  alignItems: "center",
                  width: "90px",
                }}
              >
                <Typography sx={{ fontSize: 12, fontWeight: 600, mt: 1 }}>
                  {signer}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "end",
                    width: "100%",
                    marginTop: "4px",
                  }}
                >
                  <Divider
                    flexItem
                    sx={{
                      borderBottomWidth: 1.5,
                      borderBottomColor: "#BDBDBD",
                      height: "18px",
                      width: "18px",
                    }}
                  />
                  <Box sx={{ mb: "6px" }}>
                    <Divider
                      sx={{
                        borderBottomWidth: 1.5,
                        borderBottomColor: "#BDBDBD",
                        height: "1px",
                        width: "8px",
                        transform: "rotate(110deg)",
                      }}
                    />
                  </Box>
                  <Divider
                    flexItem
                    sx={{
                      borderBottomWidth: 1.5,
                      borderBottomColor: "#BDBDBD",
                      height: "18px",
                      width: "18px",
                    }}
                  />
                  <Box sx={{ mb: "6px" }}>
                    <Divider
                      sx={{
                        borderBottomWidth: 1.5,
                        borderBottomColor: "#BDBDBD",
                        height: "1px",
                        width: "8px",
                        transform: "rotate(110deg)",
                      }}
                    />
                  </Box>
                  <Divider
                    flexItem
                    sx={{
                      borderBottomWidth: 1.5,
                      borderBottomColor: "#BDBDBD",
                      height: "18px",
                      width: "38px",
                    }}
                  />
                </Box>
                <Divider
                  flexItem
                  sx={{
                    marginTop: 2,
                    borderBottomWidth: 1.5,
                    borderBottomColor: "#BDBDBD",
                    height: "18px",
                  }}
                />
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={0.5} padding="0 15px 0 15px">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={5.75}>
          <Typography
            sx={{
              fontSize: 12,
              lineHeight: "10px",
              letterSpacing: "0.15px",
              fontWeight: 600,
              color: "#333333",
              flex: 1,
              mb: 2,
            }}
          >
            ผลิตสินค้า/วัตถุดิบ
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              flexWrap: "nowrap",
              justifyContent: "center",
              marginLeft: "30px",
            }}
          >
            {["ผู้จ่ายงาน", "ผู้ผลิต", "ผู้ตรวจสอบ", "ผู้อนุมัติ"].map(
              (signer) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    alignItems: "center",
                    width: "90px",
                  }}
                >
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mt: 1 }}>
                    {signer}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "end",
                      width: "100%",
                      marginTop: "4px",
                    }}
                  >
                    <Divider
                      flexItem
                      sx={{
                        borderBottomWidth: 1.5,
                        borderBottomColor: "#BDBDBD",
                        height: "18px",
                        width: "18px",
                      }}
                    />
                    <Box sx={{ mb: "6px" }}>
                      <Divider
                        sx={{
                          borderBottomWidth: 1.5,
                          borderBottomColor: "#BDBDBD",
                          height: "1px",
                          width: "8px",
                          transform: "rotate(110deg)",
                        }}
                      />
                    </Box>
                    <Divider
                      flexItem
                      sx={{
                        borderBottomWidth: 1.5,
                        borderBottomColor: "#BDBDBD",
                        height: "18px",
                        width: "18px",
                      }}
                    />
                    <Box sx={{ mb: "6px" }}>
                      <Divider
                        sx={{
                          borderBottomWidth: 1.5,
                          borderBottomColor: "#BDBDBD",
                          height: "1px",
                          width: "8px",
                          transform: "rotate(110deg)",
                        }}
                      />
                    </Box>
                    <Divider
                      flexItem
                      sx={{
                        borderBottomWidth: 1.5,
                        borderBottomColor: "#BDBDBD",
                        height: "18px",
                        width: "38px",
                      }}
                    />
                  </Box>
                  <Divider
                    flexItem
                    sx={{
                      marginTop: 2,
                      borderBottomWidth: 1.5,
                      borderBottomColor: "#BDBDBD",
                      height: "18px",
                    }}
                  />
                </Box>
              )
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PDFSigner;
