import { useParams, useSearchParams } from "react-router-dom";
import { SalesOrderQuery, useSalesOrderQuery } from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { Box, CircularProgress } from "@mui/material";
import { GraphQLClient } from "graphql-request";
import { formatDate } from "../../../utils/Formatter/Date";
import SOPdfLayout from "../../../components/UI/SalesPdf/SalesOrderPdf/PDFLayout";
import { ReactInstance, useRef } from "react";
import { usePDFMultiplePage } from "../../../hooks/use-pdf-multiple-page";
import { useReactToPrint } from "react-to-print";
import { renderAddressString } from "../Quotation/PDF";
import PDFTable from "../../../components/Table/Pdf/PDFTable";
import { formatTaxId } from "../../../utils/Formatter/Global";
import PDFWrapper from "../../../components/UI/Pdf/PDFWrapper";
import { useForm } from "react-hook-form";

const SalesOrderPDF = () => {
  const { id } = useParams();
  const documentType = "sales_order";
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { data, isLoading } = useSalesOrderQuery<SalesOrderQuery>(
    graphQLClientWithHeaderSales,
    {
      uniqueInput: {
        unique_id: id,
      },
    },
    {
      enabled: !!id,
    }
  );

  const { paginatedItems, startNumbers } = usePDFMultiplePage(
    data?.salesOrder,
    295,
    50,
    790,
    480,
    213,
    237
  );

  const componentRef = useRef<ReactInstance | null>(null);

  const getRightHeader = (type: string) => {
    switch (type) {
      case "sales-invoice":
      case "tax-invoice":
        return ["หมายเลขอ้างอิง", "อ้างอิงถึง"];
      case "receipt":
      case "tax-receipt":
        return ["หมายเลขอ้างอิง", "อ้างอิงถึง", "ประเภทรับชำระ"];

      default:
        return ["หมายเลขอ้างอิง", "อ้างอิงถึง", "ประเภทรับชำระ", "พนักงานขาย"];
    }
  };

  const getRightInfo = (type: string) => {
    switch (type) {
      case "sales-invoice":
      case "tax-invoice":
        return [
          data?.salesOrder?.external_reference_id
            ? data?.salesOrder.external_reference_id
            : "-",
          data?.salesOrder?.reference_unique_id_list &&
          data?.salesOrder?.reference_unique_id_list.length > 0
            ? data?.salesOrder?.reference_unique_id_list.join(", ")
            : "-",
        ];
      case "receipt":
      case "tax-receipt":
        return [
          data?.salesOrder?.external_reference_id
            ? data?.salesOrder.external_reference_id
            : "-",
          data?.salesOrder?.reference_unique_id_list &&
          data?.salesOrder?.reference_unique_id_list.length > 0
            ? data?.salesOrder?.reference_unique_id_list.join(", ")
            : "-",
          data?.salesOrder?.payment_type || "-",
        ];

      default:
        return [
          data?.salesOrder?.external_reference_id
            ? data?.salesOrder.external_reference_id
            : "-",
          data?.salesOrder?.reference_unique_id_list &&
          data?.salesOrder?.reference_unique_id_list.length > 0
            ? data?.salesOrder?.reference_unique_id_list.join(", ")
            : "-",
          data?.salesOrder?.payment_type || "-",
          data?.salesOrder?.sales_contact_list &&
          data?.salesOrder?.sales_contact_list.length > 0
            ? data?.salesOrder?.sales_contact_list
                .map((contact) => `${contact.first_name} ${contact.last_name}`)
                .join(", ")
            : "-",
        ];
    }
  };

  const getHighlightHeader = (type: string) => {
    switch (type) {
      case "sales-invoice":
      case "tax-invoice":
        return ["วันที่ออกเอกสาร", "เลขที่ใบสั่งขาย"];
      case "receipt":
        return ["วันที่ชำระ"];
      case "tax-receipt":
        return ["วันที่ออกเอกสาร", "วันที่ชำระ", "เลขที่ใบสั่งขาย"];

      default:
        return ["วันที่ออกเอกสาร", "ใช้ได้ถึง", "วันกำหนดส่งของ", "เครดิต"];
    }
  };

  const getHighlightInfo = (type: string) => {
    switch (type) {
      case "sales-invoice":
      case "tax-invoice":
        return [
          data?.salesOrder?.tax_invoice_date
            ? formatDate(data?.salesOrder?.tax_invoice_date)
            : "-",
          data?.salesOrder?.unique_id || "",
        ];
      case "receipt":
        return [formatDate(data?.salesOrder?.tax_invoice_date)];
      case "tax-receipt":
        return [
          data?.salesOrder?.tax_invoice_date
            ? formatDate(data?.salesOrder?.tax_invoice_date)
            : "-",
          data?.salesOrder?.issue_date
            ? formatDate(data?.salesOrder?.issue_date)
            : "-",
          data?.salesOrder?.unique_id || "",
        ];

      default:
        return [
          data?.salesOrder?.issue_date
            ? formatDate(data?.salesOrder?.issue_date)
            : "-",
          data?.salesOrder?.due_date
            ? formatDate(data?.salesOrder?.due_date)
            : "-",
          data?.salesOrder?.delivery_date
            ? formatDate(data?.salesOrder?.delivery_date)
            : "-",
          data?.salesOrder?.credit_day
            ? data?.salesOrder?.credit_day + " วัน"
            : "-",
        ];
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:
      (type === "tax-invoice"
        ? data?.salesOrder?.tax_invoice_number
        : data?.salesOrder?.unique_id) || "",
  });

  const leftHeader = [
    "ลูกค้า",
    "ที่อยู่",
    "เลขประจำตัวผู้เสียภาษี",
    "เบอร์โทรศัพท์",
    "โทรสาร",
    "อีเมล",
  ];

  const leftInfo = [
    data?.salesOrder?.customer_contact_unique_id +
      " - " +
      data?.salesOrder?.customer_contact?.name,
    renderAddressString(
      data?.salesOrder?.customer_contact?.billing_address || {}
    ) || "-",
    formatTaxId(
      data?.salesOrder?.customer_contact?.identity_no || "",
      data?.salesOrder?.customer_contact?.branch || ""
    ) || "-",
    data?.salesOrder?.customer_contact?.phone || "-",
    data?.salesOrder?.customer_contact?.fax || "-",
    data?.salesOrder?.customer_contact?.email || "-",
  ];

  const highlightHeader = getHighlightHeader(type || "");

  const highlightInfo: string[] = getHighlightInfo(type || "");

  const rightHeader = getRightHeader(type || "");

  const rightInfo = getRightInfo(type || "");

  const { control, setValue, reset, watch } = useForm({
    defaultValues: {
      id: 0,
      value: undefined,
    },
  });

  const addressValue = watch("value");

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const bgColor = "#E6F9F0";

  return (
    <>
      <PDFWrapper
        control={control}
        setValue={setValue}
        reset={reset}
        documentName="ใบสั่งขาย"
        documentType={documentType}
        documentId={id}
        handlePrint={handlePrint}
      >
        <Box ref={componentRef} sx={{ m: "auto" }}>
          {paginatedItems.map((item, index) => (
            <SOPdfLayout
              documentName="ใบสั่งขาย/Sales Order"
              data={data?.salesOrder}
              documentType={documentType}
              footer={index === paginatedItems.length - 1}
              page={index + 1}
              allPage={paginatedItems.length}
              leftHeader={leftHeader}
              leftInfo={leftInfo}
              highlightHeader={highlightHeader}
              highlightInfo={highlightInfo}
              rightHeader={rightHeader}
              rightInfo={rightInfo}
              bgColor={bgColor}
              value={addressValue}
            >
              <PDFTable
                data={item}
                start_no={startNumbers[index]}
                bgColor={bgColor}
              />
            </SOPdfLayout>
          ))}
        </Box>
      </PDFWrapper>
    </>
  );
};

export default SalesOrderPDF;
