import { Box, Grid, Typography } from "@mui/material";

export interface GanttStatus {
  draft: number;
  pending_manu: number;
  in_progress: number;
  finished: number;
  cancelled: number;
}

interface GanttStatusProps {
  data: GanttStatus;
}

const GanttStatus = ({ data }: GanttStatusProps) => {
  const total =
    data.draft +
    data.pending_manu +
    data.in_progress +
    data.finished +
    data.cancelled;

  return (
    <Grid container spacing={2} alignItems="center">
      {[
        { label: "ทั้งหมด", value: total, bg: "#F2F2F2", color: "#333333" },
        { label: "ร่าง", value: data.draft, bg: "#F9F9F9", color: "#737373" },
        {
          label: "รอผลิต",
          value: data.pending_manu,
          bg: "#FFF4E5",
          color: "#E58000",
        },
        {
          label: "กำลังผลิต",
          value: data.in_progress,
          bg: "#ECF6FD",
          color: "#2167D3",
        },
        {
          label: "เสร็จสิ้น",
          value: data.finished,
          bg: "#E6F9F0",
          color: "#22915A",
        },
      ].map((item, index) => (
        <Grid key={index} item xs={4} md={2.4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "8px 16px",
              background: item.bg,
              borderRadius: "8px",
              height: "100%",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography fontSize={14} fontWeight={600} color={item.color}>
              {item.label}
            </Typography>
            <Typography fontSize={14} fontWeight={400} color={"#333333"}>
              {item.value}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default GanttStatus;
