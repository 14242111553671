import { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";

import HeaderLayout from "../../../components/UI/HeaderLayout";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import SalesBomTable from "../../../components/Table/Inventory/SalesBom";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { ConfigurableAgGridReact } from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGrid";

import { ItemsQuery } from "../../../generated/inventory";

const SalesBom = () => {
  const gridRef = useRef<ConfigurableAgGridReact<ItemsQuery["items"]>>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.sales_bom.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.active"),
      path: `${pathname}`,
    },
    {
      label: t("status.in_active"),
      path: `${pathname}?filter=in_active`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current?.api.setFilterModel({});
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout marginBottom={0}>
        <Typography variant="h5">{t("inventory.sales_bom.index")}</Typography>
        <Box>
          <CustomizedButton
            title={`${t("button.create")}${t("inventory.sales_bom.index")}`}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
        </Box>
      </HeaderLayout>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
        <CustomizedButton
          title={t("button.reset_filter")}
          onClick={onFilterReset}
        />
      </Box>
      <SalesBomTable gridRef={gridRef} isFilter={isFilter} />
    </>
  );
};

export default SalesBom;
