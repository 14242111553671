import { gql } from "graphql-request";

export const GENERATE_PURCHASE_REPORT = gql`
  query GeneratePurchaseReportNoQueue($input: PurchaseReportGenerateInput) {
    GeneratePurchaseReportNoQueue(input: $input)
  }
`;

export const GET_PURCHASE_REPORT_BY_VENDOR_VIEW = gql`
    query PurchaseReportByVendorViewsAggrid($aggridInput: AnyAggridInput) {
        PurchaseReportByVendorViewsAggrid(aggridInput: $aggridInput) {
            count
            results {
                vendor_contact_unique_id
                vendor_contact_name      
                status                   
                document_amount
                pre_vat_amount       
                discount             
                additional_discount  
                post_discount_amount 
                vat_amount           
                net_amount           
                withholding_tax_value
                shipping_cost        
                total_amount         
            }
        }
    }
`;

export const GET_PURCHASE_REPORT_BY_ITEM_VIEW = gql`
    query PurchaseReportByItemViewsAggrid($aggridInput: AnyAggridInput) {
        PurchaseReportByItemViewsAggrid(aggridInput: $aggridInput) {
            count
            results {
                item_unique_id
                item_name             
                status                
                qty                   
                uom                   
                pre_vat_amount        
                discount              
                additional_discount   
                post_discount_amount  
                vat_amount            
                net_amount            
                withholding_tax_value 
                shipping_cost         
                total_amount          
            }
        }
    }
`;