import dayjs from "dayjs";
import { IPosSetting } from "../../../../../types/Sales/posSetting";
import * as Yup from "yup";

export const posSettingSchema: IPosSetting = {
  pos_name: "",
  pos_number: "",
  pin_code: "",
  warehouse_unique_id: "",
  bin_id: undefined,
  default_customer_unique_id: "",
  promptpay_id: "",
  promptpay_description: "",
  created_by_user_unique_id: "",
  created_date: dayjs().toDate(),
  isChangePassword: false,
};

export const posSettingValidation = Yup.object().shape({
  pos_name: Yup.string()
    .max(200, "กรุณาระบุไม่เกิน 200 ตัวอักษร")
    .required("กรุณากรอก"),
  pos_number: Yup.string()
    .max(200, "กรุณาระบุไม่เกิน 200 ตัวอักษร")
    .required("กรุณากรอก"),
  pin_code: Yup.string().when("isChangePassword", {
    is: true,
    then: () => Yup.string().required("กรุณากรอก").length(4),
    otherwise: () => Yup.string().notRequired(),
  }),
  warehouse_unique_id: Yup.string().required("กรุณากรอก"),
  bin_id: Yup.number().required("กรุณากรอก"),
  default_customer_unique_id: Yup.string().required("กรุณากรอก"),
  promptpay_id: Yup.string().max(20, "กรุณาระบุไม่เกิน 20 ตัวอักษร"),
  promptpay_description: Yup.string().max(200, "กรุณาระบุไม่เกิน 200 ตัวอักษร"),
  created_by_user_unique_id: Yup.string().required("กรุณากรอก"),
  isChangePassword: Yup.boolean().default(false),
});
