import { Box, Typography } from "@mui/material";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  ItemSkuDetailByItemUniqueIdQuery,
  useItemSkuDetailByItemUniqueIdQuery,
} from "../../../../generated/inventory";
import { formatNumber } from "../../../../utils/dataTransformer";

type Props = {
  sku_name: string;
};

const ItemSkuChecker = ({ sku_name }: Props) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data } =
    useItemSkuDetailByItemUniqueIdQuery<ItemSkuDetailByItemUniqueIdQuery>(
      graphQLClientWithHeaderItem,
      {
        itemUniqueId: sku_name,
      }
    );

  // const filteredQty =
  //   data &&
  //   data.itemSkuQtys &&
  //   data.itemSkuQtys.map((itemSku) => ({
  //     purchase_ordered_qty: itemSku?.purchase_ordered_qty,
  //     manufacture_ordered_qty: itemSku?.manufacture_ordered_qty,
  //     sale_committed_qty: itemSku?.sale_committed_qty,
  //     manufacture_committed_qty: itemSku?.manufacture_committed_qty,
  //     stock_qty: itemSku?.stock_qty,
  //     available_qty: itemSku?.available_qty,
  //     uom: itemSku?.item_sku_detail?.item_sku?.item?.stock_uom?.name,
  //   }));

  // const calculateSum = (array: any[], property: string) => {
  //   let sum = 0;

  //   array.forEach((element) => {
  //     sum += element[property];
  //   });

  //   return sum;
  // };

  const current_available_qty =
    (data?.itemSkuDetailByItemUniqueId?.stock_qty || 0) -
    (data?.itemSkuDetailByItemUniqueId?.sale_committed_qty || 0) -
    (data?.itemSkuDetailByItemUniqueId?.manufacture_committed_qty || 0) +
    (data?.itemSkuDetailByItemUniqueId?.purchase_ordered_qty || 0) +
    (data?.itemSkuDetailByItemUniqueId?.manufacture_ordered_qty || 0);

  const formatData: any[] = [
    {
      status: "กำลังจัดซื้อ",
      amount: data?.itemSkuDetailByItemUniqueId?.purchase_ordered_qty,
    },
    {
      status: "กำลังผลิต",
      amount: data?.itemSkuDetailByItemUniqueId?.manufacture_ordered_qty,
    },
    {
      status: "ถูกจอง (ขาย)",
      amount: data?.itemSkuDetailByItemUniqueId?.sale_committed_qty,
    },
    {
      status: "ถูกจอง (ผลิต)",
      amount: data?.itemSkuDetailByItemUniqueId?.manufacture_committed_qty,
    },
    {
      status: "คงคลัง",
      amount: data?.itemSkuDetailByItemUniqueId?.stock_qty,
    },
    {
      status: "คงเหลือสุทธิ",
      amount: current_available_qty,
    },
  ];

  const uom =
    data?.itemSkuDetailByItemUniqueId?.item_sku?.item?.stock_uom?.name;

  return (
    <>
      {formatData.map((item) => (
        <Box
          key={item.status}
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 1,
            justifyContent: "space-between",
            width: 200,
          }}
        >
          <Typography fontSize={14}>{item.status}</Typography>
          <Typography sx={{ flex: "1", mr: 1 }} align="right" fontSize={14}>
            {item.amount ? formatNumber(item.amount) : formatNumber(0)}
          </Typography>
          <Typography fontSize={14}>{uom}</Typography>
        </Box>
      ))}
    </>
  );
};

export default ItemSkuChecker;
