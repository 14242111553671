import { gql } from "graphql-request";

export const LOGISTIC_TAG_CREATE_MANY = gql`
  mutation LogisticTagCreateMany(
    $createManyInput: LogisticTagCreateManyInput
  ) {
    logisticTagCreateMany(createManyInput: $createManyInput) {
      id
      name
      entity
    }
  }
`;
