import { useCallback, useEffect, useState } from "react";
import { DraggableLocation, DropResult } from "@hello-pangea/dnd";
import { IProject, IProjectWorkflowTemplate } from "../../types/Project";
import { projectUpdatePayloadFormatter } from "../../utils/Formatter/Project";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { enqueueSnackbar } from "notistack";
import { useProjectUpdateMutation } from "../../generated/project";
import { GraphQLClient } from "graphql-request";

export const useKanbanBoard = (
  allStatus: IProjectWorkflowTemplate[],
  projects: IProject[]
) => {
  const [projectsGroupedByStatus, setProjectsGroupedByStatus] = useState<
    Record<string, IProject[]>
  >({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [dropResult, setDropResult] = useState<DropResult>();
  const [sortingAscending, setSortingAscending] = useState<
    Record<string, boolean>
  >({});

  const graphQLClientWithHeaderProject: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const { mutate: updateProject } = useProjectUpdateMutation<Error>(
    graphQLClientWithHeaderProject,
    {
      onSuccess: () => {
        enqueueSnackbar("เปลี่ยนสถานะโครงการสำเร็จ", {
          variant: "success",
        });
      },
      onError: (err) => {
        enqueueSnackbar("เปลี่ยนสถานะโครงการไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const getMainStatus = (subStatus: string) => {
    return allStatus.find((status) => status.sub_status === subStatus);
  };

  const updateProjectHandler = async (data: IProject, subStatus?: string) => {
    let projectUpdatePayload;
    if (subStatus && subStatus === "cancelled") {
      projectUpdatePayload = await projectUpdatePayloadFormatter(
        data,
        "cancelled"
      );
    } else {
      data.flag_status = [];
      data.sub_status = subStatus;
      data.main_status = getMainStatus(subStatus!)?.status_name!;
      projectUpdatePayload = await projectUpdatePayloadFormatter(data);
    }

    console.log("chage status payload ", projectUpdatePayload);
    updateProject({
      data: projectUpdatePayload,
      where: { unique_id: data.unique_id },
    });
  };

  const changeStatusHandler = (
    source: DraggableLocation,
    destination: DraggableLocation | null | undefined
  ) => {
    if (!destination) {
      return;
    }

    const listCopy = { ...projectsGroupedByStatus };

    // Handle source
    const sourceList = listCopy[source.droppableId];
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      source.index
    );
    const sortedNewSourceList = sortingAscending[source.droppableId]
      ? sortProjectsAscending(newSourceList)
      : sortProjectsDescending(newSourceList);

    listCopy[source.droppableId] = sortedNewSourceList;

    // Handle destination
    const destinationList = listCopy[destination.droppableId];
    const newDestinationList = addToList(
      destinationList,
      destination.index,
      removedElement
    );
    const sortedNewDestinationList = sortingAscending[destination.droppableId]
      ? sortProjectsAscending(newDestinationList)
      : sortProjectsDescending(newDestinationList);

    listCopy[destination.droppableId] = sortedNewDestinationList;

    // console.log(destination);

    updateProjectHandler(removedElement, destination.droppableId);
    setProjectsGroupedByStatus(listCopy);
  };

  const generateProjectRecordByStatus = useCallback(
    (projects: IProject[]): Record<string, IProject[]> => {
      const allStatusWithCancelled = [
        ...allStatus.map((status) => status.sub_status),
        "cancelled",
      ];

      return allStatusWithCancelled.reduce(
        (acc, status) => ({
          ...acc,
          [status!]: projects.filter(
            (project) => project.aggrid_status === status
          ),
        }),
        {}
      );
    },
    [allStatus]
  );

  const removeFromList = (
    list: IProject[],
    index: number
  ): [IProject, IProject[]] => {
    const result = [...list];
    const [removed] = result.splice(index, 1);
    return [removed, result];
  };

  const addToList = (
    list: IProject[],
    index: number,
    element: IProject
  ): IProject[] => {
    const result = [...list];
    result.splice(index, 0, element);
    return result;
  };

  const onDragEnd = async (result: DropResult) => {
    setDropResult(result);
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    //disabled drag if aggrid ststus is cancelled
    // if (source.droppableId === "cancelled") {
    //   return enqueueSnackbar(
    //     "ไม่สามารถเปลี่ยนสถานะได้ เนื่องจากโครงการนี้อยู่ในสถานะยกเลิก",
    //     {
    //       variant: "warning",
    //     }
    //   );
    // }

    if (destination.droppableId !== source.droppableId) {
      setOpenModal(true);
    } else {
      // changeStatusHandler(source, destination);
    }
  };

  const closeModalHandler = () => {
    setOpenModal(false);
  };

  const confirmChangeStatusHandler = (dropResult: DropResult | undefined) => {
    if (dropResult) {
      const { source, destination } = dropResult;
      changeStatusHandler(source, destination);
    }

    setOpenModal(false);
  };

  const sortProjectsAscending = useCallback((projects: IProject[]) => {
    return projects.sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  const sortProjectsDescending = (projects: IProject[]) => {
    return projects.sort((a, b) => b.name.localeCompare(a.name));
  };

  const toggleSorting = (droppableId: string) => {
    setSortingAscending((prev) => {
      const prevSortingState = prev[droppableId];

      setProjectsGroupedByStatus((prev) => {
        const resortedProjects = !prevSortingState
          ? sortProjectsAscending(prev[droppableId])
          : sortProjectsDescending(prev[droppableId]);

        return { ...prev, [droppableId]: resortedProjects };
      });
      return { ...prev, [droppableId]: !prevSortingState };
    });
  };

  const resortingProjects = useCallback(
    (projectGroupedByStatus: Record<string, IProject[]>) => {
      const resortedProjectGroupedByStatus = { ...projectGroupedByStatus };
      for (const [key, value] of Object.entries(
        resortedProjectGroupedByStatus
      )) {
        if (sortingAscending[key]) {
          const newValue = sortProjectsAscending(value);
          resortedProjectGroupedByStatus[key] = newValue;
        } else {
          const newValue = sortProjectsDescending(value);
          resortedProjectGroupedByStatus[key] = newValue;
        }
      }
      return resortedProjectGroupedByStatus;
    },
    [sortProjectsAscending, sortingAscending]
  );

  useEffect(() => {
    const projectRecordGroupedByStatus =
      generateProjectRecordByStatus(projects);
    const sortedProjects = resortingProjects(projectRecordGroupedByStatus);

    setProjectsGroupedByStatus(sortedProjects);
  }, [generateProjectRecordByStatus, projects, resortingProjects]);

  useEffect(() => {
    const sortingAscendingState: Record<string, boolean> = {};
    //set initial sorting state for all workflow templates
    for (const status of allStatus) {
      if (status.sub_status) {
        sortingAscendingState[status?.sub_status] = true;
      }
    }
    // set initial sorting state for cancelled status
    sortingAscendingState["cancelled"] = true;

    setSortingAscending(sortingAscendingState);
  }, [allStatus]);

  return {
    projectsGroupedByStatus,
    onDragEnd,
    openConfirmStatusChangeModal: openModal,
    closeConfirmStatusChangeModalHandler: closeModalHandler,
    confirmChangeStatusHandler,
    dropResult,
    toggleSorting,
    sortingAscending,
  };
};
