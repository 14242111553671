import { AgGridReact } from "ag-grid-react";
import { PurchaseReportType } from "../../generated/purchase";
import { RefObject, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { GENERATE_PURCHASE_REPORT } from "../../services/AgGrid/PurchaseReportAgGrid";

export const usePurchaseReport = (
    gridRef: RefObject<AgGridReact<any>>,
    reportType: PurchaseReportType,
) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)

    const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const {
    state: { authUser },
  } = useStateContext();

  const onBtnExport = async () => {
    setIsLoading(true);
    try {
      const filterModel = gridRef?.current?.api.getFilterModel();
      const sortModel = gridRef?.current?.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));
      const formattedFilterModel = filterModel
        ? {
            ...filterModel,
            reference_document: filterModel.reference_document
              ? {
                  filterType: "array",
                  type: "hasSome",
                  values: filterModel.reference_document.values,
                }
              : undefined,
          }
        : {};
      const params: any = {
        aggridInput: {
          filterModel: formattedFilterModel,
          sortModel,
        },
      };

      const reportInput = {
        report_type: reportType,
        user_unique_id: authUser?.unique_id || "",
        params: params,
      };

      try {
        const { GeneratePurchaseReportNoQueue } =
          await graphQLClientWithHeaderPurchase.request(GENERATE_PURCHASE_REPORT, {
            input: reportInput,
          });
        if (GeneratePurchaseReportNoQueue) {
          window.open(GeneratePurchaseReportNoQueue || "", "_blank");
          enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
            variant: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  return {
    onBtnExport,
    isLoading: isLoading,
  };
};