const GanttStyles = () => (
  <style>
    {`
        div {
          font-family: 'Kanit';
        }
        .today {
          background-color: red;
          width: 2px;
        }
        .gantt_grid_scale {
          background-color: #ECF6FD;
        }
        .gantt_task_scale{
          background-color: #ECF6FD;
        }
        .gantt_grid_scale .gantt_grid_head_cell {
          color: black;
          font-weight: 600;
          text-align: left;
          padding-left: 10px;
        }

        .gantt_grid_scale .gantt_grid_head_cell[data-column-name="text"] {
          color: black;
          font-weight: 600;
          text-align: left;
          padding-left: 17px;
        }

        .gantt_task .gantt_task_scale .gantt_scale_cell{
          color: black;
          font-size: 14px;
          font-weight: 600;
          border-right: 1px solid #E2E2E2;
        }
        .gantt_grid_data .gantt_cell:nth-child(1) {
          border-right: 1px solid #E2E2E2;;
        }
        .gantt_grid_scale .gantt_grid_head_cell:nth-child(1) {
          border-right: 1px solid #E2E2E2;;
        }
          .gantt_grid_data .gantt_cell:nth-child(2) {
          border-right: 1px solid #E2E2E2;;
        }
        .gantt_grid_scale .gantt_grid_head_cell:nth-child(2) {
          border-right: 1px solid #E2E2E2;;
        }
        .gantt_grid_data .gantt_cell, .gantt_grid_scale .gantt_grid_head_cell {
          width: 150px; 
        }
        .gantt_cell[data-column-name="capacity"] .gantt_tree_content {
            padding-left: 5px;
            text-align: left;
        }
        .gantt_cell[data-column-name="unique_id"] .gantt_tree_content {
            padding-left: 5px;
            text-align: left;
            color: #2167D3;
        }
        .gantt_task_line {
            background-color: transparent !important;
        }
        .gantt_task_content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0;
            height: 100%;
            background-color: transparent;
        }
        .gantt_task_cell {
            border-right: 1px solid #E2E2E2;
        }
        .gantt_progress {
            background-color: transparent !important;
        }
        .gantt_bar_task{
          color: white;
          font-size: 20px;
          border: none;
          border-color: transparent;
        }
        .gantt_selected {
            background-color: transparent !important; /* Disable selection background */
        }
        .gantt_grid_data .gantt_row.odd:hover,.gantt_grid_data .gantt_row:hover{
            background-color:unset
        }
        .factory-row {
            background-color: #F2F2F2 !important;
        }
        .gantt_row.factory-row .gantt_cell.gantt_cell_tree[data-column-index="0"] .gantt_tree_content {
            font-weight: 600;
        }
        .gantt_cell.gantt_last_cell .gantt_tree_content{
            cursor: pointer;
        }
        .gantt_row.factory-row.gantt_row_task .gantt_cell.gantt_last_cell .gantt_tree_content{
            cursor: default;
        }
        .factory-task-bar {
            background-color: #D9D9D9 !important; 
        }
        
        .gantt_tree_icon.gantt_open::before{
            display: none;
        }
        .gantt_tree_icon.gantt_close::before{
            display: none;
        }
        .gantt_tree_icon.gantt_open {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/></svg>');
        }      
        .gantt_tree_icon.gantt_close {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/></svg>');
        }
        
      `}
  </style>
);

export default GanttStyles;
