import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "@hello-pangea/dnd";
import { IProject } from "../../../types/Project";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import { formatNumber } from "../../../utils/dataTransformer";
import { useNavigate } from "react-router-dom";

interface Props {
  index: number;
  item: IProject;
}

const DraggableItem = ({ index, item }: Props) => {
  const navigate = useNavigate();

  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(
        providedDraggable: DraggableProvided,
        snapshotDraggable: DraggableStateSnapshot
      ) => (
        <Box
          onClick={() => navigate(`/project/projects/${item.unique_id}`)}
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
          {...providedDraggable.dragHandleProps}
          sx={{
            userSelect: "none",
            background: "#FFFFFF",
            borderRadius: "5px",
            padding: "0 10px 10px 10px",
            mb: "10px",
            display: "flex",
            flexDirection: "column",
            boxShadow: snapshotDraggable.isDragging
              ? "0px 6px 8px 0px #AEAEAE33,  0px 4px 4px 0px #00000040"
              : "none",

            ...providedDraggable.draggableProps.style,
          }}
        >
          <Box my={2}>
            <Grid item>
              <Typography
                fontWeight={600}
                fontSize={20}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                }}
                noWrap
                title={item.name}
              >
                {item.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontSize={12} color={"#ED6C02"}>
                รหัสโครงการ: {item.unique_id}
              </Typography>
            </Grid>
          </Box>
          <Grid container rowGap={1}>
            <Grid container direction={"row"} spacing={1}>
              <Grid item xs={5}>
                <Typography> ชื่อลูกค้า</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  noWrap
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                  title={item.customer_name}
                >
                  {item.customer_name ? item.customer_name : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction={"row"} spacing={1}>
              <Grid item xs={5}>
                <Typography> มูลค่า</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  noWrap
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                  title={
                    item.project_value
                      ? formatNumber(parseFloat(item.project_value))
                      : "-"
                  }
                >
                  {item.project_value
                    ? formatNumber(parseFloat(item.project_value))
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction={"row"} spacing={1} alignItems={"center"}>
              <Grid item xs={5}>
                <Typography> ผู้เกี่ยวข้อง</Typography>
              </Grid>
              <Grid item>
                {item.related_user_list.length > 0 ? (
                  <CustomizedAvatar
                    sx={{ width: 30, height: 30, fontSize: "1rem" }}
                    avatars={item.related_user_list.map((user) => {
                      return {
                        unique_id: user.user_unique_id ?? "",
                        first_name: user.first_name ?? "",
                        last_name: user.last_name ?? "",
                        img_url: user.img_url ? user.img_url[0] : "",
                      };
                    })}
                  />
                ) : (
                  "-"
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Draggable>
  );
};

export default DraggableItem;
