import { gql } from "graphql-request";

export const ITEM_CAN_DISABLE = gql`
  query ItemCanDisable($uniqueInput: ItemWhereUniqueInput!) {
    itemCanDisable(uniqueInput: $uniqueInput) {
      active_document_list
      can_disable
    }
  }
`;

export const ITEM_CAN_DELETE = gql`
  query ItemIsDeletable($uniqueInput: ItemWhereUniqueInput!) {
    itemIsDeletable(uniqueInput: $uniqueInput) {
      active_document_list
      is_deletable
    }
  }
`;
