import * as Yup from "yup";
import { ItemType, Tracability } from "../../../../generated/inventory";

export const itemSchema = {
  unique_id: "",
  name: "",
  desc: "",
  type: null,
  barcode: "",
  seller_sku_name: "",
  img_url: [],
  tag_list: [],
  item_level_1_unique_id: "",
  // is_variation: "false",
  is_purchasable: true,
  is_saleable: true,
  is_stockable: true,
  is_manufactured: true,
  is_active: 1,
  remark_status: "",
  physical_attribute: {
    weight: 0,
    length: 0,
    height: 0,
    width: 0,
    thick: 0,
    weight_uom_unique_id: "",
    height_uom_unique_id: "",
    length_uom_unique_id: "",
    width_uom_unique_id: "",
    thick_uom_unique_id: "",
  },
  package_attribute: {
    weight: 0,
    length: 0,
    height: 0,
    width: 0,
    weight_uom_unique_id: "",
    height_uom_unique_id: "",
    length_uom_unique_id: "",
    width_uom_unique_id: "",
  },
  // volume_uom: "",
  stock_uom_unique_id: "",
  purchase_uom_unique_id: "",
  sales_uom_unique_id: "",
  deliver_uom_unique_id: "",
  tracability: Tracability.Serial,
  item_variant_key_list: [],
  sku_list: [],
  bundle_item_detail_list: [],
  purchase_standard_price: 0,
  purchase_minimum_qty: 0,
  purchase_vat_type: "ไม่มี",
  sale_price: 0,
  special_price: 0,
  sale_vat_type: "ไม่มี",
  manufacturing_type: "",
  // main_bom: null,
  // main_bom_name: "",
  manufacture_minimum_qty: 0,
  bom_detail: { name: "" },
  routing_detail: { name: "" },
  // mrp_default_method: "no_setting",
  // uom_group: {
  //   id: "",
  //   base_uom: "",
  //   convertable_uom_list: [],
  //   name: "",
  //   uom_conversion_list: [],
  // },
  // uom_conversion_list: [],
  // item_group_sub_level_1: { id: "", name: "", sub_level_2_list: [] },
  attribute_list: [],
  variation_list: [],
  variation_img_list: [],
  option_1: { key: "", list: [] },
  option_2: { key: "", list: [] },
  option_3: { key: "", list: [] },
  minimum_stock: 0,
  maximum_stock: 0,
  reorder_point: 0,
};

const positiveNumberValidate = Yup.number()
  .transform((originalValue, originalObject) => {
    // Convert empty values to null to allow .nullable() to work
    return originalValue === "" || isNaN(originalValue) ? null : originalValue;
  })
  .nullable()
  .min(0, "กรุณาระบุมากกว่าหรือเท่ากับ 0")
  .typeError("กรุณาระบุค่าให้ถูกต้อง");

export const itemValidate = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุ"),
  name: Yup.string().required("กรุณาระบุ"),
  type: Yup.string(),
  tracability: Yup.string().required("กรุณาเลือกการติดตาม"),
  barcode: Yup.string().when("tracability", {
    is: (v: any) => v !== "serial",
    then: (schema) =>
      schema.when("type", {
        is: (v: any) => v !== "variant",
        then: (schema) => schema.required("กรุณาระบุ"),
        otherwise: (schema) => schema.notRequired(),
      }),
    otherwise: (schema) => schema.notRequired(),
  }),
  is_stockable: Yup.boolean().required(),
  desc: Yup.string()
    .max(500, "กรอกได้ไม่เกิน 500 ตัวอักษร")
    .transform((value) => (value === null ? "" : value)),
  // physical_attribute: Yup.object().shape({
  //   weight: positiveNumberValidate,
  //   length: positiveNumberValidate,
  //   height: positiveNumberValidate,
  //   width: positiveNumberValidate,
  //   thick: positiveNumberValidate,
  //   weight_uom_unique_id: Yup.string().required("กรุณาระบุ"),
  //   length_uom_unique_id: Yup.string().required("กรุณาระบุ"),
  //   height_uom_unique_id: Yup.string().required("กรุณาระบุ"),
  //   width_uom_unique_id: Yup.string().required("กรุณาระบุ"),
  //   thick_uom_unique_id: Yup.string().required("กรุณาระบุ"),
  // }),
  // package_attribute: Yup.object().shape({
  //   width: positiveNumberValidate,
  //   length: positiveNumberValidate,
  //   height: positiveNumberValidate,
  //   weight: positiveNumberValidate,
  // }),
  stock_uom_unique_id: Yup.string().required("กรุณาเลือก"),
  // purchase_uom_unique_id: Yup.string().when("type", (type) =>
  //   typeof type === "string" && type === ItemType.Service
  //     ? Yup.string()
  //     : Yup.string().required("กรุณาระบุ")
  // ),
  // deliver_uom_unique_id: Yup.string().when("type", (type) =>
  //   typeof type === "string" && type === "service"
  //     ? Yup.string()
  //     : Yup.string().required("กรุณาระบุ")
  // ),
  // sales_uom_unique_id: Yup.string().when("type", (type) =>
  //   typeof type === "string" && type === "service"
  //     ? Yup.string()
  //     : Yup.string().required("กรุณาระบุ")
  // ),
  item_level_1_unique_id: Yup.string().required("กรุณาเลือก"),
  attribute_list: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required("กรุณาระบุ"),
    })
  ),
  bundle_item_detail_list: Yup.array().of(
    Yup.object().shape({
      qty: Yup.number()
        .required("กรุณาระบุจำนวน")
        .min(1, "กรุณาระจำนวนอย่างน้อย 1"),
    })
  ),
  option_1: Yup.object().when("type", {
    is: (v: ItemType) => v === ItemType.Variant,
    then: (schema) =>
      schema.shape({
        key: Yup.string().required("กรุณากรอก"),
        list: Yup.array().min(1),
      }),
    otherwise: (schema) => schema.notRequired(),
  }),
  variation_list: Yup.array().when("tracability", {
    is: (v: any) => {
      return v !== Tracability.Serial;
    },
    then: (schema) =>
      schema.when("variation_list", {
        is: (v: any) => {
          return v && v.length > 0 && v[0].value && v[0].value.length > 0;
        },
        then: (schema) =>
          schema.of(
            Yup.object().shape(
              {
                value: Yup.array().when("value", {
                  is: (v: any) => {
                    return (
                      v && v.length > 0 && v[0].value && v[0].value.length > 0
                    );
                  },
                  then: (schema) =>
                    schema.of(
                      Yup.object().shape({
                        value: Yup.array().of(
                          Yup.object().shape({
                            barcode: Yup.string().required("กรุณาระบุ"),
                          })
                        ),
                      })
                    ),
                  otherwise: (schema) => {
                    console.log("otherwise");
                    return schema.of(
                      Yup.object().shape({
                        barcode: Yup.string().required("กรุณาระบุ"),
                      })
                    );
                  },
                }),
              },
              [["value", "value"]]
            )
          ),
        otherwise: (schema) =>
          schema.of(
            Yup.object().shape({ barcode: Yup.string().required("กรุณาระบุ") })
          ),
      }),
    otherwise: (schema) => schema.notRequired(),
  }),
  sale_price: positiveNumberValidate,
  special_price: positiveNumberValidate,
  sale_vat_type: Yup.string(),
  purchase_standard_price: positiveNumberValidate,
  purchase_minimum_qty: positiveNumberValidate,
  purchase_vat_type: Yup.string(),
});

// schema.of(
//   Yup.object().shape({
//     value: Yup.array().when("value", {
//       is: (v: any) => {
//         console.log("v2", v);
//         return v.value && v.value.length > 0;
//       },
//       then: () =>
//         Yup.array().of(
//           Yup.object().shape({
//             value: Yup.array().when("value", {
//               is: (v: any) => v.value && v.value.length > 0,
//               then: () =>
//                 Yup.array().of(
//                   Yup.object().shape({
//                     value: Yup.array().when("", {
//                       is: (v: any) => v.value && v.value.length > 0,
//                     }),
//                   })
//                 ),
//               otherwise: () =>
//                 Yup.array().of(
//                   Yup.object().shape({
//                     barcode: Yup.string().required("กรุณาระบุ"),
//                   })
//                 ),
//             }),
//           })
//         ),
//       otherwise: () =>
//         Yup.array().of(
//           Yup.object().shape({
//             barcode: Yup.string().required("กรุณาระบุ"),
//           })
//         ),
//     }),
//   })
// ),
