import { v4 as uuidv4 } from "uuid";
import { GraphQLClient } from "graphql-request";
import { EntityTypeEnum } from "../../generated/creatable";
import {
  InventoryDocumentStatus,
  InventoryDocumentType,
  Tracability,
} from "../../generated/inventory";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

import { IUom } from "../../types/Inventory/item";
import { ISalesItemList } from "../../types/Sales";
import { ITraceEntry } from "../../types/Inventory";
import { ISalesBomItemList } from "../../types/global";
import { IPurchaseItemList } from "../../types/Purchase";
import { IDeliveryOrderItemList } from "../../types/Logistic/deliveryOrder";
import {
  AdditionalDiscountType,
  SalesDocumentType,
} from "../../generated/sales";

export const statusValueFormatter = (status: string) => {
  switch (status) {
    case "draft":
      return "ร่าง";
    case "wait_transfer":
      return "รอโอนย้าย";
    case "wait_approve":
      return "รออนุมัติ";
    case "not_approved":
      return "ไม่อนุมัติ";
    case "approved":
      return "อนุมัติแล้ว";
    case "wait_accept":
      return "รอตอบรับ";
    case "accepted":
      return "ตอบรับแล้ว";
    case "wait_deliver":
      return "รอจัดส่ง";
    case "expired":
      return "เกินเวลา";
    case "partially_ordered":
      return "สั่งซื้อแล้วบางส่วน";
    case "fully_ordered":
      return "สั่งซื้อแล้ว";
    case "partially_imported":
      return "นำเข้าแล้วบางส่วน";
    case "fully_imported":
      return "นำเข้าแล้ว";
    case "completed":
      return "สำเร็จ";
    case "not_completed":
      return "ไม่สำเร็จ";
    case "pending_manu":
      return "รอผลิต";
    case "in_progress":
      return "กำลังผลิต";
    case "finished":
      return "เสร็จสิ้น";
    case "cancelled":
      return "ยกเลิก";
    default:
      return status;
  }
};

export const errorMessageFormatter = (error: any, type?: string) => {
  const formatError = JSON.stringify(error);
  if (formatError.includes("Unique constraint failed")) {
    if (
      formatError.includes(
        "Unique constraint failed on the fields: (`unique_id`)"
      )
    ) {
      if (type === "item") return "รหัสสินค้านี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      else if (type === "project")
        return "รหัสโครงการนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      else if (type === "setting")
        return "ไม่สามารถเพิ่มหรือแก้ไขได้เนื่องจากมีข้อมูลอยู่แล้วในระบบ";
      else return "เลขที่เอกสารนี้มีอยู่ในระบบแล้ว";
    } else if (
      formatError.includes("Unique constraint failed on the fields: (`name`)")
    ) {
      if (type === "item") return "ชื่อสินค้านี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      else if (type === "project")
        return "ชื่อโครงการนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      else if (type === "task")
        return "ชื่องานนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      else if (type === "event")
        return "ชื่อเหตุการณ์นี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      else if (type === "setting")
        return "ไม่สามารถเพิ่มหรือแก้ไขได้เนื่องจากมีข้อมูลอยู่แล้วในระบบ";
    } else if (
      formatError.includes(
        "Unique constraint failed on the fields: (`barcode`)"
      )
    ) {
      if (type === "item") return "Barcode นี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      return "ไม่สามารถสร้างสินค้าได้เนื่องจาก Barcode ซ้ำ";
    }
  } else if (formatError.includes("is in use")) {
    return "ไม่สามารถลบได้เนื่องจากถูกใช้งานอยู่";
  }
};

export const errorMessageOnDeleteFormatter = (error: any, type?: string) => {
  const formatError = JSON.stringify(error);
  if (type === "project") {
    if (formatError.includes("PROJ_201")) {
      return "ไม่สามารถลบได้เนื่องจากโครงการนี้ถูกอ้างอิงในเอกสาร QA/SO/SR";
    } else if (formatError.includes("PROJ_202")) {
      return "ไม่สามารถลบได้เนื่องจากโครงการนี้ถูกอ้างอิงในเอกสาร PR/PO/RS";
    } else if (formatError.includes("PROJ_203")) {
      return "ไม่สามารถลบได้เนื่องจากโครงการนี้ถูกอ้างอิงในเอกสาร DO/DT";
    } else if (formatError.includes("PROJ_204")) {
      return "ไม่สามารถลบได้เนื่องจากโครงการนี้ถูกอ้างอิงในเอกสาร MO";
    }
  } else if (type === "event") {
    if (formatError.includes("cannot be deleted as its status")) {
      return "ไม่สามารถลบได้เนื่องจากเหตุการณ์นี้อยู่ในสถานะเสร็จสิ้นหรือยกเลิก";
    }
  }
  return "ไม่สามารถลบได้";
};

export const itemListToTraceEntryListFormatter = (
  item_list: (ISalesItemList | IPurchaseItemList)[],
  type: InventoryDocumentType,
  documentType: EntityTypeEnum
) => {
  const formatTraceEntry: ITraceEntry[] = item_list
    .filter((list) => list.is_stockable)
    .map((item) => {
      let formatUom: IUom = {
        unique_id: item.uom || "",
        name: item.uom || "",
      };

      let formatPostedQty: number | undefined;

      if (
        documentType === EntityTypeEnum.SalesOrder ||
        documentType === EntityTypeEnum.PurchaseReturn
      ) {
        formatPostedQty = item.qty_issued;
      } else if (
        documentType === EntityTypeEnum.PurchaseOrder ||
        documentType === EntityTypeEnum.SalesReturn
      ) {
        formatPostedQty = item.qty_received;
      }

      const reference_line_item = {
        line_item_unique_id: item.unique_id,
        line_item_document_type: documentType,
        line_item_document_unique_id: item.reference_unique_id || undefined,
      };

      return {
        item_unique_id: item.item_unique_id,
        item_name: item.item_name,
        item_img_url: item.item_img_url,
        tracability: item.tracability,
        type: type,
        unique_id: uuidv4(),
        document_item_qty: item.qty,
        // reference_unique_id: item.unique_id,
        reference_line_item: reference_line_item ?? undefined,
        posted_qty: formatPostedQty,
        qty:
          type === InventoryDocumentType.GoodsReceive
            ? item.qty - (formatPostedQty || 0)
            : 0,
        uom: formatUom,
        serial_list: item.tracability === Tracability.Serial ? [] : undefined,
        status:
          item.tracability === Tracability.Normal
            ? InventoryDocumentStatus.IsActive
            : undefined,
        barcode: item.barcode || undefined,
      };
    });

  return formatTraceEntry;
};

export const itemListFormatter = async (
  item_list: (ISalesItemList | IPurchaseItemList)[]
) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const allItemListUniqueId = item_list.map((item) => item.item_unique_id);
  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: allItemListUniqueId.length,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });
  const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: (ISalesItemList | IPurchaseItemList)[] = [];

  item_list.forEach((item) => {
    const foundItemIndex = itemSkuDetails.findIndex(
      (realItem: any) => realItem.sku_name === item.item_unique_id
    );

    formatItemList.push({
      ...item,
      uom_group: itemSkuDetails[foundItemIndex]?.item_sku.item.uom_group,
      item_sku_qty: {
        stock_qty: itemSkuDetails[foundItemIndex]?.stock_qty,
        available_qty: itemSkuDetails[foundItemIndex]?.available_qty,
        purchase_ordered_qty:
          itemSkuDetails[foundItemIndex]?.purchase_ordered_qty,
        manufacture_ordered_qty:
          itemSkuDetails[foundItemIndex]?.manufacture_ordered_qty,
        sale_committed_qty: itemSkuDetails[foundItemIndex]?.sale_committed_qty,
        manufacture_committed_qty:
          itemSkuDetails[foundItemIndex]?.manufacture_committed_qty,
      },
      tracability: itemSkuDetails[foundItemIndex]?.item_sku.item.tracability,
    });
  });

  return formatItemList;
};

export const characterRegExp: RegExp = /^[\u0E00-\u0E7Fa-zA-Z\d-]+$/;

export const formatContactName = (
  contact_type: string,
  contact_type_detail: string,
  title_name: string,
  main_contact_name: string,
  secondary_contact_name: string
) => {
  if (contact_type === "นิติบุคคล") {
    switch (contact_type_detail) {
      case "บริษัท":
        return `บริษัท ${main_contact_name} จำกัด`;
      case "บริษัทมหาชนจำกัด":
        return `บริษัท ${main_contact_name} จำกัด (มหาชน)`;
      case "ห้างหุ้นส่วนจำกัด":
        return `ห้างหุ้นส่วนจำกัด ${main_contact_name}`;
      case "ห้างหุ้นส่วนสามัญนิติบุคคล":
        return `ห้างหุ้นส่วนสามัญนิติบุคคล ${main_contact_name}`;
      case "สมาคม":
        return `สมาคม ${main_contact_name}`;
      default:
        return main_contact_name;
    }
  } else if (contact_type === "บุคคลธรรมดา") {
    switch (contact_type_detail) {
      case "บุคคลธรรมดา":
        return `${title_name ?? ""}${main_contact_name}${
          secondary_contact_name ? ` ${secondary_contact_name}` : ""
        }`;
      case "ห้างหุ้นส่วนสามัญ":
        return main_contact_name;
      case "ร้านค้า":
        return `ร้าน ${main_contact_name}`;
      case "คณะบุคคล":
        return `คณะบุคคล ${main_contact_name}`;
      default:
        return main_contact_name;
    }
  }
  return main_contact_name;
};

export const formatTaxId = (taxId: string, branchName: string) => {
  if (taxId) {
    switch (branchName) {
      case "สำนักงานใหญ่":
        return `${taxId} (สำนักงานใหญ่)`;
      case "ไม่ระบุ":
        return taxId;
      default:
        return `${taxId} (${branchName})`;
    }
  } else {
    return "-";
  }
};

export const salesOrderPdfTypeThaiToEng: Record<string, string> = {
  "ใบเสร็จรับเงิน/ใบกำกับภาษี": "tax-receipt",
  "ใบแจ้งหนี้/ใบกำกับภาษี": "sales-invoice",
  ใบกำกับภาษี: "tax-invoice",
  ใบเสร็จรับเงิน: "receipt",
};

export const salesOrderPdfTypeEngToThai: Record<string, string> = {
  "tax-receipt": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
  "sales-invoice": "ใบแจ้งหนี้/ใบกำกับภาษี",
  "tax-invoice": "ใบกำกับภาษี",
  receipt: "ใบเสร็จรับเงิน",
};

export const salesOrderPdfTypeEngToEng: Record<string, string> = {
  "tax-receipt": "Receipt / Tax Invoice",
  "sales-invoice": "Sales Invoice / Tax Invoice",
  "tax-invoice": "Tax Invoice",
  receipt: "Receipt",
};

export const currencyOptions = [
  { id: 1, value: "THB", label: "THB - บาท" },
  { id: 2, value: "USD", label: "USD - ดอลลาร์สหรัฐ" },
  { id: 3, value: "EUR", label: "EUR - ยูโร" },
  { id: 4, value: "CNY", label: "CNY - หยวน" },
  { id: 5, value: "JPY", label: "JPY - เยน" },
  { id: 6, value: "GBP", label: "GBP - ปอนด์สเตอร์ลิง" },
  { id: 7, value: "SGD", label: "SGD - ดอลลาร์สิงคโปร์" },
  { id: 8, value: "AUD", label: "AUD - ดอลลาร์ออสเตรเลีย" },
  { id: 9, value: "KRW", label: "KRW - วอนเกาหลีใต้" },
  { id: 10, value: "INR", label: "INR - รูปี" },
  { id: 11, value: "HKD", label: "HKD - ดอลลาร์ฮ่องกง" },
];

export const sortDataByUniqueIdAndName = (data: any) => {
  if (Array.isArray(data)) {
    // Sort by 'unique_id' if available, otherwise by 'name'
    data = data.sort((a, b) => {
      if (a.unique_id && b.unique_id) {
        return a.unique_id.localeCompare(b.unique_id); // Sort top-level by 'unique_id'
      } else if (a.name && b.name) {
        return a.name.localeCompare(b.name); // Sort nested levels by 'name'
      }
      return 0;
    });

    // Recursively sort each level
    data.forEach((item: any) => {
      if (item.warehouse_level_1_list) {
        item.warehouse_level_1_list = sortDataByUniqueIdAndName(
          item.warehouse_level_1_list
        );
      }
      if (item.warehouse_level_2_list) {
        item.warehouse_level_2_list = sortDataByUniqueIdAndName(
          item.warehouse_level_2_list
        );
      }
      if (item.warehouse_level_3_list) {
        item.warehouse_level_3_list = sortDataByUniqueIdAndName(
          item.warehouse_level_3_list
        );
      }
    });
  }
  return data;
};

type FormattedItem =
  | IPurchaseItemList
  | ISalesItemList
  | IDeliveryOrderItemList;

export const formatPayloadSalesBomItem = (itemList: FormattedItem[]) => {
  if (itemList && itemList.length > 0) {
    const formattedItemList = itemList.reduce<FormattedItem[]>((arr, list) => {
      const { sales_bom_item, ...otherList } = list;
      arr.push({ ...otherList, is_child: false });
      if (sales_bom_item && sales_bom_item.length > 0) {
        sales_bom_item.forEach(
          ({ uom_group, item_sku_qty, ...sales_bom_item }) => {
            arr.push({
              ...sales_bom_item,
              is_child: true,
              parent_unique_id: list.unique_id,
            });
          }
        );
      }
      return arr;
    }, []);
    return formattedItemList;
  } else return itemList || [];
};

export const formatQuerySalesBomItemList = (itemList: FormattedItem[]) => {
  if (itemList && itemList.length > 0) {
    const formattedItemList: FormattedItem[] = itemList
      .filter((list) => !list.is_child)
      .map((list) => ({
        ...list,
        sales_bom_item: itemList.filter(
          (second_list: FormattedItem) =>
            second_list.is_child &&
            second_list.parent_unique_id === list.unique_id
        ),
      }));

    return formattedItemList;
  } else return itemList || [];
};

const hasPricePerUnit = (item: ISalesBomItemList): item is ISalesBomItemList =>
  "price_per_unit" in item && typeof item.price_per_unit === "number";

export const calculateParentSalesBom = (itemList: FormattedItem) => {
  if (
    !Array.isArray(itemList.sales_bom_item) ||
    itemList.sales_bom_item.length === 0
  )
    return itemList;

  const filteredBomItems = itemList.sales_bom_item.filter(hasPricePerUnit);

  const sumOfPricePerUnit = filteredBomItems.reduce<number>(
    (res, sales_bom) => res + sales_bom.price_per_unit * (sales_bom.qty || 1),
    0
  );

  const dicountAmount =
    itemList.discount_type !== AdditionalDiscountType.Baht
      ? (sumOfPricePerUnit * itemList.discount) / 100
      : itemList.discount;

  return {
    ...itemList,
    price_per_unit: sumOfPricePerUnit / (itemList.qty || 1) + dicountAmount,
  };
};

export const formattedSalesBomFormSalesOrder = (
  itemList: any[],
  inventory: boolean = false
) => {
  return itemList
    .flatMap((item) =>
      item.sales_bom_item?.length ? item.sales_bom_item : item
    )
    .map(
      ({
        reference_document_type,
        reference_unique_id,
        qty_invoiced,
        qty_manufactured,
        qty_manufacturing,
        weight,
        volume,
        so_qty,
        unique_id,
        qty_shipped,
        qty_to_ship,
        sales_bom_item,
        parent_unique_id,
        is_child,
        reference_line_item,
        ...otherValue
      }) => ({
        ...otherValue,
        reference_line_item: reference_line_item ?? {
          reference_document_type: SalesDocumentType.SalesOrder,
          reference_unique_id: unique_id,
        },
        unique_id: inventory ? unique_id : undefined,
        reference_unique_id: inventory ? reference_unique_id : undefined,
      })
    );
};

export const formattedSalesBomToDeliveryOrderFormSalesOrder = (
  itemList: any[]
) => {
  return itemList
    .flatMap((item) =>
      item.sales_bom_item?.length ? item.sales_bom_item : item
    )
    .map(
      ({
        reference_document_type,
        reference_unique_id,
        qty_invoiced,
        qty_ordered,
        qty_issued,
        qty_shipped,
        qty_to_ship,
        qty_returned,
        qty_manufactured,
        qty_manufacturing,
        so_qty,
        barcode,
        item_img_url,
        tracability,
        item_type,
        is_child,
        sales_bom_item,
        parent_unique_id,
        is_manufactured,
        is_purchasable,
        is_saleable,
        is_stockable,
        reference_line_item,
        ...otherValue
      }) => ({
        ...otherValue,
        unique_id: uuidv4(),
        reference_line_item: reference_line_item ?? {
          reference_document_type: SalesDocumentType.SalesOrder,
          reference_unique_id: otherValue.unique_id,
        },
      })
    );
};

export const formattedSalesBomToSalesReturnFormSalesOrder = (
  itemList: any[]
): ISalesItemList[] => {
  return itemList
    .flatMap((item) =>
      item.sales_bom_item?.length ? item.sales_bom_item : item
    )
    .map(
      ({
        reference_document_type,
        reference_unique_id,
        unique_id,
        sales_bom_item,
        parent_unique_id,
        is_child,
        reference_line_item,
        ...otherValue
      }) => ({
        ...otherValue,
      })
    );
};

export const formatVatType = (value: string) => {
  let vat_type = value || "";
  switch (value) {
    case "0 %":
    case "0":
      vat_type = "0";
      break;
    case "7 %":
    case "7":
      vat_type = "7";
      break;
    default:
      vat_type = "ไม่มี";
  }
  return vat_type;
};

export const graphglRequestPayload = async (
  type: string,
  document: string,
  variable: any
) => {
  const graphQLClient = createGraphQLClientWithMiddleware(type);

  return await graphQLClient.request(document, variable);
};
