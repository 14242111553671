import { gql } from "graphql-request";

export const GENERATE_SALES_REPORT = gql`
  query GenerateSalesReportNoQueue($input: SalesReportGenerateInput) {
    GenerateSalesReportNoQueue(input: $input)
  }
`;

export const GET_SALES_REPORT_BY_CUSTOMER_VIEW = gql`
    query SalesReportByCustomerViewsAggrid($aggridInput: AnyAggridInput) {
        SalesReportByCustomerViewsAggrid(aggridInput: $aggridInput) {
            count
            results {
                customer_contact_unique_id
                name
                status
                document_amount
                pre_vat_amount
                discount
                additional_discount
                post_discount_amount
                vat_amount
                net_amount
                withholding_tax_value
                shipping_cost
                total_amount
            }
        }
    }
`;

export const GET_SALES_REPORT_BY_ITEM_VIEW = gql`
    query SalesReportByItemViewsAggrid($aggridInput: AnyAggridInput) {
        SalesReportByItemViewsAggrid(aggridInput: $aggridInput) {
            count
            results {
              item_unique_id
              item_name
              status
              qty
              uom
              pre_vat_amount
              discount
              additional_discount
              post_discount_amount
              vat_amount
              net_amount
              withholding_tax_value
              shipping_cost
              total_amount
            }
        }
    }
`;

