import {
  ColDef,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { TFunction } from "i18next";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { formatNumber } from "../../../../utils/dataTransformer";
import { IOutputTax } from "../../../../types/Sales/OutputTax";
import { formatDate } from "../../../../utils/Formatter/Date";
import { useEffect, useState } from "react";

export const useOutputTaxColumnDefs = (t: TFunction) => {
  const [columnDef, setColumnDef] = useState<ColDef<IOutputTax>[]>();

  useEffect(() => {
    {
      setColumnDef([
        { // วันที่ใบกำกับภาษี
          field: "tax_invoice_date",
          headerName: t("sales.tax_invoice_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          minWidth: 200,
          flex: 1,
          // valueFormatter: (params: ValueFormatterParams) =>
          //   formatDate(params.value),
          valueGetter: (params: ValueGetterParams) =>
            params.data?.tax_invoice_date ? formatDate(new Date(params.data.tax_invoice_date)) : null,
        },
        { // เลขที่ใบกำกับภาษี
          field: "tax_invoice_number",
          headerName: t("sales.tax_invoice_number"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        { // ชื่อผู้ขาย
          field: "customer_name",
          headerName: t("sales.customer_name"),
          sortable: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        { // เลขประจำตัวผู้เสียภาษี
          field: "customer_id",
          headerName: t("sales.tax.customer_id"),
          sortable: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        { // สาขา
          field: "branch",
          headerName: t("sales.branch"), // change this
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        { // มูลค่าก่อนภาษี
          field: "post_additional_discount_amount",
          headerName: t("sales.tax.post_additional_discount_amount"), // change this
          filter: false,
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.post_additional_discount_amount),
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        { // ภาษีมูลค่าเพิ่ม
          field: "vat_amount",
          headerName: t("sales.tax.vat_amount"), // change this
          filter: false,
          minWidth: 200,
          flex: 1,
          valueGetter: (params: ValueGetterParams) =>
            formatNumber(params.data.vat_amount),
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
      ]);
    }
  }, [t]);
  return columnDef;
};
