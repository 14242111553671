import { useSnackbar } from "notistack";
import { useEffect } from "react";

export const useManufactureError = (errors: any) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    try {
      if (Object.keys(errors).length > 0) {
        const {
          unique_id,
          customer_name,
          item_unique_id,
          production_qty,
          bom_name,
          ingredient_list,
          work_order_list,
        } = errors;
        if (unique_id) {
          enqueueSnackbar(unique_id.message, {
            variant: "error",
          });
        }
        if (customer_name) {
          enqueueSnackbar(customer_name.message, {
            variant: "error",
          });
        }
        if (item_unique_id) {
          enqueueSnackbar(item_unique_id.message, {
            variant: "error",
          });
        }
        if (production_qty) {
          enqueueSnackbar(production_qty.message, {
            variant: "error",
          });
        }
        if (bom_name) {
          enqueueSnackbar(bom_name.message, {
            variant: "error",
          });
        }
        if (ingredient_list) {
          if (ingredient_list.message) {
            enqueueSnackbar(ingredient_list?.message, {
              variant: "error",
            });
          } else {
            enqueueSnackbar(
              ingredient_list?.filter(Boolean)?.[0]?.qty?.message,
              {
                variant: "error",
              }
            );
          }
        }
        if (Array.isArray(work_order_list)) {
          if (work_order_list.some((list) => list.production_center?.message)) {
            enqueueSnackbar("กรุณาระบุศูนย์การผลิต", {
              variant: "error",
            });
          }
        }
      }
    } catch (err) {
      enqueueSnackbar("เกิดความผิดพลาด", { variant: "error" });
    }
  }, [enqueueSnackbar, errors]);
  return;
};
