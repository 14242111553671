import { ItemType } from "../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import {
  ITEM_CAN_DELETE,
  ITEM_CAN_DISABLE,
} from "../../services/Inventory/Item/ItemCanDisableQuery";
import { IItem } from "../../types/Inventory/item";
import { uploadFileToS3 } from "../s3";
import { graphglRequestPayload } from "./Global";

export const salesBomQueryFormatter = (data: any) => {
  const { item_sku, seller_sku_name } = data;

  const { item } = item_sku;

  const {
    stock_uom,
    purchase_uom,
    sales_uom,
    deliver_uom,
    item_variant_key_list,
    item_variant_value_list,
    item_level_1,
    item_level_2,
    item_level_3,
    physical_attribute,
    package_attribute,
    sku_list,
    ...otherData
  } = item;

  return { ...otherData, seller_sku_name };
};

export const salesBomCreatePayloadFormatter = async (data: IItem) => {
  const {
    id,
    unique_id,
    tag_list,
    option_1,
    option_2,
    option_3,
    bom_detail,
    created_date,
    routing_detail,
    variation_list,
    variation_img_list,
    ...otherData
  } = data;

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];

  let current_img: string[] =
    data.img_url?.filter((img) => typeof img === "string") || [];

  if (data.img_url && data.img_url.length > 0) {
    const filteredFile = data.img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      for (const file of filteredFile) {
        const { Location } = await uploadFileToS3(
          file,
          "item",
          data?.unique_id || ""
        );
        current_img.push(Location);
      }
    } else {
      current_img = data.img_url;
    }
  }

  return {
    ...otherData,
    unique_id: unique_id.trim(),
    barcode: unique_id.trim(),
    type: ItemType.Bom,
    tag_list: formatTagList,
    uom_group_unique_id: data.unique_id,
    uom_group: {
      unique_id: data.unique_id,
      name: data.unique_id,
      uom_conversion_list: [],
    },
    img_url: current_img,
    is_active: [1, "1"].includes(data.is_active) ? 1 : 0,
  };
};

export const salesBomUpdatePayloadFormatter = async (data: IItem) => {
  const {
    id,
    tag_list,
    option_1,
    option_2,
    option_3,
    bom_detail,
    created_date,
    uom_group,
    physical_attribute,
    package_attribute,
    // updated_date,
    routing_detail,
    variation_list,
    variation_img_list,
    stock_uom,
    purchase_uom,
    sales_uom,
    deliver_uom,
    item_variant_key_list,
    item_variant_value_list,
    item_level_1,
    item_level_2,
    item_level_3,
    ...otherData
  } = data;

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];

  let current_img: string[] =
    data.img_url?.filter((img) => typeof img === "string") || [];

  if (data.img_url && data.img_url.length > 0) {
    const filteredFile = data.img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      for (const file of filteredFile) {
        const { Location } = await uploadFileToS3(
          file,
          "item",
          data?.unique_id || ""
        );
        current_img.push(Location);
      }
    } else {
      current_img = data.img_url;
    }
  }

  return {
    ...otherData,
    tag_list: formatTagList,
    img_url: current_img,
    is_active: [1, "1"].includes(data.is_active) ? 1 : 0,
  };
};

export const salesBomCanDisable = async (unique_id: string) => {
  const result = await graphglRequestPayload("item", ITEM_CAN_DISABLE, {
    uniqueInput: { unique_id },
  });

  return result?.itemCanDisable?.can_disable;
};

export const salesBomCanDelete = async (unique_id: string) => {
  const result = await graphglRequestPayload("item", ITEM_CAN_DELETE, {
    uniqueInput: { unique_id },
  });

  return result?.itemIsDeletable?.is_deletable;
};
