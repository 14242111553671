import { ColDef, IServerSideGetRowsParams, GridReadyEvent, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { GraphQLClient } from "graphql-request";
import { RefObject, useCallback } from "react";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import AgGrid from "../../../UI/AgGrid";
import { GET_PURCHASE_REPORT_BY_ITEM_VIEW } from "../../../../services/AgGrid/PurchaseReportAgGrid";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { formatNumber } from "../../../../utils/dataTransformer";
import { GET_ALL_UOM } from "../../../../services/AgGrid/InventoryAgGrid";
import { IUom } from "../../../../types/Inventory/item";

interface Props {
    gridRef: RefObject<AgGridReact>;
}

const PurchaseReportByItemTable = ({ gridRef }: Props) => {
    const columnDef: ColDef[] = [
        {
            field: "item_unique_id",
            headerName: "รหัสสินค้า",
            filter: "agTextColumnFilter",
            minWidth: 150,
        },
        {
            field: "item_name",
            headerName: "ชื่อสินค้า",
            filter: "agTextColumnFilter",
            minWidth: 150,
        },
        {
            field: "status",
            headerName: "สถานะเอกสาร",
            filter: "agSetColumnFilter",
            cellRenderer: (params: ValueFormatterParams) => (
                <CustomizedStatus status={params.value} />
            ),
            cellStyle: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
            filterParams: {
                values: [
                    "draft",
                    "wait_approve",
                    "not_approved",
                    "approved",
                    "partially_imported",
                    "fully_imported",
                    "cancelled",
                ],
                valueFormatter: (params: ValueFormatterParams) =>
                    statusValueFormatter(params.value),
            },
            headerClass: "ag-center-header",
            minWidth: 150,
        },
        {
            field: "qty",
            headerName: "จำนวน",
            filter: "agNumberColumnFilter",
            minWidth: 150,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: "uom",
            headerName: "หน่วย",
            filter: "agSetColumnFilter",
            filterParams: {
                values: async (params: any) => {
                    const graphQLClientWithHeaderItem: GraphQLClient =
                        createGraphQLClientWithMiddleware("item");
                    const { uoms } = await graphQLClientWithHeaderItem.request(
                        GET_ALL_UOM
                    );
                    const uomsName = uoms.map((uom: IUom) => uom.name);
                    params.success(uomsName);
                },
            },
            minWidth: 150,
        },
        {
            field: "pre_vat_amount",
            headerName: "มูลค่าก่อนภาษี (บาท)",
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),

        },
        {
            field: "discount",
            headerName: "ส่วนลด (บาท)",
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: "additional_discount",
            headerName: "ส่วนลดเพิ่มเติมก่อนภาษี (บาท)",
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: "post_discount_amount",
            headerName: "มูลค่าหลังส่วนลดก่อนภาษี (บาท)",
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: "vat_amount",
            headerName: "ภาษีมูลค่าเพิ่ม (บาท)",
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: "net_amount",
            headerName: "มูลค่ารวมสุทธิ (บาท)",
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: "withholding_tax_value",
            headerName: "ภาษีหัก ณ ที่จ่าย (บาท)",
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: "shipping_cost",
            headerName: "ค่าส่ง (บาท)",
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: "total_amount",
            headerName: "จำนวนเงินรวมทั้งสิ้น (บาท)",
            filter: false,
            cellStyle: { display: "flex", justifyContent: "flex-end" },
            headerClass: "ag-end-header",
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
    ];

    const graphQLClient: GraphQLClient =
        createGraphQLClientWithMiddleware("purchase");

    const datasource = {
        async getRows(params: IServerSideGetRowsParams) {
            const { request } = params;
            const { startRow, endRow, filterModel, sortModel } = request;
            const {
                created_date,
            } = filterModel;
            const formatFilter = {
                ...filterModel,
                created_date: dateFilterModel(created_date),
            };
            const formatSort = [...sortModel];
            try {
                const { PurchaseReportByItemViewsAggrid } =
                    await graphQLClient.request(
                        GET_PURCHASE_REPORT_BY_ITEM_VIEW,
                        {
                            aggridInput: {
                                startRow,
                                endRow,
                                filterModel: formatFilter,
                                sortModel: formatSort,
                            },
                        });
                params.success({
                    rowData: PurchaseReportByItemViewsAggrid.results,
                    rowCount: PurchaseReportByItemViewsAggrid.count,
                });
            } catch (err) {
                params.fail();
            }
        },
    };

    const onFilterChanged = useCallback(
        (params: any) => {
            const statusInstance = params.api.getFilterInstance("status");
            statusInstance.setModel({
                values: [
                    "wait_approve",
                    "approved",
                    "partially_imported",
                    "fully_imported",
                ]
            });

            params.api.onFilterChanged();
        },
        []
    );

    const onGridReady = (params: GridReadyEvent) => {
        params.api.setServerSideDatasource(datasource);
        onFilterChanged(params);
    };

    return (
        <AgGrid
            ref={gridRef}
            columnDefs={columnDef}
            height={665}
            onGridReady={onGridReady}
        />
    );
};

export default PurchaseReportByItemTable;