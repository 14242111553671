import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab, IExporter } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Box, Typography, CircularProgress } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import PurchaseOrderTable from "../../../components/Table/Purchase/Order";
import ButtonLayout from "../../../components/UI/ButtonLayout";
import { ConfigurableAgGridReact } from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGrid";
import { IPurchaseOrder } from "../../../types/Purchase/purchaseOrder";
import { useState, useEffect, useRef, useMemo } from "react";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import ConfigurableAgGridEditButton from "../../../components/UI/ConfigurableAgGrid/ConfigurableAgGridEditButton";
import ExporterModal from "../../../components/UI/ExporterModal";
import usePurchaseCheckApproval from "../../../hooks/Purchase/use-purchase-check-approval";
import { useModal } from "../../../hooks/use-modal";
import { useSnackbar } from "notistack";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  usePurchaseOrderGenerateExportQuery,
  PurchaseOrderGenerateExportQuery,
  PurchaseExportInput,
  PurchaseExportType,
} from "../../../generated/purchase";

import useConfigurableAgGridEditButtonOptions, {
  onConfigurableAgGridOptionSelect,
} from "../../../components/UI/ConfigurableAgGrid/useConfigurableAgGridEditButtonOptions";

const PurchaseOrder = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const { isHaveApproval, isLoading } = usePurchaseCheckApproval("purchase_order");

  const configrableGridOptions = useConfigurableAgGridEditButtonOptions();

  const tableRef = useRef<ConfigurableAgGridReact<any>>();

  const { enqueueSnackbar } = useSnackbar();

  const [exportInput, setExportInput] = useState<PurchaseExportInput | null>(
    null
  );

  const graphqlClient = createGraphQLClientWithMiddleware("purchase");

  const { refetch: refetchData, isLoading: isExporting } =
    usePurchaseOrderGenerateExportQuery<PurchaseOrderGenerateExportQuery>(
      graphqlClient,
      {
        input: exportInput,
      },
      {
        enabled: false,
      }
    );

  const exportHandler = async (data: IExporter) => {
    if (data) {
      const { type, start_date, end_date } = data;
      setExportInput({
        export_type: type as PurchaseExportType,
        start_date: start_date,
        end_date: end_date,
      });
    }
  };

  const { modal, openModalHandler, closeModalHandler } = useModal();

  useEffect(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        const { data: exportData } = await refetchData();
        window.open(exportData?.PurchaseOrderGenerateExport || "", "_blank");
        enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [enqueueSnackbar, exportInput, refetchData, t]);

  const options = [
    {
      value: "นำออกใบสั่งซื้อ",
    },
    ...configrableGridOptions,
  ];

  const purchaseOrderTypeOptions = [
    {
      label: "รายเอกสาร",
      value: "order",
    },
    {
      label: "รายสินค้า",
      value: "item",
    },
  ];

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.order.index"),
    },
  ];

  const approvalTabs = useMemo(() => {
      if (isLoading) return [];
      if (!isHaveApproval) return [];
      return [
        { 
          label: t("status.wait_approve"), 
          path: `${pathname}?filter=wait_approve` 
        },
        { 
          label: t("status.not_approved"), 
          path: `${pathname}?filter=not_approved` 
        },
      ];
    }, [isHaveApproval, isLoading, pathname, t]);

  const tabs: ITab[] = [
    {
      label: t("global.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    ...approvalTabs,
    {
      label: t("status.approved"),
      path: `${pathname}?filter=approved`,
    },
    {
      label: t("status.partially_imported"),
      path: `${pathname}?filter=partially_imported`,
    },
    {
      label: t("status.fully_imported"),
      path: `${pathname}?filter=fully_imported`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  if (exportInput && isExporting) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (exportInput && isExporting) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("purchase.order.index")}</Typography>
        <ButtonLayout>
          <CustomizedButton
            title={`${t("button.create")}${t("purchase.order.index")}`}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
          {/* <ConfigurableAgGridEditButton tableRef={tableRef}/> */}
          <CustomizedMenuOptions
            isIcon
            label="contact-options"
            options={options}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case "นำออกใบสั่งซื้อ":
                  openModalHandler();
                  break;
                default:
                  onConfigurableAgGridOptionSelect(value.innerText, tableRef);
              }
            }}
          />
        </ButtonLayout>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <PurchaseOrderTable isFilter={isFilter} ref={tableRef} />
      <ExporterModal
        open={modal}
        closeModalHandler={closeModalHandler}
        typeOptions={purchaseOrderTypeOptions}
        exportHandler={exportHandler}
      />
    </>
  );
};

export default PurchaseOrder;
