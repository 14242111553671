import TableCell from "@mui/material/TableCell";
import { SxProps, Theme } from "@mui/material/styles";

interface CustomTableCellProps {
  width?: number;
  align?: "left" | "center" | "right"; // Default alignment is "center"
  sx?: SxProps<Theme>; // Allows custom styling
  children: React.ReactNode;
}

const CustomTableCell: React.FC<CustomTableCellProps> = ({
  width,
  align = "center",
  sx = {}, // Default is an empty object
  children,
}) => {
  return (
    <TableCell
      align={align}
      sx={{ px: 1, py: 1, ...sx }} // Merge default and custom styles
      width={(width || 0) + 16}
    >
      {children}
    </TableCell>
  );
};

export default CustomTableCell;
