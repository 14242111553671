import {
  ColDef,
  RowDoubleClickedEvent,
  IServerSideGetRowsParams,
  GridReadyEvent,
} from "ag-grid-community";
import { useNavigate } from "react-router";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { useWasteManufactureOrderColumnDefs } from "./columnDefs";
import { useTranslation } from "react-i18next";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { WASTE_MANUFACTURE_ORDER_REPORT } from "../../../../services/AgGrid/ManufactureReportAgGrid";
import { IDefaultForm } from "../../../../types/global";
import { IWasteManufactureOrder } from "../../../../types/Manufacture/order";

interface Props {
  gridRef: RefObject<AgGridReact<IWasteManufactureOrder>>;
  onFilterChanged: (params: any) => void;
  getValues: IDefaultForm["getValues"];
  setExportInput: (params: any) => void;
}

const WasteManufactureOrderReportTable = ({ gridRef, onFilterChanged, getValues, setExportInput }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columnDef: ColDef[] | undefined = useWasteManufactureOrderColumnDefs(t);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/manufacture/order/${encodeURIComponent(params.data.unique_id)}`);
  };

  const graphQLClient: GraphQLClient =
      createGraphQLClientWithMiddleware("manufacture");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        issue_date,
        production_date,
        production_completion_date,
        delivery_date,
        created_date,
        ...otherFilter
      } = filterModel;
      const formatFilter = {
        issue_date: dateFilterModel(issue_date),
        production_date: dateFilterModel(production_date),
        production_completion_date: dateFilterModel(production_completion_date),
        delivery_date: dateFilterModel(delivery_date),
        created_date: dateFilterModel(created_date),
        ...otherFilter,
      };
      setExportInput({
        aggridInput: {
          startRow: 1,
          endRow: 10000,
          filterModel: formatFilter,
          sortModel,
        },
        dateFilter: {
          start_date: getValues("dateFrom")?.toISOString(),
          end_date: getValues("dateTo")?.toISOString(),
        },
      });
      try {
        const { WasteManufactureOrderReport } =
          await graphQLClient.request(WASTE_MANUFACTURE_ORDER_REPORT, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
            dateFilter: {
              start_date: getValues("dateFrom")?.toISOString(),
              end_date: getValues("dateTo")?.toISOString()
            },
          });
        params.success({
          rowData: WasteManufactureOrderReport.data as any[],
          rowCount: WasteManufactureOrderReport.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    onFilterChanged(params);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onGridReady={onGridReady}
      onRowDoubleClicked={onRowDoubleClicked}
    />
  );
};

export default WasteManufactureOrderReportTable;