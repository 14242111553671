import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IBreadcrumbsAndMenu, ISelectOption } from "../../../types/global";
import { IPosSetting } from "../../../types/Sales/posSetting";
import { useForm, useWatch } from "react-hook-form";
import {
  posSettingSchema,
  posSettingValidation,
} from "../../../components/Form/Sales/Pos/Setting/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../../../components/Controller/ControlledTextField";
import ControlledPinCodeField from "../../../components/Controller/ControlledPinCode";
import ControlledSelect from "../../../components/Controller/ControlledSelect";
import { sortDataByUniqueIdAndName } from "../../../utils/Formatter/Global";
import { IWarehouse } from "../../../types/Setting/inventory";
import {
  useWarehousesQuery,
  WarehousesQuery,
} from "../../../generated/inventory";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import PosCustomerDetail from "../../../components/Form/Sales/Pos/Setting/Customer";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { useStateContext } from "../../../contexts/auth-context";
import { useSnackbar } from "notistack";
import bcrypt from "bcryptjs";
import { PROMPTPAY_QR_CODE } from "../../../services/Sales/PosSetting/Prompay";
import { validatePosSetting } from "../../../utils/validator/pos-setting";
import dayjs from "dayjs";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 18,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 17,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(11px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(14px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#2167D3",
        ...(theme.palette.mode === "dark" && {
          backgroundColor: "#177ddc",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 14,
    height: 14,
    borderRadius: 7,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...(theme.palette.mode === "dark" && {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));

const PosSetting = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.pos.index"),
      to: "/sales/pos",
    },
    {
      name: "POS",
      to: "/sales/pos/add",
    },
    {
      name: t("sales.pos.setting.index"),
    },
  ];

  const {
    state: { authUser },
  } = useStateContext();

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IPosSetting>({
    defaultValues: posSettingSchema,
    resolver: yupResolver(posSettingValidation),
    mode: "onChange",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const [password, setPassword] = useState("");

  const isChangePassword = watch("isChangePassword");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("isChangePassword", event.target.checked);
  };

  async function hashPassword(password: string): Promise<string> {
    const saltRounds = 10;
    try {
      const salt = await bcrypt.genSalt(saltRounds);

      const hash = await bcrypt.hash(password, salt);

      return hash;
    } catch (error) {
      throw error;
    }
  }

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useWarehousesQuery<WarehousesQuery>(graphQLClientWithHeaderItem);

  const sortedWarehouses = sortDataByUniqueIdAndName(
    warehouseData?.warehouses || []
  ) as IWarehouse[];

  const warehouseOptions: ISelectOption[] =
    sortedWarehouses?.map((warehouse) => ({
      label: warehouse?.name || "",
      value: warehouse?.unique_id || "",
    })) || [];

  const watchSourceWarehouseUniqueId = useWatch({
    control,
    name: "warehouse_unique_id",
  });

  const [binLocationOptions, setBinLocationOptions] = useState<ISelectOption[]>(
    []
  );

  useEffect(() => {
    if (!warehouseData?.warehouses) return;

    const foundWarehouse = warehouseData.warehouses.find(
      (warehouse) => warehouse?.unique_id === watchSourceWarehouseUniqueId
    );

    let allBinLocation: any[] = [];

    foundWarehouse?.warehouse_level_1_list?.forEach((level1) => {
      level1.sub_level_2_list?.forEach((level2) => {
        level2?.sub_level_3_list?.forEach((level3) => {
          if (level3?.bin_location_list) {
            allBinLocation.push(...level3.bin_location_list);
          }
        });
      });
    });

    setBinLocationOptions(
      allBinLocation.map((bin) => ({
        label: bin.bin_name,
        value: bin.id,
      }))
    );
  }, [warehouseData, watchSourceWarehouseUniqueId]);

  const currentTenantId = sessionStorage.getItem("tenant-id");

  const savedDefaultPosSetting = localStorage.getItem(`POS_${currentTenantId}`);

  useEffect(() => {
    if (savedDefaultPosSetting && validatePosSetting(savedDefaultPosSetting)) {
      const posSetting = JSON.parse(savedDefaultPosSetting) as IPosSetting;
      setValue("pos_name", posSetting.pos_name);
      setValue("pos_number", posSetting.pos_number);
      setValue("warehouse_unique_id", posSetting.warehouse_unique_id);
      setValue(
        "default_customer_unique_id",
        posSetting.default_customer_unique_id
      );
      setValue("bin_id", posSetting.bin_id);
      setValue("promptpay_id", posSetting.promptpay_id);
      setValue("promptpay_description", posSetting.promptpay_description);
      setPassword(posSetting.pin_code);
    } else {
      setIsFirst(true);
    }
    setValue("created_by_user_unique_id", authUser?.unique_id || "");
  }, [authUser?.unique_id, savedDefaultPosSetting, setValue]);

  useEffect(() => {
    if (isFirst) {
      setValue("isChangePassword", true);
    }
  }, [isFirst]);

  const onSubmit = async (data: IPosSetting) => {
    setIsSubmitting(true);
    const hashedPinCode = await hashPassword(data.pin_code);

    const { pin_code, customer_name, isChangePassword, ...otherData } = data;
    const posSetting = {
      ...otherData,
      created_date: dayjs().toDate(),
      pin_code: isChangePassword ? hashedPinCode : password,
    };

    const graphQLClientWithHeaderSales: GraphQLClient =
      createGraphQLClientWithMiddleware("sales");

    if (data.promptpay_id) {
      try {
        const { promptPayQRCode } = await graphQLClientWithHeaderSales.request(
          PROMPTPAY_QR_CODE,
          {
            promptPayId: data.promptpay_id,
          }
        );

        localStorage.setItem(
          `POS_${currentTenantId}`,
          JSON.stringify(posSetting)
        );

        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } catch (error) {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } else {
      const { promptpay_id, ...otherData } = posSetting;
      localStorage.setItem(`POS_${currentTenantId}`, JSON.stringify(otherData));
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
    }
    setIsSubmitting(false);
  };

  if (
    isWarehouseLoading ||
    isSubmitting ||
    (!watch("bin_id") &&
      savedDefaultPosSetting &&
      validatePosSetting(savedDefaultPosSetting))
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("sales.pos.setting.index")}</Typography>
      </HeaderLayout>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <CustomizedBox
          padding={16}
          margin={0}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "16px", marginBottom: "15px" }}
          >
            เครื่อง POS
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <ControlledTextField
                control={control}
                name="pos_name"
                label="ชื่อเครื่อง POS"
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                }}
                error={Boolean(errors?.pos_name)}
                helperText={errors?.pos_name?.message}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlledTextField
                control={control}
                name="pos_number"
                label="หมายเลขเครื่อง POS"
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                }}
                error={Boolean(errors?.pos_number)}
                helperText={errors?.pos_number?.message}
                required
              />
            </Grid>
            {!isFirst && (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <AntSwitch checked={isChangePassword} onChange={handleChange} />
                <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                  เปลี่ยน PIN CODE
                </Typography>
              </Grid>
            )}
            {isChangePassword && (
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={12} sm={4}>
                  <ControlledPinCodeField
                    control={control}
                    name="pin_code"
                    label="PIN CODE"
                    sx={{
                      "& .MuiInputBase-root": {
                        borderRadius: "8px",
                      },
                    }}
                    error={Boolean(errors?.pin_code)}
                    helperText={errors?.pin_code?.message}
                    required
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </CustomizedBox>
        <CustomizedBox
          padding={16}
          margin={0}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "16px", marginBottom: "15px" }}
          >
            สถานที่จัดเก็บ
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <ControlledSelect
                label={t("inventory.warehouse")}
                control={control}
                name="warehouse_unique_id"
                options={warehouseOptions}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                  },
                }}
                error={Boolean(errors?.warehouse_unique_id)}
                helperText={errors?.warehouse_unique_id?.message}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlledSelect
                label="เลือกสถานที่"
                control={control}
                name="bin_id"
                options={binLocationOptions || []}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                  },
                }}
                error={Boolean(errors?.bin_id)}
                helperText={errors?.bin_id?.message}
                disabled={!watchSourceWarehouseUniqueId}
                required
              />
            </Grid>
          </Grid>
        </CustomizedBox>
        <CustomizedBox
          padding={16}
          margin={0}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <PosCustomerDetail
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            disabled={false}
          />
        </CustomizedBox>
        <CustomizedBox
          padding={16}
          margin={"0px 0px 50px 0px"}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "16px", marginBottom: "15px" }}
          >
            พร้อมเพย์
          </Typography>

          <Grid container spacing={2}>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={4}>
                <ControlledTextField
                  control={control}
                  name="promptpay_id"
                  label="Promptpay ID"
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                    },
                  }}
                  error={Boolean(errors?.promptpay_id)}
                  helperText={errors?.promptpay_id?.message}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                name="promptpay_description"
                label="คำอธิบาย"
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                }}
                multiline
                rows={3}
                error={Boolean(errors?.promptpay_description)}
                helperText={errors?.promptpay_description?.message}
              />
            </Grid>
          </Grid>
        </CustomizedBox>
        <BottomNavbar>
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.cancel")}
              onClick={() => navigate("/sales/pos/add")}
              sx={{ minWidth: "120px" }}
            />
            <CustomizedButton
              variant="contained"
              title={t("button.confirm")}
              sx={{ minWidth: "120px" }}
              onClick={handleSubmit(async (data) => {
                await onSubmit(data);
              })}
            />
          </Stack>
        </BottomNavbar>
      </Box>
    </Box>
  );
};

export default PosSetting;
