import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { UomsQuery } from "../generated/inventory";
import { ISelectOption } from "../types/global";
import { useUomsQuery } from "../generated/inventory";

export const useUomOptions = () => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: uoms, isLoading: isUomLoading } = useUomsQuery<UomsQuery>(
    graphQLClientWithHeaderItem
  );

  const uomOptions: ISelectOption[] =
    uoms?.uoms?.map((uom) => ({
      id: uom?.unique_id || "",
      label: uom?.name || "",
      value: uom?.unique_id || "",
    })) || [];

  return {
    uomOptions,
    isUomLoading,
  };
};
