import { GraphQLClient } from "graphql-request";
import { IBom } from "../../types/Manufacture/bom";
import { IAttachment } from "../../types/global";
import { uploadFileToS3 } from "../s3";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import { IIngredient } from "../../types/Manufacture";

export const formatterBom = async (data: IBom) => {
  const {
    creator_unique_id,
    created_by,
    ingredient_list,
    minimum_stock_qty,
    attachment_list,
    ...otherData
  } = data;
  const new_ingredient_list = ingredient_list?.map(
    ({
      cuid = undefined,
      item_unique_id,
      item_name,
      item_description,
      qty,
      uom_unique_id,
      uom,
      item_barcode,
      item_img_url,
      item_tracability,
      cost_per_unit,
      is_purchasable,
      is_saleable,
      is_stockable,
      is_manufactured,
    }) => ({
      cuid,
      item_unique_id,
      item_name,
      item_description,
      qty,
      uom_unique_id,
      uom,
      item_barcode,
      item_img_url,
      item_tracability,
      cost_per_unit,
      is_purchasable,
      is_saleable,
      is_stockable,
      is_manufactured,
    })
  );

  let new_attachment_list: IAttachment[] = [];

  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "bom",
          data?.id?.toString() || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        new_attachment_list.push(formatAttachment);
      } else {
        new_attachment_list.push(file);
      }
    }
  }

  return {
    ...otherData,
    ingredient_list: new_ingredient_list,
    attachment_list: new_attachment_list,
    is_active: data.is_active === 1 || data.is_active === "1" ? 1 : 0,
  };
};

export const bomQueryFormatter = async (data: IBom) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");
  const allItemListUniqueId =
    data.ingredient_list?.map((item) => item.item_unique_id) || [];
  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: allItemListUniqueId.length,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });
  const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatIngredientList: IIngredient[] = [];

  data.ingredient_list?.forEach((ingredient) => {
    const foundItemIndex = itemSkuDetails.findIndex(
      (realItem: any) => realItem.sku_name === ingredient.item_unique_id
    );

    formatIngredientList.push({
      ...ingredient,
      item_sku_qty: {
        stock_qty: itemSkuDetails[foundItemIndex]?.stock_qty,
        available_qty: itemSkuDetails[foundItemIndex]?.available_qty,
        purchase_ordered_qty:
          itemSkuDetails[foundItemIndex]?.purchase_ordered_qty,
        manufacture_ordered_qty:
          itemSkuDetails[foundItemIndex]?.manufacture_ordered_qty,
        sale_committed_qty: itemSkuDetails[foundItemIndex]?.sale_committed_qty,
        manufacture_committed_qty:
          itemSkuDetails[foundItemIndex]?.manufacture_committed_qty,
      },
    });
  });

  return {
    ...data,
    ingredient_list: formatIngredientList,
  };
};
