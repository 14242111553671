import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../types/global";
import { useEffect, useState } from "react";

export const useItemSkuQty = (
  control: IDefaultForm["control"],
  index: number,
  documentType: string,
  itemListName?: string
) => {
  const [isSufficient, setIsSufficient] = useState<boolean>(true);
  const status = useWatch({
    control,
    name: `aggrid_status`,
  });

  const watchItemList = useWatch({
    control,
    name: itemListName ? `${itemListName}.${index}` : `item_list.${index}`,
  });

  const watchUom = watchItemList.uom;

  const watchStockQty = watchItemList.stock_qty;

  const watchItemSkuQty = watchItemList.item_sku_qty;

  const current_available_qty =
    watchItemSkuQty.stock_qty -
    watchItemSkuQty.sale_committed_qty -
    watchItemSkuQty.manufacture_committed_qty +
    watchItemSkuQty.purchase_ordered_qty +
    watchItemSkuQty.manufacture_ordered_qty;

  useEffect(() => {
    const curr_qty = watchStockQty ? parseFloat(watchStockQty) : 0;
    switch (documentType) {
      // case "quotation": {
      //   const curr_qty = watchStockQty ? parseFloat(watchStockQty) : 0;
      //   const totalAvailableQty = current_available_qty - curr_qty;
      //   if (totalAvailableQty >= 0) {
      //     setIsSufficient(true);
      //   } else {
      //     setIsSufficient(false);
      //   }
      //   break;
      // }
      // case "purchase_request": {
      //   const curr_qty = watchStockQty ? parseFloat(watchStockQty) : 0;
      //   const totalAvailableQty = current_available_qty + curr_qty;
      //   if (totalAvailableQty >= 0) {
      //     setIsSufficient(true);
      //   } else {
      //     setIsSufficient(false);
      //   }
      //   break;
      // }
      // case "sales_order": {
      //   switch (status) {
      //     case null:
      //     case undefined:
      //     case "draft":
      //     case "wait_approve":
      //       const curr_qty = watchStockQty ? parseFloat(watchStockQty) : 0;
      //       const totalAvailableQty = current_available_qty - curr_qty;
      //       if (totalAvailableQty >= 0) {
      //         setIsSufficient(true);
      //       } else {
      //         setIsSufficient(false);
      //       }
      //       break;
      //     default:
      //       if (current_available_qty >= 0) {
      //         setIsSufficient(true);
      //       } else {
      //         setIsSufficient(false);
      //       }
      //       break;
      //   }
      //   break;
      // }
      // case "purchase_order": {
      //   switch (status) {
      //     case null:
      //     case undefined:
      //     case "draft":
      //     case "wait_approve":
      //       const curr_qty = watchStockQty ? parseFloat(watchStockQty) : 0;
      //       const totalAvailableQty = current_available_qty + curr_qty;
      //       if (totalAvailableQty >= 0) {
      //         setIsSufficient(true);
      //       } else {
      //         setIsSufficient(false);
      //       }
      //       break;
      //     default:
      //       if (current_available_qty >= 0) {
      //         setIsSufficient(true);
      //       } else {
      //         setIsSufficient(false);
      //       }
      //       break;
      //   }
      //   break;
      // }
      case "quotation":
      case "sales_order":
      case "sales_return":
        if (current_available_qty >= curr_qty) {
          setIsSufficient(true);
        } else {
          setIsSufficient(false);
        }
        break;
      case "purchase_request":
      case "purchase_order":
      case "purchase_return":
        if (current_available_qty + curr_qty >= 0) {
          setIsSufficient(true);
        } else {
          setIsSufficient(false);
        }

        break;
      default:
        if (current_available_qty > 0) {
          setIsSufficient(true);
        } else {
          setIsSufficient(false);
        }
        break;
    }
  }, [current_available_qty, documentType, status, watchStockQty]);

  const formatData: any[] = [
    {
      status: "กำลังจัดซื้อ",
      amount: watchItemSkuQty.purchase_ordered_qty,
    },
    {
      status: "กำลังผลิต",
      amount: watchItemSkuQty.manufacture_ordered_qty,
    },
    {
      status: "ถูกจอง (ขาย)",
      amount: watchItemSkuQty.sale_committed_qty,
    },
    {
      status: "ถูกจอง (ผลิต)",
      amount: watchItemSkuQty.manufacture_committed_qty,
    },
    {
      status: "คงคลัง",
      amount: watchItemSkuQty.stock_qty,
    },
    {
      status: "คงเหลือสุทธิ",
      amount: current_available_qty,
    },
  ];

  return {
    isSufficient,
    formatData,
    uom: watchUom,
  };
};
