import { GraphQLClient } from "graphql-request";
import {
  BomQuery,
  ManufactureOrderQuery,
  useBomQuery,
} from "../../generated/manufacture";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

const usePDFMultiplePage = (data: ManufactureOrderQuery) => {
  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { data: bomData } = useBomQuery<BomQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: { name: data?.manufactureOrder?.bom_name },
    },
    {
      enabled: Boolean(data?.manufactureOrder?.bom_name),
    }
  );
  let page = 0;
  let pageLeft = 710;
  let paginatedItems: any[][][] = [];
  let extraSpace = 0;

  if (!data) {
    return [];
  }

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Cannot get 2D context");
  }
  const letterSpacing = 0.15;
  context.font = "400 11px 'Kanit'";

  //item section
  const itemDescriptionLines = Math.ceil(
    context.measureText(data.manufactureOrder?.item_description || "").width /
      250
  );
  const itemRemarkLines = Math.ceil(
    context.measureText(data.manufactureOrder?.item_remark || "").width / 250
  );

  const itemTypeLines = Math.ceil(
    context.measureText(data.manufactureOrder?.type || "").width / 86
  );

  const bomDescriptionLines = Math.ceil(
    context.measureText(bomData?.bom?.description || "").width / 95
  );

  extraSpace += Math.max(
    (itemDescriptionLines + itemRemarkLines - 3) * 15,
    (itemTypeLines - 4) * 15,
    (bomDescriptionLines - 4) * 15,
    0
  );

  //header section
  //Left side
  const customerNameLines = Math.ceil(
    context.measureText(data.manufactureOrder?.customer_name || "").width /
      288.67
  );
  const externalReferenceIDLines = Math.ceil(
    context.measureText(data.manufactureOrder?.external_reference_id || "")
      .width / 288.67
  );
  const remarkLines = Math.ceil(
    (context.measureText(data.manufactureOrder?.remark || "").width +
      (data.manufactureOrder?.remark || "").length * letterSpacing) /
      288.67
  );
  //Right side
  const relatedUserLines = Math.ceil(
    (context.measureText(
      (data.manufactureOrder?.created_by?.first_name ||
        "" + " " + data.manufactureOrder?.created_by?.last_name + ", ") +
        data.manufactureOrder?.related_user_list?.join(", ") || ""
    ).width +
      (
        (data.manufactureOrder?.created_by?.first_name ||
          "" + " " + data.manufactureOrder?.created_by?.last_name + ", ") +
          data.manufactureOrder?.related_user_list?.join(", ") || ""
      ).length *
        letterSpacing) /
      180
  );

  extraSpace += Math.max(
    (relatedUserLines - 1) * 20,
    (remarkLines + externalReferenceIDLines + customerNameLines - 3) * 20,
    0
  );

  pageLeft -= extraSpace;
  if (data.manufactureOrder?.ingredient_list) {
    pageLeft -= 32.5;

    for (let i = 0; i < data.manufactureOrder.ingredient_list.length; i++) {
      //ingredient section
      const descriptionLines = Math.ceil(
        context.measureText(
          data.manufactureOrder.ingredient_list[i]?.item_description || ""
        ).width / 257
      );
      const nameLines = Math.ceil(
        context.measureText(
          data.manufactureOrder.ingredient_list[i]?.item_name || ""
        ).width / 257
      );

      const lineHeight =
        73.5 + Math.max(descriptionLines + nameLines - 3, 0) * 15;

      if (pageLeft < lineHeight + 10) {
        page++;
        pageLeft = 1000;
      }

      pageLeft -= lineHeight;

      if (!paginatedItems[page]) {
        paginatedItems[page] = [[], []];
      }

      paginatedItems[page][0].push(data.manufactureOrder.ingredient_list[i]);
    }
  }

  if (data.manufactureOrder?.work_order_list) {
    if (pageLeft - 32.5 < 120) {
      pageLeft = 1000 - 32.5;
      page++;
    }
    for (let i = 0; i < data.manufactureOrder.work_order_list.length; i++) {
      const nameLines = Math.ceil(
        context.measureText(
          data.manufactureOrder.work_order_list[i]?.name || ""
        ).width / 124
      );
      const plantLines = Math.ceil(
        context.measureText(
          data.manufactureOrder.work_order_list[i]?.plant || ""
        ).width / 79
      );
      const productionCenterLines = Math.ceil(
        context.measureText(
          data.manufactureOrder.work_order_list[i]?.production_center || ""
        ).width / 94
      );
      const remarkLines = Math.ceil(
        context.measureText(
          data.manufactureOrder.work_order_list[i]?.remark || ""
        ).width / 175
      );

      const lineHeight =
        62 +
        Math.max(
          (nameLines - 2) * 15,
          (plantLines - 3) * 15,
          (productionCenterLines - 3) * 15,
          (remarkLines - 3) * 15,
          0
        );

      if (pageLeft < 120 + lineHeight) {
        page++;
        pageLeft = 1000;
      }

      pageLeft -= lineHeight;

      if (!paginatedItems[page]) {
        paginatedItems[page] = [[], []];
      }
      paginatedItems[page][1].push(data.manufactureOrder.work_order_list[i]);
    }
  }

  if (pageLeft < 110) {
    page++;
    paginatedItems[page] = [[], []];
  }

  if (
    data.manufactureOrder?.work_order_list &&
    !(data.manufactureOrder.work_order_list.length > 0) &&
    !((data.manufactureOrder?.ingredient_list?.length ?? 0) > 0)
  ) {
    paginatedItems[page] = [[], []];
  }

  return paginatedItems;
};

export default usePDFMultiplePage;
