import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import ReportDateFilter from "../../../components/UI/ReportDateFilter";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useRef } from "react";
import InputTaxReportTable from "../../../components/Table/Purchase/Tax/Report";
import { IInputTax } from "../../../types/Purchase/InputTax";
import { useState } from "react";

// exporter
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useSnackbar } from "notistack";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { usePurchaseInputTaxExportQuery, PurchaseInputTaxExportQuery, TaxExportInput  } from "../../../generated/purchase";

const InputTaxReport = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<IInputTax>>(null);
  const [showTable, setShowTable] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("report"),
      to: "/purchase/report",
    },
    {
      name: t("purchase.tax.index"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "this_month",
      dateFrom: dayjs().startOf("month").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    },
  });

  const graphqlClient = createGraphQLClientWithMiddleware("purchase");
  
  const [exportInput, setExportInput] = useState<TaxExportInput | null>(
    null
  );

  const { refetch: refetchData, isLoading: isExporting } =
    usePurchaseInputTaxExportQuery<PurchaseInputTaxExportQuery>(
      graphqlClient,
      {
        input: exportInput,
      },
      {
        enabled: false,
      }
    );

  const onBtnExport = useCallback(() => {
    // let startDate = getValues("dateFrom");
    // let endDate = getValues("dateTo");
    // const params = exportCSVParams(
    //   t("purchase.tax.index"),
    //   startDate,
    //   endDate
    // );
    // if (gridRef && gridRef.current) {
    //   gridRef.current.api.exportDataAsCsv(params);
    //   enqueueSnackbar("นำออกใบภาษีซื้อสำเร็จ", {
    //     variant: "success",
    //   });
    // }

    const fetchDataAndOpenWindow = async () => {
      try {
        const { data: exportData } = await refetchData();
        window.open(exportData?.GenerateInputTaxExport || "", "_blank");
        enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [enqueueSnackbar, exportInput, t]);

  const onFilterChanged = useCallback(
    (params: any) => {

      const tax_invoice_date = params.api.getFilterInstance("tax_invoice_date");

      tax_invoice_date?.setModel({
        filterType: "date",
        type: "inRange",
      });

      params.api.onFilterChanged();
    },
    [getValues]
  );

  const getFilteredData = () => {
    setShowTable(true);
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
  };

  const onFilterReset = async () => {
    if (gridRef && gridRef.current) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
    await reset({
      dateType: "this_month",
      dateFrom: dayjs().startOf("month").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    });
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("purchase.tax.index")}</Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
        />
      </HeaderLayout>
      <ReportDateFilter
        control={control}
        setValue={setValue}
        getValues={getValues}
        onFilterReset={onFilterReset}
        getFilteredData={getFilteredData}
        future
        disabledControlledSelect={true}
        disabledDate={true}
      />
      {showTable && (<InputTaxReportTable
        gridRef={gridRef}
        onFilterChanged={onFilterChanged}
        getValues={getValues}
        setExportInput={setExportInput}
      />)}
    </>
  );
}

export default InputTaxReport