import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { useSnackbar } from "notistack";
import { useDisable } from "../../../hooks/use-disable";

import { Typography, Stack } from "@mui/material";
import LoadingUI from "../../../components/UI/LoadingUI";
import RbacHeader from "../../../components/Form/Rbac/Header";
import PermissionForm from "../../../components/Form/Permission";
import UserListForm from "../../../components/Form/Rbac/UserList";
import RbacInformation from "../../../components/Form/Rbac/Information";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { rbacSchema, validation } from "../../../components/Form/Rbac/schema";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";

import { GraphQLClient } from "graphql-request";
import {
  useRoleCreateMutation,
  useRoleQuery,
  useRoleUpdateMutation,
} from "../../../generated/rbac";
import { USERS_AGGRID } from "../../../services/AgGrid/UserAgGrid";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { IRole, IUser, IUserInfo } from "../../../types/Auth/user";

import { formatterRole } from "../../../utils/Formatter/Role";
import { mergeUsersFormatter } from "../../../utils/Formatter/User";

const RbacContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState<IUser[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);
  const [userIdsSnapshot, setUserIdsSnapshot] = useState<string[]>([]);

  const methods = useForm<IRole>({
    defaultValues: { ...rbacSchema },
    resolver: yupResolver(validation),
  });

  const { reset, handleSubmit } = methods;

  const [disabled, setDisabled] = useDisable();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const graphQLClientCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const { data, isSuccess, isLoading } = useRoleQuery(
    graphQLClient,
    {
      where: { id: parseInt(id || "0") },
    },
    { enabled: !!id }
  );

  const { mutateAsync: roleCreate, isLoading: creatingRole } =
    useRoleCreateMutation<Error>(graphQLClient, {
      onMutate(variables) {
        setDisabled(true);
      },
      onSuccess({ RoleCreate }, variables, context) {
        enqueueSnackbar("สร้างสิทธิ์สำเร็จ", {
          variant: "success",
        });
        navigate("/user/rbac/" + RoleCreate?.id + "?subtab=inventory");
      },
      onError(error, variables, context) {
        const formatError = JSON.stringify(error);
        if (
          formatError.includes(
            "Unique constraint failed on the fields: (`name`)"
          )
        ) {
          enqueueSnackbar("ชื่อสิทธิ์นี้มีในระบบแล้ว", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างสิทธิ์ไม่สำเร็จ", {
            variant: "error",
          });
        }
        setDisabled(false);
      },
    });

  const { mutateAsync: roleUpdate, isLoading: updatingRole } =
    useRoleUpdateMutation<Error>(graphQLClient, {
      onMutate(variables) {
        setDisabled(true);
      },
      onSuccess({ RoleUpdate }, variables, context) {
        navigate("/user/rbac/" + RoleUpdate?.id + "?subtab=inventory");
        enqueueSnackbar("แก้ไขสิทธิ์สำเร็จ", {
          variant: "success",
        });
      },
      onError(error, variables, context) {
        const formatError = JSON.stringify(error);
        if (
          formatError.includes(
            "Unique constraint failed on the fields: (`name`)"
          )
        ) {
          enqueueSnackbar("ชื่อสิทธิ์นี้มีในระบบแล้ว", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("แก้ไขสิทธิ์ไม่สำเร็จ", {
            variant: "error",
          });
        }
        setDisabled(false);
      },
    });

  const getUsersData = useCallback(
    async (roleType: IRole) => {
      const { usersFindManyAggrid } = await graphQLClientCompany.request(
        USERS_AGGRID,
        {
          aggridInput: {
            filterModel: {
              unique_id: {
                values: roleType.user_unique_id_list,
                filterType: "set",
              },
            },
            startRow: 0,
            endRow: 999,
          },
        }
      );
      setUsers(usersFindManyAggrid.data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const resetRole = useCallback(
    (roleData: IRole) => {
      const mergeData = mergeUsersFormatter(
        users as IUser[],
        roleData.user_list as IUserInfo[]
      );
      setUserIds(mergeData.map((data) => data.unique_id));
      setUserIdsSnapshot(mergeData.map((data) => data.unique_id));
      reset({ ...roleData, user_list: mergeData });
      setDisabled(true);
    },
    [reset, users, setDisabled]
  );

  useEffect(() => {
    if (id) {
      if (isSuccess) {
        const roleType = data.Role as IRole;
        resetRole(roleType);
      }
    }
    return () => setDisabled(false);
  }, [data?.Role, setDisabled, id, isSuccess, reset, users, resetRole]);

  useEffect(() => {
    if (id) {
      if (isSuccess) {
        const roleType = data.Role as IRole;
        getUsersData(roleType);
      }
    }
  }, [data?.Role, getUsersData, id, isSuccess]);

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.rbac.index"),
      to: "/user/rbac",
    },
    {
      name: id ? `${data?.Role?.name || ""}` : t("user.rbac.add_new_role"),
    },
  ];

  const onSubmit = async (data: IRole) => {
    const { id, ...formatData } = formatterRole(data);
    if (!id) await roleCreate({ input: formatData });
    else {
      await roleUpdate({
        input: formatData,
        where: { id: id },
      });
    }
  };

  if (id && (isLoading || creatingRole || updatingRole)) {
    return <LoadingUI />;
  }

  return (
    <FormProvider {...methods}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <RbacHeader />
      <RbacInformation />
      <CustomizedBox margin="2rem 0 0 0">
        <Typography variant="h6">{t("user.rbac.permission")}</Typography>
        <PermissionForm disabled={disabled} />
      </CustomizedBox>
      <UserListForm
        userIds={userIds}
        setUserIds={setUserIds}
        userIdsSnapshot={userIdsSnapshot}
        setUserIdsSnapshot={setUserIdsSnapshot}
      />
      {!disabled && (
        <BottomNavbar>
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.cancel")}
              onClick={() => {
                if (id) {
                  const roleType = data?.Role as IRole;
                  resetRole(roleType);
                } else navigate("/user/rbac");
              }}
            />
            <CustomizedButton
              type="submit"
              variant="contained"
              title={t("button.save")}
              onClick={handleSubmit(onSubmit)}
            />
          </Stack>
        </BottomNavbar>
      )}
    </FormProvider>
  );
};

export default RbacContainer;
