import { useCallback, useEffect } from "react";
import { IDefaultForm, ISalesBomItemList } from "../../../../types/global";
import { useWatch } from "react-hook-form";
import { Typography } from "@mui/material";
import { formatNumber } from "../../../../utils/dataTransformer";
import { ISalesItemList } from "../../../../types/Sales";

interface Props {
  control: IDefaultForm["control"];
  index: number;
  disabled: IDefaultForm["disabled"];
  setValue: IDefaultForm["setValue"];
  field: ISalesItemList;
}

const ParentSalesBomPreVatAmountCell = ({
  control,
  field,
  index,
  disabled,
  setValue,
}: Props) => {
  const itemList = useWatch({
    control,
    name: `item_list.${index}.sales_bom_item`,
  });

  const preDiscountAmount = useWatch({
    control,
    name: `item_list.${index}.pre_discount_amount`,
  });

  const calPreVatAmount = useCallback(
    (salesBomList: ISalesItemList["sales_bom_item"]) => {
      const sumOfPreVatAmount = salesBomList?.reduce(
        (res: number, list: ISalesBomItemList) => (res += list.pre_vat_amount),
        0
      );

      return sumOfPreVatAmount || 0;
    },
    []
  );

  const calPreDiscountAmount = useCallback(
    (salesBomList: ISalesItemList["sales_bom_item"]) => {
      const sumOfPreVatAmount = salesBomList?.reduce(
        (res: number, list: ISalesBomItemList) =>
          (res += list.pre_discount_amount),
        0
      );

      return sumOfPreVatAmount || 0;
    },
    []
  );

  useEffect(() => {
    const preVatAmount = calPreVatAmount(itemList);
    const preDiscountAmount = calPreDiscountAmount(itemList);

    setValue(`item_list.${index}.pre_vat_amount`, preVatAmount);
    setValue(`item_list.${index}.pre_discount_amount`, preDiscountAmount);
  }, [itemList, calPreVatAmount, calPreDiscountAmount, setValue, index]);

  if (disabled)
    return (
      <Typography fontSize={14}>
        {formatNumber(field.pre_discount_amount || field.pre_vat_amount)}
      </Typography>
    );

  return (
    <Typography fontSize={14}>
      {formatNumber(preDiscountAmount || 0)}
    </Typography>
  );
};

export default ParentSalesBomPreVatAmountCell;
