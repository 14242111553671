import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { dateFilterParams } from "../../../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../../../utils/Formatter/Date";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import {
  GET_ALL_BIN_LOCATION,
  GET_ALL_WAREHOUSES,
} from "../../../../../services/AgGrid/InventoryAgGrid";
import {
  Warehouse,
  WarehouseBinLocation,
} from "../../../../../generated/inventory";
import { formatNumber } from "../../../../../utils/dataTransformer";

export const useCurrentStockColumnDefs = (
  type: "warehouse" | "bin_location" | "serial_number"
) => {
  const { t } = useTranslation();

  const columnDefs = useMemo<ColDef[]>(() => {
    const defaultColumnDefs: ColDef[] = [
      {
        field: "warehouse_unique_id",
        headerName: t("inventory.warehouseId"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
        sort: "asc",
      },
      {
        field: "warehouse_name",
        headerName: t("inventory.warehouse"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            try {
              const graphQLClientWithHeaderItem: GraphQLClient =
                createGraphQLClientWithMiddleware("item");
              const { warehouses } = await graphQLClientWithHeaderItem.request(
                GET_ALL_WAREHOUSES
              );
              const warehousesName = warehouses.map(
                (warehouse: Warehouse) => warehouse.name
              );
              params.success(warehousesName);
            } catch (err) {
              params.fail();
            }
          },
        },
        minWidth: 200,
        flex: 1,
      },
    ];

    const defaultSumColumnDefs: ColDef[] = [
      {
        field: "stock_qty",
        headerName: t("inventory.quantities.stock_qty"),
        filter: "agNumberColumnFilter",
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          formatNumber(params.value),
        headerClass: "ag-end-header",
        cellStyle: {
          display: "flex",
          justifyContent: "flex-end",
        },
      },
    ];
    switch (type) {
      case "warehouse":
        return [...defaultColumnDefs, ...defaultSumColumnDefs];
      case "bin_location":
        return [
          ...defaultColumnDefs,
          {
            field: "bin_name",
            headerName: t("inventory.location"),
            filter: "agSetColumnFilter",
            filterParams: {
              values: async (params: any) => {
                try {
                  const graphQLClientWithHeaderItem: GraphQLClient =
                    createGraphQLClientWithMiddleware("item");
                  const { warehouseBinLocations } =
                    await graphQLClientWithHeaderItem.request(
                      GET_ALL_BIN_LOCATION
                    );
                  const binLocationsName = warehouseBinLocations.map(
                    (binLocation: WarehouseBinLocation) => binLocation.bin_name
                  );
                  params.success(binLocationsName);
                } catch (err) {
                  params.fail();
                }
              },
            },
            sort: "asc",
          },
          ...defaultSumColumnDefs,
        ];
      case "serial_number":
        return [
          ...defaultColumnDefs,
          {
            field: "serial_no",
            headerName: "Batch/SN",
            filter: "agTextColumnFilter",
            sort: "asc",
          },
          {
            field: "bin_name",
            headerName: t("inventory.location"),
            filter: "agSetColumnFilter",
            filterParams: {
              values: async (params: any) => {
                try {
                  const graphQLClientWithHeaderItem: GraphQLClient =
                    createGraphQLClientWithMiddleware("item");
                  const { warehouseBinLocations } =
                    await graphQLClientWithHeaderItem.request(
                      GET_ALL_BIN_LOCATION
                    );
                  const binLocationsName = warehouseBinLocations.map(
                    (binLocation: WarehouseBinLocation) => binLocation.bin_name
                  );
                  params.success(binLocationsName);
                } catch (err) {
                  params.fail();
                }
              },
            },
            sort: "asc",
          },
          ...defaultSumColumnDefs,
          {
            field: "lot_date",
            headerName: "วันที่ Lot",
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            valueFormatter: (params: ValueFormatterParams) =>
              formatDate(params.value),
          },
          {
            field: "barcode",
            headerName: "Barcode",
            filter: "agTextColumnFilter",
            width: 320,
            sort: "asc",
          },
        ];
      default:
        return defaultColumnDefs;
    }
  }, [t, type]);

  return columnDefs;
};
