import { Box, IconButton, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
// import ControlledNumberTextField from "../../../../Controller/ControlledNumberTextField";
import { formatNumber } from "../../../../../utils/dataTransformer";
import CloseIcon from "@mui/icons-material/Close";

const gutter = 8;

const PosItemCard = ({ style, index, visibleStartIndex }: any) => {
  return (
    <div
      style={{
        ...style,
        top: (style?.top || 0) + (index - visibleStartIndex) * gutter,
        border: "1px solid #E2E2E2",
        borderRadius: "16px",
        padding: "8px",
        width: "100%",
      }}
    >
      <Box display={"flex"} gap={2} width={"100%"}>
        <Box
          sx={{
            height: 56,
            objectFit: "contain",
          }}
        >
          <img
            height={"100%"}
            width={"100%"}
            alt="product"
            src="https://www.dohome.co.th/media/catalog/product/cache/e446f15aaa8dc66b80b7a0df334f7c5a/1/0/10320572_bt_1200_1.jpg"
          />
        </Box>
        <Box width={"100%"}>
          <Typography fontSize={14}>
            <b>P00001</b> น้ํายาล้าง จานซันไลต์ กลิ่นเลม่อน
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={1}
          >
            <Box display={"flex"} gap={2} alignItems={"center"}>
              <div>- Number +</div>
              {/* <ControlledNumberTextField /> */}
              <Box
                sx={{
                  backgroundColor: "#E6F9F0",
                  border: "1px solid #22915A",
                  borderRadius: "50%",
                  width: `18px`,
                  height: `18px`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CheckIcon
                  sx={{ color: "#22915A", fontSize: `${18 * 0.6}px` }}
                />
              </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              <Typography color={"primary.main"} fontWeight={600}>
                {formatNumber(100)}
              </Typography>
              <IconButton size="small" sx={{ color: "#BDBDBD" }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default PosItemCard;
