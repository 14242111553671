import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import "./i18n/i18n";
import DashboardLayout from "./components/UI/Dashboard/Dashboard";
import Login from "./pages/Login";
import User from "./pages/User";
import Landing from "./pages/Landing";
import Setting from "./pages/Setting";
import Company from "./pages/Setting/Company";
import Inventory from "./pages/Inventory";
import UserAccount from "./pages/User/Account";
import UserAccountContainer from "./pages/User/Account/Container";
import Contact from "./pages/Contact";
import ContactContainer from "./pages/Contact/Container";
// import ContactImporter from "./pages/Contact/Importer";
import Items from "./pages/Inventory/Items";
import ItemsContainer from "./pages/Inventory/Items/Container";
// import ItemImporterNormal from "./pages/Inventory/Items/Importer/Normal";
// import ItemImporterService from "./pages/Inventory/Items/Importer/Service";
// import ItemImporterBundle from "./pages/Inventory/Items/Importer/Bundle";
// import ItemImporterVariant from "./pages/Inventory/Items/Importer/Variant";
import Sales from "./pages/Sales";
import SalesReturnContainer from "./pages/Sales/Return/Container";
import SalesReturn from "./pages/Sales/Return";
import SalesOrderContainer from "./pages/Sales/Order/Container";
import SalesOrder from "./pages/Sales/Order";
import QuotationContainer from "./pages/Sales/Quotation/Container";
import Quotation from "./pages/Sales/Quotation";
import RequireUser from "./pages/RequireUser";
import GoodsReceive from "./pages/Inventory/GoodsReceive";
import GoodsReceiveContainer from "./pages/Inventory/GoodsReceive/Container";
import PrintLabel from "./pages/PrintLabel";
import GoodsReturn from "./pages/Inventory/GoodsReturn";
import GoodsReturnContainer from "./pages/Inventory/GoodsReturn/Container";
import GoodsTransfer from "./pages/Inventory/GoodsTransfer";
import GoodsAdjustment from "./pages/Inventory/GoodsAdjustment";
import GoodsAdjustmentContainer from "./pages/Inventory/GoodsAdjustment/Container";
import GoodsTransferContainer from "./pages/Inventory/GoodsTransfer/Container";
import GoodsIssue from "./pages/Inventory/GoodsIssue";
import GoodsIssueContainer from "./pages/Inventory/GoodsIssue/Container";
import GoodsIssuePDF from "./pages/Inventory/GoodsIssue/PDF";
import Purchase from "./pages/Purchase";
import PurchaseRequest from "./pages/Purchase/Request";
import PurchaseOrder from "./pages/Purchase/Order";
import PurchaseReturn from "./pages/Purchase/Return";
import PurchaseRequestContainer from "./pages/Purchase/Request/Container";
import PurchaseOrderContainer from "./pages/Purchase/Order/Container";
import PurchaseReturnContainer from "./pages/Purchase/Return/Container";
import Remark from "./pages/Setting/Remark";
import SalesRemark from "./pages/Setting/Remark/Sales";
import PurchaseRemark from "./pages/Setting/Remark/Purchase";
import LogisticRemark from "./pages/Setting/Remark/Logistic";
import ManufactureRemark from "./pages/Setting/Remark/Manufacture";
import Approval from "./pages/Setting/Approval";
import SalesApproval from "./pages/Setting/Approval/Sales";
import PurchaseApproval from "./pages/Setting/Approval/Purchase";
import InventorySetting from "./pages/Setting/Inventory";
import WarehouseSetting from "./pages/Setting/Inventory/Warehouse";
import CategorySetting from "./pages/Setting/Inventory/Category";
import UomSetting from "./pages/Setting/Inventory/Uom";
import AttributeSetting from "./pages/Setting/Inventory/Attribute";
import LogisticSetting from "./pages/Setting/Logistic";
import Vehicle from "./pages/Setting/Logistic/Vehicle";
import VehicleForm from "./components/Form/Logistic/Vehicle/VehicleForm";
import LevelOne from "./pages/Setting/Inventory/Category/LevelOne";
import LevelTwo from "./pages/Setting/Inventory/Category/LevelTwo";
import LevelThree from "./pages/Setting/Inventory/Category/LevelThree";
import InventoryReport from "./pages/Inventory/Reports";
import CurrentStockBySN from "./pages/Inventory/Reports/CurrentStockBySN";
import Project from "./pages/Project";
import Projects from "./pages/Project/Projects";
import ProjectContainer from "./pages/Project/Projects/Container";
import ProjectStatusSetting from "./pages/Setting/Project/Status";
import SelectCompany from "./pages/SelectCompany";
import PurchaseOrderPDF from "./pages/Purchase/Order/PDF";
import Manufacture from "./pages/Manufacture";
import Bom from "./pages/Manufacture/Bom";
import ManufactureOrder from "./pages/Manufacture/Order";
import ManufactureReport from "./pages/Manufacture/Report";
import BomContainer from "./pages/Manufacture/Bom/Container";
import ManufactureOrderContainer from "./pages/Manufacture/Order/Container";
import SalesReport from "./pages/Sales/Report";
import QuotationReport from "./pages/Sales/Report/QuotationReport";
import SalesOrderReport from "./pages/Sales/Report/SalesOrderReport";
import SalesReturnReport from "./pages/Sales/Report/SalesReturnReport";
import OutputTaxReport from "./pages/Sales/Report/OutputTaxReport";
import PurchaseReport from "./pages/Purchase/Report";
import PurchaseRequestReport from "./pages/Purchase/Report/PurchaseRequestReport";
import PurchaseOrderReport from "./pages/Purchase/Report/PurchaseOrderReport";
import PurchaseReturnReport from "./pages/Purchase/Report/PurchaseReturnReport";
import InputTaxReport from "./pages/Purchase/Report/InputTaxReport";
import QuotationPDF from "./pages/Sales/Quotation/PDF";
import SalesOrderPDF from "./pages/Sales/Order/PDF";
import PurchaseRequestPDF from "./pages/Purchase/Request/PDF";
import BomPDF from "./pages/Manufacture/Bom/PDF";
import ManufactureOrderPDF from "./pages/Manufacture/Order/PDF";
import Logistic from "./pages/Logistic";
import DeliveryOrder from "./pages/Logistic/DeliveryOrder";
import DeliveryOrderContainer from "./pages/Logistic/DeliveryOrder/Container";
import DeliveryOrderPDF from "./pages/Logistic/DeliveryOrder/PDF";
import { Cookies } from "react-cookie";
import Maintenance from "./pages/Maintenance";
import NotFound from "./pages/NotFound";
import StockReport from "./pages/Inventory/Reports/Stock";
import StockByLotReport from "./pages/Inventory/Reports/StockByLot";
import TraceEntryReport from "./pages/Inventory/Reports/TraceEntry";
import Package from "./pages/Setting/Package";
import ItemImporter from "./pages/Inventory/Items/Importer";
import NewContactImporter from "./pages/Contact/NewImporter";
import ImportExportLog from "./pages/ImportExportLog";
import Routing from "./pages/Manufacture/Routing";
import RoutingContainer from "./pages/Manufacture/Routing/Container";
import Unauthorized from "./pages/Unauthoried";
import RequirePermission from "./pages/RequirePermission";
import Rbac from "./pages/User/Rbac";
import RbacContainer from "./pages/User/Rbac/Container";
import Liff from "./pages/Liff";

import { useStateContext } from "./contexts/auth-context";
import SalesOrderCustomPDF from "./pages/Sales/Order/CustomPDF";
import MovementSummaryReport from "./pages/Inventory/Reports/MovementSummaryReport";
import ManufactureOrderReport from "./pages/Manufacture/Report/ManufactureOrderReport";
import ManufactureBomReport from "./pages/Manufacture/Report/ManufactureBomReport";
import ManufactureRoutingReport from "./pages/Manufacture/Report/ManufactureProcessReport";
// import InvoicePDF from "./pages/Sales/Order/InvoicePDF";
import CompanyExpired from "./pages/CompanyExpired";
import liff from "@line/liff";
// import LiffMock from "./pages/LiffMock";
import ManufactureIngredientReport from "./pages/Manufacture/Report/ManufactureIngredientReport";
import WasteManufactureOrderReport from "./pages/Manufacture/Report/WasteManufactureOrderReport";
import Contacts from "./pages/Contact/Contacts";
import PurchaseOrderInternationalPDF from "./pages/Purchase/Order/InternationalPDF";
import OCRNavigate from "./pages/OCRNavigate";
import ActionSetting from "./pages/Setting/Action";
import Pos from "./pages/Sales/Pos";
import PosOrder from "./pages/Sales/Pos/PosOrder";
import PosSetting from "./pages/Sales/Pos/PosSetting";
import SalesReportByCustomer from "./pages/Sales/Report/SalesReportByCustomer";
import SalesReportByItem from "./pages/Sales/Report/SalesReportByItem";
import DeliveryOrderCustomPDF from "./pages/Logistic/DeliveryOrder/CustomPDF";
import PurchaseReportByVendor from "./pages/Purchase/Report/PurchaseReportByVendor";
import PurchaseReportByItem from "./pages/Purchase/Report/PurchaseReportByItem";
import SalesBom from "./pages/Inventory/SalesBom";
import SalesBomContainer from "./pages/Inventory/SalesBom/Container";

const cookies = new Cookies();

function App() {
  const isLoggedIn = cookies.get("logged_in");
  const selectTenant = cookies.get("selected_tenant");

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const viewPermission = ["ALL", "SELF", "ALLOW"];

  return (
    <>
      <CompanyExpired />
      <Routes>
        {liff.isInClient() ? (
          <>
            <Route path="/" element={<Liff />} />
            <Route path="/login" element={<Navigate replace to="/" />} />
          </>
        ) : (
          <Route
            path="/"
            element={
              isLoggedIn && selectTenant ? (
                <Navigate replace to="/landing" />
              ) : isLoggedIn && !selectTenant ? (
                <Navigate replace to="/select_company" />
              ) : (
                <Navigate replace to="/login" />
              )
            }
          />
        )}

        {/* <Route path="/" element={<Liff />} /> */}
        {/* <Route
          path="/*"
          element={
            isLoggedIn ? (
              <Navigate replace to="/landing" />
            ) : (
              <Navigate replace to="/login" />
            )
          }
        /> */}

        <Route path="/login" element={<Login />} />
        <Route path="/select_company" element={<SelectCompany />} />
        <Route path="/print-label" element={<PrintLabel />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/liff" element={<Liff />} />
        <Route element={<RequireUser allowedRoles={["ADMIN"]} />}>
          <Route path="/action" element={<OCRNavigate />} />
          <Route element={<DashboardLayout />}>
            <Route path="/landing" element={<Landing />} />
            {authUser?.unique_id && permissions && (
              <>
                <Route path="/admin" element={<Maintenance />} />
                <Route path="/user">
                  <Route index element={<User />} />
                  <Route
                    element={
                      <RequirePermission
                        allowedPermissions={[
                          viewPermission.includes(
                            permissions.user_information.view
                          ),
                        ]}
                      />
                    }
                  >
                    <Route path="account">
                      <Route index element={<UserAccount />} />
                      <Route path="add" element={<UserAccountContainer />} />
                      <Route path=":id" element={<UserAccountContainer />} />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequirePermission
                        allowedPermissions={[
                          viewPermission.includes(permissions.role.view),
                        ]}
                      />
                    }
                  >
                    <Route path="rbac">
                      <Route index element={<Rbac />} />
                      <Route path="add" element={<RbacContainer />} />
                      <Route path=":id" element={<RbacContainer />} />
                    </Route>
                  </Route>
                  <Route path="setting">
                    <Route path=":id" element={<UserAccountContainer />} />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      allowedPermissions={[
                        viewPermission.includes(
                          permissions.contact?.view || ""
                        ),
                      ]}
                    />
                  }
                >
                  <Route path="/contact">
                    <Route index element={<Contact />} />
                    <Route path="all" element={<Contacts type="all" />} />
                    <Route
                      path="customer"
                      element={<Contacts type="customer" />}
                    />
                    <Route path="vendor" element={<Contacts type="vendor" />} />
                    <Route
                      path="all/add"
                      element={<ContactContainer isContactPage />}
                    />
                    <Route
                      path="all/:id"
                      element={<ContactContainer isContactPage />}
                    />
                    {/* <Route path="import" element={<ContactImporter />} /> */}
                    <Route path="import" element={<NewContactImporter />} />
                    <Route
                      path="report"
                      element={<ContactContainer isContactPage />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      allowedPermissions={[
                        viewPermission.includes(
                          permissions.inventory?.view || ""
                        ),
                      ]}
                    />
                  }
                >
                  <Route path="/inventory">
                    <Route index element={<Inventory />} />
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(permissions.item.view),
                          ]}
                        />
                      }
                    >
                      <Route path="items">
                        <Route index element={<Items />} />
                        <Route path="add" element={<ItemsContainer />} />
                        <Route path=":id" element={<ItemsContainer />} />
                        <Route path="import" element={<ItemImporter />} />
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.goods_receive.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="goods_receive">
                        <Route index element={<GoodsReceive />} />
                        <Route path="add" element={<GoodsReceiveContainer />} />
                        <Route path=":id" element={<GoodsReceiveContainer />} />
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.goods_return.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="goods_return">
                        <Route index element={<GoodsReturn />} />
                        <Route path="add" element={<GoodsReturnContainer />} />
                        <Route path=":id" element={<GoodsReturnContainer />} />
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.goods_issue.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="goods_issue">
                        <Route index element={<GoodsIssue />} />
                        <Route path="add" element={<GoodsIssueContainer />} />
                        <Route path=":id">
                          <Route index element={<GoodsIssueContainer />} />
                          <Route path="pdf" element={<GoodsIssuePDF />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.goods_transfer.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="goods_transfer">
                        <Route index element={<GoodsTransfer />} />
                        <Route
                          path="add"
                          element={<GoodsTransferContainer />}
                        />
                        <Route
                          path=":id"
                          element={<GoodsTransferContainer />}
                        />
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.goods_adjustment.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="goods_adjustment">
                        <Route index element={<GoodsAdjustment />} />
                        <Route
                          path="add"
                          element={<GoodsAdjustmentContainer />}
                        />
                        <Route
                          path=":id"
                          element={<GoodsAdjustmentContainer />}
                        />
                      </Route>
                    </Route>
                    <Route path="sales_bom">
                      <Route index element={<SalesBom />} />
                      <Route path="add" element={<SalesBomContainer />} />
                      <Route path=":id" element={<SalesBomContainer />} />
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.inventory_report.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="report">
                        <Route index element={<InventoryReport />} />
                        <Route path="stock" element={<StockReport />} />
                        <Route
                          path="stock-by-lot"
                          element={<StockByLotReport />}
                        />
                        <Route
                          path="trace-entry"
                          element={<TraceEntryReport />}
                        />
                        <Route
                          path="current-stock-by-sn"
                          element={<CurrentStockBySN />}
                        />
                        <Route
                          path="movement-summary-report"
                          element={<MovementSummaryReport />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      allowedPermissions={[
                        viewPermission.includes(permissions.sales?.view || ""),
                      ]}
                    />
                  }
                >
                  <Route path="/sales">
                    <Route index element={<Sales />} />
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(permissions.quotation.view),
                          ]}
                        />
                      }
                    >
                      <Route path="quotation">
                        <Route index element={<Quotation />} />
                        <Route path="add" element={<QuotationContainer />} />
                        <Route path=":id">
                          <Route index element={<QuotationContainer />} />
                          <Route path="pdf" element={<QuotationPDF />} />
                          <Route
                            path="pdf-image"
                            element={<QuotationPDF isImage={true} />}
                          />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.sales_order.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="order">
                        <Route index element={<SalesOrder />} />
                        <Route path="add" element={<SalesOrderContainer />} />
                        <Route path=":id">
                          <Route index element={<SalesOrderContainer />} />
                          <Route path="pdf" element={<SalesOrderPDF />} />
                          <Route
                            path="custom-pdf"
                            element={<SalesOrderCustomPDF />}
                          />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.sales_return.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="return">
                        <Route index element={<SalesReturn />} />
                        <Route path="add" element={<SalesReturnContainer />} />
                        <Route path=":id">
                          <Route index element={<SalesReturnContainer />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="pos">
                      <Route index element={<Pos />} />
                      <Route path="setting">
                        <Route index element={<PosSetting />} />
                      </Route>
                      <Route path="add">
                        <Route index element={<PosOrder />} />
                      </Route>
                      <Route path=":id">
                        <Route index element={/* POS View */ null} />
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.sales_report.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="report">
                        <Route index element={<SalesReport />} />
                        <Route path="quotation" element={<QuotationReport />} />
                        <Route path="order" element={<SalesOrderReport />} />
                        <Route path="return" element={<SalesReturnReport />} />
                        <Route
                          path="by_customer"
                          element={<SalesReportByCustomer />}
                        />
                        <Route path="by_item" element={<SalesReportByItem />} />
                        <Route path="tax" element={<OutputTaxReport />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      allowedPermissions={[
                        viewPermission.includes(
                          permissions.purchase?.view || ""
                        ),
                      ]}
                    />
                  }
                >
                  <Route path="/purchase">
                    <Route index element={<Purchase />} />
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.purchase_request.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="request">
                        <Route index element={<PurchaseRequest />} />
                        <Route
                          path="add"
                          element={<PurchaseRequestContainer />}
                        />
                        <Route path=":id">
                          <Route index element={<PurchaseRequestContainer />} />
                          <Route path="pdf" element={<PurchaseRequestPDF />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.purchase_order.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="order">
                        <Route index element={<PurchaseOrder />} />
                        <Route
                          path="add"
                          element={<PurchaseOrderContainer />}
                        />
                        <Route path=":id">
                          <Route index element={<PurchaseOrderContainer />} />
                          <Route path="pdf" element={<PurchaseOrderPDF />} />
                          <Route
                            path="pdf-international"
                            element={<PurchaseOrderInternationalPDF />}
                          />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.purchase_return.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="return">
                        <Route index element={<PurchaseReturn />} />
                        <Route
                          path="add"
                          element={<PurchaseReturnContainer />}
                        />
                        <Route path=":id">
                          <Route index element={<PurchaseReturnContainer />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.purchase_report.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="report">
                        <Route index element={<PurchaseReport />} />
                        <Route
                          path="request"
                          element={<PurchaseRequestReport />}
                        />
                        <Route path="order" element={<PurchaseOrderReport />} />
                        <Route
                          path="return"
                          element={<PurchaseReturnReport />}
                        />
                        <Route
                          path="by_vendor"
                          element={<PurchaseReportByVendor />}
                        />
                        <Route
                          path="by_item"
                          element={<PurchaseReportByItem />}
                        />
                        <Route path="tax" element={<InputTaxReport />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      allowedPermissions={[
                        viewPermission.includes(
                          permissions.project?.view || ""
                        ),
                      ]}
                    />
                  }
                >
                  <Route path="project">
                    <Route index element={<Project />} />
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(permissions.projects.view),
                          ]}
                        />
                      }
                    >
                      <Route path="projects">
                        <Route index element={<Projects />} />
                        <Route path="add" element={<ProjectContainer />} />
                        <Route path=":id" element={<ProjectContainer />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      allowedPermissions={[
                        viewPermission.includes(
                          permissions.delivery?.view || ""
                        ),
                      ]}
                    />
                  }
                >
                  <Route path="logistic">
                    <Route index element={<Logistic />} />
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.delivery_order.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="delivery_order">
                        <Route index element={<DeliveryOrder />} />
                        <Route
                          path="add"
                          element={<DeliveryOrderContainer />}
                        />
                        <Route path=":id">
                          <Route index element={<DeliveryOrderContainer />} />
                          <Route path="pdf" element={<DeliveryOrderPDF />} />
                          <Route
                            path="custom-pdf"
                            element={<DeliveryOrderCustomPDF />}
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      allowedPermissions={[
                        viewPermission.includes(
                          permissions.manufacture?.view || ""
                        ),
                      ]}
                    />
                  }
                >
                  <Route path="manufacture">
                    <Route index element={<Manufacture />} />
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(permissions.bom.view),
                          ]}
                        />
                      }
                    >
                      <Route path="bom">
                        <Route index element={<Bom />} />
                        <Route path="add" element={<BomContainer />} />
                        <Route path=":id">
                          <Route index element={<BomContainer />} />
                          <Route path="pdf" element={<BomPDF />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.manufacture_order.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="order">
                        <Route index element={<ManufactureOrder />} />
                        <Route
                          path="add"
                          element={<ManufactureOrderContainer />}
                        />
                        <Route path=":id">
                          <Route
                            index
                            element={<ManufactureOrderContainer />}
                          />
                          <Route path="pdf" element={<ManufactureOrderPDF />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(permissions.routing.view),
                          ]}
                        />
                      }
                    >
                      <Route path="routing">
                        <Route index element={<Routing />} />
                        <Route path="add" element={<RoutingContainer />} />
                        <Route path=":id">
                          <Route index element={<RoutingContainer />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.manufacture_report.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="report">
                        <Route index element={<ManufactureReport />} />
                        <Route
                          path="order"
                          element={<ManufactureOrderReport />}
                        />
                        <Route path="bom" element={<ManufactureBomReport />} />
                        <Route
                          path="process"
                          element={<ManufactureRoutingReport />}
                        />
                        <Route
                          path="ingredient"
                          element={<ManufactureIngredientReport />}
                        />
                        <Route
                          path="waste"
                          element={<WasteManufactureOrderReport />}
                        />
                        <Route></Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      allowedPermissions={[
                        viewPermission.includes(
                          permissions.setting?.view || ""
                        ),
                      ]}
                    />
                  }
                >
                  <Route path="/setting">
                    <Route index element={<Setting />} />
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.setting_company.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="company">
                        <Route index element={<Company />} />
                      </Route>
                    </Route>
                    <Route path="package">
                      <Route index element={<Package />} />
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.setting_approval.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="approval">
                        <Route index element={<Approval />} />
                        <Route path="sales" element={<SalesApproval />} />
                        <Route path="purchase" element={<PurchaseApproval />} />
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.setting_remark.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="remark">
                        <Route index element={<Remark />} />
                        <Route path="sales" element={<SalesRemark />} />
                        <Route path="purchase" element={<PurchaseRemark />} />
                        <Route path="logistic" element={<LogisticRemark />} />
                        <Route
                          path="manufacture"
                          element={<ManufactureRemark />}
                        />
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.setting_inventory.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="inventory">
                        <Route index element={<InventorySetting />} />
                        <Route
                          path="warehouse"
                          element={<WarehouseSetting />}
                        />
                        <Route path="category">
                          <Route index element={<CategorySetting />} />
                          <Route path="level_one" element={<LevelOne />} />
                          <Route path="level_two" element={<LevelTwo />} />
                          <Route path="level_three" element={<LevelThree />} />
                        </Route>
                        <Route path="uom" element={<UomSetting />} />
                        <Route
                          path="attribute"
                          element={<AttributeSetting />}
                        />
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.setting_logistic.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="logistic">
                        <Route index element={<LogisticSetting />} />
                        <Route path="vehicle">
                          <Route index element={<Vehicle />} />
                          <Route path="add" element={<VehicleForm />} />
                          <Route path=":id">
                            <Route index element={<VehicleForm />} />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      element={
                        <RequirePermission
                          allowedPermissions={[
                            viewPermission.includes(
                              permissions.setting_project.view
                            ),
                          ]}
                        />
                      }
                    >
                      <Route path="project-status">
                        <Route index element={<ProjectStatusSetting />} />
                      </Route>
                    </Route>
                    <Route path="action" element={<ActionSetting />} />
                  </Route>
                  <Route
                    path="/import-export-log"
                    element={<ImportExportLog />}
                  />
                </Route>
              </>
            )}
          </Route>
        </Route>
        {/* </>
        )} */}
      </Routes>
    </>
  );
}

export default App;
