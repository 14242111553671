import {
  ValueFormatterParams,
  ColDef,
  ICellRendererParams,
} from "ag-grid-community";
import { Box } from "@mui/material";
import CustomizedChips from "../../../Custom/CustomizedChips";
import { TFunction } from "i18next";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { formatNumber } from "../../../../utils/dataTransformer";
import { IWasteManufactureOrder } from "../../../../types/Manufacture/order";
import { formatDate } from "../../../../utils/Formatter/Date";
import { useEffect, useState } from "react";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import { ManufactureTagsFindByEntityQuery } from "../../../../generated/manufacture";

interface Props {
  isReport: boolean;
  allTag?: ManufactureTagsFindByEntityQuery["manufactureTagsFindByEntity"];
}

export const useWasteManufactureOrderColumnDefs = (
  t: TFunction,
  allTag?: Props["allTag"]
) => {
  const [columnDef, setColumnDef] = useState<ColDef<IWasteManufactureOrder>[]>();

  useEffect(() => {
    {
      setColumnDef([
        {
          field: "unique_id",
          headerName: t("manufacture.order.unique_id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "issue_date",
          headerName: t("manufacture.order.issue_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "aggrid_status",
          headerName: t("status.index"),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              statusValueFormatter(params.value),
            values: [
              "draft",
              "pending_manu",
              "in_progress",
              "finished",
              "cancelled",
            ],
          },
          cellRenderer: (params: ValueFormatterParams) => (
            <CustomizedStatus status={params.value} />
          ),
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
        },
        {
          field: "reference_unique_id_list",
          headerName: t("manufacture.order.reference_unique_id_list"),
          filter: "agTextColumnFilter",
          minWidth: 150,
        },
        {
          field: "external_reference_id",
          headerName: t("manufacture.order.external_reference_id"),
          filter: "agTextColumnFilter",
          minWidth: 150,
        },
        {
          field: "production_date",
          headerName: t("manufacture.order.production_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "production_completion_date",
          headerName: t("manufacture.order.production_completion_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "delivery_date",
          headerName: t("manufacture.order.delivery_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "customer_contact_unique_id",
          headerName: t("manufacture.order.contact_document_id"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 250,
        },
        {
          field: "customer_name",
          headerName: t("manufacture.order.customer_name"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 250,
        },
        {
          field: "item_unique_id",
          headerName: t("manufacture.order.item_unique_id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "item_name",
          headerName: t("manufacture.order.item_name"),
          filter: "agTextColumnFilter",
        },
        {
          field: "production_qty",
          headerName: t("manufacture.order.production_qty_1"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params: ValueFormatterParams) => {
            return formatNumber(params.value);
          },
          floatingFilter: false,
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "actual_production_qty",
          headerName: t("manufacture.order.actual_production_qty") + "จริง",
          filter: "agNumberColumnFilter",
          valueFormatter: (params: ValueFormatterParams) => {
            return formatNumber(params.value);
          },
          floatingFilter: false,
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "goods_receive_qty",
          headerName: t("manufacture.order.goods_receive_qty_1"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) => {
            return formatNumber(params.value);
          },
          cellStyle: { display: "flex", justifyContent: "flex-end" },

          floatingFilter: false,
          minWidth: 150,
          headerClass: "ag-end-header",
        },
        {
          field: "uom",
          headerName: t("manufacture.order.uom"),
          filter: "agTextColumnFilter",
          floatingFilter: false,
          minWidth: 150,
        },
        {
          field: "item_description",
          headerName: t("manufacture.order.item_description"),
          filter: "agTextColumnFilter",
          floatingFilter: false,
          minWidth: 150,
        },
        {
          field: "item_remark",
          headerName: t("manufacture.order.item_remark"),
          filter: "agTextColumnFilter",
          floatingFilter: false,
          minWidth: 150,
        },
        {
          field: "bom_name",
          headerName: t("manufacture.order.bom"),
          filter: "agTextColumnFilter",
          minWidth: 150,
        },
        {
          field: "routing_name",
          headerName: t("manufacture.routing.rounting_name"),
          filter: "agTextColumnFilter",
          minWidth: 150,
        },
        {
          field: "type",
          headerName: t("manufacture.order.type"),
          filter: "agTextColumnFilter",
        },
        {
          field: "tag_list",
          headerName: t("inventory.items.group_tag_list"),
          filter: "agSetColumnFilter",
          // sortable: false,
          filterParams: {
            values: async (params: any) => {
              const values = allTag;
              const formatValues = values?.map((tag) => tag.name);
              params.success(formatValues);
            },
          },
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value)
              return params.value?.map((tag: { name: string }) => (
                <Box sx={{ display: "inline-block", mr: 1, gap: 1 }}>
                  <CustomizedChips key={tag.name} value={`${tag.name}`} />
                </Box>
              ));
          },
        },
        {
          field: "other_manufacture_cost",
          headerName: t("manufacture.order.cost.other_manufacture_cost"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) => formatNumber(params.value),
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          floatingFilter: false,
          minWidth: 150,
          headerClass: "ag-end-header",
        },
        {
          field: "work_order_cost",
          headerName: t("manufacture.order.cost.work_order_cost"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) => formatNumber(params.value),
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          floatingFilter: false,
          minWidth: 150,
          headerClass: "ag-end-header",
        },
        {
          field: "bom_cost",
          headerName: t("manufacture.order.cost.bom_cost"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) => formatNumber(params.value),
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          floatingFilter: false,
          minWidth: 150,
          headerClass: "ag-end-header",
        },
        {
          field: "total_cost",
          headerName: t("manufacture.order.cost.total_cost"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) => formatNumber(params.value),
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          floatingFilter: false,
          minWidth: 150,
          headerClass: "ag-end-header",
        },
        {
          field: "remark",
          headerName: t("manufacture.bom.internal_remark"),
          filter: "agTextColumnFilter",
          floatingFilter: false,
          minWidth: 150,
        },
        {
          field: "created_date",
          headerName: t("date.created_date"),
          sort: "desc",
          hide: true,
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          suppressColumnsToolPanel: true,
          minWidth: 200,
          flex: 1,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "created_by",
          headerName: t("sentence.created_by"),
          filter: "agTextColumnFilter",
          minWidth: 200,
        },
        {
          field: "related_users",
          headerName: t("sales.employee_list"),
          filter: "agTextColumnFilter",
          minWidth: 200,
          flex: 1,
        },
        {
          field: "related_document",
          headerName: t("manufacture.order.attachment_urls"),
          filter: "agTextColumnFilter",
          suppressMenu: true,
          floatingFilter: false,
          sortable: false,
          hide: true,
          minWidth: 200,
        },
        {
          field: "waste_order",
          headerName: t("manufacture.waste.order"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => formatNumber(params.value, 0),
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          floatingFilter: false,
          minWidth: 150,
          headerClass: "ag-end-header",
        },
        {
          field: "waste_item_unique_id",
          headerName: t("manufacture.waste.item_unique_id"),
          filter: "agTextColumnFilter",
        },
        {
          field: "waste_item_name",
          headerName: t("manufacture.waste.item_name"),
          filter: "agTextColumnFilter",
        },
        {
          field: "waste_qty",
          headerName: t("manufacture.waste.qty"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params: ValueFormatterParams) => {
            return formatNumber(params.value);
          },
          floatingFilter: false,
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "waste_uom",
          headerName: t("manufacture.waste.uom"),
          filter: "agTextColumnFilter",
          floatingFilter: false,
          minWidth: 150,
        },
        {
          field: "waste_remark",
          headerName: t("manufacture.waste.remark"),
          filter: "agTextColumnFilter",
          floatingFilter: false,
          minWidth: 150,
        },
      ]);
    }
  }, [t]);
  return columnDef;
};