import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import { IBreadcrumbsAndMenu } from "../../types/global";
import { Box, Grid, styled, alpha, Typography } from "@mui/material";
import {
  useItemSkuDetailRecalculateQuery,
  useRecalculateItemSkuQtysMutation,
  useUtilResolveMismatchBarcodesMutation,
} from "../../generated/inventory";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ReactNode } from "react";
import { BoxProps } from "@mui/system";
import { useSnackbar } from "notistack";

type ExtendedBoxProps = BoxProps & {
  icon?: ReactNode;
  padding?: number | string;
  isgriditem?: string;
  ismoblie?: boolean;
  testId?: string;
  width?: number | string;
  title?: string;
  onClick?: () => void;
};

const ColorIconBox = styled(Box)<ExtendedBoxProps>(
  ({ theme, padding, isgriditem, ismoblie }) => ({
    display: "flex",
    flexDirection: !ismoblie ? "row" : "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${!ismoblie ? "#d6cfcf" : "#328F35"}`,
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      border: `1px solid ${theme.palette.primary.main}`,
    },
    cursor: "pointer",
    padding: ismoblie ? "1.5rem" : padding ? padding : ".5rem 3rem .5rem 3rem",
    width: isgriditem ? "100%" : ismoblie ? "230px" : "fit-content",
    gap: "1rem",
    height: "100%",
  })
);

const Maintenance = () => {
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: "ซ่อมบำรุง",
      to: "/admin",
    },
  ];

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: recalculateSkuQty } =
    useRecalculateItemSkuQtysMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: () => {
        enqueueSnackbar("Recalculate สำเร็จ", {
          variant: "success",
        });
      },
      onError: (err) => {
        console.error(err);
        enqueueSnackbar("Recalculate ไม่สำเร็จ", {
          variant: "error",
        });
      },
    });

  const { refetch } = useItemSkuDetailRecalculateQuery(
    graphQLClientWithHeaderItem,
    {},
    {
      enabled: false,
    }
  );

  const { mutate: resolveMismatchBarcodes } =
    useUtilResolveMismatchBarcodesMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: () => {
        enqueueSnackbar("Reset Barcode สำเร็จ", {
          variant: "success",
        });
      },
      onError: (err) => {
        console.error(err);
        enqueueSnackbar("Reset Barcode ไม่สำเร็จ", {
          variant: "error",
        });
      },
    });

  const recalculateSkuQtyHandler = async () => {
    try {
      await refetch();
      enqueueSnackbar("Recalculate สำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Recalculate ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
            <ColorIconBox
              isgriditem={"true"}
              onClick={() => recalculateSkuQty({})}
            >
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Recalculate Stock
              </Typography>
            </ColorIconBox>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
            <ColorIconBox
              isgriditem={"true"}
              onClick={recalculateSkuQtyHandler}
            >
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Recalculate Stock (New)
              </Typography>
            </ColorIconBox>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
            <ColorIconBox
              isgriditem={"true"}
              onClick={() => resolveMismatchBarcodes({})}
            >
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Reset Barcode
              </Typography>
            </ColorIconBox>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Maintenance;
