import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { RolesQuery, useRolesQuery } from "../../../generated/rbac";

import { Grid, Typography } from "@mui/material";

import PermissionForm from "../../../components/Form/Permission";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import CustomizedChips from "../../../components/Custom/CustomizedChips";

import { mergeUserPermissions } from "../../../utils/Formatter/Role";

type ExtendProps = {
  disabled: boolean;
};

const Permission = ({ disabled }: ExtendProps) => {
  const { t } = useTranslation();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: rolesResult } = useRolesQuery<RolesQuery>(graphQLClient);

  const { control, watch, setValue } = useFormContext();

  const roleList = watch("role_list");

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "role_list", // unique name for your Field Array
  });

  useEffect(() => {
    if (roleList) {
      const mergeData = mergeUserPermissions(roleList);
      setValue("permission_list", mergeData);
    }
  }, [roleList, setValue]);

  return (
    <CustomizedBox margin={"2rem 0 4rem 0"}>
      <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
        {t("user.account.permission")}
      </Typography>
      <Grid container my={2} spacing={1}>
        <Grid item sm={12} md={4}>
          <CustomizedComboBox
            options={
              rolesResult?.Roles?.map((role) => ({
                id: role?.id,
                value: role?.name,
                label: role?.name,
                role,
              })) || []
            }
            label={t("user.rbac.permission")}
            onChange={(e, newValue) => {
              if (
                newValue &&
                newValue.role &&
                roleList.filter((role: any) => role.id === newValue.role.id)
                  .length === 0
              )
                append(newValue.role);
            }}
            disabled={disabled}
            clearOnBlur
            blurOnSelect
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} alignContent="center" mb={1}>
        {fields.map((data, index) => (
          <Grid item key={index + Math.random()}>
            <Controller
              name={`role_list.${index}.name`}
              control={control}
              render={({ field }) => (
                <CustomizedChips
                  handleDelete={!disabled ? () => remove(index) : null}
                  {...field}
                  ref={field.ref}
                />
              )}
            />
          </Grid>
        ))}
      </Grid>
      <PermissionForm disabled={true} />
    </CustomizedBox>
  );
};

export default Permission;
