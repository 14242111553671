import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";

import { Typography } from "@mui/material";

import { IBom } from "../../../../types/Manufacture/bom";
import { IDefaultForm } from "../../../../types/global";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedButton from "../../../Custom/CustomizedButton";

import CheckedItemModal from "../../../Table/CheckedItemModal";
import BomItemList from "../../../Table/Manufacture/Bom/BomItemList";

import { useModal } from "../../../../hooks/use-modal";

import { ISkuDetail } from "../../../../types/Inventory/item";
import { Tracability } from "../../../../generated/inventory";

type ExtenedProps = IDefaultForm & {
  isMainPage?: boolean;
  handleClickOpenItem?: (unique_id?: string) => void;
};

type FieldArrayType = {
  ingredient_list: IBom["ingredient_list"];
};

const BomTableForm = ({
  control,
  errors,
  disabled,
  isMainPage,
  handleClickOpenItem,
}: ExtenedProps) => {
  const { t } = useTranslation();
  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);

  const { fields, remove, append, move } = useFieldArray<
    FieldArrayType,
    "ingredient_list",
    "id"
  >({
    control,
    name: "ingredient_list",
  });

  const {
    modal: itemListModal,
    openModalHandler: openItemListModalHandler,
    closeModalHandler: closeItemListModalHandler,
  } = useModal();

  const finishItemsSelect = (data: ISkuDetail[]) => {
    data.forEach((item: ISkuDetail) => {
      const newData = {
        item_unique_id: item?.sku_name ?? "",
        item_name: item?.item_sku?.item?.name ?? "",
        item_description: item?.item_sku?.item?.desc ?? "",
        item_tracability:
          item?.item_sku?.item?.tracability ?? Tracability.Serial,
        item_img_url: item?.item_sku?.item?.img_url ?? [],
        item_barcode: item?.item_sku?.item?.barcode ?? "",
        qty: "1",
        cost_per_unit: item?.item_sku?.item?.purchase_standard_price,
        uom: item?.item_sku?.item?.stock_uom?.name,
        uom_unique_id: item?.item_sku?.item?.stock_uom?.unique_id,
        item_sku_qty: {
          stock_qty: item.stock_qty,
          available_qty: item.available_qty,
          purchase_ordered_qty: item.purchase_ordered_qty,
          manufacture_ordered_qty: item.manufacture_ordered_qty,
          sale_committed_qty: item.sale_committed_qty,
          manufacture_committed_qty: item.manufacture_committed_qty,
        },
      };
      if (
        fields.every(
          ({ item_unique_id }) => item_unique_id !== newData.item_unique_id
        )
      ) {
        append(newData);
      }
    });
  };

  return (
    <CustomizedBox>
      <Typography ml={1} fontWeight="bold">
        {t("manufacture.bom.ingredient")}
      </Typography>
      <BomItemList
        fields={fields}
        move={move}
        remove={remove}
        control={control}
        errors={errors}
        disabled={disabled}
        setItemIds={setItemIds}
        setItemIdsSnapshot={setItemIdsSnapshot}
        handleClickOpenItem={handleClickOpenItem}
      />
      {!disabled && (
        <Fragment>
          <CustomizedButton
            variant="outlined"
            title="เพิ่มส่วนประกอบ"
            onClick={openItemListModalHandler}
          />
          <CheckedItemModal
            showSelectItem={itemListModal}
            closeItemTable={closeItemListModalHandler}
            finishItemsSelect={finishItemsSelect}
            itemIds={itemIds}
            setItemIds={setItemIds}
            itemIdsSnapshot={itemIdsSnapshot}
            setItemIdsSnapshot={setItemIdsSnapshot}
            rowSelection="multiple"
          />
        </Fragment>
      )}
    </CustomizedBox>
  );
};

export default BomTableForm;
