import {
  IServerSideGetRowsParams,
  GridReadyEvent,
  ColGroupDef,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { RefObject } from "@fullcalendar/core/preact";
import { GET_MOVEMENT_SUMMARY_REPORT_VIEW } from "../../../../services/AgGrid/InventoryReportAgGrid";
import { formatNumber } from "../../../../utils/dataTransformer";

interface Props {
  gridRef: RefObject<AgGridReact>;
  onFilterChanged: (params: any) => void;
}

const MovementSummaryReportTable = ({ gridRef, onFilterChanged }: Props) => {
  const { t } = useTranslation();

  const columnDefs: ColGroupDef[] = [
    {
      headerName: "",
      children: [
        {
          field: "created_date",
          headerName: t("inventory.stockEntry.createdDate"),
          filter: "agDateColumnFilter",
          hide: true,
        },
        {
          field: "source_warehouse_unique_id",
          headerName: t("inventory.warehouse"),
          filter: "agTextColumnFilter",
          hide: true,
        },
        {
          field: "item_unique_id",
          headerName: t("inventory.items.unique_id"),
          filter: "agTextColumnFilter",
          pinned: "left",
        },
        {
          field: "item_name",
          headerName: t("inventory.items.name"),
          filter: "agTextColumnFilter",
          pinned: "left",
        },
      ],
    },
    {
      headerName: "รายการเพิ่ม",
      children: [
        {
          field: "import_purchase_order",
          headerName: "นำเข้า(ซื้อ)",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "import_manufacture_order",
          headerName: "นำเข้า(ผลิต)",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "import_other",
          headerName: "นำเข้า(อื่นๆ)",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "import_sales_return",
          headerName: "รับคืน",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "import_adjust",
          headerName: "ปรับเพิ่ม",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "import_transfer",
          headerName: "โอนย้ายเข้า",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "import_sum",
          headerName: "รวมยอดเข้า",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
      ],
    },
    {
      headerName: "รายการลด",
      children: [
        {
          field: "export_sales_order",
          headerName: "นำออก(ขาย)",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "export_manufacture_order",
          headerName: "นำออก(ผลิต)",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "export_other",
          headerName: "นำออก(อื่นๆ)",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "export_purchase_return",
          headerName: "ส่งคืน",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "export_adjust",
          headerName: "ปรับลด",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "export_transfer",
          headerName: "โอนย้ายออก",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "export_sum",
          headerName: "รวมยอดออก",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
      ],
    },
    {
      headerName: "สรุป",
      children: [
        {
          field: "total_sum",
          headerName: "รวมเพิ่ม/ลด",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
      ],
    },
    {
      headerName: "ข้อมูลสินค้า",
      children: [
        {
          field: "available_qty",
          headerName: "คงเหลือสุทธิ",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "stock_qty",
          headerName: "คงคลัง",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "purchase_ordered_qty",
          headerName: "กำลังจัดซื้อ",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "manufacture_ordered_qty",
          headerName: "กำลังผลิต",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "sale_committed_qty",
          headerName: "ถูกจอง(ขาย)",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
        {
          field: "manufacture_committed_qty",
          headerName: "ถูกจอง(ผลิต)",
          filter: false,
          valueFormatter: (params) => formatNumber(params.value),
          headerClass: "ag-end-header",
          cellClass: "ag-end-cell",
        },
      ],
    },
  ];

  const groupInventoryData = (data: any) => {
    return Object.values(
      data.reduce((acc: any, item: any) => {
        const key = `${item.item_unique_id}-${item.item_name}`;

        if (!acc[key]) {
          acc[key] = {
            item_unique_id: item.item_unique_id,
            item_name: item.item_name,
            import_purchase_order: 0,
            import_manufacture_order: 0,
            import_other: 0,
            import_sales_return: 0,
            import_adjust: 0,
            import_transfer: 0,
            import_sum: 0,
            export_sales_order: 0,
            export_manufacture_order: 0,
            export_other: 0,
            export_purchase_return: 0,
            export_adjust: 0,
            export_transfer: 0,
            export_sum: 0,
            total_sum: 0,
            available_qty: 0,
            stock_qty: 0,
            purchase_ordered_qty: 0,
            manufacture_ordered_qty: 0,
            sale_committed_qty: 0,
            manufacture_committed_qty: 0,
          };
        }

        // Sum up numeric fields
        acc[key].import_purchase_order += item.import_purchase_order;
        acc[key].import_manufacture_order += item.import_manufacture_order;
        acc[key].import_other += item.import_other;
        acc[key].import_sales_return += item.import_sales_return;
        acc[key].import_adjust += item.import_adjust;
        acc[key].import_transfer += item.import_transfer;
        acc[key].import_sum += item.import_sum;
        acc[key].export_sales_order += item.export_sales_order;
        acc[key].export_manufacture_order += item.export_manufacture_order;
        acc[key].export_other += item.export_other;
        acc[key].export_purchase_return += item.export_purchase_return;
        acc[key].export_adjust += item.export_adjust;
        acc[key].export_transfer += item.export_transfer;
        acc[key].export_sum += item.export_sum;
        acc[key].total_sum += item.total_sum;
        acc[key].available_qty += item.available_qty;
        acc[key].stock_qty += item.stock_qty;
        acc[key].purchase_ordered_qty += item.purchase_ordered_qty;
        acc[key].manufacture_ordered_qty += item.manufacture_ordered_qty;
        acc[key].sale_committed_qty += item.sale_committed_qty;
        acc[key].manufacture_committed_qty += item.manufacture_committed_qty;

        return acc;
      }, {})
    );
  };

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { filterModel, sortModel } = request;
      const { created_date, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        created_date: dateFilterModel(created_date),
      };
      try {
        const { MovementSummaryReportViews } = await graphQLClient.request(
          GET_MOVEMENT_SUMMARY_REPORT_VIEW,
          {
            aggridInput: {
              startRow: 0,
              endRow: 999999,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );

        const rowData = groupInventoryData(MovementSummaryReportViews.data);
        params.success({
          rowData,
          rowCount: MovementSummaryReportViews.count,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    onFilterChanged(params);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default MovementSummaryReportTable;
