import { Box, Typography, useMediaQuery, Link } from "@mui/material";
import { Theme } from "@mui/material";
import LoginForm from "../components/Form/Login";
import { COMPANY_NAME, CURRENT_VERSION } from "../config/variables";
import { useLocation, useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { useStateContext } from "../contexts/auth-context";
import { useLoginMutation } from "../generated/company-user";
import { useCookies } from "react-cookie";
import jwt from "jwt-decode";
import { IToken } from "../types/Auth/token";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { IUser } from "../types/Auth/user";
import dayjs from "dayjs";
import { ILogin } from "../types/Auth/login";
import { usePostHog } from "posthog-js/react";
import CryptoJS from "crypto-js";

// import { defaultPermission } from "../utils/userInfo";
const secretKey = process.env.REACT_APP_SECRET_KEY_CLIENT as string;

const Login = () => {
  const posthog = usePostHog();
  const [, setCookie] = useCookies([
    "access_token",
    "refresh_token",
    "logged_in",
    "selected_tenant",
  ]);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  const location = useLocation();
  const from = ((location.state as any)?.from.pathname as string) || "/landing";

  const { enqueueSnackbar } = useSnackbar();

  const stateContext = useStateContext();

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware(
    "company-user",
    false
  );

  const { isLoading, mutateAsync: login } =
    useLoginMutation<Error>(graphQLClient);

  const onLoginSubmit = async ({ email, password }: ILogin) => {
    const ciphertext = CryptoJS.AES.encrypt(password, secretKey).toString();
    try {
      posthog?.capture("User clicked log in");
      const { login: loginData } = await login({
        loginInput: {
          email,
          password: ciphertext,
        },
      });

      if (loginData) {
        const { access_token, refresh_token, user } = loginData;

        const { exp: accessMaxAge } = jwt<IToken>(access_token);
        const { exp: refreshMaxAge } = jwt<IToken>(refresh_token);

        setCookie("access_token", access_token, {
          path: "/",
          maxAge: accessMaxAge,
        });
        setCookie("refresh_token", refresh_token, {
          path: "/",
          maxAge: refreshMaxAge,
        });
        setCookie("logged_in", "true", {
          path: "/",
        });

        if (user.company_list) {
          if (user.company_list.length === 1) {
            sessionStorage.setItem(
              "tenant-id",
              user.company_list[0]?.unique_id || ""
            );
            setCookie("selected_tenant", "true", { path: "/" });
            const is_expire = dayjs(
              user.company_list[0]?.expired_date
            ).isBefore(dayjs());

            const companyInfo = {
              is_expired: is_expire,
              expired_date: user.company_list[0]?.expired_date,
              subscription_level_id:
                user.company_list[0]?.subscription_level_id,
              resubscription_date: user.company_list[0]?.resubscription_date,
              register_date: user.company_list[0]?.register_date,
            };

            sessionStorage.setItem("company-info", JSON.stringify(companyInfo));

            //need to change register_date to expired_date after fix
            if (is_expire) {
              navigate("/setting/package");
            } else navigate(from);

            navigate(from);
          } else {
            navigate("/select_company");
          }
        }
        stateContext.dispatch({
          type: "SET_USER",
          payload: user as IUser,
        });
      }
    } catch (error: any) {
      error.response.errors.forEach((err: any) => {
        enqueueSnackbar("อีเมลหรือรหัสผ่านไม่ถูกต้อง", {
          variant: "error",
        });
      });
    }
  };

  return !isMobile ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background:
          "linear-gradient(116.58deg, #36458C 10.99%, #C4CAE4 50.84%, #FFFFFF 60%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="login" src="/static/loginBg.svg" />
        </Box>
        <Box
          sx={{
            borderRadius: "40px 0 0 40px",
            background: "white",
            display: "flex",
            flex: 1,
            height: "inherit",
            alignItems: "center",
            flexDirection: "column",
            p: "2rem",
            justifyContent: "space-between",
            overflowY: "scroll",
            overflowX: "hidden",
            minWidth: "fit-content",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              flex: 15,
            }}
          >
            <img alt="logo" src="/static/logo.png" style={{ height: 75 }} />
            <Typography variant="h4" sx={{ mt: 8, mb: 2 }}>
              เข้าสู่ระบบ
            </Typography>
            <LoginForm
              isMobile={isMobile}
              isLoading={isLoading}
              onLoginSubmit={onLoginSubmit}
            />
            <Box sx={{ display: "flex", gap: 0.5, mt: 4 }}>
              <Typography sx={{ fontSize: 14 }}>
                บริษัท/ร้านค้าใหม่ ?
              </Typography>
              <Link
                rel="noopener noreferrer"
                href="https://forms.clickup.com/3654689/f/3fh11-20922/J08LMINOHLJX89ST77"
                underline="hover"
                target="_blank"
                sx={{
                  fontSize: 14,
                }}
              >
                สมัครใช้งาน
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 3,
            }}
          >
            <Link
              rel="noopener noreferrer"
              href="https://doc.clickup.com/3654689/p/h/3fh11-57182/e95aa4dfc72747b"
              underline="hover"
              target="_blank"
              sx={{
                fontSize: 14,
              }}
            >
              ข้อตกลงและเงื่อนไข
            </Link>
            <Typography mx={1}>|</Typography>
            <Link
              rel="noopener noreferrer"
              href="https://doc.clickup.com/3654689/p/h/3fh11-57162/7dccea7012a1e75"
              underline="hover"
              target="_blank"
              sx={{
                fontSize: 14,
              }}
            >
              นโยบายความเป็นส่วนตัว
            </Link>
          </Box>
          <Box sx={{ width: "100%", pt: "2rem", flex: 1 }}>
            <Box sx={{ display: "flex", gap: 0.5, justifyContent: "center" }}>
              <Typography sx={{ opacity: 0.52, fontSize: 14 }}>
                Powered by
              </Typography>
              <Link
                rel="noopener noreferrer"
                href="https://www.npr.digital"
                underline="hover"
                target="_blank"
                sx={{
                  fontSize: 14,
                  opacity: 1,
                }}
              >
                {COMPANY_NAME}
              </Link>
            </Box>
            <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
              Application version {CURRENT_VERSION}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        background: "white",
        display: "flex",
        height: "100dvh",
        flex: 1,
        alignItems: "center",
        flexDirection: "column",
        p: 4,
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          flex: 15,
        }}
      >
        <img alt="logo" src="/static/logo.png" style={{ height: 75 }} />
        <Typography variant="h4" sx={{ mt: 8, mb: 2 }}>
          เข้าสู่ระบบ
        </Typography>
        <LoginForm
          isMobile={isMobile}
          isLoading={isLoading}
          onLoginSubmit={onLoginSubmit}
        />
        <Box sx={{ display: "flex", gap: 0.5, mt: 4 }}>
          <Typography sx={{ fontSize: 14 }}>บริษัท/ร้านค้าใหม่ ?</Typography>
          <Link
            rel="noopener noreferrer"
            href="https://forms.clickup.com/3654689/f/3fh11-20922/J08LMINOHLJX89ST77"
            underline="hover"
            target="_blank"
            sx={{
              fontSize: 14,
            }}
          >
            สมัครใช้งาน
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          py: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          rel="noopener noreferrer"
          href="https://doc.clickup.com/3654689/p/h/3fh11-57182/e95aa4dfc72747b"
          underline="hover"
          target="_blank"
          sx={{
            fontSize: 14,
          }}
        >
          ข้อตกลงและเงื่อนไข
        </Link>
        <Typography mx={1}>|</Typography>
        <Link
          rel="noopener noreferrer"
          href="https://doc.clickup.com/3654689/p/h/3fh11-57162/7dccea7012a1e75"
          underline="hover"
          target="_blank"
          sx={{
            fontSize: 14,
          }}
        >
          นโยบายความเป็นส่วนตัว
        </Link>
      </Box>
      <Box sx={{ width: "100%", pt: 4 }}>
        <Box sx={{ display: "flex", gap: 0.5, justifyContent: "center" }}>
          <Typography sx={{ opacity: 0.52, fontSize: 14 }}>
            Powered by
          </Typography>
          <Link
            rel="noopener noreferrer"
            href="https://www.npr.digital"
            underline="hover"
            target="_blank"
            sx={{
              fontSize: 14,
              opacity: 1,
            }}
          >
            {COMPANY_NAME}
          </Link>
        </Box>
        <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
          Application version {CURRENT_VERSION}
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
