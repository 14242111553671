import { CustomizedBox } from "../../Custom/CustomizedBox";
import { IRemark, IRemarkTemplate } from "../../../types/Setting/remark";
import CustomizedButton from "../../Custom/CustomizedButton";
import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import ControlledTextField from "../../Controller/ControlledTextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFieldArray } from "react-hook-form";
import { IDefaultForm } from "../../../types/global";
import {
  EntityTypeEnum,
  useRemarkTemplatesQuery,
} from "../../../generated/remark";
import { Dispatch, SetStateAction, useEffect } from "react";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";

interface Props {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  tab: string | null;
  setRemoveIds: Dispatch<SetStateAction<number[]>>;
}

const RemarkTemplateForm = ({ control, errors, tab, setRemoveIds }: Props) => {
  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading, isFetching } = useRemarkTemplatesQuery(
    graphQLClientWithHeaderSetting,
    {
      whereInput: {
        entity_type: {
          equals: tab,
        },
      },
    }
  );

  const { fields, append, remove, replace } = useFieldArray<
    IRemarkTemplate,
    "remark_templates",
    "fieldId"
  >({
    control,
    name: "remark_templates",
    keyName: "fieldId",
  });

  useEffect(() => {
    if (data?.remarkTemplates && data?.remarkTemplates.length > 0) {
      const formatData: IRemark[] = data?.remarkTemplates.map((template) => ({
        id: template.id,
        name: template.name || "",
        remark_text: template.remark_text || "",
        entity_type: template.entity_type,
      }));
      replace(formatData);
    } else {
      replace([]);
    }
  }, [data?.remarkTemplates, replace]);

  const addRemarkTemplateHandler = () => {
    append({
      entity_type: tab as EntityTypeEnum,
      name: "",
      remark_text: "",
    });
  };

  const removeRemarkTemplateHandler = (
    index: number,
    id: number | undefined
  ) => {
    remove(index);
    if (id) {
      setRemoveIds((prev) => [...prev, id]);
    }
  };

  if (isLoading || isFetching) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <CustomizedBox>
      <CustomizedButton
        variant="outlined"
        title="เพิ่มหมายเหตุ"
        onClick={addRemarkTemplateHandler}
      />
      <Box>
        {fields.map((field, index) => (
          <Grid container spacing={2} key={field.fieldId} mt={2}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Box display="flex" gap={1}>
                <ControlledTextField
                  label="ชื่อหมายเหตุ"
                  control={control}
                  name={`remark_templates.${index}.name`}
                  error={Boolean(
                    errors?.remark_templates &&
                      errors?.remark_templates[index] &&
                      errors?.remark_templates[index]?.name
                  )}
                  helperText={
                    errors?.remark_templates &&
                    errors?.remark_templates[index] &&
                    errors?.remark_templates[index]?.name &&
                    errors?.remark_templates[index]?.name?.message
                  }
                />
                <IconButton
                  onClick={() => removeRemarkTemplateHandler(index, field.id)}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                name={`remark_templates.${index}.remark_text`}
                multiline
                minRows={3}
                error={Boolean(
                  errors?.remark_templates &&
                    errors?.remark_templates[index] &&
                    errors?.remark_templates[index]?.remark_text
                )}
                helperText={
                  errors?.remark_templates &&
                  errors?.remark_templates[index] &&
                  errors?.remark_templates[index]?.remark_text &&
                  errors?.remark_templates[index]?.remark_text?.message
                }
              />
            </Grid>
          </Grid>
        ))}
      </Box>
    </CustomizedBox>
  );
};

export default RemarkTemplateForm;
