import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IDefaultForm, ISelectOption } from "../../../../types/global";
import { useGoodsReturnItemList } from "../../../../hooks/Inventory/use-return-item-list";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import Confirmation from "../../../UI/Confirmation";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useForm,
  useWatch,
} from "react-hook-form";
import { IGoodsReturn } from "../../../../types/Inventory/goodsReturn";
import { IBarcodeForm, ITraceEntry } from "../../../../types/Inventory";
import {
  InventoryDocumentType,
  Tracability,
  TraceEntriesFindManyAggridQuery,
  useTraceEntriesFindManyAggridQuery,
} from "../../../../generated/inventory";
import { useStateContext } from "../../../../contexts/auth-context";
import { goodsReturnTraceEntryFormatter } from "../../../../utils/Formatter/GoodsReturn";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { formatDateTimeNoAMPM } from "../../../../utils/Formatter/Date";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import ClearIcon from "@mui/icons-material/Clear";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import SerialList from "./SerialList";
import { Fragment, useState } from "react";
import TotalPostedQuantityCell from "./TotalPostedQuantityCell";
import { useSnackbar } from "notistack";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import useBarcodeScanner from "../../../../hooks/Inventory/use-barcode-scanner";
import { QrReader } from "react-qr-reader";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { formatNumber } from "../../../../utils/dataTransformer";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import { IWarehouse } from "../../../../types/Setting/inventory";

type Props = IDefaultForm & {
  fields: FieldArrayWithId<IGoodsReturn, "trace_entry_list", "id">[];
  append: UseFieldArrayAppend<IGoodsReturn, "trace_entry_list">;
  update: UseFieldArrayUpdate<IGoodsReturn, "trace_entry_list">;
  remove: UseFieldArrayRemove;
  status?: string | null;
  allWarehouses?: IWarehouse[];
  step: number;
  initialTraceItems?: string[];
  existingInitialTraceItems?: string[];
};

const GoodsReturnScanItemList = ({
  control,
  errors,
  disabled,
  fields,
  getValues,
  append,
  remove,
  status,
  allWarehouses,
  step,
  update,
  initialTraceItems,
  existingInitialTraceItems,
}: Props) => {
  const {
    state: { authUser },
  } = useStateContext();
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const watchMainStatus = useWatch({
    control,
    name: "main_status",
  });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const headers = useGoodsReturnItemList(step, disabled);

  const deleteItemHandler = () => {
    remove(deletedIndex);
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(deleteItemHandler);

  const watchSourceWarehouseUniqueId = useWatch({
    control,
    name: "source_warehouse_unique_id",
  });

  const foundWarehouse = allWarehouses?.find(
    (warehouse) => warehouse?.unique_id === watchSourceWarehouseUniqueId
  );

  let allBinLocation: any[] | undefined | null = [];

  foundWarehouse?.warehouse_level_1_list?.forEach((level1) => {
    level1.sub_level_2_list?.forEach((level2) => {
      level2?.sub_level_3_list?.forEach((level3) => {
        if (level3?.bin_location_list) {
          allBinLocation?.push(...level3.bin_location_list);
        }
      });
    });
  });

  const binLocationOptions: ISelectOption[] = allBinLocation.map((bin) => ({
    label: bin.bin_name,
    value: bin.id,
  }));

  const {
    control: barcodeControl,
    getValues: getBarcodeValues,
    reset: resetBarcode,
    watch: watchBarcode,
    setValue: setBarcodeValue,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      barcodeMobile: "",
      source_bin_location_id: "",
    },
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { refetch } =
    useTraceEntriesFindManyAggridQuery<TraceEntriesFindManyAggridQuery>(
      graphQLClientWithHeaderItem,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            barcode: {
              filterType: "text",
              type: "equals",
              filter:
                watchBarcode("barcodeMobile")?.trim() ||
                watchBarcode("barcode").trim(),
            },
          },
        },
      },
      {
        enabled: false,
      }
    );

  const onBarcodeSubmitHandler = async () => {
    const { data } = await refetch();
    const source_bin_location_id = getBarcodeValues("source_bin_location_id");
    const foundBinLocation = allBinLocation?.find(
      (bin) => bin.id === source_bin_location_id
    );

    const traceEntries = data?.traceEntriesFindManyAggrid?.data;

    const traceEntry = traceEntries
      ? traceEntries.find(
          (trace) => trace?.type === InventoryDocumentType.GoodsReceive
        )
      : null;

    //TODO: validation
    if (traceEntry) {
      const traceEntryType = traceEntry as ITraceEntry;
      const formatTrace = goodsReturnTraceEntryFormatter(
        traceEntryType,
        foundBinLocation,
        authUser
      );

      console.log("formatTrace", formatTrace);

      if (formatTrace) {
        const foundExisting = fields.findIndex(
          (trace) => trace.item_unique_id === formatTrace.item_unique_id
        );
        if (formatTrace.tracability === Tracability.Serial) {
          if (foundExisting === -1) {
            const {
              qty,
              posted_date,
              serial_no,
              source_bin_location,
              source_bin_location_id,
              scanned_by,
              barcode,
              ...otherTrace
            } = formatTrace;
            const formatTraceWithSerial = {
              ...otherTrace,
              qty: 0,
              serial_list: [
                {
                  ...otherTrace,
                  qty,
                  posted_date,
                  serial_no,
                  source_bin_location,
                  source_bin_location_id,
                  scanned_by,
                  barcode,
                },
              ],
            };
            append(formatTraceWithSerial);
          } else {
            const currentTrace: ITraceEntry = getValues(
              `trace_entry_list[${foundExisting}]`
            );

            if (currentTrace && currentTrace.serial_list) {
              const foundBarcode = currentTrace.serial_list.findIndex(
                (serial) =>
                  serial.barcode ===
                  (watchBarcode("barcodeMobile")?.trim() ||
                    watchBarcode("barcode").trim())
              );
              if (foundBarcode === -1) {
                const { document_item_qty, posted_qty, ...otherTrace } =
                  formatTrace;
                const formatTraceWithDocument: ITraceEntry = {
                  ...otherTrace,
                  document_item_qty: currentTrace.document_item_qty,
                  posted_qty: currentTrace.posted_qty,
                  reference_line_item: currentTrace.reference_line_item,
                };
                update(foundExisting, {
                  ...currentTrace,
                  serial_list: [
                    ...currentTrace.serial_list,
                    formatTraceWithDocument,
                  ],
                });
              } else {
                enqueueSnackbar(
                  `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                  {
                    variant: "error",
                    style: { whiteSpace: "pre-line" },
                  }
                );
              }
            }
          }
        } else {
          const foundExisting = fields.findIndex(
            (trace) => trace.item_unique_id === formatTrace.item_unique_id
          );
          if (foundExisting === -1) {
            append(formatTrace);
          } else {
            const currentTrace: ITraceEntry = getValues(
              `trace_entry_list[${foundExisting}]`
            );
            const { document_item_qty, posted_qty, ...otherTrace } =
              formatTrace;

            const formatTraceWithDocument: ITraceEntry = {
              ...otherTrace,
              document_item_qty: currentTrace.document_item_qty,
              posted_qty: currentTrace.posted_qty,
            };

            update(foundExisting, formatTraceWithDocument);
          }
        }
        enqueueSnackbar(`สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`, {
          variant: "success",
          style: { whiteSpace: "pre-line" },
        });
      }
    } else {
      // Barcode not found
      enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่", {
        variant: "error",
      });
    }
    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  };

  const { scrollRef, showCamera, setShowCamera, setScanData, setIsInterval } =
    useBarcodeScanner(onBarcodeSubmitHandler);

  return (
    <>
      {step === 2 && (
        <>
          {!isMobile && <Typography fontWeight="bold">สแกน Barcode</Typography>}
          <Grid container spacing={2} mt={1} mb={3}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <ControlledSelect
                label="เลือกสถานที่"
                control={barcodeControl}
                name="source_bin_location_id"
                options={binLocationOptions || []}
                disabled={disabled || !watchSourceWarehouseUniqueId}
              />
            </Grid>
            {!isMobile ? (
              <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                <ControlledTextField
                  label="Scan Barcode"
                  control={barcodeControl}
                  name="barcode"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey === false) {
                      onBarcodeSubmitHandler();
                    }
                  }}
                  disabled={disabled || !watchBarcode("source_bin_location_id")}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={10}>
                  <ControlledTextField
                    label="Scan Barcode"
                    control={barcodeControl}
                    name="barcode"
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.shiftKey === false) {
                        onBarcodeSubmitHandler();
                      }
                    }}
                    disabled={
                      disabled || !watchBarcode("source_bin_location_id")
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    disabled={
                      disabled || !watchBarcode("source_bin_location_id")
                    }
                    onClick={() => setShowCamera(!showCamera)}
                  >
                    <QrCodeScannerIcon />
                  </IconButton>
                </Grid>
              </>
            )}
          </Grid>
          {isMobile ? (
            <Box display={showCamera ? "block" : "none"} my={2}>
              {showCamera && (
                <QrReader
                  constraints={{ facingMode: "environment" }}
                  onResult={(result) => {
                    if (!!result) {
                      setScanData(result.getText());
                      setIsInterval(true);
                      setBarcodeValue("barcodeMobile", result.getText());
                    }
                  }}
                  scanDelay={2000}
                  containerStyle={{
                    width: "calc(100vw-48px)",
                    height: "100%",
                    contentVisibility: "auto",
                  }}
                  videoContainerStyle={{
                    width: "calc(100vw-48px)",
                    height: "100%",
                    contentVisibility: "auto",
                  }}
                  videoStyle={{
                    height: "100%",
                    contentVisibility: "auto",
                  }}
                />
              )}
            </Box>
          ) : null}
        </>
      )}
      <TableContainer ref={scrollRef}>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align="center"
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    width={header.width}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((trace, index) => (
              <Fragment key={trace.id}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[0]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{index + 1}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[1]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {formatDateTimeNoAMPM(trace.posted_date)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[2]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        minWidth: "90px",
                        justifyContent: "center",
                      }}
                    >
                      <Avatar
                        alt={trace.item_name}
                        src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                        sx={{
                          width: 60,
                          height: 60,
                          border: "1px solid #BEBEBE",
                          borderRadius: "2px",
                        }}
                        variant="square"
                      >
                        <ImageOutlinedIcon
                          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                          fontSize="medium"
                        />
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[3]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {trace.item_unique_id}
                    </Typography>
                    <Typography fontSize={14}>{trace.item_name}</Typography>
                    {watchMainStatus === "draft" &&
                      initialTraceItems?.includes(trace.item_unique_id) &&
                      !existingInitialTraceItems?.includes(
                        trace.item_unique_id
                      ) && (
                        <Typography
                          fontSize={12}
                          sx={{
                            mt: 0.5,
                            color: (theme) => theme.palette.error.main,
                          }}
                        >
                          สินค้านี้ถูกลบออกจากระบบแล้ว กรุณาลบสินค้า
                        </Typography>
                      )}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[4]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {formatNumber(trace.document_item_qty || 0)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[5]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {formatNumber(trace.posted_qty || 0)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[6]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {formatNumber(
                        (trace.document_item_qty || 0) - (trace.posted_qty || 0)
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[7]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal ? (
                      <>
                        {disabled || !trace.scanned_by ? (
                          <Typography fontSize={14}>
                            {formatNumber(trace.qty)}
                          </Typography>
                        ) : (
                          // <ControlledTextField
                          //   type="number"
                          //   control={control}
                          //   name={`trace_entry_list[${index}].qty`}
                          //   disabled={disabled}
                          // error={Boolean(
                          //   errors &&
                          //     errors.trace_entry_list &&
                          //     errors.trace_entry_list[index] &&
                          //     errors.trace_entry_list[index].qty
                          // )}
                          //   InputProps={{
                          //     inputProps: {
                          //       min: 0,
                          //     },
                          //   }}
                          //   FormHelperTextProps={{
                          //     style: { fontSize: "10px" },
                          //   }}
                          // />
                          <ControlledNumberTextField
                            control={control}
                            name={`trace_entry_list.${index}.qty`}
                            disabled={disabled}
                            error={Boolean(
                              errors &&
                                errors.trace_entry_list &&
                                errors.trace_entry_list[index] &&
                                errors.trace_entry_list[index].qty
                            )}
                            inputFontSize={14}
                            helperTextFontSize={10}
                          />
                        )}
                      </>
                    ) : (
                      <TotalPostedQuantityCell
                        control={control}
                        nestedIndex={index}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[8]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.uom?.name}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[8]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.serial_no}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[9]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {trace.source_bin_location?.bin_name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[10]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.scanned_by && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CustomizedAvatar
                          avatars={[
                            {
                              unique_id: trace.scanned_by.user_unique_id || "",
                              first_name: trace.scanned_by.first_name || "",
                              last_name: trace.scanned_by.last_name || "",
                              img_url: trace.scanned_by.img_url
                                ? trace.scanned_by.img_url[0]
                                : "",
                            },
                          ]}
                        />
                      </Box>
                    )}
                  </TableCell>
                  <TableCell
                    width={
                      (headers && headers.length > 0
                        ? headers[11]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.barcode}</Typography>
                  </TableCell>
                  {!disabled && (
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[12]?.width || 0
                          : 0) + 16
                      }
                    >
                      <IconButton
                        onClick={() => {
                          openConfirmationHandler();
                          setDeletedIndex(index);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
                <SerialList
                  control={control}
                  errors={errors}
                  nestedIndex={index}
                  disabled={disabled}
                />
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title="ยืนยันหากต้องการลบสินค้า"
        message="หากลบสินค้าแล้ว รายการ SN ที่สแกนไว้จะถูกลบทั้งหมด"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default GoodsReturnScanItemList;
