import { Box, Typography } from "@mui/material";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import PosPaymentFooter from "./PaymentFooter";

const PosLeftPanel = () => {
  return (
    <Box width={"100%"}>
      <CustomizedBox
        //   minWidth={766}
        height={"calc(100% - 84px)"}
        width={"100%"}
        padding={"1rem"}
        margin={"1rem 0"}
        border={`1px solid #E2E2E2`}
        boxShadow={"unset"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
        >
          <Typography variant="h5">{"Left Panel"}</Typography>
        </Box>
      </CustomizedBox>
      <PosPaymentFooter />
    </Box>
  );
};

export default PosLeftPanel;
