import { t } from "i18next";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { GraphQLClient } from "graphql-request";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../../contexts/auth-context";

import { useDisable } from "../../../hooks/use-disable";

import { IBreadcrumbsAndMenu } from "../../../types/global";

import { Box } from "@mui/material";

import ButtonLayout from "../../../components/UI/ButtonLayout";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import SalesBomImg from "../../../components/Form/Inventory/SalesBom/SalesBomImg";
import SalesBomInfo from "../../../components/Form/Inventory/SalesBom/SalesBomInfo";
import SalesBomHeader from "../../../components/Form/Inventory/SalesBom/SalesBomHeader";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import {
  salesBomSchema,
  salesBomValidate,
} from "../../../components/Form/Inventory/SalesBom/schema";

import {
  salesBomCanDisable,
  salesBomCreatePayloadFormatter,
  salesBomQueryFormatter,
  salesBomUpdatePayloadFormatter,
} from "../../../utils/Formatter/SalesBom";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  UomsQuery,
  useItemCreateWithUomMutation,
  useItemSkuDetailQuery,
  useItemUpdateWithSkuMutation,
  useUomsQuery,
} from "../../../generated/inventory";

const SalesBomContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useDisable();
  const { enqueueSnackbar } = useSnackbar();
  const graphQLClientItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const {
    state: { authUser },
  } = useStateContext();

  const methods = useForm({
    defaultValues: salesBomSchema,
    resolver: yupResolver(salesBomValidate),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const { data, isSuccess, isLoading, refetch } = useItemSkuDetailQuery(
    graphQLClientItem,
    {
      uniqueInput: {
        sku_name: id || "",
      },
    },
    { enabled: !!id }
  );

  const { data: uomData, isLoading: isLoadingUom } =
    useUomsQuery<UomsQuery>(graphQLClientItem);

  useEffect(() => {
    if (isSuccess) {
      const { itemSkuDetail } = data;
      const getSalesBomData = async () => {
        const formattedSalesBom = salesBomQueryFormatter(itemSkuDetail);
        reset(formattedSalesBom);
      };
      getSalesBomData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (id) setDisabled(true);
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.sales_bom.index"),
      to: "/inventory/sales_bom",
    },
    {
      name: Boolean(id)
        ? data?.itemSkuDetail?.item_sku?.item?.unique_id ?? ""
        : t("inventory.sales_bom.add"),
    },
  ];

  const { mutateAsync: createSalesBom, isLoading: isCreating } =
    useItemCreateWithUomMutation(graphQLClientItem);

  const { mutateAsync: updateSalesBom, isLoading: isUpdating } =
    useItemUpdateWithSkuMutation(graphQLClientItem);

  //submit
  const salesBomCreateHandler = async (createData: any) => {
    if (createData) {
      try {
        const salesBomFormatted = await salesBomCreatePayloadFormatter({
          ...createData,
          stock_uom_unique_id: uomData?.uoms?.[0]?.unique_id,
          created_by: {
            user_unique_id: authUser?.unique_id,
            email: authUser?.email,
            first_name: authUser?.first_name,
            last_name: authUser?.last_name,
            img_url: authUser?.img_url,
          },
        });

        const { itemCreateWithUOM } = await createSalesBom({
          createInput: salesBomFormatted,
        });

        navigate("/inventory/sales_bom/" + itemCreateWithUOM?.unique_id);

        enqueueSnackbar("สร้างชุดสินค้าสำเร็จ", {
          variant: "success",
        });
      } catch (error) {
        const formatError = JSON.stringify(error);

        if (
          formatError.includes(
            "Unique constraint failed on the fields: (`barcode`)"
          )
        )
          enqueueSnackbar("รหัสสินค้านี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่", {
            variant: "error",
          });
        else
          enqueueSnackbar("สร้างชุดสินค้าไม่สำเร็จ", {
            variant: "error",
          });
      }
    }
  };

  const salesBomUpdateHandler = async (updatedData: any) => {
    if (updatedData) {
      try {
        const { unique_id, ...salesBomFormatted } =
          await salesBomUpdatePayloadFormatter(updatedData);

        if (
          data?.itemSkuDetail?.is_active &&
          !salesBomFormatted.is_active &&
          !(await salesBomCanDisable(unique_id))
        )
          return enqueueSnackbar(
            "ไม่สามารถหยุดใช้งานได้ เนื่องจากถูกใช้ในเอกสารที่กำลังดำเนินการ",
            {
              variant: "error",
            }
          );

        await updateSalesBom({
          uniqueInput: { unique_id },
          updateInput: salesBomFormatted,
        });

        await refetch();

        setDisabled(true);

        enqueueSnackbar("แก้ไขสำเร็จ", {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar("แก้ไขไม่สำเร็จ", {
          variant: "error",
        });
      }
    }
  };

  const cancelEdit = () => {
    if (!id) navigate("/inventory/sales_bom");
    else {
      setDisabled(true);
      reset();
    }
  };

  //snackbar error
  useEffect(() => {
    if (errors) {
      if (errors.unique_id)
        enqueueSnackbar("กรุณาระบุรหัสสินค้า", {
          variant: "error",
        });
      if (errors.name)
        enqueueSnackbar("กรุณาระบุชื่อสินค้า", {
          variant: "error",
        });
    }
  }, [enqueueSnackbar, errors]);

  if (id && isLoading && isLoadingUom) return <></>;

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: 1650,
        }}
      >
        <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      </Box>
      <SalesBomHeader />
      <SalesBomInfo />
      <SalesBomImg />
      {(!id || !disabled) && (
        <BottomNavbar>
          <ButtonLayout>
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              onClick={cancelEdit}
            />
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={handleSubmit(
                id ? salesBomUpdateHandler : salesBomCreateHandler
              )}
            />
          </ButtonLayout>
        </BottomNavbar>
      )}
    </FormProvider>
  );
};

export default SalesBomContainer;
