import { Box, Divider, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { formatNumber } from "../../utils/dataTransformer";

interface Props {
  isSufficient: boolean;
  formatData: any[];
  uom: string;
}

const ItemQty = ({ isSufficient, formatData, uom }: Props) => {
  return (
    <Box
      sx={{ p: 1, display: "flex", flexDirection: "column", width: "250px" }}
    >
      <Box
        sx={{
          mb: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: isSufficient ? "#8FCBB1" : "#F44336",
          fontSize: "13px",
        }}
      >
        <CircleIcon fontSize="inherit" />
        <Typography ml={1}>
          {isSufficient ? "สินค้าเพียงพอ" : "สินค้าไม่เพียงพอ"}
        </Typography>
      </Box>
      <Divider />
      {formatData.map((item) => (
        <Box
          key={item.status}
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 1,
            justifyContent: "space-between",
          }}
        >
          <Typography>{item.status}</Typography>
          <Typography sx={{ flex: "1", mr: 1 }} align="right">
            {formatNumber(item.amount)}
          </Typography>
          <Typography>{uom}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ItemQty;
