import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import ReportDateFilter from "../../../components/UI/ReportDateFilter";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useRef } from "react";
import WasteManufactureOrderReportTable from "../../../components/Table/Manufacture/Waste/Report";
import { IWasteManufactureOrder } from "../../../types/Manufacture/order";
import { useState } from "react";

// exporter
import CustomizedButton from "../../../components/Custom/CustomizedButton";

import { useSnackbar } from "notistack";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {useWasteManufactureOrderExportQuery, WasteManufactureOrderExportQuery, DateFilter} from "../../../generated/manufacture";

const WasteManufactureOrderReport = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<IWasteManufactureOrder>>(null);
  const [showTable, setShowTable] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("report"),
      to: "/manufacture/report",
    },
    {
      name: t("manufacture.sentence.waste_report"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "today",
      dateFrom: dayjs().startOf("day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    },
  });

  const graphqlClient = createGraphQLClientWithMiddleware("manufacture");

  const [exportInput, setExportInput] = useState<any | null>(
    null
  );

  const { refetch: refetchData, isLoading: isExporting } =
    useWasteManufactureOrderExportQuery<WasteManufactureOrderExportQuery>(
      graphqlClient,
      {
        aggridInput: exportInput?.aggridInput,
        dateFilter: exportInput?.dateFilter
      },
      {
        enabled: false,
      }
    );

  const onBtnExport = useCallback(() => {
    const fetchDataAndOpenWindow = async () => {
      try {
        const { data: exportData } = await refetchData();
        window.open(exportData?.WasteManufactureOrderExport || "", "_blank");
        enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    };

    if (exportInput !== null) {
      fetchDataAndOpenWindow();
    }
  }, [enqueueSnackbar, exportInput, t]);

  const onFilterChanged = useCallback(
    (params: any) => {
      const issue_date = params.api.getFilterInstance("issue_date");

      issue_date?.setModel({
        filterType: "date",
        type: "inRange",
      });

      params.api.onFilterChanged();
    },
    [getValues]
  );

  const getFilteredData = () => {
    setShowTable(true);
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
  };

  const onFilterReset = async () => {
    if (gridRef && gridRef.current) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
    await reset({
      dateType: "today",
      dateFrom: dayjs().startOf("day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    });
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel({});
      onFilterChanged(gridRef.current);
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("manufacture.sentence.waste_report")}</Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
        />
      </HeaderLayout>
      <ReportDateFilter
        control={control}
        setValue={setValue}
        getValues={getValues}
        onFilterReset={onFilterReset}
        getFilteredData={getFilteredData}
        future={false}
      />
      {showTable && (<WasteManufactureOrderReportTable
        gridRef={gridRef}
        onFilterChanged={onFilterChanged}
        getValues={getValues}
        setExportInput={setExportInput}
      />)}
    </>
  );
}

export default WasteManufactureOrderReport