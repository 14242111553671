import { Box, Typography } from "@mui/material";

const PosSummary = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={90}
    >
      <Typography variant="h5">Summary</Typography>
    </Box>
  );
};

export default PosSummary;
