import * as Yup from "yup";
import { IPurchaseRequest } from "../../../../types/Purchase/purchaseRequest";
import dayjs from "dayjs";

export const purchaseRequestSchema: IPurchaseRequest = {
  unique_id: "",
  created_by: null,
  created_date: dayjs().toDate(),
  issue_date: dayjs(),
  due_date: dayjs().add(7, "day"),
  destination_warehouse_unique_id: "",
  requestor_contact: {
    name: "",
    department: "",
    position: "",
  },
  remark: "",
  item_list: [],
  related_user_list: [],
  external_reference_id: "",
};

const purchaseRequestItemListSchema = Yup.object().shape({
  item_name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
  item_sku_desc: Yup.string()
    .max(500, "กรอกได้ไม่เกิน 500 ตัวอักษร")
    .nullable(),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนที่มากกว่า 0")
    .required("กรุณาระบุจำนวนสินค้า"),
  uom: Yup.string().required("กรุณาเลือกหน่วย"),
});

export const purchaseRequestValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date().required("กรุณาระบุวันที่ออกเอกสาร"),
  requestor_contact: Yup.object().shape({
    name: Yup.string().required("กรุณาระบุผู้ขอ"),
    department: Yup.string().required("กรุณาระบุแผนก"),
  }),
  item_list: Yup.array()
    .of(purchaseRequestItemListSchema)
    .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
  remark: Yup.string().max(500, "กรอกได้ไม่เกิน 500 ตัวอักษร"),
});
