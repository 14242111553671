import {
  InventoryDocumentStatus,
  InventoryDocumentType,
  Tracability,
} from "../../generated/inventory";
import { v4 as uuidv4 } from "uuid";
import { IUser } from "../../types/Auth/user";
import { ITraceEntry } from "../../types/Inventory";
import { IGoodsReceive } from "../../types/Inventory/goodsReceive";
import dayjs from "dayjs";

export const goodsReceiveCreatePayloadFormatter = (data: IGoodsReceive) => {
  const {
    created_date,
    main_status,
    aggrid_status,
    trace_entry_list,
    ...otherPayload
  } = data;
  let allTraceEntries: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({ uom, ...serial }) => ({
            ...serial,
            reference_unique_id: data.unique_id,
            qty:
              typeof serial.qty === "string"
                ? parseFloat(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            uom_unique_id: uom?.unique_id,
            serial_no: `${otherPayload.source_warehouse_unique_id}#${otherPayload.unique_id}#${serial.serial_no}`,
            barcode: `${serial.item_unique_id}#${otherPayload.source_warehouse_unique_id}#${otherPayload.unique_id}#${serial.serial_no}#${serial.qty}`,
          })
        );
        allTraceEntries.push(...formatSerialList);
      } else {
        const { serial_list, uom, ...otherTrace } = trace;
        const formatTrace = {
          ...otherTrace,
          reference_unique_id: data.unique_id,
          qty:
            typeof otherTrace.qty === "string"
              ? parseFloat(otherTrace.qty)
              : otherTrace.qty,
          uom_unique_id: uom?.unique_id,
        };
        allTraceEntries.push(formatTrace);
      }
    } else {
      allTraceEntries.push(trace);
    }
  });

  const payload = {
    ...otherPayload,
    trace_entry_list: allTraceEntries,
  };

  return payload;
};

const groupBy = (xs: any[], key: string, secondKey: string) => {
  return xs.reduce((acc, item) => {
    const firstKey = item?.[key.split(".")[0]]?.[key.split(".")[1]];
    const secondKeyVal = item?.[secondKey];

    // Ensure unique grouping
    const groupKey = `${firstKey || "NULL"}|${secondKeyVal || "NULL"}`;
    (acc[groupKey] = acc[groupKey] || []).push(item);
    return acc;
  }, {} as Record<string, any[]>);
};

export const goodsReceiveQueryFormatter = (
  data: IGoodsReceive,
  step: number
) => {
  const { trace_entry_list, ...otherData } = data;
  const groupedTrace = groupBy(
    trace_entry_list,
    "reference_line_item.line_item_unique_id",
    "item_unique_id"
  );

  const allTraceEntries: ITraceEntry[] = [];
  for (const entry of Object.entries(groupedTrace)) {
    const value = entry[1] as ITraceEntry[];

    let formatSerial: ITraceEntry[] = [];

    if (step === 1) {
      formatSerial = value
        .map((serial) => ({
          ...serial,
          serial_no: serial.serial_no?.split("#")[2],
          posted_date: serial.posted_date ?? undefined,
          scanned_by: serial.scanned_by ?? undefined,
        }))
        .filter((serial) => serial.serial_no);
    } else {
      formatSerial = value.map((serial) => ({
        ...serial,
        posted_date: serial.posted_date ?? undefined,
        scanned_by: serial.scanned_by ?? undefined,
      }));
    }

    const formatTrace: ITraceEntry = {
      item_unique_id: value[0].item_unique_id,
      item_name: value[0].item_name,
      item_img_url: value[0].item_img_url,
      tracability: value[0].tracability,
      type: value[0].type,
      unique_id: value[0].unique_id,
      qty: value.reduce((prev, curr) => prev + curr.qty, 0),
      posted_qty: value[0].posted_qty,
      document_item_qty: value[0].document_item_qty,
      posted_date:
        value[0].tracability === Tracability.Normal
          ? value[0].posted_date
          : undefined,
      created_date: value[0].created_date,
      uom: value[0].uom,
      source_bin_location:
        value[0].tracability === Tracability.Normal
          ? value[0].source_bin_location
          : undefined,
      source_bin_location_id:
        value[0].tracability === Tracability.Normal
          ? value[0].source_bin_location_id
          : undefined,
      barcode:
        value[0].tracability === Tracability.Normal
          ? value[0].barcode
          : undefined,
      serial_list: value.some(
        (serial) => serial.tracability === Tracability.Normal
      )
        ? undefined
        : formatSerial,
      status:
        value[0].tracability === Tracability.Normal
          ? value[0].status
          : undefined,
      scanned_by:
        value[0].tracability === Tracability.Normal
          ? value[0].scanned_by ?? undefined
          : undefined,
      reference_unique_id: value[0].reference_unique_id,
      reference_line_item:
        value[0].tracability === Tracability.Normal
          ? value[0].reference_line_item ?? undefined
          : undefined,
    };
    allTraceEntries.push(formatTrace);
  }

  return {
    ...otherData,
    trace_entry_list: allTraceEntries,
  };
};

export const goodsReceiveUpdatePayloadFormatter = (
  data: IGoodsReceive,
  step: number
) => {
  const {
    created_date,
    unique_id,
    main_status,
    aggrid_status,
    source_warehouse,
    destination_warehouse,
    trace_entry_list,
    created_by,
    workflow_progress_list,
    ...otherPayload
  } = data;
  let allTraceEntries: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            uom,
            created_date,
            source_bin_location,
            destination_bin_location,
            reference_line_item,
            ...serial
          }) => ({
            ...serial,
            reference_line_item: reference_line_item ?? undefined,
            qty:
              typeof serial.qty === "string"
                ? parseFloat(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            posted_qty: trace.posted_qty,
            uom_unique_id: uom?.unique_id,
            serial_no:
              step === 1
                ? `${otherPayload.source_warehouse_unique_id}#${unique_id}#${serial.serial_no}`
                : serial.serial_no,
            barcode:
              step === 1
                ? `${serial.item_unique_id}#${otherPayload.source_warehouse_unique_id}#${unique_id}#${serial.serial_no}#${serial.qty}`
                : serial.barcode,
          })
        );
        allTraceEntries.push(...formatSerialList);
      } else {
        const {
          serial_list,
          uom,
          reference_line_item,
          source_bin_location,
          destination_bin_location,
          created_date,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          reference_line_item: reference_line_item ?? undefined,
          qty:
            typeof otherTrace.qty === "string"
              ? parseFloat(otherTrace.qty)
              : otherTrace.qty,
          uom_unique_id: uom?.unique_id,
        };
        allTraceEntries.push(formatTrace);
      }
    } else {
      allTraceEntries.push(trace);
    }
  });

  const payload = {
    ...otherPayload,
    trace_entry_list: allTraceEntries,
    id: undefined,
  };
  return payload;
};

export const goodsReceiveTypeValueFormatter = (type: string) => {
  switch (type) {
    case "purchase_order":
      return "ซื้อ";
    case "manufacture_order":
      return "ผลิต";
    case "other":
      return "อื่นๆ";
    default:
      return "";
  }
};

export const autoGoodsReceiveFormatter = (
  data: any,
  created_by: IUser | null
) => {
  const { trace_entry_list } = data;
  let allTraceEntries: ITraceEntry[] = [];

  trace_entry_list.forEach((trace: ITraceEntry) => {
    allTraceEntries.push({
      tracability: Tracability.Normal,
      qty: trace.qty,
      unique_id: uuidv4(),
      type: InventoryDocumentType.GoodsReceive,
      item_unique_id: trace.item_unique_id,
      item_name: trace.item_name,
      item_img_url: trace.item_img_url,
      status: InventoryDocumentStatus.IsScan,
      posted_qty: trace.posted_qty,
      document_item_qty: trace.document_item_qty,
      posted_date: dayjs() as unknown as Date,
      source_bin_location_id: trace.source_bin_location_id,
      reference_unique_id: data.unique_id,
      reference_line_item: trace.reference_line_item,
      barcode: trace.barcode,
      scanned_by: {
        user_unique_id: created_by?.unique_id,
        email: created_by?.email,
        first_name: created_by?.first_name,
        last_name: created_by?.last_name,
        img_url: created_by?.img_url,
      },
      uom_unique_id: trace.uom?.unique_id,
    });
  });

  const payload = {
    unique_id: data.unique_id,
    type: "purchase_order",
    reference_unique_id: data.reference_unique_id,
    source_warehouse_unique_id: data.source_warehouse_unique_id,
    posted_date: data.posted_date,
    remark: data.remark,
    flag_status: [],
    draft_step_no: 2,
    sub_status: "finished",
    created_by: {
      user_unique_id: created_by?.unique_id,
      email: created_by?.email,
      first_name: created_by?.first_name,
      last_name: created_by?.last_name,
      img_url: created_by?.img_url,
    },
    trace_entry_list: allTraceEntries,
  };

  return payload;
};
