import React, { forwardRef, useImperativeHandle, useState } from 'react'
import ButtonLayout from '../ButtonLayout'
import CustomizedButton from '../../Custom/CustomizedButton'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { ColumnApi, GridApi } from 'ag-grid-community'
import RotateLeft from "@mui/icons-material/RotateLeft";

type Props = {
  api: GridApi,
  columnApi: ColumnApi
  onReset: (api: GridApi, columnApi: ColumnApi, editMode: string, isColumnDirty: boolean, isFilterDirty: boolean) => void,
  onApply: (api: GridApi, columnApi: ColumnApi, editMode: string, isColumnDirty: boolean, isFilterDirty: boolean) => void,
  onCancel: (api: GridApi, columnApi: ColumnApi, editMode: string, isColumnDirty: boolean, isFilterDirty: boolean) => void,
}

const CustomViewActionStatusPanel = forwardRef(({
  onReset,
  onApply,
  api,
  columnApi,
  onCancel,
  ...rest
}: Props, ref) => {
  const { t } = useTranslation();
  const [editMode, seteditMode] = useState<'everyone' | 'onlyme'>()
  const [isColumnDirty, setisColumnDirty] = useState<boolean>(false)
  const [isFilterDirty, setisFilterDirty] = useState<boolean>(false)
  const visible = !!editMode
  useImperativeHandle(ref, () => {
    return {
      setMode: (mode: 'everyone' | 'onlyme') => {
        seteditMode(mode)
      },
      setColumnDirty: (isDirty: boolean) => {
        setisColumnDirty(isDirty)
      },
      setFilterDirty: (isDirty: boolean) => {
        setisFilterDirty(isDirty)
      }
    };
  })
  if (!visible) {
    return null
  }
  return (
    <div style={{height: '60px', display: 'flex', alignItems: 'center'}}>
      <ButtonLayout>
        <CustomizedButton
          title={"คืนค่ารูปแบบเริ่มต้น"}
          startIcon={<RotateLeft />}
          onClick={() => onReset(api, columnApi, editMode, isColumnDirty, isFilterDirty)}
        />
        <CustomizedButton
          title={t("button.cancel")}
          variant="outlined"
          onClick={() => onCancel(api, columnApi, editMode, isColumnDirty, isFilterDirty)}
        />
        <CustomizedButton
          title={t("button.save")}
          variant="contained"
          onClick={() => onApply(api, columnApi, editMode, isColumnDirty, isFilterDirty)}
        />
      </ButtonLayout>
    </div>
  )
})

export default CustomViewActionStatusPanel