import { Controller, Control } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  LocalizationProvider,
  DesktopDatePicker,
  DesktopDatePickerProps,
} from "@mui/x-date-pickers";
import CustomizedTextField from "../Custom/CustomizedTextField";
import { TextFieldProps } from "@mui/material/TextField";

type ExtendedDatePickerProps = {
  name: string;
  control: Control<any>;
  error?: any;
  required?: boolean;
  open?: DesktopDatePickerProps<HTMLInputElement, Date>["open"];
  onClose?: DesktopDatePickerProps<HTMLInputElement, Date>["onClose"];
  onOpen?: DesktopDatePickerProps<HTMLInputElement, Date>["onOpen"];
  label?: DesktopDatePickerProps<HTMLInputElement, Date>["label"];
  disabled?: DesktopDatePickerProps<HTMLInputElement, Date>["disabled"];
  disablePast?: DesktopDatePickerProps<HTMLInputElement, Date>["disablePast"];
  sx?: TextFieldProps["sx"];
  onChange?: (e: any, field?: any) => void;
  helperTextPosition?: boolean;
  disabledDate?: boolean;
};

const ControlledDatePicker = ({
  name,
  control,
  error,
  open,
  onChange,
  onClose,
  onOpen,
  label,
  required,
  disabled,
  disablePast,
  helperTextPosition,
  sx,
  disabledDate = false,
}: ExtendedDatePickerProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            {...field}
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            inputFormat={disabledDate ? "MMMM YYYY" : "DD/MM/YYYY"}
            views={disabledDate ? ["year", "month"] : ["year", "month", "day"]}
            label={label}
            onChange={
              onChange ? (e: any) => onChange(e, field) : field.onChange
            }
            renderInput={(params: any) => (
              <CustomizedTextField
                {...params}
                ref={params.inputRef}
                error={Boolean(error)}
                helperText={error?.message}
                required={required}
                FormHelperTextProps={
                  !helperTextPosition && {
                    style: {
                      margin: 0,
                      position: "absolute",
                      bottom: "-1.5rem",
                    },
                  }
                }
                sx={sx}
              />
            )}
            disablePast={disablePast}
            disabled={disabled}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default ControlledDatePicker;
