import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { IDefaultForm } from "../../../../types/global";
import { useFieldArray } from "react-hook-form";
import { IGoodsReturn } from "../../../../types/Inventory/goodsReturn";
import ClearIcon from "@mui/icons-material/Clear";
import { formatDateTimeNoAMPM } from "../../../../utils/Formatter/Date";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import { formatNumber } from "../../../../utils/dataTransformer";

type Props = {
  control: IDefaultForm<IGoodsReturn>["control"];
  errors: IDefaultForm<IGoodsReturn>["errors"];
  disabled: IDefaultForm["disabled"];
  nestedIndex: number;
};

const SerialList = ({ control, errors, nestedIndex, disabled }: Props) => {
  const { fields, remove } = useFieldArray({
    control,
    name: `trace_entry_list[${nestedIndex}].serial_list` as `trace_entry_list`,
  });

  return (
    <>
      {fields.map((serial, index) => (
        <TableRow key={serial.id}>
          <TableCell></TableCell>
          <TableCell align="center">
            {formatDateTimeNoAMPM(serial.posted_date)}
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="right">
            {disabled ? (
              <Typography fontSize={14}>{formatNumber(serial.qty)}</Typography>
            ) : (
              // <ControlledTextField
              //   control={control}
              //   type="number"
              //   name={`trace_entry_list[${nestedIndex}].serial_list[${index}].qty`}
              // error={Boolean(
              //   errors?.trace_entry_list &&
              //     errors?.trace_entry_list[nestedIndex] &&
              //     errors?.trace_entry_list[nestedIndex]?.serial_list &&
              //     errors?.trace_entry_list[nestedIndex]?.serial_list[index] &&
              //     errors?.trace_entry_list[nestedIndex]?.serial_list[index]
              //       .qty
              // )}
              //   InputProps={{
              //     inputProps: { min: 0 },
              //   }}
              // />
              <ControlledNumberTextField
                control={control}
                name={`trace_entry_list[${nestedIndex}].serial_list.${index}.qty`}
                error={Boolean(
                  errors?.trace_entry_list &&
                    errors?.trace_entry_list[nestedIndex] &&
                    errors?.trace_entry_list[nestedIndex]?.serial_list &&
                    errors?.trace_entry_list[nestedIndex]?.serial_list[index] &&
                    errors?.trace_entry_list[nestedIndex]?.serial_list[index]
                      .qty
                )}
                inputFontSize={14}
              />
            )}
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.uom?.name}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.serial_no}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>
              {serial.source_bin_location?.bin_name}
            </Typography>
          </TableCell>
          <TableCell align="center">
            {serial.scanned_by && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CustomizedAvatar
                  avatars={[
                    {
                      unique_id: serial.scanned_by.user_unique_id || "",
                      first_name: serial.scanned_by.first_name || "",
                      last_name: serial.scanned_by.last_name || "",
                      img_url: serial.scanned_by.img_url
                        ? serial.scanned_by.img_url[0]
                        : "",
                    },
                  ]}
                />
              </Box>
            )}
          </TableCell>
          <TableCell>{serial.barcode}</TableCell>
          {!disabled && (
            <TableCell align="center">
              <IconButton onClick={() => remove(index)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default SerialList;
