import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useTranslation } from "react-i18next";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import PosLeftPanel from "../../../components/Form/Sales/Pos/LeftPanel";
import PosRightPanel from "../../../components/Form/Sales/Pos/RightPanel";
import { useLocation, useNavigate } from "react-router-dom";

const PosOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} maxWidth={1440}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography variant="h5">{"POS"}</Typography>
          <Typography fontWeight={600}>เครื่องหน้าร้าน01</Typography>
          <CustomizedButton
            title="ตั้งค่า"
            variant="text"
            startIcon={<SettingsOutlinedIcon />}
            onClick={() => navigate("/sales/pos/setting")}
          />
        </Box>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <Box width={235}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                // value="bottom"
                control={<Switch color="primary" />}
                label={t("sales.pos.print")}
              />
              <FormControlLabel
                // value="end"
                control={<Switch color="primary" />}
                label={t("sales.pos.vat")}
              />
            </FormGroup>
          </Box>
          <Box width={120}>
            <CustomizedSelect
              label={t("sales.pos.price_vat_type")}
              options={[]}
            />
          </Box>

          <Box bgcolor={"primary.main"} px={1} py={0.5} borderRadius={8}>
            <Typography color={"white"} fontSize={14} fontWeight={600}>
              {0} รายการ
            </Typography>
          </Box>
          <Box width={75}>
            <CustomizedButton
              title={t("sales.pos.clear")}
              variant="text"
              startIcon={<RestartAltOutlinedIcon />}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        gap={3}
        maxWidth={1440}
        height={"calc(100vh - 164px)"}
      >
        <PosLeftPanel />
        <PosRightPanel />
      </Box>
    </>
  );
};

export default PosOrder;
