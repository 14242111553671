import { enqueueSnackbar } from "notistack";
import { useState, useCallback, useEffect } from "react";
import { DropResult, DraggableLocation } from "@hello-pangea/dnd";
import { useProjectUpdateMutation } from "../../../generated/project";
import { IProject, ITask } from "../../../types/Project";
import { projectUpdatePayloadFormatter } from "../../../utils/Formatter/Project";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

export const useTaskDnD = (
  allTasks?: ITask[],
  project?: IProject,
  refetchProject?: any
) => {
  const [doneTasks, setDoneTasks] = useState<ITask[]>([]);
  const [notDoneTasks, setNotDoneTasks] = useState<ITask[]>([]);

  const graphQLClientWithHeaderProject: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const { mutate: updateProject } = useProjectUpdateMutation<Error>(
    graphQLClientWithHeaderProject,
    {
      onSuccess: ({ updateProject }) => {
        enqueueSnackbar("เปลี่ยนแปลงลำดับงานสำเร็จ", {
          variant: "success",
        });
        refetchProject();
      },
      onError: (err) => {
        enqueueSnackbar("เปลี่ยนแปลงลำดับงานไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const updateProjectHandler = async (
    data: IProject,
    tasksIdList: number[]
  ) => {
    data.tasks_id_list = tasksIdList;
    const projectUpdatePayload = await projectUpdatePayloadFormatter(data);

    console.log(projectUpdatePayload);
    updateProject({
      data: projectUpdatePayload,
      where: { unique_id: data.unique_id },
    });
  };

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId) {
      changeOrderHandler(source, destination);
    }
  };

  const changeOrderHandler = (
    source: DraggableLocation,
    destination: DraggableLocation | null | undefined
  ) => {
    if (!destination) {
      return;
    }

    let newTaskIdList: number[];

    if (source.droppableId === "done_task") {
      if (source.index === destination.index) return;

      const newDoneTasks = [...doneTasks];
      const startIndex = source.index;
      const endIndex = destination.index;

      const [removedTask] = newDoneTasks.splice(startIndex, 1);
      newDoneTasks.splice(endIndex, 0, removedTask);

      setDoneTasks(newDoneTasks);
      newTaskIdList = extractTaskId([...notDoneTasks, ...newDoneTasks]);
    } else {
      if (source.index === destination.index) return;

      const newNotDoneTasks = [...notDoneTasks];
      const startIndex = source.index;
      const endIndex = destination.index;

      const [removedTask] = newNotDoneTasks.splice(startIndex, 1);
      newNotDoneTasks.splice(endIndex, 0, removedTask);

      setNotDoneTasks(newNotDoneTasks);
      newTaskIdList = extractTaskId([...newNotDoneTasks, ...doneTasks]);
    }

    project && updateProjectHandler(project, newTaskIdList);
  };

  const seperateTaskByStatus = (tasks: ITask[]) => {
    let doneTasks = [];
    let notDoneTasks = [];

    for (const task of tasks) {
      if (task.status === 1) doneTasks.push(task);
      else notDoneTasks.push(task);
    }
    return { doneTasks, notDoneTasks };
  };

  const extractTaskId = useCallback((tasks: ITask[]) => {
    return tasks.map((task) => parseInt(task.id));
  }, []);

  useEffect(() => {
    const mapTaskByOrder = () => {
      let taskByOrder: ITask[] = [];
      if (project?.tasks_id_list) {
        for (const taskId of project?.tasks_id_list) {
          const matchingTask = allTasks?.find(
            (task) =>
              task.reference_unique_id === project?.unique_id &&
              parseInt(task.id) === taskId
          );
          if (matchingTask) taskByOrder.push(matchingTask);
        }
      }
      return taskByOrder;
    };
    const orderedTask = mapTaskByOrder();

    const { doneTasks, notDoneTasks } = seperateTaskByStatus(orderedTask);

    doneTasks && setDoneTasks(doneTasks);
    notDoneTasks && setNotDoneTasks(notDoneTasks);
  }, [project, allTasks]);

  return {
    doneTasks,
    notDoneTasks,
    onDragEnd,
    updateProjectHandler,
    seperateTaskByStatus,
  };
};
