import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IDefaultForm } from "../../../../types/global";
import { useGoodsIssueItemList } from "../../../../hooks/Inventory/use-issue-item-list";
import ControlledTextField from "../../../Controller/ControlledTextField";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useForm,
  useWatch,
} from "react-hook-form";
import { IBarcodeForm, ITraceEntry } from "../../../../types/Inventory";
import { IGoodsIssue } from "../../../../types/Inventory/goodsIssue";
import {
  formatDate,
  formatDateTimeNoAMPM,
} from "../../../../utils/Formatter/Date";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import ClearIcon from "@mui/icons-material/Clear";
import {
  InventoryDocumentStatus,
  InventoryDocumentType,
  ItemSkuQtysQuery,
  Tracability,
  TraceEntriesFindManyAggridQuery,
  WarehouseBinLocation,
  useItemSkuQtysQuery,
  useTraceEntriesFindManyAggridQuery,
} from "../../../../generated/inventory";
import { Fragment, useCallback, useState } from "react";
import { useStateContext } from "../../../../contexts/auth-context";
import SerialList from "./SerialList";
import TotalPostedQuantityCell from "./TotalPostedQuantityCell";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import Confirmation from "../../../UI/Confirmation";
import { useSnackbar } from "notistack";
import { goodsIssueTraceEntryFormatter } from "../../../../utils/Formatter/GoodsIssue";
import ControlledSelect from "../../../Controller/ControlledSelect";
import useBarcodeScanner from "../../../../hooks/Inventory/use-barcode-scanner";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { QrReader } from "react-qr-reader";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { formatNumber } from "../../../../utils/dataTransformer";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import GIMultiScanBarcodeModal from "../../../UI/Modal/GIMultiScanBarcodeModal";
import { useModal } from "../../../../hooks/use-modal";

type Props = IDefaultForm & {
  fields: FieldArrayWithId<IGoodsIssue, "trace_entry_list", "id">[];
  append: UseFieldArrayAppend<IGoodsIssue, "trace_entry_list">;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsIssue, "trace_entry_list">;
  initialTraceItems?: string[];
  existingInitialTraceItems?: string[];
};

const GoodsIssueItemList = ({
  control,
  errors,
  disabled,
  fields,
  getValues,
  append,
  update,
  remove,
  initialTraceItems,
  existingInitialTraceItems,
}: Props) => {
  const {
    state: { authUser },
  } = useStateContext();
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );
  const [traceMultiScan, setTraceMultiScan] = useState<ITraceEntry[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const headers = useGoodsIssueItemList(disabled);

  const multipleScanModal = useModal();

  const watchType = useWatch({
    control,
    name: "type",
  });

  const watchSourceWarehouseUniqueId = useWatch({
    control,
    name: "source_warehouse_unique_id",
  });

  const watchMainStatus = useWatch({
    control,
    name: "main_status",
  });

  const deleteItemHandler = () => {
    remove(deletedIndex);
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(deleteItemHandler);

  const {
    control: barcodeControl,
    reset: resetBarcode,
    watch: watchBarcode,
    setValue: setBarcodeValue,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      barcodeMobile: "",
    },
  });

  const trimBarcode =
    watchBarcode("barcodeMobile")?.trim() || watchBarcode("barcode").trim();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: traceData, refetch } =
    useTraceEntriesFindManyAggridQuery<TraceEntriesFindManyAggridQuery>(
      graphQLClientWithHeaderItem,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            barcode: {
              filterType: "text",
              type: "equals",
              filter:
                watchBarcode("barcodeMobile")?.trim() ||
                watchBarcode("barcode")?.trim(),
            },
          },
        },
      },
      {
        enabled: false,
      }
    );

  const { refetch: refetchSkuQty } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClientWithHeaderItem,
    {
      findManyInput: {
        where: {
          barcode: {
            equals:
              watchBarcode("barcodeMobile")?.trim() ||
              watchBarcode("barcode")?.trim(),
          },
        },
      },
    },
    {
      enabled: false,
    }
  );

  const resetBarcodeHandler = useCallback(() => {
    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  }, [resetBarcode]);

  const onBarcodeSubmitHandler = useCallback(async () => {
    const { data } = await refetch();
    const { data: skuQtyData } = await refetchSkuQty();
    const warehouse_unique_id = getValues("source_warehouse_unique_id");
    const duplicateTrace = fields.filter(
      (trace) => trace.item_unique_id === skuQtyData?.itemSkuQtys?.[0]?.sku_name
    );

    if (
      fields &&
      fields.length > 0 &&
      fields.some(
        (trace) =>
          trace.item_unique_id === skuQtyData?.itemSkuQtys?.[0]?.sku_name
      ) &&
      watchType === "อื่นๆ"
    ) {
      enqueueSnackbar(`QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`, {
        variant: "error",
        style: { whiteSpace: "pre-line" },
      });
    } else {
      if (!skuQtyData?.itemSkuQtys) return resetBarcodeHandler();

      if (skuQtyData.itemSkuQtys.length <= 0) {
        enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่", {
          variant: "error",
        });

        return resetBarcodeHandler();
      }

      const filteredWarehouse = skuQtyData.itemSkuQtys.filter(
        (itemSkuQty) =>
          itemSkuQty?.warehouse_level_3?.sub_level_2?.sub_level_1
            ?.warehouse_unique_id === warehouse_unique_id
      );

      if (filteredWarehouse.length <= 0) {
        // Warehouse not match
        enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในคลังที่เลือกกรุณาสแกนใหม่", {
          variant: "error",
        });
        return resetBarcodeHandler();
      }

      if (
        duplicateTrace.filter(
          (trace) => trace.status !== InventoryDocumentStatus.IsScan
        ).length > 1 ||
        duplicateTrace.filter((trace) =>
          trace.serial_list?.some((serial) => serial.barcode === trimBarcode)
        ).length > 1
      ) {
        setTraceMultiScan(duplicateTrace);
        return multipleScanModal.openModalHandler();
      }

      const formatBinLocations = skuQtyData.itemSkuQtys
        .map(
          (itemSku) =>
            ({
              id: itemSku?.bin_id,
              bin_name: itemSku?.bin_location?.bin_name ?? itemSku?.bin_name,
              sub_level_3_id: itemSku?.sub_level_3_id,
              stock_qty: itemSku?.stock_qty,
              warehouse_unique_id:
                itemSku?.warehouse_level_3?.sub_level_2?.sub_level_1
                  ?.warehouse_unique_id,
            } as WarehouseBinLocation & {
              warehouse_unique_id: string;
              stock_qty?: number;
            })
        )
        .filter((bin) => bin.stock_qty && bin.stock_qty > 0);

      const filterBinLocations = formatBinLocations.filter(
        (bin) => bin.warehouse_unique_id === watchSourceWarehouseUniqueId
      );

      const traceEntries = data?.traceEntriesFindManyAggrid?.data;

      const traceEntry = traceEntries
        ? traceEntries.find(
            (trace) => trace?.type === InventoryDocumentType.GoodsReceive
          )
        : null;

      if (!traceEntry) return resetBarcodeHandler();

      const { reference_line_item, ...traceEntryType } =
        traceEntry as ITraceEntry;

      const formatTrace = goodsIssueTraceEntryFormatter(
        {
          ...traceEntryType,
          item_img_url:
            skuQtyData.itemSkuQtys?.[0]?.item_sku_detail?.img_url || [],
        },
        filterBinLocations,
        authUser
      );

      if (!formatTrace) return resetBarcodeHandler();

      const foundExisting = fields.findIndex(
        (trace) => trace.item_unique_id === formatTrace.item_unique_id
      );

      const foundNotScanned = fields.findIndex(
        (trace) =>
          trace.item_unique_id === formatTrace.item_unique_id &&
          trace.status !== InventoryDocumentStatus.IsScan
      );

      if (formatTrace.tracability === Tracability.Serial) {
        if (foundExisting === -1) {
          // append new trace (serial view)
          const {
            posted_date,
            serial_no,
            source_bin_location,
            source_bin_location_id,
            scanned_by,
            barcode,
            ...otherTrace
          } = formatTrace;

          const formatTraceWithSerial = {
            ...otherTrace,
            serial_list: [
              {
                ...otherTrace,
                posted_date,
                serial_no,
                source_bin_location,
                source_bin_location_id,
                scanned_by,
                barcode,
              },
            ],
          };
          append(formatTraceWithSerial);

          enqueueSnackbar(`สแกน SN :\n${barcode}\nสำเร็จ`, {
            variant: "success",
            style: { whiteSpace: "pre-line" },
          });
        } else {
          // update serial
          const currentTrace: ITraceEntry = getValues(
            `trace_entry_list[${foundExisting}]`
          );

          if (!currentTrace || !currentTrace.serial_list) {
            enqueueSnackbar(
              `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
              {
                variant: "error",
                style: { whiteSpace: "pre-line" },
              }
            );
            return resetBarcodeHandler();
          }

          const foundBarcode = currentTrace.serial_list.findIndex(
            (serial) => serial.barcode === trimBarcode
          );

          if (foundBarcode !== -1) {
            enqueueSnackbar(
              `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
              {
                variant: "error",
                style: { whiteSpace: "pre-line" },
              }
            );
            return resetBarcodeHandler();
          }

          const { document_item_qty, posted_qty, ...otherTrace } = formatTrace;

          const minQty = Math.min(
            (currentTrace.document_item_qty || 0) -
              (currentTrace.posted_qty || 0) -
              currentTrace.serial_list.reduce(
                (qty, curr) => qty + (curr.qty || 0),
                0
              ),
            filterBinLocations?.[0].stock_qty || 0
          );

          const formatTraceWithDocument: ITraceEntry = {
            ...otherTrace,
            item_img_url: currentTrace.item_img_url,
            document_item_qty: currentTrace.document_item_qty,
            posted_qty: currentTrace.posted_qty,
            qty: minQty,
          };

          update(foundExisting, {
            ...currentTrace,
            serial_list: [...currentTrace.serial_list, formatTraceWithDocument],
          });
          enqueueSnackbar(`สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`, {
            variant: "success",
            style: { whiteSpace: "pre-line" },
          });
        }
      } else {
        // append new trace (normal view)
        if (foundExisting === -1) {
          append(formatTrace);
          enqueueSnackbar(`สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`, {
            variant: "success",
            style: { whiteSpace: "pre-line" },
          });
        } else {
          const currentTrace: ITraceEntry = getValues(
            `trace_entry_list.${foundNotScanned}`
          );

          if (
            currentTrace?.status === InventoryDocumentStatus.IsScan ||
            foundNotScanned === -1
          ) {
            enqueueSnackbar(
              `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
              {
                variant: "error",
                style: { whiteSpace: "pre-line" },
              }
            );
            return resetBarcodeHandler();
          }

          const { document_item_qty, posted_qty, ...otherTrace } = formatTrace;

          const minQty = Math.min(
            (currentTrace.document_item_qty || 0) -
              (currentTrace.posted_qty || 0),
            filterBinLocations?.[0].stock_qty || 0
          );

          const formatTraceWithDocument: ITraceEntry = {
            ...otherTrace,
            item_img_url: currentTrace.item_img_url,
            document_item_qty: currentTrace.document_item_qty,
            posted_qty: currentTrace.posted_qty,
            qty: minQty,
          };

          update(foundNotScanned, formatTraceWithDocument);
          enqueueSnackbar(`สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`, {
            variant: "success",
            style: { whiteSpace: "pre-line" },
          });
        }
      }
    }
    resetBarcodeHandler();
  }, [
    refetch,
    refetchSkuQty,
    getValues,
    fields,
    watchType,
    enqueueSnackbar,
    resetBarcodeHandler,
    trimBarcode,
    multipleScanModal,
    watchSourceWarehouseUniqueId,
    authUser,
    append,
    update,
  ]);

  const selectedTraceToScan = async (tracesToScan: ITraceEntry[]) => {
    if (!tracesToScan || tracesToScan.length === 0) return;

    const { data: skuQtyData } = await refetchSkuQty();

    const formatBinLocations = skuQtyData?.itemSkuQtys
      ?.map(
        (itemSku) =>
          ({
            id: itemSku?.bin_id,
            bin_name: itemSku?.bin_location?.bin_name ?? itemSku?.bin_name,
            sub_level_3_id: itemSku?.sub_level_3_id,
            stock_qty: itemSku?.stock_qty,
            warehouse_unique_id:
              itemSku?.warehouse_level_3?.sub_level_2?.sub_level_1
                ?.warehouse_unique_id,
          } as WarehouseBinLocation & {
            warehouse_unique_id: string;
            stock_qty?: number;
          })
      )
      .filter((bin) => bin.stock_qty && bin.stock_qty > 0);

    const filterBinLocations = formatBinLocations?.filter(
      (bin) => bin.warehouse_unique_id === watchSourceWarehouseUniqueId
    );

    const traceEntry = getValues("trace_entry_list");

    const oldTraceEntries = traceData?.traceEntriesFindManyAggrid?.data;

    const oldtTraceEntry = oldTraceEntries
      ? oldTraceEntries.find(
          (trace) => trace?.type === InventoryDocumentType.GoodsReceive
        )
      : null;

    if (!traceEntry) return;

    tracesToScan.forEach((traceScan) => {
      if (!traceScan.reference_line_item?.line_item_unique_id)
        return enqueueSnackbar(`เกิดเหตุผิดพลาด`, {
          variant: "error",
        });

      const currentTraceIndex = traceEntry.findIndex(
        (trace: ITraceEntry) =>
          trace.reference_line_item?.line_item_unique_id ===
          traceScan.reference_line_item?.line_item_unique_id
      );

      if (currentTraceIndex === -1) return;

      const currentTrace: ITraceEntry = traceEntry[currentTraceIndex];

      const formatTrace = goodsIssueTraceEntryFormatter(
        {
          ...oldtTraceEntry,
          ...traceEntry[currentTraceIndex],
          item_img_url: traceEntry[currentTraceIndex].item_img_url,
        },
        filterBinLocations || [],
        authUser
      );

      if (!formatTrace) return;

      const existingBarcode = formatTrace?.serial_list?.findIndex(
        (serial) => serial.barcode === trimBarcode
      );

      if (existingBarcode !== -1) {
        enqueueSnackbar(`QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`, {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        });
        return;
      }

      //start of serial item
      if (formatTrace.tracability === Tracability.Serial) {
        const { document_item_qty, posted_qty, ...otherTrace } = formatTrace;

        const { serial_list, ...trace } = otherTrace;

        const minQty = Math.min(
          (currentTrace.document_item_qty || 0) -
            (currentTrace.posted_qty || 0) -
            (currentTrace?.serial_list?.reduce(
              (qty, curr) => qty + (curr.qty || 0),
              0
            ) || 0),
          filterBinLocations?.[0].stock_qty || 0
        );

        const formatTraceWithDocument: ITraceEntry = {
          ...trace,
          document_item_qty: currentTrace.document_item_qty,
          posted_qty: currentTrace.posted_qty,
          qty: minQty,
          barcode: trimBarcode,
          status: InventoryDocumentStatus.IsScan,
        };

        update(currentTraceIndex, {
          ...currentTrace,
          serial_list: [
            ...(currentTrace.serial_list || []),
            formatTraceWithDocument,
          ],
        });

        enqueueSnackbar(`สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`, {
          variant: "success",
          style: { whiteSpace: "pre-line" },
        });

        //end of serial item
      } else {
        //start of normal item
        const { document_item_qty, posted_qty, ...otherTrace } = formatTrace;

        const minQty = Math.min(
          (currentTrace.document_item_qty || 0) -
            (currentTrace.posted_qty || 0),
          filterBinLocations?.[0].stock_qty || 0
        );

        const formatTraceWithDocument: ITraceEntry = {
          ...otherTrace,
          document_item_qty: currentTrace.document_item_qty,
          posted_qty: currentTrace.posted_qty,
          qty: minQty,
          status: InventoryDocumentStatus.IsScan,
        };

        if (currentTrace.status === InventoryDocumentStatus.IsScan) {
          enqueueSnackbar(
            `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
            {
              variant: "error",
              style: { whiteSpace: "pre-line" },
            }
          );
          return;
        }
        update(currentTraceIndex, formatTraceWithDocument);

        enqueueSnackbar(`สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`, {
          variant: "success",
          style: { whiteSpace: "pre-line" },
        });
      }
    });
  };

  const { scrollRef, showCamera, setShowCamera, setScanData, setIsInterval } =
    useBarcodeScanner(onBarcodeSubmitHandler);

  return (
    <>
      <>
        {!isMobile && <Typography fontWeight="bold">สแกน Barcode</Typography>}
        <Grid container spacing={2} mt={1} mb={3}>
          {!isMobile ? (
            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
              <ControlledTextField
                label="Scan Barcode"
                control={barcodeControl}
                name="barcode"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    onBarcodeSubmitHandler();
                  }
                }}
                disabled={disabled || !watchSourceWarehouseUniqueId}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={10}>
                <ControlledTextField
                  label="Scan Barcode"
                  control={barcodeControl}
                  name="barcode"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey === false) {
                      onBarcodeSubmitHandler();
                    }
                  }}
                  disabled={disabled || !watchSourceWarehouseUniqueId}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  disabled={disabled || !watchSourceWarehouseUniqueId}
                  onClick={() => setShowCamera(!showCamera)}
                >
                  <QrCodeScannerIcon />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
        {isMobile ? (
          <Box display={showCamera ? "block" : "none"} my={2}>
            {showCamera && (
              <QrReader
                constraints={{ facingMode: "environment" }}
                onResult={(result) => {
                  if (!!result) {
                    setScanData(result.getText());
                    setIsInterval(true);
                    setBarcodeValue("barcodeMobile", result.getText());
                  }
                }}
                scanDelay={2000}
                containerStyle={{
                  width: "calc(100vw-48px)",
                  height: "100%",
                  contentVisibility: "auto",
                }}
                videoContainerStyle={{
                  width: "calc(100vw-48px)",
                  height: "100%",
                  contentVisibility: "auto",
                }}
                videoStyle={{
                  height: "100%",
                  contentVisibility: "auto",
                }}
              />
            )}
          </Box>
        ) : null}
      </>
      <TableContainer ref={scrollRef}>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align ? header.align : "center"}
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    width={header.width}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((trace, index) => (
              <Fragment key={trace.id}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[0]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{index + 1}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[1]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {formatDateTimeNoAMPM(trace.posted_date)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[2]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        minWidth: "90px",
                        justifyContent: "center",
                      }}
                    >
                      <Avatar
                        alt={trace.item_name}
                        src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                        sx={{
                          width: 60,
                          height: 60,
                          border: "1px solid #BEBEBE",
                          borderRadius: "2px",
                        }}
                        variant="square"
                      >
                        <ImageOutlinedIcon
                          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                          fontSize="medium"
                        />
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[3]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {trace.item_unique_id}
                    </Typography>
                    <Typography fontSize={14}>{trace.item_name}</Typography>{" "}
                    {watchMainStatus === "draft" &&
                      initialTraceItems?.includes(trace.item_unique_id) &&
                      !existingInitialTraceItems?.includes(
                        trace.item_unique_id
                      ) && (
                        <Typography
                          fontSize={12}
                          sx={{
                            mt: 0.5,
                            color: (theme) => theme.palette.error.main,
                          }}
                        >
                          สินค้านี้ถูกลบออกจากระบบแล้ว กรุณาลบสินค้า
                        </Typography>
                      )}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[4]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {formatNumber(trace.document_item_qty || 0)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[5]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {formatNumber(trace.posted_qty || 0)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[6]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {formatNumber(
                        (trace.document_item_qty || 0) -
                          (trace.posted_qty || 0) >=
                          0
                          ? (trace.document_item_qty || 0) -
                              (trace.posted_qty || 0)
                          : 0
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[7]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal ? (
                      <>
                        {disabled || !trace.scanned_by ? (
                          <Typography fontSize={14}>
                            {formatNumber(trace.qty)}
                          </Typography>
                        ) : (
                          // <ControlledTextField
                          //   type="number"
                          //   control={control}
                          //   name={`trace_entry_list[${index}].qty`}
                          //   disabled={disabled}
                          //   error={Boolean(
                          //     errors &&
                          //       errors.trace_entry_list &&
                          //       errors.trace_entry_list[index] &&
                          //       errors.trace_entry_list[index].qty
                          //   )}
                          //   InputProps={{
                          //     inputProps: {
                          //       min: 0,
                          //       // style: {
                          //       //   fontSize: 14,
                          //       // },
                          //     },
                          //   }}
                          //   FormHelperTextProps={{
                          //     style: { fontSize: "10px" },
                          //   }}
                          //   helperText={
                          //     trace.scanned_by
                          //       ? `จำนวนคงคลัง ${
                          //           trace.stock_qty?.toString() || "-"
                          //         }`
                          //       : undefined
                          //   }
                          // />
                          <ControlledNumberTextField
                            control={control}
                            name={`trace_entry_list.${index}.qty`}
                            disabled={disabled}
                            error={Boolean(
                              errors &&
                                errors.trace_entry_list &&
                                errors.trace_entry_list[index] &&
                                errors.trace_entry_list[index].qty
                            )}
                            helperText={
                              trace.scanned_by
                                ? `จำนวนคงคลัง ${
                                    trace?.stock_qty
                                      ? formatNumber(trace.stock_qty)
                                      : "-"
                                  }`
                                : undefined
                            }
                            inputFontSize={14}
                            helperTextFontSize={10}
                          />
                        )}
                      </>
                    ) : (
                      <TotalPostedQuantityCell
                        control={control}
                        nestedIndex={index}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[8]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.uom?.name}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[8]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.serial_no}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[9]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal ? (
                      trace.all_bin_locations &&
                      trace.all_bin_locations.length > 1 &&
                      !disabled ? (
                        <ControlledSelect
                          control={control}
                          name={`trace_entry_list.${index}.source_bin_location_id`}
                          options={trace.all_bin_locations.map((bin) => ({
                            label: bin.bin_name,
                            value: bin.id,
                          }))}
                          onChange={(e: any) => {
                            const { id, ...otherFields } = fields[index];
                            update(index, {
                              ...otherFields,
                              stock_qty:
                                trace.all_bin_locations &&
                                trace.all_bin_locations.find(
                                  (bin) => bin.id === e.target.value
                                ).stock_qty,
                            });
                          }}
                          inputProps={{
                            style: {
                              fontSize: 14,
                            },
                          }}
                          MenuProps={{
                            style: {
                              fontSize: 14,
                            },
                          }}
                          helperTextSize={10}
                        />
                      ) : (
                        <Typography fontSize={14}>
                          {trace.source_bin_location?.bin_name}
                        </Typography>
                      )
                    ) : null}
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[10]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.scanned_by && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CustomizedAvatar
                          avatars={[
                            {
                              unique_id: trace.scanned_by.user_unique_id || "",
                              first_name: trace.scanned_by.first_name || "",
                              last_name: trace.scanned_by.last_name || "",
                              img_url: trace.scanned_by.img_url
                                ? trace.scanned_by.img_url[0]
                                : "",
                            },
                          ]}
                        />
                      </Box>
                    )}
                  </TableCell>
                  <TableCell
                    width={
                      (headers && headers.length > 0
                        ? headers[11]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.barcode}</Typography>
                  </TableCell>
                  <TableCell>
                    {trace.tracability === Tracability.Normal && (
                      <Typography fontSize={14}>
                        {formatDate(trace.lot_date)}
                      </Typography>
                    )}
                  </TableCell>
                  {!disabled && (
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[13]?.width || 0
                          : 0) + 16
                      }
                    >
                      <IconButton
                        onClick={() => {
                          openConfirmationHandler();
                          setDeletedIndex(index);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
                <SerialList
                  control={control}
                  errors={errors}
                  nestedIndex={index}
                  disabled={disabled}
                />
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <GIMultiScanBarcodeModal
        traceEntry={traceMultiScan}
        modal={multipleScanModal.modal}
        closeModalHandler={() => {
          resetBarcode((prev) => ({
            ...prev,
            barcode: "",
          }));
          multipleScanModal.closeModalHandler();
        }}
        barcode={trimBarcode}
        selectedTraceToScan={selectedTraceToScan}
      />
      <Confirmation
        title="ยืนยันหากต้องการลบสินค้า"
        message="หากลบสินค้าแล้ว รายการ SN ที่สแกนไว้จะถูกลบทั้งหมด"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default GoodsIssueItemList;
