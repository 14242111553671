import dayjs from "dayjs";
import {
  InventoryDocumentType,
  Tracability,
  WarehouseBinLocation,
} from "../../generated/inventory";
import { IUser } from "../../types/Auth/user";
import { ITraceEntry } from "../../types/Inventory";
import { IGoodsReturn } from "../../types/Inventory/goodsReturn";
import { v4 as uuidv4 } from "uuid";

export const goodsReturnCreatePayloadFormatter = (data: IGoodsReturn) => {
  const {
    created_date,
    main_status,
    aggrid_status,
    trace_entry_list,
    ...otherPayload
  } = data;
  let allTraceEntries: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            uom,
            source_bin_location,
            destination_bin_location,
            ...serial
          }) => ({
            ...serial,
            reference_unique_id: data.unique_id,
            qty:
              typeof serial.qty === "string"
                ? parseFloat(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            posted_qty: trace.posted_qty,
            uom_unique_id: uom?.unique_id,
          })
        );
        allTraceEntries.push(...formatSerialList);
      } else {
        const {
          serial_list,
          uom,
          source_bin_location,
          destination_bin_location,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          reference_unique_id: data.unique_id,
          qty:
            typeof otherTrace.qty === "string"
              ? parseFloat(otherTrace.qty)
              : otherTrace.qty,
          uom_unique_id: uom?.unique_id,
        };
        allTraceEntries.push(formatTrace);
      }
    } else {
      const {
        serial_list,
        uom,
        source_bin_location,
        destination_bin_location,
        ...otherTrace
      } = trace;
      const formatTrace = {
        ...otherTrace,
        qty:
          typeof otherTrace.qty === "string"
            ? parseFloat(otherTrace.qty)
            : otherTrace.qty,
        uom_unique_id: uom?.unique_id,
      };
      allTraceEntries.push(formatTrace);
    }
  });

  const payload = {
    ...otherPayload,
    trace_entry_list: allTraceEntries,
  };

  return payload;
};

const groupBy = (xs: any[], key: string, secondKey: string) => {
  return xs.reduce((acc, item) => {
    const firstKey = item?.[key.split(".")[0]]?.[key.split(".")[1]];
    const secondKeyVal = item?.[secondKey];

    // Ensure unique grouping
    const groupKey = `${firstKey || "NULL"}|${secondKeyVal || "NULL"}`;
    (acc[groupKey] = acc[groupKey] || []).push(item);
    return acc;
  }, {} as Record<string, any[]>);
};

export const goodsReturnQueryFormatter = (data: IGoodsReturn) => {
  const { trace_entry_list, ...otherData } = data;
  const groupedTrace = groupBy(
    trace_entry_list,
    "reference_line_item.line_item_unique_id",
    "item_unique_id"
  );

  const allTraceEntries: ITraceEntry[] = [];
  for (const entry of Object.entries(groupedTrace)) {
    const value = entry[1] as ITraceEntry[];

    const formatSerial: ITraceEntry[] = value.map((serial) => ({
      ...serial,
      posted_date: serial.posted_date ?? undefined,
      scanned_by: serial.scanned_by ?? undefined,
    }));

    const formatTrace: ITraceEntry = {
      item_unique_id: value[0].item_unique_id,
      item_name: value[0].item_name,
      item_img_url: value[0].item_img_url,
      tracability: value[0].tracability,
      type: value[0].type,
      unique_id: value[0].unique_id,
      qty: value.reduce((prev, curr) => prev + curr.qty, 0),
      posted_qty: value[0].posted_qty,
      document_item_qty: value[0].document_item_qty,
      posted_date:
        value[0].tracability === Tracability.Normal
          ? value[0].posted_date
          : undefined,
      created_date: value[0].created_date,
      uom: value[0].uom,
      source_bin_location:
        value[0].tracability === Tracability.Normal
          ? value[0].source_bin_location
          : undefined,
      source_bin_location_id:
        value[0].tracability === Tracability.Normal
          ? value[0].source_bin_location_id
          : undefined,
      barcode:
        value[0].tracability === Tracability.Normal
          ? value[0].barcode
          : undefined,
      serial_list: value.some(
        (serial) => serial.tracability === Tracability.Normal
      )
        ? undefined
        : formatSerial,
      status:
        value[0].tracability === Tracability.Normal
          ? value[0].status
          : undefined,
      scanned_by:
        value[0].tracability === Tracability.Normal
          ? value[0].scanned_by ?? undefined
          : undefined,
      reference_unique_id: value[0].reference_unique_id,
      reference_line_item:
        value[0].tracability === Tracability.Normal
          ? value[0].reference_line_item ?? undefined
          : undefined,
    };
    allTraceEntries.push(formatTrace);
  }

  return {
    ...otherData,
    trace_entry_list: allTraceEntries,
  };
};

export const goodsReturnTraceEntryFormatter = (
  trace: ITraceEntry,
  binLocation: WarehouseBinLocation,
  user: IUser | null
) => {
  const {
    type,
    posted_date,
    created_date,
    scanned_by,
    unique_id,
    source_bin_location,
    source_bin_location_id,
    ...otherPayload
  } = trace;
  if (user) {
    const formatTrace: ITraceEntry = {
      ...otherPayload,
      unique_id: uuidv4(),
      type: InventoryDocumentType.GoodsReturn,
      scanned_by: {
        user_unique_id: user.unique_id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        img_url: user.img_url,
      },
      source_bin_location: binLocation,
      source_bin_location_id: binLocation.id,
      posted_date: dayjs().toDate(),
    };
    return formatTrace;
  }
  return;
};

export const goodsReturnUpdatePayloadFormatter = (data: IGoodsReturn) => {
  const {
    id,
    created_date,
    unique_id,
    main_status,
    aggrid_status,
    source_warehouse,
    destination_warehouse,
    trace_entry_list,
    created_by,
    workflow_progress_list,
    ...otherPayload
  } = data;
  let allTraceEntries: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            uom,
            created_date,
            source_bin_location,
            destination_bin_location,
            reference_line_item,
            ...serial
          }) => ({
            ...serial,
            reference_line_item: reference_line_item ?? undefined,
            qty:
              typeof serial.qty === "string"
                ? parseFloat(serial.qty)
                : serial.qty,
            document_item_qty: trace.document_item_qty,
            posted_qty: trace.posted_qty,
            uom_unique_id: uom?.unique_id,
          })
        );
        allTraceEntries.push(...formatSerialList);
      } else {
        const {
          serial_list,
          uom,
          source_bin_location,
          destination_bin_location,
          created_date,
          reference_line_item,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          reference_line_item: reference_line_item ?? undefined,
          qty:
            typeof otherTrace.qty === "string"
              ? parseFloat(otherTrace.qty)
              : otherTrace.qty,
          uom_unique_id: uom?.unique_id,
        };
        allTraceEntries.push(formatTrace);
      }
    } else {
      const {
        serial_list,
        uom,
        source_bin_location,
        destination_bin_location,
        created_date,
        reference_line_item,
        ...otherTrace
      } = trace;
      const formatTrace = {
        ...otherTrace,
        reference_line_item: reference_line_item ?? undefined,
        qty:
          typeof otherTrace.qty === "string"
            ? parseFloat(otherTrace.qty)
            : otherTrace.qty,
        uom_unique_id: uom?.unique_id,
      };
      allTraceEntries.push(formatTrace);
    }
  });
  const payload = {
    ...otherPayload,
    trace_entry_list: allTraceEntries,
  };
  return payload;
};

export const goodsReturnTypeValueFormatter = (type: string) => {
  switch (type) {
    case "sales_return":
      return "รับคืนจากลูกค้า";
    case "manufacture_order":
      return "รับคืนจากผลิต";
    default:
      return "";
  }
};
