import React, { forwardRef, useEffect, useRef, useState } from "react";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import "dhtmlx-gantt";
import GanttStyles from "./GanttStyle";
import { GanttStatic } from "dhtmlx-gantt";
import { GanttData } from "../../../types/Manufacture/gantt";
import { IDefaultForm } from "../../../types/global";
import dayjs from "dayjs";
import GanttTaskInfoModal from "../Modal/GanttTaskInfoModal";
import GanttManufactureOrderInfoModal from "../Modal/GanttManufactureOrderInfoModal";
import { Box } from "@mui/material";

declare const gantt: GanttStatic;

interface GanttChartProps {
  data: GanttData;
  countParentTask: number;
  startDate: any;
  endDate: any;
  watch: IDefaultForm["watch"];
  ganttCollapsed: boolean;
  setGanttCollapsed: (value: boolean) => void;
}

const statusColorMap: { [key: string]: [string, string, string, string] } = {
  //border, background, highlight, text
  draft: ["#BDBDBD", "#F9F9F9", "#333333", "#333333"],
  pending_manu: ["#e58000", "#feeed6", "#e58000", "#333333"],
  in_progress: ["#2167D3", "#ECF6FD", "#2167D3", "#333333"],
  finished: ["#22915A", "#E6F9F0", "#22915A", "#333333"],
  cancelled: ["#333333", "#595959", "#ffffff", "#ffffff"],
};

const GanttChart = forwardRef<any, GanttChartProps>(
  (
    {
      data,
      countParentTask,
      startDate,
      endDate,
      watch,
      ganttCollapsed,
      setGanttCollapsed: setGanttClosed,
    }: GanttChartProps,
    ref
  ) => {
    const ganttContainer = useRef<HTMLDivElement>(null);
    const isInitialized = useRef(false);
    const todayMarker = useRef<any>(null);
    const openParentTaskRef = useRef(0);
    const [manufactureOrderModalOpen, setManufactureOrderModalOpen] =
      useState(false);
    const [taskModalOpen, setTaskModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState<any>(null);
    const [selectedManufactureOrder, setSelectedManufactureOrder] =
      useState<any>(null);

    // Initialize Gantt chart configuration
    const initGanttConfig = () => {
      if (!ganttContainer.current) return;

      gantt.config.resource_store = "production_center";
      gantt.config.resource_property = "production_center";

      gantt.config.grid_width = 250;
      gantt.config.min_column_width = 300;
      gantt.config.scales = [{ unit: "day", format: "%d" }];
      gantt.config.date_format = "%Y-%m-%d %H:%i";
      gantt.config.scale_height = 50;
      gantt.config.open_tree_initially = true;
      gantt.config.readonly = true;
      gantt.config.show_progress = false;
      gantt.config.initial_scroll = false;
      gantt.config.show_unscheduled = true;

      gantt.plugins({
        marker: true,
      });

      setupTemplatesAndHandlers();

      gantt.init(ganttContainer.current);

      todayMarker.current = gantt.addMarker({
        start_date: new Date(),
        css: "today",
        text: "Now",
        title: "Current time",
      });

      isInitialized.current = true;
    };

    const setupTemplatesAndHandlers = () => {
      gantt.templates.task_text = function (start, end, task) {
        if (task.parent !== 0) {
          const [borderColor, backgroundColor, highlightTextColor, textColor] =
            statusColorMap[task.aggrid_status];
          return `
            <div task-id="${
              task.id
            }" style="display:flex; flex-direction: column; padding: 4px 8px; height: 100%; background-color: ${backgroundColor}; border: 1px solid ${borderColor}; border-radius: 4px; font-family: 'Kanit', sans-serif; font-size: 14px;">
            <div task-id="${
              task.id
            }" style="display: flex; align-items: center; height: 50%; gap: 8px;">
              ${
                task.reference_unique_id_list &&
                task.reference_unique_id_list.length > 0
                  ? `<span task-id="${task.id}" style="font-weight: 600; color: ${highlightTextColor};">
                  ${task.reference_unique_id_list[0]}
                  </span>
                  <span task-id="${task.id}"
                  style="flex-shrink: 0; width: 4px; height: 4px; background: #BDBDBD; border-radius: 50%;"
                  ></span>`
                  : ""
              }
              <span task-id="${
                task.id
              }" style="font-weight: 400; color: ${textColor}; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">${
            task.customer_name || ""
          }</span>
            </div>  
            <div task-id="${
              task.id
            }" style="display: flex; align-items: center; height: 50%; gap: 8px; color: ${textColor}">
              <div task-id="${task.id}" style="display: inline-flex; gap: 4px;">
                <span task-id="${task.id}" style="font-weight: 600;">${
            task.production_qty ? task.production_qty : ""
          }</span>
                <span task-id="${task.id}" style="font-weight: 600;">${
            task.uom ? task.uom : ""
          }</span>
              </div>
              <span task-id="${
                task.id
              }" style="flex-shrink: 0; width: 4px; height: 4px; background: #BDBDBD; border-radius: 50%;"></span>
              <span task-id="${task.id}" style="font-weight: 600;">${
            task.item_unique_id ? task.item_unique_id : ""
          }</span>
              <span task-id="${
                task.id
              }" style="font-weight: 400; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">${
            task.item_description ? task.item_description : ""
          }</span>
            </div> 
            </div>
          `;
        } else return "";
      };

      gantt.templates.task_class = (start, end, task) => {
        return task.parent === 0 ? "factory-task-bar" : "";
      };

      gantt.templates.task_row_class = (start, end, task) => {
        return task.parent === 0 ? "factory-row" : "";
      };

      gantt.templates.grid_row_class = (start, end, task) => {
        return task.parent === 0 ? "factory-row" : "";
      };

      gantt.addMarker({
        start_date: new Date(),
        css: "today",
        text: "Now",
        title: "Current time",
      });

      gantt.config.columns = [
        { name: "text", label: "ศูนย์การผลิต", tree: true, width: 200 },
        { name: "capacity", label: "Capacity", width: 100 },
        { name: "unique_id", label: "ใบสั่งผลิต", width: 150 },
      ];

      const handleUniqueIdClick = (event: Event) => {
        const target = event.target as HTMLElement;
        const targetParent = target.parentElement;
        if (
          targetParent &&
          targetParent.getAttribute("data-column-name") === "unique_id"
        ) {
          const targetGrandParent = targetParent.parentElement;
          const taskId = targetGrandParent
            ? targetGrandParent.getAttribute("data-task-id")
            : null;
          if (taskId) {
            const task = gantt.getTask(taskId);
            setSelectedManufactureOrder(task.manufactureOrder);
            setManufactureOrderModalOpen(true);
          }
        }
      };

      const handleTaskBarClick = (event: Event) => {
        const target = event.target as HTMLElement;
        if (target.getAttribute("task-id")) {
          const id = target.getAttribute("task-id");
          if (id) {
            const task = gantt.getTask(id);
            setSelectedTask(task);
            setTaskModalOpen(true);
          }
        }
      };

      gantt.attachEvent("onTaskOpened", (id) => {
        const taskID = Number(gantt.getTask(id).id);

        if (gantt.hasChild(id) && taskID >= 10000) {
          openParentTaskRef.current += 1;

          if (openParentTaskRef.current === countParentTask) {
            setGanttClosed(false);
          }
        }
      });

      gantt.attachEvent("onTaskClosed", (id) => {
        const taskID = Number(gantt.getTask(id).id);
        if (gantt.hasChild(id) && taskID >= 10000) {
          openParentTaskRef.current -= 1;

          if (openParentTaskRef.current === 0) {
            setGanttClosed(true);
          }
        }
      });
      if (ganttContainer.current) {
        ganttContainer.current.addEventListener("click", handleUniqueIdClick);
        ganttContainer.current.addEventListener("click", handleTaskBarClick);
      }
    };

    useEffect(() => {
      initGanttConfig();
      return () => {
        if (isInitialized.current) {
          gantt.clearAll();
        }
      };
    }, []);

    useEffect(() => {
      if (!isInitialized.current) return;

      gantt.config.start_date = startDate;
      gantt.config.end_date = endDate;

      gantt.clearAll();
      gantt.parse(data);

      openParentTaskRef.current = countParentTask;

      if (todayMarker.current) {
        todayMarker.current = gantt.addMarker({
          start_date: new Date(),
          css: "today",
          text: "Now",
          title: "Current time",
        });
      }

      const selectedDate = watch && watch("selectedDate");
      if (
        dayjs(selectedDate).isAfter(startDate) &&
        dayjs(selectedDate).isBefore(endDate)
      ) {
        gantt.showDate(new Date(new Date(selectedDate).setHours(0, 0, 0, 0)));
      } else {
        gantt.showDate(new Date(new Date(startDate).setHours(0, 0, 0, 0)));
      }

      gantt.render();
    }, [data, startDate, endDate]);

    const toggleTasks = () => {
      gantt.eachTask((task) => {
        if (gantt.hasChild(task.id)) {
          if (!ganttCollapsed) {
            if (task.$open) {
              gantt.close(task.id);
            }
          } else {
            if (!task.$open) {
              gantt.open(task.id);
            }
          }
        }
      });
      setGanttClosed(!ganttCollapsed);
    };

    React.useImperativeHandle(ref, () => ({
      showDate: (date: Date) => {
        if (isInitialized.current) {
          gantt.showDate(date);
        }
      },
      toggleTasks: () => {
        if (isInitialized.current) {
          toggleTasks();
        }
      },
    }));

    const handleTaskModalClose = () => setTaskModalOpen(false);
    const handleManufactureOrderModalClose = () =>
      setManufactureOrderModalOpen(false);

    return (
      <>
        <GanttStyles />
        <Box ref={ganttContainer} sx={{ width: "100%", height: "100vh" }} />
        <GanttTaskInfoModal
          open={taskModalOpen}
          handleClose={handleTaskModalClose}
          selectedTask={selectedTask}
        />
        <GanttManufactureOrderInfoModal
          open={manufactureOrderModalOpen}
          handleClose={handleManufactureOrderModalClose}
          selectedManufactureOrder={selectedManufactureOrder}
        />
      </>
    );
  }
);

export default GanttChart;
