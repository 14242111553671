import { Box } from "@mui/material";
import CustomizedButton from "../../../../Custom/CustomizedButton";
import { useTranslation } from "react-i18next";

const PosPaymentFooter = () => {
  const { t } = useTranslation();
  return (
    <Box display={"flex"} gap={2} justifyContent={"space-between"}>
      <Box flex={1}>
        <CustomizedButton
          sx={{ width: "100%", fontSize: 20, py: 1.5 }}
          variant="contained"
          title={t("sales.pos.payment.cash")}
        />
      </Box>
      <Box flex={1}>
        <CustomizedButton
          sx={{ width: "100%", fontSize: 20, py: 1.5 }}
          variant="contained"
          title={t("sales.pos.payment.promptpay")}
        />
      </Box>
    </Box>
  );
};

export default PosPaymentFooter;
