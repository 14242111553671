import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  UseFieldArrayMove,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  UseFieldArrayUpdate,
  useForm,
  useWatch,
} from "react-hook-form";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import TocIcon from "@mui/icons-material/Toc";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useModal } from "../../../../hooks/use-modal";

import CloseIcon from "@mui/icons-material/Close";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

import Confirmation from "../../../UI/Confirmation";

import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";

import CheckedUserModal from "../../CheckedUserModal";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledServerSideCreatable from "../../../Controller/ControlledSSCreatable";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";

import RelatedEmployeeIcon from "../../../Form/EmployeeList/RelatedEmployeeIcon";
import ChangeStatusWoConfirmation from "../../../UI/ChangeStatusWoConfirmation";

import { IAvatar, IDefaultForm, IMenuOption } from "../../../../types/global";
import { IWorkOrder } from "../../../../types/Manufacture/workOrder";
import { IManufactureOrder } from "../../../../types/Manufacture/order";
import { EntityTypeEnum } from "../../../../generated/creatable";

import { formatDateTime } from "../../../../utils/Formatter/Date";
import { formatNumber } from "../../../../utils/dataTransformer";
import { addUsersToRelatedEmployee } from "../../../../utils/Formatter/ManufactureOrder";
import { useStateContext } from "../../../../contexts/auth-context";

const mapOption = (option?: string) => {
  switch (option) {
    case "in_progress":
      return "กำลังผลิต";
    default:
      break;
  }
};

const formatExportedWorkOrderStatus = (status?: string) => {
  switch (status) {
    case "day":
      return "วัน";
    case "hour":
      return "ชั่วโมง";
    case "minute":
      return "นาที";
    default:
      return "วัน";
  }
};

const timeUnit = [
  {
    value: "minute",
    label: "นาที",
  },
  {
    value: "hour",
    label: "ชั่วโมง",
  },
  {
    value: "day",
    label: "วัน",
  },
];

type Props = IDefaultForm & {
  fields: IManufactureOrder["work_order_list"];
  move: UseFieldArrayMove;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IManufactureOrder, "work_order_list">;
  replace: UseFieldArrayReplace<IManufactureOrder, "work_order_list">;
  workOrderUpdateSubmit: () => void;
};

type ModalProps = {
  id: number | null;
  curr_status: string;
  next_status: string;
  value: boolean;
  index: number | null;
  row: IWorkOrder | null;
  is_cancel: boolean;
};

const defaultValues = {
  started_date: new Date(),
  finished_date: new Date(),
  actual_production_qty: "",
  remark: "",
};

const WorkInfoTable = ({
  fields,
  move,
  remove,
  control,
  errors,
  setValue,
  getValues,
  update,
  replace,
  disabled,
  workOrderUpdateSubmit,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [headers, setHeaders] = useState<
    { label: string; width?: number; align?: TableCellProps["align"] }[]
  >([]);
  const [woIndex, setWoIndex] = useState<number>(0);
  const [employeeIds, setEmployeeIds] = useState<string[]>([]);
  const [employeeIdsSnapshot, setEmployeeIdsSnapshot] = useState<string[]>([]);
  const {
    state: { authUser },
  } = useStateContext();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const watchCreatedBy = useWatch({
    control,
    name: "created_by",
  });

  const {
    control: changeStatusControl,
    handleSubmit: changeStatusHandleSubmit,
    setValue: changeStatueSetValue,
    reset: changeStatueReset,
  } = useForm({ defaultValues });

  const [modal, setModal] = useState<ModalProps>({
    value: false,
    curr_status: "",
    next_status: "",
    id: null,
    index: null,
    row: null,
    is_cancel: false,
  });

  const [deletedIndex, setDeletedIndex] = useState<number | null>(null);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState(false);

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const disabledStatusButton = ["finished", "cancelled"];
  const selectStatusOptions: IMenuOption[] = [
    {
      value: "กำลังผลิต",
    },
    {
      value: "เสร็จสิ้น",
    },
    {
      value: "ยกเลิก",
    },
  ];

  useEffect(() => {
    if (!disabled)
      setHeaders([
        { label: t("manufacture.workOrder.no"), width: 70 },
        { label: t("manufacture.workOrder.user"), width: 100 },
        { label: t("manufacture.workOrder.name"), width: 200 },
        { label: t("manufacture.workOrder.plant"), width: 150 },
        { label: t("manufacture.workOrder.production_center"), width: 150 },
        {
          label: t("manufacture.workOrder.duration"),
          width: 120,
          align: "right",
        },
        { label: t("manufacture.workOrder.unitDuration"), width: 130 },
        {
          label: t("manufacture.workOrder.cost_price"),
          width: 130,
          align: "right",
        },
        { label: t("manufacture.workOrder.started_date"), width: 175 },
        { label: t("manufacture.workOrder.finished_date"), width: 175 },
        { label: t("manufacture.workOrder.remark"), width: 150 },
        { label: "", width: 20 },
      ]);
    else
      setHeaders([
        { label: t("manufacture.workOrder.no"), width: 70 },
        { label: t("manufacture.bom.status"), width: 150 },
        { label: t("manufacture.workOrder.user"), width: 100 },
        { label: t("manufacture.workOrder.name"), width: 200 },
        { label: t("manufacture.workOrder.plant"), width: 150 },
        { label: t("manufacture.workOrder.production_center"), width: 150 },
        {
          label: t("manufacture.workOrder.duration"),
          width: 120,
          align: "right",
        },
        { label: t("manufacture.workOrder.unitDuration"), width: 130 },
        {
          label: t("manufacture.workOrder.cost_price"),
          width: 130,
          align: "right",
        },
        { label: t("manufacture.workOrder.started_date"), width: 175 },
        { label: t("manufacture.workOrder.finished_date"), width: 175 },
        { label: t("manufacture.workOrder.remark"), width: 150 },
      ]);
  }, [disabled, t, id, status]);

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(null);
  };

  const deleteItemConfirmationAction = () => {
    if (deletedIndex !== null) {
      remove(deletedIndex);
      closeDeleteItemConfirmationHandler();
    }
  };

  const openConfirmationHandler = (
    id: ModalProps["id"],
    curr_status: ModalProps["curr_status"],
    next_status: ModalProps["next_status"],
    index: ModalProps["index"],
    row: IWorkOrder,
    is_cancel: ModalProps["is_cancel"]
  ) => {
    changeStatueSetValue("remark", row.remark || "");
    changeStatueSetValue("started_date", new Date());
    changeStatueSetValue("finished_date", new Date());
    setModal({
      value: true,
      is_cancel: is_cancel,
      curr_status,
      next_status,
      id,
      index,
      row,
    });
  };

  const closeConfirmationHandler = () => {
    setModal((prev) => ({
      ...prev,
      value: false,
      is_cancel: false,
    }));
    setTimeout(() => {
      setModal((prev) => ({
        ...prev,
        curr_status: "",
        next_status: "",
        id: null,
        row: null,
      }));
      changeStatueReset(defaultValues);
    }, 700);
  };

  const confirmationAction = (data: any) => {
    const { remark, started_date, finished_date } = data;
    // changeStatusWoHandler();
    const woData = (modal.row as IWorkOrder) ?? {};
    let formatWoData = {
      ...woData,
      main_status: modal.next_status,
      remark,
      updated_status_detail: [
        ...(woData.updated_status_detail || []),
        {
          user_unique_id: authUser?.unique_id || "",
          email: authUser?.email || "",
          first_name: authUser?.first_name || "",
          last_name: authUser?.last_name,
          img_url: authUser?.img_url,
          updated_date: new Date(),
        },
      ],
    };

    if (modal.next_status === "in_progress") {
      formatWoData = { ...formatWoData, started_date };
    } else if (modal.next_status === "finished") {
      formatWoData = { ...formatWoData, finished_date };
    }

    update(modal.index ?? 0, formatWoData);

    try {
      workOrderUpdateSubmit();
    } catch (error) {
      console.log(error);
    }
    closeConfirmationHandler();
  };

  const {
    modal: responsibleModal,
    openModalHandler,
    closeModalHandler,
  } = useModal();

  const openEmployeeTable = async (index: number) => {
    const workOrder = getValues(`work_order_list.${index}`);
    setWoIndex(index);
    setEmployeeIds(
      workOrder.responsible_user_list.map((list: any) => list.user_unique_id)
    );
    setEmployeeIdsSnapshot(
      workOrder.responsible_user_list.map((list: any) => list.user_unique_id)
    );
    openModalHandler();
  };

  const finishUsersSelect = (data: any) => {
    const workOrder = getValues(`work_order_list[${woIndex}]`);
    const relatedUsers = getValues(`related_user_list`);

    const formatData = [
      {
        user_unique_id: data.unique_id,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        img_url: data.img_url,
      },
    ];

    addUsersToRelatedEmployee(
      watchCreatedBy,
      formatData,
      relatedUsers,
      setValue
    );

    update(woIndex, {
      ...workOrder,
      responsible_user_list: formatData,
    });
    closeModalHandler();
  };

  const removeEmployee = (index: number, listIndex: number) => {
    const woList = getValues(`work_order_list.${index}`);
    const relatedUsers = getValues(`related_user_list`);
    const remove = woList.responsible_user_list.filter(
      (_: any, userInd: number) => userInd !== listIndex
    );

    setValue(
      "related_user_list",
      relatedUsers.filter(
        (user: any) =>
          user.user_unique_id !==
          woList.responsible_user_list[listIndex].user_unique_id
      )
    );

    update(index, {
      ...woList,
      responsible_user_list: remove,
    });
  };

  // const Renderproduction_centerFields = ({
  //   control,
  //   errors,
  //   setValue,
  //   rowIndex,
  // }: {
  //   control: IDefaultForm["control"];
  //   errors: IDefaultForm["errors"];
  //   setValue: IDefaultForm["setValue"];
  //   rowIndex: number;
  // }) => {
  //   const watchPlant = useWatch({
  //     control,
  //     name: `work_order_list[${rowIndex}].plant`,
  //   });

  //   return (
  //     <ControlledSelect
  //       name={`work_order_list[${rowIndex}].production_center`}
  //       control={control}
  //       options={
  //         allWorkCenter &&
  //         allWorkCenter.length > 0 &&
  //         allWorkCenter
  //           .filter((wc) => {
  //             if (watchPlant !== "") return wc.plant === watchPlant;
  //             else return wc;
  //           })
  //           .map((wc) => wc.name)
  //       }
  //       error={Boolean(
  //         errors.work_order_list &&
  //           errors.work_order_list[rowIndex] &&
  //           errors.work_order_list[rowIndex].production_center
  //       )}
  //       helperText={
  //         errors.work_order_list &&
  //         errors.work_order_list[rowIndex] &&
  //         errors.work_order_list[rowIndex].production_center &&
  //         errors.work_order_list[rowIndex].production_center.message
  //       }
  //       onChange={(e) =>
  //         setValue(
  //           `work_order_list[${rowIndex}].plant`,
  //           allWorkCenter.find((wc) => wc.name === e.target.value).plant
  //         )
  //       }
  //     />
  //   );
  // };

  const sxMenuOptions = (status: string) => {
    let sx = {};

    const setColor = (color: string, bgColor: string) => ({
      color: color,
      bgcolor: bgColor,
      "&:hover": {
        backgroundColor: bgColor,
        color: color,
      },
    });

    switch (status) {
      case "in_progress":
        sx = setColor("#1F5BB2", "#E5F0FF");
        break;
      case "finished":
        sx = setColor("#246527", "#E5F8EF");
        break;
      case "cancelled":
        sx = setColor("#FFFFFF", "#D3D3D3");
        break;
      default:
        sx = setColor("#333333", "#E6E6E6");
        break;
    }
    return sx;
  };

  return (
    <Box sx={{ my: 3 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={!header.align ? "center" : header.align}
                  key={index}
                  width={header.width}
                >
                  <Box
                    minWidth={header.width}
                    justifyContent={!header.align ? "center" : header.align}
                  >
                    <Typography
                      textAlign={!header.align ? "center" : header.align}
                      fontSize={14}
                      fontWeight={700}
                    >
                      {header.label}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable
              droppableId="droppable-ingredient"
              isDropDisabled={disabled}
            >
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {fields?.map((row, index) => (
                    <Draggable
                      key={row.id}
                      draggableId={"work-info-" + row.id}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...provided.draggableProps.style,
                            breakInside: "avoid",
                          }}
                        >
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!disabled && <TocIcon fontSize="small" />}
                              <Typography fontSize={14}>{index + 1}</Typography>
                            </Box>
                          </TableCell>
                          {disabled && (
                            <CustomizedTooltip
                              title={
                                row.updated_status_detail &&
                                row.updated_status_detail.length > 0 && (
                                  <Box>
                                    <Typography>
                                      เมื่อ{" "}
                                      {formatDateTime(
                                        row.updated_status_detail?.at(-1)
                                          ?.updated_date
                                      )}
                                    </Typography>
                                    <Typography>
                                      โดยพนักงาน{" "}
                                      {
                                        row.updated_status_detail?.at(-1)
                                          ?.first_name
                                      }{" "}
                                      {
                                        row.updated_status_detail?.at(-1)
                                          ?.last_name
                                      }
                                    </Typography>
                                  </Box>
                                )
                              }
                              placement="right"
                              enterNextDelay={200}
                            >
                              <TableCell align="center">
                                <CustomizedMenuOptions
                                  fullWidth
                                  size="medium"
                                  label={t(`status.${row.main_status}`)}
                                  variant="contained"
                                  sx={sxMenuOptions(row.main_status ?? "draft")}
                                  options={selectStatusOptions.map(
                                    ({ value, disabled }) => ({
                                      value: value,
                                      disabled:
                                        disabled ||
                                        value === mapOption(row.main_status)
                                          ? true
                                          : false,
                                    })
                                  )}
                                  onSelect={(e) => {
                                    const value = e.target as HTMLElement;
                                    switch (value.innerText) {
                                      case "กำลังผลิต":
                                        openConfirmationHandler(
                                          row.id ?? 0,
                                          row.main_status ?? "",
                                          "in_progress",
                                          index,
                                          row,
                                          false
                                        );
                                        break;
                                      case "เสร็จสิ้น":
                                        openConfirmationHandler(
                                          row.id ?? 0,
                                          row.main_status ?? "",
                                          "finished",
                                          index,
                                          row,
                                          false
                                        );
                                        break;
                                      case "ยกเลิก":
                                        openConfirmationHandler(
                                          row.id ?? 0,
                                          row.main_status ?? "",
                                          "cancelled",
                                          index,
                                          row,
                                          true
                                        );
                                        break;
                                      default:
                                        break;
                                    }
                                  }}
                                  disabled={disabledStatusButton.includes(
                                    row.main_status ?? ""
                                  )}
                                />
                              </TableCell>
                            </CustomizedTooltip>
                          )}
                          {disabled ? (
                            <TableCell align="center">
                              <Grid container sx={{ placeContent: "center" }}>
                                <CustomizedAvatar
                                  avatars={
                                    (row.responsible_user_list as IAvatar[]) ||
                                    []
                                  }
                                />
                              </Grid>
                            </TableCell>
                          ) : (
                            <TableCell align="center">
                              <Grid
                                container
                                spacing={1}
                                sx={{ placeContent: "center" }}
                              >
                                {row.responsible_user_list?.map(
                                  (item, listIndex) => {
                                    return (
                                      <RelatedEmployeeIcon
                                        isMain={false}
                                        key={item.user_unique_id}
                                        first_name={item.first_name || ""}
                                        last_name={item.last_name}
                                        index={index}
                                        img_url={
                                          item.img_url ? item?.img_url[0] : ""
                                        }
                                        remove={() =>
                                          removeEmployee(index, listIndex)
                                        }
                                        disabled={disabled}
                                      />
                                    );
                                  }
                                )}
                                {!disabled &&
                                  row.responsible_user_list?.length === 0 && (
                                    <Grid item>
                                      <CustomizedTooltip
                                        title="เพิ่มผู้รับผิดชอบ"
                                        enterNextDelay={200}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            openEmployeeTable(index)
                                          }
                                          color="primary"
                                        >
                                          <ControlPointOutlinedIcon />
                                        </IconButton>
                                      </CustomizedTooltip>
                                    </Grid>
                                  )}
                              </Grid>
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {disabled ? (
                              <Typography
                                fontSize={14}
                                onClick={() =>
                                  window.open(
                                    `/manufacture/work-order/${row.id}`,
                                    "_blank"
                                  )
                                }
                                paragraph
                                component="span"
                                color="primary"
                                sx={{ cursor: "pointer" }}
                              >
                                {row.name}
                              </Typography>
                            ) : (
                              <ControlledServerSideCreatable
                                dataName="work_order_list_name"
                                name={`work_order_list.${index}.name`}
                                control={control}
                                error={Boolean(
                                  errors.work_order_list &&
                                    errors.work_order_list[index] &&
                                    errors.work_order_list[index].name
                                )}
                                helperText={
                                  errors.work_order_list &&
                                  errors.work_order_list[index] &&
                                  errors.work_order_list[index].name &&
                                  errors.work_order_list[index].name.message
                                }
                                documentType={EntityTypeEnum.WorkOrder}
                                defaultOptions={[]}
                                setValue={setValue}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {disabled ? (
                              <Typography fontSize={14}>{row.plant}</Typography>
                            ) : (
                              <ControlledServerSideCreatable
                                dataName="work_order_list_plant"
                                name={`work_order_list.${index}.plant`}
                                control={control}
                                error={Boolean(
                                  errors.work_order_list &&
                                    errors.work_order_list[index] &&
                                    errors.work_order_list[index].plant
                                )}
                                helperText={
                                  errors.work_order_list &&
                                  errors.work_order_list[index] &&
                                  errors.work_order_list[index].plant &&
                                  errors.work_order_list[index].plant.message
                                }
                                documentType={EntityTypeEnum.WorkOrder}
                                defaultOptions={[]}
                                setValue={setValue}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {row.production_center}
                              </Typography>
                            ) : (
                              <ControlledServerSideCreatable
                                dataName="work_order_list_production_center"
                                name={`work_order_list.${index}.production_center`}
                                control={control}
                                error={Boolean(
                                  errors.work_order_list &&
                                    errors.work_order_list[index] &&
                                    errors.work_order_list[index]
                                      .production_center
                                )}
                                helperText={
                                  errors.work_order_list &&
                                  errors.work_order_list[index] &&
                                  errors.work_order_list[index]
                                    .production_center &&
                                  errors.work_order_list[index]
                                    .production_center.message
                                }
                                documentType={EntityTypeEnum.WorkOrder}
                                defaultOptions={[]}
                                setValue={setValue}
                              />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatNumber(row.duration ?? 0)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                name={`work_order_list.${index}.duration`}
                                control={control}
                                error={Boolean(
                                  errors.work_order_list &&
                                    errors.work_order_list[index] &&
                                    errors.work_order_list[index].duration
                                )}
                                helperText={
                                  errors.work_order_list &&
                                  errors.work_order_list[index] &&
                                  errors.work_order_list[index].duration &&
                                  errors.work_order_list[index].duration.message
                                }
                                decimalScale={0}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatExportedWorkOrderStatus(
                                  row.duration_unit
                                )}
                              </Typography>
                            ) : (
                              <ControlledSelect
                                options={timeUnit}
                                name={`work_order_list.${index}.duration_unit`}
                                control={control}
                                error={Boolean(
                                  errors.work_order_list &&
                                    errors.work_order_list[index] &&
                                    errors.work_order_list[index].duration_unit
                                )}
                                helperText={
                                  errors.work_order_list &&
                                  errors.work_order_list[index] &&
                                  errors.work_order_list[index].duration_unit &&
                                  errors.work_order_list[index].duration_unit
                                    .message
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {formatNumber(row.cost_price ?? 0)}
                              </Typography>
                            ) : (
                              <ControlledNumberTextField
                                name={`work_order_list.${index}.cost_price`}
                                control={control}
                                error={Boolean(
                                  errors.work_order_list &&
                                    errors.work_order_list[index] &&
                                    errors.work_order_list[index].cost_price
                                )}
                                helperText={
                                  errors.work_order_list &&
                                  errors.work_order_list[index] &&
                                  errors.work_order_list[index].cost_price &&
                                  errors.work_order_list[index].cost_price
                                    .message
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Typography fontSize={14}>
                              {formatDateTime(row.started_date) || "-"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography fontSize={14}>
                              {formatDateTime(row.finished_date) || "-"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {disabled ? (
                              <Typography fontSize={14}>
                                {row.remark}
                              </Typography>
                            ) : (
                              <ControlledTextField
                                name={`work_order_list.${index}.remark`}
                                control={control}
                              />
                            )}
                          </TableCell>

                          {!disabled && (
                            <TableCell align="center">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                                onClick={() => {
                                  if (id)
                                    openDeleteItemConfirmationHandler(index);
                                  else remove(index);
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <ChangeStatusWoConfirmation
        currStatus={modal.curr_status}
        nextStatus={modal.next_status}
        title={t("manufacture.order.sentence.wo_change_status_header")}
        message={t("manufacture.order.sentence.wo_change_status_message")}
        open={modal.value}
        handleClose={closeConfirmationHandler}
        confirmationAction={changeStatusHandleSubmit((data) =>
          confirmationAction(data)
        )}
        control={changeStatusControl}
      />
      {/* <Confirmation
        title="ต้องการยกเลิก การทำงาน ใช่หรือไม่"
        open={modal.value && modal.is_cancel}
        action={cancelConfirmationAction}
        handleClose={closeConfirmationHandler}
      /> */}
      <Confirmation
        title="ต้องการลบ การทำงาน ใช่หรือไม่"
        open={openDeleteItemConfirmation}
        action={deleteItemConfirmationAction}
        handleClose={closeDeleteItemConfirmationHandler}
      />
      <CheckedUserModal
        showSelectUser={responsibleModal}
        closeUserTable={closeModalHandler}
        finishUsersSelect={finishUsersSelect}
        userIds={employeeIds}
        setUserIds={setEmployeeIds}
        userIdsSnapshot={employeeIdsSnapshot}
        setUserIdsSnapshot={setEmployeeIdsSnapshot}
        // lockRows={[authUser?.unique_id]}
        rowSelection="single"
      />
    </Box>
  );
};

export default WorkInfoTable;
