import { Box, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/system";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { formatDate } from "../../utils/Formatter/Date";
import { CustomizedTooltip } from "./CustomizedTooltip";

interface Props {
    dueDate: Date;
    status?: string;
    allowedWarningStatuses?: string[];
}

const calculateDaysLeft = (dueDate: Date): number => {
    const due = new Date(dueDate);
    const now = new Date();
    const utc = now.getTime() + now.getTimezoneOffset() * 60000;
    const bangkokTime = new Date(utc + 7 * 60 * 60000);

    const dueDateOnly = new Date(due.getFullYear(), due.getMonth(), due.getDate());
    const todayOnly = new Date(bangkokTime.getFullYear(), bangkokTime.getMonth(), bangkokTime.getDate());

    const timeDiff = dueDateOnly.getTime() - todayOnly.getTime();
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
};

const StyledDateText = styled(Typography)(() => ({
    fontFamily: "Kanit",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "13px",
}));

const StyledDaysLeft = styled(Box)<{ overdue?: boolean }>(({ overdue }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: overdue ? "#FCE8E8" : "#FEFCE6",
    color: overdue ? "#E41B1B" : "#C67E0B",
    borderRadius: "4px",
    padding: "2px 4px",
    width: "fit-content",
    height: "16px",
    gap: "2px",
    marginTop: "4px",
}));

const StyledTypography = styled(Typography)<TypographyProps>(
    ({ color, fontSize }) => ({
        "&": {
            variant: "button",
            color: color,
            fontSize: fontSize ?? ".875rem",
        },
    })
);

const CustomizedDueDateBox = ({ dueDate, status, allowedWarningStatuses = [] }: Props) => {
    const daysLeft = calculateDaysLeft(dueDate);

    const isOverdue = daysLeft < 0;
    const isToday = daysLeft === 0;
    const isNearDue = daysLeft >= 1 && daysLeft <= 7;

    const showWarning = (isOverdue || isToday || isNearDue) && allowedWarningStatuses.includes(status ?? "");

    const displayText = isToday ? "วันนี้" : `${Math.abs(daysLeft)} วัน`;
    const textColor = isOverdue ? "#E41B1B" : "#C67E0B";

    let tooltipText = "";
    if (isOverdue) tooltipText = `ครบกำหนดแล้ว ${Math.abs(daysLeft)} วัน`;
    else if (isToday) tooltipText = "ครบกำหนดวันนี้";
    else if (isNearDue) tooltipText = `ใกล้ครบกำหนดภายใน ${daysLeft} วัน`;

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
            <StyledDateText>{formatDate(dueDate)}</StyledDateText>
            {showWarning && (
                <CustomizedTooltip title={tooltipText} enterNextDelay={200}>
                    <StyledDaysLeft overdue={isOverdue}>
                        {isOverdue ? (
                            <WarningAmberIcon sx={{ fontSize: 12, color: textColor }} />
                        ) : (
                            <AccessTimeIcon sx={{ fontSize: 12, color: textColor }} />
                        )}
                        <StyledTypography fontSize="11px" color={textColor}>
                            {displayText}
                        </StyledTypography>
                    </StyledDaysLeft>
                </CustomizedTooltip>
            )}
        </Box>
    );
};

export default CustomizedDueDateBox;