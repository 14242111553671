import { GraphQLClient } from "graphql-request";
import { InventoryDocumentType } from "../../generated/inventory";
import { SalesDocumentType, SalesReturnQuery } from "../../generated/sales";
import { EntityTypeEnum } from "../../generated/user-infomation";
import {
  ITEM_STOCK_UOMS,
  ITEMS_SKU_AGGRID,
} from "../../services/AgGrid/InventoryAgGrid";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ISalesItemList } from "../../types/Sales";
import { ISalesReturn } from "../../types/Sales/salesReturn";
import { ICreatedBy } from "../../types/global";
import { itemListToTraceEntryListFormatter } from "./Global";
import { v4 as uuidv4 } from "uuid";

export const salesReturnCreatePayloadFormatter = (
  data: ISalesReturn,
  status: string
) => {
  const {
    item_list,
    customer_contact,
    tag_list,
    created_date,
    is_effect_stock,
    ...otherData
  } = data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];
  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, ...otherItem }) => ({
      ...otherItem,
      reference_document_type: SalesDocumentType.SalesReturn,
      reference_unique_id: data.unique_id,
    })
  );

  const { unique_id_name, ...customer } = customer_contact;
  const formatPayload = {
    ...otherData,
    customer_contact: customer,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
    is_effect_stock: String(is_effect_stock).toLowerCase() === "true",
  };
  return formatPayload;
};

export const salesReturnUpdatePayloadFormatter = (
  data: ISalesReturn,
  status: string,
  isNotApprove?: boolean
) => {
  const {
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    customer_contact,
    updated_date,
    item_list,
    tag_list,
    created_by,
    created_date,
    is_effect_stock,
    external_reference_id,
    external_reference_id_confirmation,
    ...otherData
  } = data;
  const { unique_id_name, ...customer } = customer_contact;

  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, ...otherItem }) => ({
      ...otherItem,
      reference_document_type: SalesDocumentType.SalesReturn,
      reference_unique_id: data.unique_id,
    })
  );

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];

  let formatExternalReferenceId: string | undefined = external_reference_id;

  if (
    external_reference_id_confirmation &&
    external_reference_id_confirmation.trim().length > 0
  ) {
    if (external_reference_id && external_reference_id.trim().length > 0) {
      const externalToArray = external_reference_id.trim().split(",");
      const newExternalConfirmation = [
        ...externalToArray,
        external_reference_id_confirmation,
      ];
      const formatNewExternal = newExternalConfirmation.join(", ");
      formatExternalReferenceId = formatNewExternal;
    } else {
      formatExternalReferenceId = external_reference_id_confirmation;
    }
  }

  const formatPayload = {
    ...otherData,
    customer_contact: customer,
    flag_status:
      isNotApprove && flag_status
        ? !flag_status.find((fl) => fl === "not_approved")
          ? [...flag_status, "not_approved"]
          : flag_status
        : undefined,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
    is_effect_stock: String(is_effect_stock).toLowerCase() === "true",
    external_reference_id:
      status === "finished" ? formatExternalReferenceId : external_reference_id,
  };
  return formatPayload;
};

export const salesReturnQueryFormatter = async (data: ISalesReturn) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const allItemListUniqueId = data.item_list.map((item) => item.item_unique_id);
  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: 999,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });
  const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: ISalesItemList[] = [];

  data.item_list.forEach((item) => {
    const foundItemIndex = itemSkuDetails.findIndex(
      (realItem: any) => realItem.sku_name === item.item_unique_id
    );

    formatItemList.push({
      ...item,
      uom_group: itemSkuDetails[foundItemIndex]?.item_sku.item.uom_group,
      item_sku_qty: {
        stock_qty: itemSkuDetails[foundItemIndex]?.stock_qty,
        available_qty: itemSkuDetails[foundItemIndex]?.available_qty,
        purchase_ordered_qty:
          itemSkuDetails[foundItemIndex]?.purchase_ordered_qty,
        manufacture_ordered_qty:
          itemSkuDetails[foundItemIndex]?.manufacture_ordered_qty,
        sale_committed_qty: itemSkuDetails[foundItemIndex]?.sale_committed_qty,
        manufacture_committed_qty:
          itemSkuDetails[foundItemIndex]?.manufacture_committed_qty,
      },
    });
  });

  const formatCustomer = {
    ...data.customer_contact,
    unique_id_name: `${data.customer_contact_unique_id} - ${data.customer_contact.name}`,
  };

  const formatPayload = {
    ...data,
    customer_contact: formatCustomer,
    item_list: formatItemList,
  };
  return formatPayload;
};

export const copySalesReturnFormatter = (
  data?: SalesReturnQuery["salesReturn"]
) => {
  if (data) {
    const {
      unique_id,
      created_date,
      issue_date,
      credit_day,
      created_by,
      external_reference_id,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      reference_unique_id_list,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(({ ...otherItemList }) => ({
        ...otherItemList,
        uuidv4: uuidv4(),
      }));

    return {
      ...otherData,
      item_list: formatItemList,
    };
  }
};

export const createGoodsReturnFromReturn = async (
  data: ISalesReturn,
  currentUser: ICreatedBy
) => {
  if (data) {
    const { unique_id, item_list } = data;

    const item_unique_id_list = item_list.map(
      (item: any) => item.item_unique_id
    );

    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("item");

    const { itemStockUoms } = await graphQLClientWithHeaderItem.request(
      ITEM_STOCK_UOMS,
      {
        itemUniqueIdList: item_unique_id_list,
      }
    );

    const itemListWithStockUoms = item_list.map((item: any) => {
      const foundItemWithStockUom = itemStockUoms.find(
        (stockUom: any) => stockUom.unique_id === item.item_unique_id
      );

      return {
        ...item,
        uom: foundItemWithStockUom?.stock_uom?.unique_id ?? item.uom,
      };
    });

    const formatTraceEntry = itemListToTraceEntryListFormatter(
      itemListWithStockUoms,
      InventoryDocumentType.GoodsReceive,
      EntityTypeEnum.SalesReturn
    );

    return {
      type: "sales_return",
      reference_unique_id: unique_id,
      trace_entry_list: formatTraceEntry,
      created_by: currentUser,
    };
  }
};
