import { useState } from "react";
import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../types/global";

import { BomQuery, useBomQuery } from "../generated/manufacture";

import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";

import { IBom } from "../types/Manufacture/bom";
import { GraphQLClient } from "graphql-request";
import { IIngredient } from "../types/Manufacture";
import { ITEMS_SKU_AGGRID } from "../services/AgGrid/InventoryAgGrid";

export const useBomDescription = (
  control: IDefaultForm["control"],
  reset: IDefaultForm["reset"]
) => {
  const bomId = useWatch({
    control,
    name: "bom_id",
  });

  const [openBomDetail, setOpenBomDetail] = useState<boolean>(false);
  const [openBomTable, setOpenBomTable] = useState<boolean>(false);

  const [bomIds, setBomIds] = useState<string[]>([]);
  const [bomIdsSnapshot, setBomIdsSnapshot] = useState<string[]>([]);

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { refetch: bomRefetch } = useBomQuery<BomQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: {
        id: bomId,
      },
    },
    {
      enabled: false,
    }
  );

  const openBomDetailHandler = async () => {
    if (reset) {
      const { data } = await bomRefetch();
      const bomType = data?.bom as IBom;

      const allItemListUniqueId =
        bomType.ingredient_list?.map(
          (ingredient) => ingredient.item_unique_id
        ) ?? [];

      const client: GraphQLClient = createGraphQLClientWithMiddleware("item");
      const { itemSkuDetailsFindManyAggrid } = await client.request(
        ITEMS_SKU_AGGRID,
        {
          aggridInput: {
            startRow: 0,
            endRow: allItemListUniqueId.length,
            filterModel: {
              sku_name: {
                filterType: "set",
                values: allItemListUniqueId,
              },
            },
          },
        }
      );
      const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

      const mapIngredientsWithSku = (
        ingredients: IIngredient[]
      ): IIngredient[] =>
        ingredients.map((ingredient) => {
          const itemDetail = itemSkuDetails.find(
            (realItem: any) => realItem.sku_name === ingredient?.item_unique_id
          );
          return {
            ...ingredient,
            item_sku_qty: {
              stock_qty: itemDetail?.stock_qty,
              available_qty: itemDetail?.available_qty,
              purchase_ordered_qty: itemDetail?.purchase_ordered_qty,
              manufacture_ordered_qty: itemDetail?.manufacture_ordered_qty,
              sale_committed_qty: itemDetail?.sale_committed_qty,
              manufacture_committed_qty: itemDetail?.manufacture_committed_qty,
            },
          };
        });

      // Default ingredient lists to empty array if undefined
      const formattedBomList = mapIngredientsWithSku(
        bomType.ingredient_list ?? []
      );

      reset({ ...bomType, ingredient_list: formattedBomList });
      setOpenBomDetail(true);
    }
  };

  const closeBomDetailHandler = () => {
    setOpenBomDetail(false);
  };

  return {
    openBomDetail,
    openBomDetailHandler,
    closeBomDetailHandler,
    openBomTable,
    setOpenBomTable,
    bomIds,
    setBomIds,
    bomIdsSnapshot,
    setBomIdsSnapshot,
  };
};
