import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFieldArray, useForm } from "react-hook-form";

import {
  Avatar,
  Box,
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import ModalUI from "../ModalUI";
import ButtonLayout from "../ButtonLayout";
import CustomizedButton from "../../Custom/CustomizedButton";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

import {
  Tracability,
  InventoryDocumentStatus,
} from "../../../generated/inventory";

import { ITraceEntry } from "../../../types/Inventory";

import {
  useMultiScanBarcodeHeader,
  useGoodsReceiveCheckboxTable,
} from "../../../hooks/Inventory/use-receive-item-list";

type MultiGRType = {
  trace_entry_list: ITraceEntry[];
};

type Props = {
  traceEntry: ITraceEntry[];
  modal: boolean;
  closeModalHandler: () => void;
  selectedTraceToScan: (tracesToScan: ITraceEntry[]) => void;
};

const StyledTableRow = styled(TableRow)<{
  selected: boolean;
  scanned?: boolean;
}>(({ theme, selected }) => ({
  backgroundColor: selected ? `#F9F9F9 !important` : undefined,
}));

const GRMultiScanBarcodeModal = ({
  traceEntry,
  modal,
  closeModalHandler,
  selectedTraceToScan,
}: Props) => {
  const { t } = useTranslation();

  const methods = useForm<MultiGRType>({
    defaultValues: { trace_entry_list: [] },
  });

  const { control, reset, getValues, handleSubmit } = methods;

  const { fields } = useFieldArray({ control, name: "trace_entry_list" });

  const { selectedRows, handleSelectAll, handleSelectRow, isRowSelected } =
    useGoodsReceiveCheckboxTable(getValues);

  const headers = useMultiScanBarcodeHeader();

  useEffect(() => {
    if (traceEntry && traceEntry.length > 0) {
      const filteredEntries = traceEntry.filter(
        (item) =>
          item.tracability === Tracability.Normal &&
          item.status !== InventoryDocumentStatus.IsScan &&
          item.barcode // Ensure barcode exists
      );

      // Count occurrences of each barcode
      const barcodeCount = filteredEntries.reduce((acc, item) => {
        if (item.barcode) {
          // Check again to ensure barcode is defined
          acc[item.barcode] = (acc[item.barcode] || 0) + 1;
        }
        return acc;
      }, {} as Record<string, number>);

      // Filter entries where barcode appears more than once
      const duplicateEntries = filteredEntries.filter(
        (item) => item.barcode && barcodeCount[item.barcode] > 1
      );

      reset({
        trace_entry_list: duplicateEntries,
      });

      handleSelectAll(true);
    }
  }, [handleSelectAll, reset, traceEntry]);

  const submitModal = async (data: MultiGRType) => {
    if (data) {
      const selectedTrace = data.trace_entry_list.filter((trace) =>
        selectedRows.includes(trace.unique_id)
      );

      selectedTraceToScan(selectedTrace);

      closeModalHandler();
    }
  };

  return (
    <ModalUI
      title="เลือกรายการที่ต้องการสแกน"
      open={modal}
      handleClose={closeModalHandler}
      action={
        <ButtonLayout>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={closeModalHandler}
          />
          <CustomizedButton
            title={t("button.confirm")}
            variant="contained"
            onClick={handleSubmit(submitModal)}
          />
        </ButtonLayout>
      }
      maxWidth="md"
      titleSize="24px"
      dialogContentSx={{ padding: "0px 20px 20px 20px !important" }}
    >
      <TableContainer sx={{ maxHeight: 480 }}>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
          <TableHead
            sx={{
              backgroundColor: (theme) => `${theme.palette.primary.light}`,
            }}
          >
            <TableRow>
              {fields.length > 0 && (
                <TableCell
                  padding="checkbox"
                  align="center"
                  sx={{
                    px: 1,
                    backgroundColor: (theme) =>
                      `${theme.palette.primary.light}`,
                  }}
                >
                  <Checkbox
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < fields.length
                    }
                    checked={
                      selectedRows.length > 0 &&
                      selectedRows.length === fields.length
                    }
                    onChange={(e, checked) => handleSelectAll(checked)}
                  />
                </TableCell>
              )}
              {headers.map((header, index) => (
                <TableCell
                  align={header.align ? header.align : "center"}
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                    backgroundColor: (theme) =>
                      `${theme.palette.primary.light}`,
                    justifyItems: header.align || "center",
                  }}
                  width={header.width}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 0.5,
                      width: header.width,
                      justifyContent: header.align || "center",
                    }}
                  >
                    <Typography fontSize={14} fontWeight={600}>
                      {header.thaiLabel}
                    </Typography>
                    {header.required && (
                      <Typography fontSize={14} color="error">
                        *
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((trace, index) => (
              <Fragment key={trace.id}>
                <StyledTableRow
                  selected={isRowSelected(trace.unique_id)}
                  scanned={trace.status === "is_scan"}
                >
                  {fields.length > 0 && (
                    <TableCell padding="checkbox" sx={{ textAlign: "center" }}>
                      <Checkbox
                        checked={isRowSelected(trace.unique_id)} // The checkbox is checked when the row is selected
                        onClick={(e) => e.stopPropagation()} // Prevents row selection when clicking checkbox
                        onChange={() => handleSelectRow(trace.unique_id)} // This only handles the checkbox change
                      />
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[0]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14} color="#737373">
                      {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align={headers[1]?.align}
                    width={headers[1]?.width}
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Avatar
                        alt={trace.item_name}
                        src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                        sx={{
                          width: 32,
                          height: 32,
                          border: "1px solid #BEBEBE",
                          borderRadius: "2px",
                        }}
                        variant="square"
                      >
                        <ImageOutlinedIcon
                          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                          fontSize="medium"
                        />
                      </Avatar>
                      <Box>
                        <Typography fontSize={14} color="#737373">
                          {trace.item_unique_id}
                        </Typography>
                        <Typography fontSize={14} fontWeight={600}>
                          {trace.item_name}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    width={headers[2]?.width}
                    align={headers[2]?.align}
                  >
                    <Box>
                      <Typography fontSize={14}>{trace.qty}</Typography>
                      <Typography fontSize={12} color="#737373">
                        {trace.uom?.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    align={headers[3]?.align}
                    width={headers[3]?.width}
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <Typography align={headers[7]?.align}>
                      {trace.barcode}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalUI>
  );
};

export default GRMultiScanBarcodeModal;
