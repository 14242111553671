import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import HistoryIcon from "@mui/icons-material/History";
import { Box, Grid, IconButton, Typography } from "@mui/material";

import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";

import { useDisable } from "../../../../hooks/use-disable";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useSnackbar } from "notistack";
import { useItemDeleteMutation } from "../../../../generated/inventory";
import Confirmation from "../../../UI/Confirmation";
import { salesBomCanDelete } from "../../../../utils/Formatter/SalesBom";

const SalesBomHeader = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useDisable();
  const graphQLClientItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { control, getValues } = useFormContext();

  const options = [
    {
      value: "แก้ไข",
      disabled: !disabled,
    },
    {
      value: "ลบ",
      // disabled: !deletePermission,
    },
  ];

  const deleteConfirmation = useConfirmation();

  const { mutateAsync: deleteSalesBom } =
    useItemDeleteMutation<Error>(graphQLClientItem);

  const salesBomDeleteHandler = async () => {
    try {
      if (!(await salesBomCanDelete(id || ""))) {
        return enqueueSnackbar(
          "ไม่สามารถลบได้\nเนื่องจากสินค้านี้ถูกใช้งานอยู่",
          {
            variant: "error",
            style: { whiteSpace: "pre-line" },
          }
        );
      }

      await deleteSalesBom({
        uniqueInput: { unique_id: id },
      });

      navigate("/inventory/sales_bom");

      enqueueSnackbar("ลบสินค้าสำเร็จ", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("ลบสินค้าไม่สำเร็จ", {
        variant: "error",
      });
    } finally {
      deleteConfirmation.closeConfirmationHandler();
    }
  };

  return (
    <>
      <Grid container spacing={2} my={1}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5">
            {id ? getValues("name") : t("inventory.sales_bom.add")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" gap={2} justifyContent="flex-end">
            <Box width={152}>
              <ControlledDatePicker
                label={t("date.created_date")}
                control={control}
                open={false}
                onClose={() => {}}
                onOpen={() => {}}
                name="created_date"
                disabled
              />
            </Box>
            {id && (
              <CustomizedMenuOptions
                label="ตัวเลือก"
                options={options}
                size="medium"
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerHTML) {
                    case "แก้ไข":
                      setDisabled(false);
                      break;
                    case "ลบ":
                      deleteConfirmation.openConfirmationHandler();
                      break;
                    default:
                      break;
                  }
                }}
              />
            )}
            <CustomizedTooltip title="ดูการเคลื่อนไหว">
              <IconButton
                color="primary"
                // onClick={() => setOpenDrawer(true)}
              >
                <HistoryIcon fontSize="small" />
              </IconButton>
            </CustomizedTooltip>
          </Box>
        </Grid>
      </Grid>
      <Confirmation
        open={deleteConfirmation.confirmation}
        handleClose={deleteConfirmation.closeConfirmationHandler}
        action={salesBomDeleteHandler}
        title="ยืนยันการต้องการลบชุดสินค้า"
      />
    </>
  );
};

export default SalesBomHeader;
