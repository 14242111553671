import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";
import { EntityTypeEnum } from "../../generated/creatable";

export const useSalesItemListHeader = (
  documentType: EntityTypeEnum,
  disabled: boolean
) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    const arrayMove = (arr: any[], fromIndex: number, toIndex: number) => {
      let element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    };

    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: "",
        label: "",
        width: 75,
      },
      {
        thaiLabel: "ชื่อสินค้า",
        label: "Name",
        width: 350,
      },
      {
        thaiLabel: documentType === "sales_return" ? "จำนวนคืน" : "จำนวน",
        label: documentType === "sales_return" ? "Return Qty" : "Qty",
        width: 100,
        align: "right",
      },
      {
        thaiLabel: "หน่วย",
        label: "Unit",
        width: 100,
      },
      {
        thaiLabel: "ราคา/หน่วย",
        label: "Price/Unit",
        width: documentType === EntityTypeEnum.Quotation ? 170 : 150,
        align: "right",
      },
      {
        thaiLabel: "ส่วนลด/หน่วย",
        label: "Discount",
        width: 150,
        align: "right",
      },
      {
        thaiLabel: "ภาษี",
        label: "Vat",
        width: 100,
      },
      {
        thaiLabel: "มูลค่าก่อนภาษี",
        label: "Pre-vat Amount",
        width: 150,
        align: "right",
      },
      {
        thaiLabel: "หัก ณ ที่จ่าย (%)",
        label: "Withholding Tax (%)",
        width: 150,
        align: "right",
      },
      {
        thaiLabel: "จำนวนคลัง",
        label: "Stock Qty",
        width: 100,
        align: "right",
      },
      {
        thaiLabel: "หมายเหตุสินค้า",
        label: "Product Remark",
        width: 150,
      },
    ];

    if (!disabled) {
      if (documentType === "sales_return") {
        const salesReturnHeaders = [...defaultHeaders];
        salesReturnHeaders.splice(2, 0, {
          thaiLabel: "จำนวนขาย",
          label: "Sales Qty",
          width: 100,
          align: "right",
        });
        arrayMove(salesReturnHeaders, 10, 5);
        setHeaders([
          ...salesReturnHeaders,
          // {
          //   label: "",
          //   thaiLabel: "",
          //   width: 20,
          // },
        ]);
      } else {
        setHeaders([
          // {
          //   label: "",
          //   thaiLabel: "",
          //   width: 20,
          // },
          ...defaultHeaders,
        ]);
      }
    } else {
      if (documentType === "sales_return") {
        const salesReturnHeaders = [...defaultHeaders];
        salesReturnHeaders.splice(2, 0, {
          thaiLabel: "จำนวนขาย",
          label: "Sales Qty",
          width: 100,
          align: "right",
        });
        arrayMove(salesReturnHeaders, 10, 5);
        setHeaders(salesReturnHeaders);
      } else if (documentType === "sales_order") {
        setHeaders([
          ...defaultHeaders,
          {
            thaiLabel: "สั่งผลิต",
            label: "Qty. Mfg",
            width: 100,
            align: "right",
          },
          {
            thaiLabel: "นำออกแล้ว",
            label: "Qty. Issued",
            width: 100,
            align: "right",
          },
          // {
          //   thaiLabel: "รอส่ง",
          //   label: "Qty. to Ship",
          //   width: 100,
          //   align: "right",
          // },
          {
            thaiLabel: "ส่งแล้ว",
            label: "Qty. Shipped",
            width: 100,
            align: "right",
          },
          // {
          //   thaiLabel: "แจ้งหนี้แล้ว",
          //   label: "Qty. Invoiced",
          //   width: 100,
          // },
          // {
          //   thaiLabel: "คืนของ",
          //   label: "Qty. Returned",
          //   width: 100,
          //   align: "right",
          // },
        ]);
      } else {
        setHeaders(defaultHeaders);
      }
    }
  }, [disabled, documentType]);

  return headers;
};
