import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";

const ManufactureReport = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("report"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    // {
    //   name: t("manufacture.bom.index"),
    //   to: "/manufacture/report/bom",
    // },
    {
      name: t("manufacture.order.index"),
      to: "/manufacture/report/order",
    },
    {
      name: t("manufacture.sentence.process_report"),
      to: "/manufacture/report/process",
    },
    {
      name: t("manufacture.sentence.ingredient_report"),
      to: "/manufacture/report/ingredient",
    },
    {
      name: t("manufacture.sentence.waste_report"),
      to: "/manufacture/report/waste",
    },
    // {
    //   name: t("manufacture.workOrder.index"),
    //   to: "/manufacture/work-order",
    // },
    // {
    //   name: t("manufacture.bom.under_quantity_item_list"),
    //   to: "/manufacture/report/under-quality",
    // },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default ManufactureReport;
