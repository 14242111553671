import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SalesOrderHeader from "../../../components/Form/Sales/Order/Header";
import CustomerInfoForm from "../../../components/Form/CustomerInfo/CustomerInfoForm";
import SalesItemList from "../../../components/Table/Sales/ItemList/SalesItemList";
import SalesFooter from "../../../components/Form/Sales/Footer";
import PriceVatType from "../../../components/Form/Sales/PriceVatType";
import { ISalesOrder } from "../../../types/Sales/salesOrder";
import {
  salesOrderSchema,
  salesOrderValidation,
} from "../../../components/Form/Sales/Order/schema";
import { EntityTypeEnum } from "../../../generated/creatable";
import {
  ActivityType,
  ApprovalAction,
  SalesActivityLogDocumentType,
  SalesApprovalTemplatesQuery,
  SalesDocumentType,
  SalesOrderCreateInput,
  SalesOrderQuery,
  SalesOrderUpdateInput,
  Status,
  useApproveWorkflowMutation,
  useSalesApprovalTemplatesQuery,
  useSalesDocumentNextStatusMutation,
  useSalesOrderCreateMutation,
  useSalesOrderQuery,
  useSalesOrderUpdateMutation,
} from "../../../generated/sales";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import {
  salesOrderCreatePayloadFormatter,
  salesOrderQueryFormatter,
  salesOrderUpdatePayloadFormatter,
  salesOrderFromOCRFormatter,
} from "../../../utils/Formatter/SalesOrder";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useSalesError } from "../../../hooks/Sales/use-sales-error";
import { yupResolver } from "@hookform/resolvers/yup";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { useActivityLogCreate } from "../../../hooks/use-global-activity-log";
import { useStateContext } from "../../../contexts/auth-context";
import { ICreatedBy } from "../../../types/global";
import { useDisable } from "../../../hooks/use-disable";
import SalesOrderAttachment from "../../../components/Form/Sales/Order/Attachment";
import { useOnLeavePage } from "../../../hooks/use-on-leave-page";
import LoadingMascot from "../../../components/UI/LoadingMascot";
import dayjs from "dayjs";

const DocumentInfoTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useDisable();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editAttachment, setEditAttachment] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [allLoading, setAllLoading] = useState<boolean>(false);

  const methods = useForm<ISalesOrder>({
    defaultValues: salesOrderSchema,
    resolver: yupResolver(salesOrderValidation),
    mode: "onChange",
  });

  const {
    control,
    setValue,
    formState: { errors, dirtyFields },
    getValues,
    reset,
    resetField,
    handleSubmit,
  } = methods;

  const [approvalTemplate, setApprovalTemplate] = useState<any[]>([]);

  const {
    state: { authUser },
  } = useStateContext();

  const currentUser: ICreatedBy = {
    user_unique_id: authUser?.unique_id || "",
    email: authUser?.email || "",
    first_name: authUser?.first_name || "",
    last_name: authUser?.last_name,
    img_url: authUser?.img_url,
  };

  const { salesActivityLogCreate } = useActivityLogCreate();

  const createdBy = useWatch({ control, name: "created_by" });

  const relatedUserList = useWatch({ control, name: "related_user_list" });

  useRoleSelfPermission(createdBy, relatedUserList);

  const isAttachmentDirty = Array.isArray(dirtyFields?.attachment_list)
    ? dirtyFields.attachment_list.some((attachment) =>
      Object.values(attachment).some(
        (value) =>
          typeof value === "object"
            ? Object.values(value).some(Boolean) // Check nested objects
            : Boolean(value) // Check top-level fields
      )
    )
    : Boolean(dirtyFields?.attachment_list);

  const { allowNextNavigation } = useOnLeavePage(isAttachmentDirty);

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { data: approvalTemplates } =
    useSalesApprovalTemplatesQuery<SalesApprovalTemplatesQuery>(
      graphQLClientWithHeaderSales
    );
  useEffect(() => {
    if (
      approvalTemplates &&
      approvalTemplates.approvalTemplates &&
      approvalTemplates.approvalTemplates.length > 0
    ) {
      setApprovalTemplate(approvalTemplates.approvalTemplates);
    } else {
      setApprovalTemplate([]);
    }
  }, [approvalTemplates]);
  const { mutateAsync: approveWorkflow } = useApproveWorkflowMutation<Error>(
    graphQLClientWithHeaderSales
  );

  const { data, isLoading, isSuccess, refetch } =
    useSalesOrderQuery<SalesOrderQuery>(
      graphQLClientWithHeaderSales,
      {
        uniqueInput: {
          unique_id: id,
        },
      },
      {
        enabled: !!id,
      }
    );

  const { isLoading: isCreating, mutateAsync: create } =
    useSalesOrderCreateMutation<Error>(graphQLClientWithHeaderSales);
  // useSalesOrderCreateMutation<Error>(graphQLClientWithHeaderSales, {
  //   onSuccess: ({ salesOrderCreate }) => {
  //     if (salesOrderCreate) {
  //       if (salesOrderCreate.sub_status === "wait_approve") {
  //         updateStatus({
  //           documentInput: {
  //             reference_document_type: SalesDocumentType.SalesOrder,
  //             unique_id: salesOrderCreate.unique_id,
  //           },
  //         });
  //       }
  //       navigate(`/sales/order/${salesOrderCreate?.unique_id}`);
  //       enqueueSnackbar("สร้างใบสั่งขายสำเร็จ", {
  //         variant: "success",
  //       });
  //     }
  //     // if (salesOrderCreate.sub_status === "approved") {
  //     //   updateStatus({
  //     //     documentInput: {
  //     //       reference_document_type: SalesDocumentType.SalesOrder,
  //     //       unique_id: salesOrderCreate.unique_id,
  //     //     },
  //     //   });
  //     // }
  //     // navigate(`/sales/order/${salesOrderCreate?.unique_id}`);
  //     // enqueueSnackbar("สร้างใบสั่งขายสำเร็จ", {
  //     //   variant: "success",
  //     // });
  //   },
  //   onError: (err) => {
  //     const duplicatedUniqueId = errorMessageFormatter(err);
  //     if (duplicatedUniqueId) {
  //       enqueueSnackbar(duplicatedUniqueId, {
  //         variant: "error",
  //       });
  //     } else {
  //       enqueueSnackbar("สร้างใบสั่งขายไม่สำเร็จ", {
  //         variant: "error",
  //       });
  //     }
  //   },
  // });

  const { isLoading: isUpdating, mutateAsync: update } =
    useSalesOrderUpdateMutation<Error>(graphQLClientWithHeaderSales);

  const { isLoading: isChanging, mutateAsync: updateStatus } =
    useSalesDocumentNextStatusMutation<Error>(graphQLClientWithHeaderSales);

  useEffect(() => {
    if (isSuccess) {
      const { salesOrder } = data;

      const getSalesOrderData = async () => {
        const salesOrderType = salesOrder as ISalesOrder;
        const formattedSalesOrder = await salesOrderQueryFormatter(
          salesOrderType
        );
        reset(formattedSalesOrder);
      };
      getSalesOrderData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (state && !state.is_ocr_create) {
      const { copied_id, copied_unique_id, reference_id_list, ...otherState } =
        state;
      reset({
        ...otherState,
        issue_date: dayjs(),
        due_date: dayjs().add(7, "day"),
        delivery_date: dayjs().add(7, "day"),
      });
    }
    if (state && state.is_ocr_create) {
      const formatted = salesOrderFromOCRFormatter(state);
      reset(formatted);
    }
  }, [reset, state]);

  useEffect(() => {
    if (
      !isLoading &&
      id &&
      data?.salesOrder?.aggrid_status !== "draft" &&
      !isLoading &&
      id &&
      data?.salesOrder?.aggrid_status !== "not_approved"
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [data?.salesOrder?.aggrid_status, id, isLoading, setDisabled]);

  const onSalesOrderCreate = async (data: ISalesOrder, status: string) => {
    try {
      setAllLoading(true);
      const payload = (await salesOrderCreatePayloadFormatter(
        data,
        status
      )) as SalesOrderCreateInput;
      const { salesOrderCreate } = await create({
        createInput: payload,
      });

      if (
        state &&
        !state.is_ocr_create &&
        state.reference_id_list &&
        state.reference_id_list.length > 0
      ) {
        await salesActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: SalesActivityLogDocumentType.Quotation,
          reference_id: state.reference_id_list[0],
          activity_detail: {
            curr_status: Status.Finished,
          },
        });
      }

      if (salesOrderCreate.sub_status === "wait_approve") {
        await updateStatus({
          documentInput: {
            reference_document_type: SalesDocumentType.SalesOrder,
            unique_id: salesOrderCreate.unique_id,
          },
        });
        try {
          await salesActivityLogCreate({
            activity_type: ActivityType.Create,
            document_type: SalesActivityLogDocumentType.SalesOrder,
            reference_id: salesOrderCreate.id,
            activity_detail: {},
          });
          if (state && state.copied_id) {
            await salesActivityLogCreate({
              activity_type: ActivityType.Copy,
              document_type: SalesActivityLogDocumentType.SalesOrder,
              reference_id: salesOrderCreate.id,
              activity_detail: {
                copied_from: {
                  id: state.copied_id,
                  unique_id: state.copied_unique_id,
                },
                copied_to: {
                  id: salesOrderCreate.id,
                  unique_id: salesOrderCreate.unique_id,
                },
              },
            });
          }
          await salesActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: SalesActivityLogDocumentType.SalesOrder,
            reference_id: salesOrderCreate.id,
            activity_detail: {
              curr_status: Status.WaitApprove,
            },
          });
        } catch (err) {
          console.error("activity log error", err);
        }
      } else if (salesOrderCreate.sub_status === "approved") {
        await updateStatus({
          documentInput: {
            reference_document_type: SalesDocumentType.SalesOrder,
            unique_id: salesOrderCreate.unique_id,
          },
        });
        try {
          await salesActivityLogCreate({
            activity_type: ActivityType.Create,
            document_type: SalesActivityLogDocumentType.SalesOrder,
            reference_id: salesOrderCreate.id,
            activity_detail: {},
          });
          if (state && state.copied_id) {
            await salesActivityLogCreate({
              activity_type: ActivityType.Copy,
              document_type: SalesActivityLogDocumentType.SalesOrder,
              reference_id: salesOrderCreate.id,
              activity_detail: {
                copied_from: {
                  id: state.copied_id,
                  unique_id: state.copied_unique_id,
                },
                copied_to: {
                  id: salesOrderCreate.id,
                  unique_id: salesOrderCreate.unique_id,
                },
              },
            });
          }
          await salesActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: SalesActivityLogDocumentType.SalesOrder,
            reference_id: salesOrderCreate.id,
            activity_detail: {
              curr_status: Status.Approved,
            },
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          await salesActivityLogCreate({
            activity_type: ActivityType.Create,
            document_type: SalesActivityLogDocumentType.SalesOrder,
            reference_id: salesOrderCreate.id,
            activity_detail: {},
          });

          if (state && state.copied_id) {
            await salesActivityLogCreate({
              activity_type: ActivityType.Copy,
              document_type: SalesActivityLogDocumentType.SalesOrder,
              reference_id: salesOrderCreate.id,
              activity_detail: {
                copied_from: {
                  id: state.copied_id,
                  unique_id: state.copied_unique_id,
                },
                copied_to: {
                  id: salesOrderCreate.id,
                  unique_id: salesOrderCreate.unique_id,
                },
              },
            });
          }

          await salesActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: SalesActivityLogDocumentType.SalesOrder,
            reference_id: salesOrderCreate.id,
            activity_detail: {
              curr_status: Status.Draft,
            },
          });
        } catch (err) {
          console.error("activity log error", err);
        }
      }

      setAllLoading(false);

      allowNextNavigation();

      navigate(`/sales/order/${salesOrderCreate.unique_id}`);
      enqueueSnackbar("สร้างใบสั่งขายสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      setAllLoading(false);
      const duplicatedUniqueId = errorMessageFormatter(err);
      if (duplicatedUniqueId) {
        enqueueSnackbar(duplicatedUniqueId, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("สร้างใบสั่งขายไม่สำเร็จ", {
          variant: "error",
        });
      }
    }
  };

  const onSalesOrderUpdate = async (data: ISalesOrder, status: string) => {
    try {
      setAllLoading(true);

      const payload = (await salesOrderUpdatePayloadFormatter(
        data,
        status
      )) as SalesOrderUpdateInput;
      const { salesOrderUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });

      const hasDirty = (dirtyFields: {}) => {
        if (dirtyFields === true) return true; // Base case: found a true value
        if (Array.isArray(dirtyFields)) {
          // Check all items in the array
          return dirtyFields.some(hasDirty);
        }
        if (typeof dirtyFields === "object" && dirtyFields !== null) {
          // Check all values in the object
          return Object.values(dirtyFields).some(hasDirty);
        }
        return false; // Base case: no true value found
      };

      const changedData = hasDirty(dirtyFields);

      if (changedData) {
        try {
          await salesActivityLogCreate({
            activity_type: ActivityType.Edit,
            document_type: SalesActivityLogDocumentType.SalesOrder,
            reference_id: salesOrderUpdate.id,
            activity_detail: {},
          });
        } catch (error) {
          console.error("activity log error", error);
        }
      }
      setAllLoading(false);

      enqueueSnackbar(`แก้ไขใบสั่งขายสำเร็จ`, {
        variant: "success",
      });

      await refetch();
    } catch (err) {
      setAllLoading(false);

      const duplicatedUniqueId = errorMessageFormatter(err);
      if (duplicatedUniqueId) {
        enqueueSnackbar(duplicatedUniqueId, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`แก้ไขใบสั่งขายไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const onSalesOrderSendApprove = async (
    data: ISalesOrder,
    isResend: boolean = false
  ) => {
    try {
      setAllLoading(true);

      if (data) {
        const payload = (await salesOrderUpdatePayloadFormatter(
          data,
          "wait_approve"
        )) as SalesOrderUpdateInput;

        const isHaveApproval = approvalTemplates?.approvalTemplates?.some(
          (template: any) =>
            template.document_type === "sales_order" &&
            template.is_have_approval === true
        );

        if (!isHaveApproval) {
          const approvedPayload = (await salesOrderUpdatePayloadFormatter(
            data,
            "approved"
          )) as SalesOrderUpdateInput;

          if (!id) {
            await onSalesOrderCreate(data, "approved");
          } else {
            const { salesOrderUpdate } = await update({
              uniqueInput: {
                unique_id: id,
              },
              updateInput: approvedPayload,
            });
            await updateStatus({
              documentInput: {
                reference_document_type: SalesDocumentType.SalesOrder,
                unique_id: data.unique_id,
              },
            });
            try {
              await salesActivityLogCreate({
                activity_type: ActivityType.StatusChange,
                document_type: SalesActivityLogDocumentType.SalesOrder,
                reference_id: salesOrderUpdate.id,
                activity_detail: {
                  curr_status: Status.Approved,
                },
              });
            } catch (error) {
              console.error(error);
            }
            await refetch();
          }
        } else {
          if (!id) {
            await onSalesOrderCreate(data, "wait_approve");
          } else {
            const { salesOrderUpdate } = await update({
              uniqueInput: {
                unique_id: id,
              },
              updateInput: payload,
            });
            if (!isResend) {
              await updateStatus({
                documentInput: {
                  reference_document_type: SalesDocumentType.SalesOrder,
                  unique_id: data.unique_id,
                },
              });
              try {
                await salesActivityLogCreate({
                  activity_type: ActivityType.StatusChange,
                  document_type: SalesActivityLogDocumentType.SalesOrder,
                  reference_id: salesOrderUpdate.id,
                  activity_detail: {
                    curr_status: Status.WaitApprove,
                  },
                });
              } catch (err) {
                console.error("activity log error", err);
              }

              enqueueSnackbar(`ส่งอนุมัติใบสั่งขายสำเร็จ`, {
                variant: "success",
              });
            } else {
              try {
                await salesActivityLogCreate({
                  activity_type: ActivityType.StatusChange,
                  document_type: SalesActivityLogDocumentType.SalesOrder,
                  reference_id: salesOrderUpdate.id,
                  activity_detail: {
                    curr_status: Status.WaitApprove,
                  },
                });
              } catch (error) {
                console.log(error);
              }

              enqueueSnackbar(`รออนุมัติใบสั่งขายสำเร็จ`, {
                variant: "success",
              });
            }

            await refetch();
          }
        }
        setAllLoading(false);
      }
    } catch (err) {
      setAllLoading(false);

      if (!isResend) {
        enqueueSnackbar(`ส่งอนุมัติใบสั่งขายไม่สำเร็จ`, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`รออนุมัติใบสั่งขายไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const onSalesOrderNotApprove = async (data: ISalesOrder) => {
    try {
      setAllLoading(true);

      const payload = (await salesOrderUpdatePayloadFormatter(
        data,
        "wait_approve",
        true
      )) as SalesOrderUpdateInput;
      const { salesOrderUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      const reviewerUniqueId = currentUser.user_unique_id;
      await approveWorkflow({
        document: {
          reference_document_type: SalesDocumentType.SalesOrder,
          unique_id: data.unique_id,
        },
        reviewerUniqueId: reviewerUniqueId || "",
        approvedAction: ApprovalAction.Ejected,
      });
      try {
        await salesActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: SalesActivityLogDocumentType.SalesOrder,
          reference_id: salesOrderUpdate.id,
          activity_detail: {
            curr_status: Status.NotApproved,
          },
        });
      } catch (err) {
        console.error("activity log error", err);
      }

      setAllLoading(false);

      enqueueSnackbar(`ไม่อนุมัติใบสั่งขายสำเร็จ`, {
        variant: "success",
      });

      await refetch();
    } catch (err) {
      setAllLoading(false);

      enqueueSnackbar(`ไม่อนุมัติใบสั่งขายไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onSalesOrderApprove = async (data: ISalesOrder) => {
    try {
      setAllLoading(true);

      const payload = (await salesOrderUpdatePayloadFormatter(
        data,
        "approved"
      )) as SalesOrderUpdateInput;
      const { salesOrderUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      const reviewerUniqueId = currentUser.user_unique_id;
      await approveWorkflow({
        document: {
          reference_document_type: SalesDocumentType.SalesOrder,
          unique_id: data.unique_id,
        },
        reviewerUniqueId: reviewerUniqueId || "",
        approvedAction: ApprovalAction.Approved,
      });
      await updateStatus({
        documentInput: {
          reference_document_type: SalesDocumentType.SalesOrder,
          unique_id: data.unique_id,
        },
      });
      try {
        await salesActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: SalesActivityLogDocumentType.SalesOrder,
          reference_id: salesOrderUpdate.id,
          activity_detail: {
            curr_status: Status.Approved,
          },
        });
      } catch (error) {
        console.error("activity log error", error);
      }

      setAllLoading(false);

      enqueueSnackbar(`อนุมัติใบสั่งขายสำเร็จ`, {
        variant: "success",
      });

      await refetch();
    } catch (err) {
      setAllLoading(false);

      enqueueSnackbar(`อนุมัติใบสั่งขายไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onSalesOrderFinish = async (data: ISalesOrder) => {
    try {
      setAllLoading(true);

      const payload = (await salesOrderUpdatePayloadFormatter(
        data,
        "finished"
      )) as SalesOrderUpdateInput;
      const { salesOrderUpdate } = await update({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
      await updateStatus({
        documentInput: {
          reference_document_type: SalesDocumentType.SalesOrder,
          unique_id: data.unique_id,
        },
      });

      try {
        await salesActivityLogCreate({
          activity_type: ActivityType.StatusChange,
          document_type: SalesActivityLogDocumentType.SalesOrder,
          reference_id: salesOrderUpdate.id,
          activity_detail: {
            curr_status: Status.Finished,
          },
        });
      } catch (err) {
        console.log(err);
      }
      setAllLoading(false);

      enqueueSnackbar(`เสร็จสิ้นใบสั่งขายสำเร็จ`, {
        variant: "success",
      });

      await refetch();
    } catch (err) {
      setAllLoading(false);

      enqueueSnackbar(`เสร็จสิ้นใบสั่งขายไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
    setIsEdit(true);
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    setIsEdit(false);
    setEditAttachment(false);
    reset();
  };

  const onSalesOrderEditHandler = async (data: ISalesOrder) => {
    setDisabled(true);
    setIsEdit(false);
    setEditAttachment(false);
    await onSalesOrderUpdate(data, data.sub_status ? data.sub_status : "");
  };

  const isUserAuthorized = approvalTemplate.some(
    (template) =>
      template.document_type === "sales_order" &&
      template.approval_detail_list.some(
        (detail: { approver_list: { unique_id: string }[] }) =>
          detail.approver_list.some(
            (approver: { unique_id: string }) =>
              approver.unique_id === currentUser.user_unique_id
          )
      ) &&
      template.is_have_approval === true
  );

  const renderButton = () => {
    switch (data?.salesOrder?.aggrid_status) {
      case "draft":
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isUpdating}
              onClick={handleSubmit((data) =>
                onSalesOrderUpdate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit((data) => onSalesOrderSendApprove(data))}
              disabled={isChanging}
            />
          </Stack>
        );
      case "wait_approve":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onSalesOrderEditHandler)}
              />
            </Stack>
          );
        } else {
          return (
            isUserAuthorized && (
              <Stack direction="row" spacing={1} alignItems="center">
                <CustomizedButton
                  variant="outlined"
                  title={t("button.not_approve")}
                  disabled={isUpdating}
                  onClick={handleSubmit(onSalesOrderNotApprove)}
                />
                <CustomizedButton
                  title={t("button.approve")}
                  variant="contained"
                  onClick={handleSubmit(onSalesOrderApprove)}
                  disabled={isChanging}
                />
              </Stack>
            )
          );
        }
      case "approved":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onSalesOrderEditHandler)}
              />
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                title={t("button.finish")}
                variant="contained"
                onClick={handleSubmit(onSalesOrderFinish)}
                disabled={isChanging}
              />
            </Stack>
          );
        }

      case "not_approved":
        if (isEdit) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onSalesOrderEditHandler)}
              />
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.save")}
                onClick={handleSubmit((data) =>
                  onSalesOrderUpdate(data, "not_approved")
                )}
              />
              <CustomizedButton
                title={t("button.wait_approve")}
                variant="contained"
                onClick={handleSubmit((data) =>
                  onSalesOrderSendApprove(data, true)
                )}
              />
            </Stack>
          );
        }
      case "cancelled":
      case "finished":
        if (isEdit || editAttachment) {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isCreating}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(onSalesOrderEditHandler)}
              />
            </Stack>
          );
        }
        return;
      default:
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating}
              onClick={handleSubmit((data) =>
                onSalesOrderCreate(data, "draft")
              )}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit((data) => onSalesOrderSendApprove(data))}
            />
          </Stack>
        );
    }
  };

  useSalesError(errors);

  if (id && isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <LoadingMascot isLoading={allLoading} />
      <SalesOrderHeader
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
        refetch={refetch}
        editClickHandler={editClickHandler}
        update={update}
        updateStatus={updateStatus}
        handleSubmit={handleSubmit}
        setEditAttachment={setEditAttachment}
        taxInvoiceNumber={data?.salesOrder?.tax_invoice_number}
      />
      <CustomerInfoForm
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
        reset={reset}
        documentType={EntityTypeEnum.SalesOrder}
      />
      <PriceVatType control={control} errors={errors} disabled={disabled} />
      <SalesItemList
        documentType={EntityTypeEnum.SalesOrder}
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={disabled}
        getValues={getValues}
      />
      <SalesFooter
        control={control}
        errors={errors}
        disabled={disabled}
        setValue={setValue}
        resetField={resetField}
        documentType={EntityTypeEnum.SalesOrder}
      />
      <SalesOrderAttachment
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={
          (!editAttachment && disabled) ||
          data?.salesOrder?.aggrid_status === "cancelled"
        }
        getValues={getValues}
      />
      <Box sx={{ mt: "4rem" }} />
      {(!["cancelled", "finished"].includes(
        data?.salesOrder?.aggrid_status || ""
      ) ||
        (["cancelled", "finished"].includes(
          data?.salesOrder?.aggrid_status || ""
        ) &&
          (isEdit || editAttachment))) && (
        <BottomNavbar>{renderButton()}</BottomNavbar>
      )}
    </FormProvider>
  );
};

export default DocumentInfoTab;
