import { Box, Collapse, Grid, SxProps, Theme, Typography } from "@mui/material";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import { CalendarIcon } from "@mui/x-date-pickers-pro";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IDefaultForm, ISelectOption } from "../../../types/global";
import dayjs from "dayjs";
import GanttChartStatus, { GanttStatus } from "./GanttChartStatus";
import ControlledTextField from "../../Controller/ControlledTextField";
import CustomizedButton from "../../Custom/CustomizedButton";
import { useEffect, useState } from "react";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ControlledSetSelect from "../../Controller/ControlledSetSelect";

import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  CreatablesQuery,
  EntityTypeEnum,
  useCreatablesQuery,
} from "../../../generated/creatable";
import {
  ManufactureTagsFindByEntityQuery,
  useManufactureTagsFindByEntityQuery,
} from "../../../generated/manufacture";
import { UsersQuery, useUsersQuery } from "../../../generated/company-user";
import {
  UserInformationsQuery,
  useUserInformationsQuery,
} from "../../../generated/user-infomation";
import { mergeUsersFormatter } from "../../../utils/Formatter/User";
import { IUser, IUserInfo } from "../../../types/Auth/user";

interface GanttChartFilterProps {
  startDate: any;
  endDate: any;
  control: IDefaultForm["control"];
  setValue: IDefaultForm["setValue"];
  ganttCollapsed: boolean;
  setGanttCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  handleDateChange: (direction: "next" | "prev") => void;
  handleFilterCilck: () => void;
  handleClearFilterClick: () => void;
  handleToggleExpand: () => void;
  ganttStatus: GanttStatus;
  sx?: SxProps<Theme>;
}

const GanttChartFilter = ({
  startDate,
  endDate,
  control,
  setValue,
  ganttCollapsed,
  setGanttCollapsed,
  handleDateChange,
  handleFilterCilck,
  handleClearFilterClick,
  handleToggleExpand,
  ganttStatus,
  sx,
}: GanttChartFilterProps) => {
  const formattedMonth =
    startDate.month() !== endDate.month()
      ? startDate.year() !== endDate.year()
        ? `${startDate.format("MMMM YYYY")} - ${endDate.format("MMMM YYYY")}`
        : `${startDate.format("MMMM")} - ${endDate.format("MMMM YYYY")}`
      : startDate.format("MMMM YYYY");

  const [collapsed, setCollapsed] = useState(false);
  const [typeOptions, setTypeOptions] = useState<ISelectOption[]>([]);
  const [tagOptions, setTagOptions] = useState<ISelectOption[]>([]);
  const [userOptions, setUserOptions] = useState<ISelectOption[]>([]);

  const graphQLClientWithHeaderSetting =
    createGraphQLClientWithMiddleware("general");
  const graphQLClientWithHeaderManufacture =
    createGraphQLClientWithMiddleware("manufacture");
  const graphQLClientWithHeaderCompany =
    createGraphQLClientWithMiddleware("company-user");

  const { data: userData, isSuccess: isSuccessUser } =
    useUsersQuery<UsersQuery>(graphQLClientWithHeaderCompany);
  const { data: userInformationData, isSuccess: isSuccessUserInformation } =
    useUserInformationsQuery<UserInformationsQuery>(
      graphQLClientWithHeaderSetting
    );
  const { data: typeData, isSuccess: isSuccessType } =
    useCreatablesQuery<CreatablesQuery>(graphQLClientWithHeaderSetting, {
      filterInput: {
        usage_field_name: "manufacture_type",
        usage_field_type: EntityTypeEnum.ManufactureOrder,
      },
    });
  const { data: tagListData, isSuccess: isSuccessTagList } =
    useManufactureTagsFindByEntityQuery<ManufactureTagsFindByEntityQuery>(
      graphQLClientWithHeaderManufacture,
      { entityName: EntityTypeEnum.ManufactureOrder as any }
    );

  useEffect(() => {
    if (isSuccessType) {
      setTypeOptions(
        typeData.creatables?.map((option) => ({
          value: option.name,
          label: option.name,
        })) || []
      );
    }
  }, [isSuccessType, typeData]);

  useEffect(() => {
    if (isSuccessTagList) {
      setTagOptions(
        tagListData.manufactureTagsFindByEntity?.map((tag) => ({
          value: tag.name,
          label: tag.name,
        })) || []
      );
    }
  }, [isSuccessTagList, tagListData]);

  useEffect(() => {
    if (isSuccessUser && isSuccessUserInformation) {
      const mergeData = mergeUsersFormatter(
        userData.users as IUser[],
        userInformationData.userInformations as IUserInfo[]
      );
      setUserOptions(
        mergeData.map((user) => ({
          value: user.unique_id,
          label: `${user?.first_name ?? ""} ${user?.last_name ?? ""}`,
        }))
      );
    }
  }, [isSuccessUser, isSuccessUserInformation, userData, userInformationData]);

  return (
    <Grid container direction="column" spacing={"16px"} sx={sx}>
      <Grid item container justifyContent="flex-end">
        <Box
          onClick={() => setCollapsed(!collapsed)}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            cursor: "pointer",
            userSelect: "none",
          }}
        >
          <Typography color="#2167D3" fontSize={14} fontWeight={600}>
            ตัวกรอง
          </Typography>
          {collapsed ? (
            <KeyboardArrowUpOutlinedIcon
              sx={{ fontSize: "16px", color: "#2167D3" }}
            />
          ) : (
            <KeyboardArrowDownOutlinedIcon
              sx={{ fontSize: "16px", color: "#2167D3" }}
            />
          )}
        </Box>
      </Grid>

      <Grid item>
        <Collapse in={collapsed}>
          <Box
            sx={{
              p: 2,
              bgcolor: "#F9F9F9",
              borderRadius: "8px",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} container spacing={"16px"}>
                <Grid item xs={6} md={3}>
                  <ControlledTextField
                    name="item_unique_id"
                    label="รหัสสินค้า"
                    control={control}
                    sx={{
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                      height: "37px",
                    }}
                    InputLabelProps={{ style: { fontSize: "14px" } }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <ControlledTextField
                    name="item_name"
                    label="ชื่อสินค้า"
                    control={control}
                    sx={{
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                      height: "37px",
                    }}
                    InputLabelProps={{ style: { fontSize: "14px" } }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <ControlledTextField
                    name="customer_name"
                    label="ชื่อลูกค้า"
                    control={control}
                    sx={{
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                      height: "37px",
                    }}
                    InputLabelProps={{ style: { fontSize: "14px" } }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <ControlledSetSelect
                    name="related_user_list"
                    label="ผู้เกี่ยวข้อง"
                    control={control}
                    options={userOptions}
                    sx={{
                      "& .MuiFormControl-root": { top: "-10px" },
                      borderRadius: "8px",
                      height: "37px",
                    }}
                    InputLabelProps={{ fontSize: "14px" }}
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <ControlledSetSelect
                    name="type"
                    label="ประเภทการผลิต"
                    control={control}
                    options={typeOptions}
                    sx={{
                      "& .MuiFormControl-root": { top: "-10px" },
                      borderRadius: "8px",
                      height: "37px",
                    }}
                    InputLabelProps={{ fontSize: "14px" }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <ControlledSetSelect
                    name="tag_list"
                    label="การจัดกลุ่ม"
                    control={control}
                    options={tagOptions}
                    sx={{
                      "& .MuiFormControl-root": { top: "-10px" },
                      borderRadius: "8px",
                      height: "37px",
                    }}
                    InputLabelProps={{ fontSize: "14px" }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <ControlledTextField
                    name="reference_unique_id"
                    label="เอกสารอ้างอิง"
                    control={control}
                    sx={{
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                      height: "37px",
                    }}
                    InputLabelProps={{ style: { fontSize: "14px" } }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <ControlledSetSelect
                    name="status"
                    label="สถานะ"
                    control={control}
                    options={[
                      { label: "ร่าง", value: "draft" },
                      { label: "รอผลิต", value: "pending_manu" },
                      { label: "กำลังผลิต", value: "in_progress" },
                      { label: "เสร็จสิ้น", value: "finished" },
                      { label: "ยกเลิก", value: "cancelled" },
                    ]}
                    sx={{
                      "& .MuiFormControl-root": { top: "-10px" },
                      borderRadius: "8px",
                      height: "37px",
                    }}
                    InputLabelProps={{ fontSize: "14px" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{ display: "flex", justifyContent: "end", gap: "8px" }}
                  >
                    <CustomizedButton
                      title="ล้างค่า"
                      variant="text"
                      onClick={handleClearFilterClick}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        height: "37px",
                      }}
                    />
                    <CustomizedButton
                      title="กรองข้อมูล"
                      variant="contained"
                      onClick={handleFilterCilck}
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "600",
                        height: "37px",
                        width: "150px",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Grid>

      <Grid item>
        <GanttChartStatus data={ganttStatus} />
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        spacing={1}
        pt={{ md: "0px !important" }}
      >
        <Grid item xs={12} md={4} container alignItems="center" spacing={1.25}>
          <Grid item sx={{ flex: { xs: 1, md: 0 } }}>
            <ControlledDatePicker
              control={control}
              name="selectedDate"
              label="วันที่"
              sx={{
                "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                width: { xs: "100%", md: "200px !important" },
              }}
            />
          </Grid>

          <Grid item alignItems="center">
            <Box
              onClick={() => setValue("selectedDate", dayjs().toDate())}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                pt: "6px",
                gap: "4px",
                cursor: "pointer",
                userSelect: "none",
              }}
            >
              <CalendarIcon sx={{ color: "#2167D3" }} />
              <Typography color="#2167D3" fontSize={16}>
                วันนี้
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          item
          xs={8}
          md={4}
          container
          justifyContent={{ xs: "flex-start", md: "center" }}
          alignItems="center"
          sx={{ mt: { xs: 1, md: 0 } }}
        >
          <KeyboardArrowLeftIcon
            sx={{ color: "#2167D3", fontSize: 26, cursor: "pointer" }}
            onClick={() => handleDateChange("prev")}
          />
          <Typography
            sx={{ fontSize: 16, fontWeight: 600, color: "#2167D3", px: 1.5 }}
          >
            {formattedMonth}
          </Typography>
          <KeyboardArrowRightIcon
            sx={{ color: "#2167D3", fontSize: 26, cursor: "pointer" }}
            onClick={() => handleDateChange("next")}
          />
        </Grid>

        <Grid
          item
          xs={4}
          md={4}
          container
          justifyContent="end"
          sx={{ mt: { xs: 1, md: 0 } }}
        >
          <Box
            onClick={() => {
              setGanttCollapsed(!ganttCollapsed);
              handleToggleExpand();
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            {ganttCollapsed ? (
              <KeyboardArrowUpOutlinedIcon
                sx={{ fontSize: "16px", color: "#2167D3" }}
              />
            ) : (
              <KeyboardArrowDownOutlinedIcon
                sx={{ fontSize: "16px", color: "#2167D3" }}
              />
            )}
            <Typography color="#2167D3" fontSize={14} fontWeight={400}>
              {ganttCollapsed ? "ขยาย" : "ย่อ"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GanttChartFilter;
